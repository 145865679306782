import React from 'react';
import {
    BrowserRouter as Router,
    Route,
    Switch,
    Redirect,
} from 'react-router-dom';
import { remote, UserContextModule } from '@boyuai/utils';
import errorReport from '@boyuai/error-report';
import EmailValidation from '@boyuai/email-validation';
import Header from './components/header';
import Home from './pages/home';
import Course from './pages/course';
import Admin from './pages/admin';
import GiftCard from './pages/gift-card';
import ErrorPage from './pages/error';
import MobileTip from './assets/images/mobile-tip.png';
import MobileTipComputer from './assets/images/mobile-tip-computer.png';
import Register from './pages/user/register';
import Login from './pages/user/login';
import HomeHeader from './pages/home/header';
import UserCenter from './pages/user';
import Activity from './components/activity';
// import Advertisement from './components/advertisement';
import DailyCreditHelper from '@boyuai/daily-credit-helper';
import RecallReward from './components/recall-reward';
import { isMobile } from './utils';

// 一些复用比较多的 css
import '@boyuai/comment/dist/index.css';
import '@boyuai/fuwuhao-bind-modal/dist/index.css';
import '@boyuai/header/dist/index.css';
import '@boyuai/user-info/dist/index.css'; // 嵌在 header 中的 user info 样式
import '@boyuai/currency-intro/dist/index.css';
import '@boyuai/email-validation/dist/index.css';
import '@boyuai/recharge/dist/index.css';
import '@boyuai/search-course/dist/index.css';
import '@boyuai/captcha/dist/index.css';
import './App.less';
import OJ from './pages/oj/index';

const baseName = process.env.REACT_APP_SUB_DIRECTORY;

const { UserContext, user } = UserContextModule;

const isElite = process.env.REACT_APP_SITE === 'elites';
const isCourseIntroPage = (() => {
    const pathName = window.location.pathname;
    let num = 0;
    pathName.split('').forEach(item => {
        if (item === '/') {
            num++;
        }
    });
    const pathBeginName =
        process.env.NODE_ENV !== 'production' ? '/course' : '/elites/course';
    const targetSplitNum = process.env.NODE_ENV !== 'production' ? 2 : 3;
    return (
        pathName.indexOf(pathBeginName) === 0 &&
        (num === targetSplitNum ||
            (num === targetSplitNum + 1 &&
                pathName.lastIndexOf('/') === pathName.length - 1))
    );
})();

const BlankLayout = ({ children }) => {
    return <div id="layout">{children}</div>;
};

function RouteWrapper({ component: Component, layout: Layout, ...rest }) {
    return (
        <Route
            {...rest}
            render={props => (
                <Layout {...props}>
                    <Component {...props} />
                </Layout>
            )}
        />
    );
}

export default class App extends React.Component {
    state = {
        user,
        weChatNickname: '',
        balance: null,
        credit: null,
        useMobile: false,
        creditExpire: null,
    };

    async componentDidMount() {
        if (remote.isLogin()) {
            const user = await remote.$get('/user', {}, { hideLoading: true });
            const wechatUser = await remote.$get(
                '/oauth/fuwuhao',
                {},
                { hideLoading: true }
            );
            this.setState({ user, weChatNickname: wechatUser.nickname });
        }

        if (
            process.env.NODE_ENV !== 'production' &&
            window.location.pathname === '/course'
        ) {
            window.location.href = '/';
        }
    }
    componentDidCatch(error, info) {
        errorReport(
            process.env.REACT_APP_ENV !== 'production',
            process.env.REACT_APP_SITE,
            error.message,
            { stack: error.stack, info },
            error
        );
    }

    useMobile = () => {
        this.setState({ useMobile: true });
        localStorage.setItem('useMobile', true);
    };

    render() {
        // 是移动端，不是elite，则直接渲染提示
        // 是移动端，是elite，则判断是否设定过用手机访问，或者是注册页，如果是则继续访问，不是则渲染提示
        if (
            isMobile() &&
            isCourseIntroPage &&
            !/register/.test(window.location.pathname) &&
            (!isElite ||
                (!this.state.useMobile && !localStorage.getItem('useMobile')))
        ) {
            return (
                <div
                    className="mobile-tip"
                    style={{ backgroundImage: `url(${MobileTip})` }}
                >
                    <img src={MobileTipComputer} alt="computer" />
                    <div className="use-mobile-link">boyuai.com</div>
                    {isElite && (
                        <div className="use-mobile" onClick={this.useMobile}>
                            仍然使用移动端访问
                        </div>
                    )}
                </div>
            );
        }

        const contextValue = {
            user: this.state.user,
            weChatNickname: this.state.weChatNickname,
            balance: this.state.balance,
            credit: this.state.credit,
            qianren: this.state.qianren,
            creditExpire: this.state.creditExpire,
            updateUser: user => this.setState({ user }),
            updateWeChatNickname: weChatNickname =>
                this.setState({ weChatNickname }),
            updateBalance: balance => this.setState({ balance }),
            updateCredit: credit => this.setState({ credit }),
            updateCreditExpire: creditExpire => this.setState({ creditExpire }),
        };
        return (
            <div
                className="App"
                style={{
                    '--color-warning': '#faad14',
                    '--color-success': '#52c41a',
                    '--color-success-bg': '#f6ffed',
                    '--color-error': '#f5222d',
                    '--color-error-bg': '#fff1f0',
                    '--color-info': '#1890ff',
                    minWidth:
                        /login/.test(window.location.pathname) ||
                        /register/.test(window.location.pathname)
                            ? 'unset'
                            : '1280px',
                    '--color-boyu-blue': '#30B1F8',
                }}
            >
                <Router basename={baseName}>
                    <UserContext.Provider value={contextValue}>
                        {window.location.pathname !== '/login' &&
                            !/admin/.test(window.location.pathname) &&
                            !/emailValidation/.test(window.location.pathname) &&
                            !/recall/.test(window.location.pathname) &&
                            !/register/.test(window.location.pathname) && (
                                <Activity />
                            )}
                        {/*{window.location.pathname !== '/login' &&*/}
                        {/*    !/admin/.test(window.location.pathname) &&*/}
                        {/*    !/register/.test(window.location.pathname) && (*/}
                        {/*        <Advertisement />*/}
                        {/*    )}*/}
                        <Route
                            path="/"
                            render={props =>
                                props.location.pathname !== '/' &&
                                props.location.pathname !== '/login' &&
                                !(
                                    props.location.pathname.startsWith(
                                        '/course'
                                    ) && isMobile()
                                ) &&
                                !/register/.test(props.location.pathname) && (
                                    <Header />
                                )
                            }
                        />
                        <Route path="/" exact render={() => <HomeHeader />} />
                        <Route
                            path="/"
                            render={props =>
                                remote.isLogin() &&
                                props.location.pathname !== '/login' &&
                                !/register/.test(props.location.pathname) &&
                                !/admin/.test(props.location.pathname) && (
                                    <DailyCreditHelper
                                        activeList={[
                                            'daily-view-lessons',
                                            'daily-view-courses',
                                            'daily-sign-in',
                                        ]}
                                    />
                                )
                            }
                        />
                        <Switch>
                            <RouteWrapper
                                path="/"
                                exact
                                component={Home}
                                layout={BlankLayout}
                            />
                            <RouteWrapper
                                path="/giftcard"
                                component={GiftCard}
                                layout={BlankLayout}
                            />
                            <RouteWrapper
                                path="/course/:uuid"
                                component={Course}
                                layout={BlankLayout}
                            />
                            <RouteWrapper
                                path="/oj/:id"
                                component={OJ}
                                layout={BlankLayout}
                            />
                            {remote.isLogin() && (
                                <RouteWrapper
                                    path="/admin"
                                    component={Admin}
                                    layout={BlankLayout}
                                />
                            )}
                            <RouteWrapper
                                path="/login"
                                component={Login}
                                layout={BlankLayout}
                            />
                            <RouteWrapper
                                path="/user"
                                component={UserCenter}
                                layout={BlankLayout}
                            />
                            <RouteWrapper
                                path="/register/:uuid"
                                component={Register}
                                layout={BlankLayout}
                            />
                            <RouteWrapper
                                path="/emailValidation/:uuid"
                                component={EmailValidation}
                                layout={BlankLayout}
                            />
                            <RouteWrapper
                                path="/teaching-skill/:uuid/:from"
                                exact
                                component={RecallReward}
                                layout={BlankLayout}
                            />
                            <Route path="/rlchina">
                                <Redirect to={'/course/cZu18YmweLv10OeV/'} />
                            </Route>
                            <RouteWrapper
                                component={ErrorPage}
                                layout={BlankLayout}
                            />
                        </Switch>
                    </UserContext.Provider>
                </Router>
            </div>
        );
    }
}
