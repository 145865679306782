/**
 * CRUDL API
 */
import { remote } from '@boyuai/utils';
import { message } from 'antd';
import { API_BASE_URL } from './config';

export const fetchList = ({
    offset: _offset,
    limit: _limit,
    tagIds,
    userId,
}) => {
    let offset = _offset ?? 0;
    let limit = _limit ?? 9;
    const tags = (tagIds || []).join(',');
    let query = tags ? `tags:${tags} ` : '';
    query += userId ? `userId:${userId} ` : '';
    return remote
        .$get(API_BASE_URL, {
            offset,
            limit,
            query,
        })
        .then(data => {
            if (!data) return [[], 0];
            return data;
        });
};

export const createDetail = payload => {
    return remote.$post(API_BASE_URL, payload);
};

export const fetchDetail = id => {
    return remote.$get(`${API_BASE_URL}/${id}`);
};

export const updateDetail = (id, payload) => {
    return remote.$put(`${API_BASE_URL}/${id}`, payload);
};

export const deleteDetail = id => {
    message.info('暂未提供删除功能');
    return Promise.resolve();
    // return remote.$delete(`${API_BASE_URL}/${id}`, null, {
    //     throwException: 'showMessage',
    // });
};
