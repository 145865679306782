import React, { useCallback, useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { remote } from '@boyuai/utils';
import { Pagination, Radio, Spin, Input, Button, message } from 'antd';
import CommentTableItem from './components/comment-table-item';

export default function AdminComments() {
    const match = useRouteMatch('/admin/comments/:userId?');
    const [onlyUnRespondedQuestions, setOnlyUnRespondedQuestions] = useState(
        false
    );
    const [comments, setComments] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalComments, setTotalComments] = useState(0);
    const [loading, setLoading] = useState(true);
    const [searchVisible, setSearchVisible] = useState(
        !!(match.params && match.params.userId)
    );
    const [searchInput, setSearchInput] = useState(
        match.params && match.params.userId ? match.params.userId : null
    );
    const [searchKey, setSearchKey] = useState(
        match.params && match.params.userId ? 'userId' : null
    );
    const [searchValue, setSearchValue] = useState(
        match.params && match.params.userId ? match.params.userId : null
    );

    const fetchComments = useCallback(async () => {
        setLoading(true);
        if (
            searchKey === 'userId' &&
            !(searchValue && Number.isInteger(Number(searchValue)))
        ) {
            return message.error('用户ID只能为数字');
        }
        const payload = {
            offset: (currentPage - 1) * 20,
            onlyUnRespondedQuestions,
        };
        if (searchKey) {
            if (searchValue) {
                payload[searchKey] = searchValue;
            } else {
                message.error('请输入搜索条件');
                setLoading(false);
                return;
            }
        }
        const [commentList, total] = await remote.$get(
            '/course/admin/comments',
            { ...payload, showDeleted: searchKey === 'userId' }
        );
        setComments(commentList);
        setTotalComments(total);
        setLoading(false);
        window.scrollTo(0, 0);
    }, [currentPage, onlyUnRespondedQuestions, searchKey, searchValue]);

    useEffect(() => {
        fetchComments();
    }, [fetchComments]);

    return (
        <div style={{ width: 'calc(100vw - 400px)' }}>
            <Radio.Group
                style={{ marginBottom: 5 }}
                defaultValue={0}
                buttonStyle="solid"
                onChange={e => {
                    setCurrentPage(1);
                    setOnlyUnRespondedQuestions(!!e.target.value);
                }}
            >
                <Radio.Button value={0}>全部</Radio.Button>
                <Radio.Button value={1}>只显示未回复的提问</Radio.Button>
            </Radio.Group>
            <div
                style={{
                    marginBottom: 8,
                }}
            >
                <Radio.Group
                    defaultValue={match.params && match.params.userId ? 1 : 0}
                    onChange={e => {
                        if (e.target.value === 0) {
                            setSearchVisible(false);
                            setCurrentPage(1);
                            setSearchInput(null);
                            setSearchValue(null);
                            setSearchKey(null);
                        } else {
                            setSearchVisible(true);
                        }
                    }}
                    value={searchVisible ? 1 : 0}
                    buttonStyle="solid"
                >
                    <Radio.Button value={0}>显示全部</Radio.Button>
                    <Radio.Button value={1}>显示搜索</Radio.Button>
                </Radio.Group>
                {searchVisible && (
                    <div>
                        <Input
                            defaultValue={
                                match.params && match.params.userId
                                    ? match.params.userId
                                    : null
                            }
                            style={{
                                width: 240,
                            }}
                            placeholder="输入搜索内容"
                            onBlur={e => setSearchInput(e.target.value)}
                            allowClear
                        />
                        <Button
                            onClick={() => {
                                setCurrentPage(1);
                                setSearchValue(searchInput);
                                setSearchKey('categoryLabel');
                            }}
                        >
                            搜索分组标签
                        </Button>
                        <Button
                            onClick={() => {
                                setCurrentPage(1);
                                setSearchValue(searchInput);
                                setSearchKey('name');
                            }}
                        >
                            搜索昵称
                        </Button>
                        <Button
                            onClick={() => {
                                setCurrentPage(1);
                                setSearchValue(searchInput);
                                setSearchKey('phone');
                            }}
                        >
                            搜索手机号
                        </Button>
                        <Button
                            onClick={() => {
                                setCurrentPage(1);
                                setSearchValue(searchInput);
                                setSearchKey('userId');
                            }}
                        >
                            搜索用户ID
                        </Button>
                    </div>
                )}
            </div>
            {comments && comments.length ? (
                <Spin spinning={loading}>
                    <Pagination
                        showQuickJumper
                        defaultCurrent={1}
                        current={currentPage}
                        total={totalComments}
                        pageSize={20}
                        showSizeChanger={false}
                        onChange={setCurrentPage}
                    />
                    <div style={{ width: '100%', margin: 5 }}>
                        {comments.map((item, index) => (
                            <CommentTableItem
                                key={item.id}
                                item={item}
                                index={index}
                                onSearch={() => {
                                    setCurrentPage(1);
                                    setSearchVisible(true);
                                    setSearchValue(item.userId);
                                    setSearchKey('userId');
                                }}
                                onDelete={() => fetchComments()}
                            />
                        ))}
                    </div>
                    <Pagination
                        showQuickJumper
                        defaultCurrent={1}
                        current={currentPage}
                        total={totalComments}
                        pageSize={20}
                        showSizeChanger={false}
                        onChange={setCurrentPage}
                    />
                </Spin>
            ) : (
                <div>无评论</div>
            )}
        </div>
    );
}
