import React, { Component } from 'react';
import { Menu, Badge } from 'antd';
import { withRouter, Switch, Route } from 'react-router-dom';
import { $get, logout } from '@boyuai/utils/es/remote';
import { UserContext } from '@boyuai/utils/es/context/user-context';
import moment from 'moment';
import AccountBalance from './account-balance';
import Message from './message';
import Comment from './comment';
import MyLessons from './my-lessons';
import MyProgress from './my-progress';
import MyDiscussions from './my-discussions';
import MyNotes from './my-notes';

import './index.less';
import { MyAccount } from './my-account';

const DEFAULT_AVATAR =
    'https://staticcdn.boyuai.com/user/portrait/2019/06/12/HV1escOlF_DXE3jfs-P4J.jpg';

const { Item } = Menu;

const menus = [
    { key: 'lessons', label: '我的知识点' },
    { key: 'notes', label: '我的笔记' },
    { key: 'discussions', label: '讨论与点赞' },
    { key: 'progress', label: '学习进度' },
    { key: 'balance', label: '账户余额' },
    { key: 'message', label: '消息通知' },
    // { key: 'question', label: '我的提问' },
    { key: 'security', label: '账户安全' },
];

class UserCenter extends Component {
    static contextType = UserContext;
    // 云朵: value->balance, 雨滴: credit, 消耗数: consumed
    state = {
        balance: null,
        credit: null,
        consumed: null,
        consumedCredit: null,
        activeMenu: null,
        messageArr: [],
        unreadCount: 0,
        creditExpire: null,
    };
    componentDidMount() {
        this.fetchBalance();
        this.fetchExpireGroup();
        this.fetchMessage();
    }
    componentDidUpdate() {
        this.setActiveMenu();
    }
    setActiveMenu() {
        const active =
            menus.find(menu => {
                const reg = new RegExp(`/user/${menu.key}`);
                return reg.test(this.props.location.pathname);
            }) || menus[0];
        if (this.state.activeMenu === active.key) {
            return;
        }
        this.setState({ activeMenu: active.key });
    }
    async fetchBalance() {
        const { value: balance, consumed, credit, consumedCredit } = await $get(
            '/wallet'
        );
        this.setState({
            balance: balance,
            credit: credit,
            consumed: consumed,
            consumedCredit: consumedCredit,
        });
    }
    async fetchExpireGroup() {
        const creditExpireGroups = await $get('/wallet/expires-soon', {});
        if (creditExpireGroups?.length) {
            this.setState({
                creditExpire: creditExpireGroups.sort(
                    (a, b) =>
                        moment(a.expiresAt).unix() - moment(b.expiresAt).unix()
                ),
            });
        }
    }
    async fetchMessage() {
        const [messageArr] = await $get('/message', {}, { hideLoading: true });
        this.setState({ messageArr });
        this.setState({
            unreadCount: messageArr.filter(item => item.site && !item.read)
                .length,
        });
    }
    onLogout() {
        logout();
        window.location.reload();
    }
    displayNumber = value => {
        return typeof value === 'number' ? value / 100 : '--';
    };
    render() {
        const { name, portrait } = this.context.user;
        const { balance, credit, messageArr, creditExpire } = this.state;
        return (
            <div className="page-user ui-layout-container">
                <div className="main">
                    <div className="left">
                        <div className="portrait">
                            <img src={portrait || DEFAULT_AVATAR} alt="头像" />
                        </div>
                        <div className="name">{name}</div>
                        {this.renderConsumedDetail()}
                        {this.renderLeftMenu()}
                    </div>
                    <div className="right">
                        <Switch>
                            <Route
                                path="/user"
                                exact
                                render={() => <MyLessons />}
                            />
                            <Route
                                path="/user/balance"
                                render={() => (
                                    <AccountBalance
                                        balance={balance}
                                        credit={credit}
                                        creditExpire={creditExpire}
                                    />
                                )}
                            />
                            <Route
                                path="/user/message"
                                render={() => (
                                    <Message
                                        messageArr={messageArr}
                                        onUnreadChange={count =>
                                            this.setState({
                                                unreadCount: count,
                                            })
                                        }
                                        updateMessageList={
                                            this.updateMessageList
                                        }
                                    />
                                )}
                            />
                            <Route
                                path="/user/lessons"
                                render={() => <MyLessons />}
                            />
                            <Route
                                path="/user/discussions"
                                render={() => <MyDiscussions />}
                            />
                            <Route
                                path="/user/notes"
                                render={() => <MyNotes />}
                            />
                            <Route
                                path="/user/question"
                                render={() => <Comment type="question" />}
                            />
                            <Route
                                path="/user/progress"
                                render={() => <MyProgress />}
                            />
                            <Route
                                path="/user/security"
                                render={() => <MyAccount />}
                            />
                        </Switch>
                    </div>
                </div>
            </div>
        );
    }

    renderLeftMenu() {
        const { activeMenu, unreadCount } = this.state;
        return (
            <Menu
                onClick={this.handleMenuClick}
                mode="vertical"
                selectedKeys={[activeMenu]}
                className="left-menu"
            >
                {menus.map(menu => {
                    if (menu.key === 'message') {
                        return (
                            <Item key="message">
                                <Badge
                                    count={unreadCount}
                                    overflowCount={99}
                                    offset={[6, -5]}
                                >
                                    {menu.label}
                                </Badge>
                            </Item>
                        );
                    }
                    return <Item key={menu.key}>{menu.label}</Item>;
                })}
                <Item key="logout" onClick={this.onLogout}>
                    退出登录
                </Item>
            </Menu>
        );
    }

    handleMenuClick = e => {
        this.props.history.push(`/user/${e.key}`);
    };

    updateMessageList = newMessageList => {
        this.setState({ messageArr: newMessageList });
        this.setState({ unreadCount: 0 });
    };

    renderConsumedDetail() {
        const { consumed, consumedCredit } = this.state;
        return (
            <div className="consumed-group">
                <div>
                    <div className="consumed-label">累计消耗雨滴</div>
                    <div className="consumed">
                        {this.displayNumber(consumedCredit)}
                    </div>
                </div>
                <div>
                    <div className="consumed-label">累计消耗云朵</div>
                    <div className="consumed">
                        {this.displayNumber(consumed)}
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(UserCenter);
