import React, { useState, useCallback, useEffect } from 'react';
import { Button, Radio, Input, message, Popconfirm } from 'antd';
import { remote } from '@boyuai/utils';
import WechatMenuEditor from './menu-editor';

const FUWUHAO = 'fuwuhao'; // 伯禹教育
const FUWUHAO_BOXIAOYU = 'fuwuhao-boxiaoyu'; // 博小鱼

export default function AdminFuwuhaoMenu() {
    const [fuwuhaoType, setFuwuhaoType] = useState(FUWUHAO);
    const [menu, setMenu] = useState(null);

    const fetchMenu = useCallback(async () => {
        setMenu(null);
        const res = await remote.$get('/admin/fuwuhao/menu', { fuwuhaoType });
        setMenu(JSON.stringify(res.selfmenu_info));
    }, [fuwuhaoType]);

    const updateMenu = useCallback(async () => {
        try {
            const menuObject = JSON.parse(menu);
            await remote.$post('/admin/fuwuhao/menu', {
                fuwuhaoType,
                menu: menuObject,
            });
            await fetchMenu();
        } catch (e) {
            message.error('输入必须是JSON，请检查');
        }
    }, [fuwuhaoType, menu, fetchMenu]);

    useEffect(() => {
        fetchMenu();
    }, [fetchMenu]);

    return (
        <div>
            <h3 style={{ marginBottom: 16 }}>服务号菜单管理</h3>
            <div style={{ marginBottom: 16 }}>
                <Radio.Group
                    buttonStyle="solid"
                    value={fuwuhaoType}
                    onChange={e => setFuwuhaoType(e.target.value)}
                >
                    <Radio.Button value={FUWUHAO}>伯禹教育</Radio.Button>
                    <Radio.Button value={FUWUHAO_BOXIAOYU}>博小鱼</Radio.Button>
                </Radio.Group>
            </div>
            <div style={{ marginBottom: 16 }}>
                <Input.TextArea
                    style={{ width: 500 }}
                    rows={5}
                    value={menu}
                    onChange={e => setMenu(e.target.value)}
                />
            </div>
            <div style={{ marginBottom: 16 }}>
                <Popconfirm title="确定要更新菜单吗" onConfirm={updateMenu}>
                    <Button type="primary">更新菜单</Button>
                </Popconfirm>
            </div>
            <div>
                参考：
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={
                        'https://developers.weixin.qq.com/doc/offiaccount/Custom_Menus/Creating_Custom-Defined_Menu.html'
                    }
                >
                    https://developers.weixin.qq.com/doc/offiaccount/Custom_Menus/Creating_Custom-Defined_Menu.html
                </a>
            </div>
            {menu && (
                <WechatMenuEditor
                    menu={JSON.parse(menu)}
                    onChange={() => {}}
                    onRelease={() => {}}
                />
            )}
        </div>
    );
}
