import React, { useCallback } from 'react';
import { Modal, message, Form } from 'antd';
import Feedback from './form';
import { remote } from '@boyuai/utils';

export default function FeedbackModal({ visible, onClose, uuid }) {
    const [form] = Form.useForm();
    const onSubmit = useCallback(() => {
        form.validateFields().then(async values => {
            const { reward, result } = await remote.$post(
                '/course/feedbacks',
                { content: JSON.stringify(values), uuid },
                { throwException: 'showMessage' }
            );
            if (reward) {
                message.success(`评价反馈成功，获得${reward}雨滴奖励`);
            } else {
                message.success('评价反馈成功');
            }
            onClose(result);
        });
    }, [onClose, uuid, form]);
    return (
        <Modal
            visible={visible}
            onCancel={() => onClose()}
            onOk={onSubmit}
            okText="提交"
            width="600px"
            title="主讲教师、理论助教、伯禹教研组都期待收到你的点评"
        >
            <Feedback form={form} />
        </Modal>
    );
}
