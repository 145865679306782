import React, { useState, useEffect, useCallback } from 'react';
import { remote } from '@boyuai/utils';
import { Button, Modal, Alert, Popconfirm, Upload } from 'antd';
import AdminTable from './table';
import AdminFormModal from './form-modal';
import Markdown from '../../components/markdown';
import Options from './quiz-options';
import MarkdownIt from 'markdown-it';
import { convertMarkdownToJson } from '../../utils';

const formItems = [
    {
        label: '题干',
        name: 'title',
        type: 'textarea',
        required: true,
    },
    {
        label: '类型',
        name: 'type',
        type: 'select',
        datasource: [
            {
                text: '选择题',
                value: 'SELECT',
            },
            {
                text: '填空题',
                value: 'FILL',
            },
        ],
        required: true,
    },
    {
        label: '分值',
        name: 'score',
        type: 'number',
        required: true,
    },
    {
        label: '答案',
        name: 'answer',
        type: 'textarea',
        required: true,
        placeholder: '选择题请填写0,1,2,3表示选项',
    },
    {
        label: '提示',
        name: 'hint',
        type: 'textarea',
    },
];

function AdminLessonQuiz(props) {
    const [visible, setVisible] = useState(false); // 创建题目弹窗
    const [questionId, setQuestionId] = useState(null);
    const [questions, setQuestions] = useState([]);
    const [submitCount, setSubmitCount] = useState(0);

    useEffect(() => {
        if (props.quizId) {
            remote
                .$get(
                    `/course/admin/courses/0/lessons/0/quizzes/${props.quizId}`
                )
                .then(quiz => setQuestions(quiz.questions));
            remote
                .$get(
                    `/course/admin/courses/0/lessons/0/quizzes/${props.quizId}/submitCount`
                )
                .then(setSubmitCount);
        }
        return () => setQuestions([]);
    }, [props.quizId]);

    const onCreate = async values => {
        const data = { ...values };
        try {
            await remote.$post(
                `/course/admin/courses/0/lessons/0/quizzes/${props.quizId}/questions`,
                data,
                { throwException: 'showMessage' }
            );
            setVisible(false);
            const { questions } = await remote.$get(
                `/course/admin/courses/0/lessons/0/quizzes/${props.quizId}`
            );
            setQuestions(questions);
        } catch (e) {}
    };

    const onDelete = async id => {
        try {
            await remote.$delete(
                `/course/admin/courses/0/lessons/0/quizzes/0/questions/${id}`,
                {},
                { throwException: 'showMessage' }
            );
            const { questions } = await remote.$get(
                `/course/admin/courses/0/lessons/0/quizzes/${props.quizId}`
            );
            setQuestions(questions);
        } catch (e) {}
    };

    const onOptionsChange = async options => {
        await remote.$patch(
            `/course/admin/courses/0/lessons/0/quizzes/0/questions/${questionId}/options`,
            { options },
            { throwException: 'showMessage' }
        );
        setQuestions(
            questions.map(question => {
                if (question.id === questionId) {
                    return { ...question, options };
                }
                return question;
            })
        );
    };

    const onMarkdownLoad = useCallback(
        async markdown => {
            let md = new MarkdownIt();
            // const a = md.parse(markdown, {});
            // console.log(a);
            const newQuestions = convertMarkdownToJson(md.parse(markdown, {}));
            // console.log('newQuestions', newQuestions);
            let i;
            for (i = 0; i < newQuestions.length; i++) {
                const { title, type, answer, options, hint } = newQuestions[i];
                const createdQuestion = await remote.$post(
                    `/course/admin/courses/0/lessons/0/quizzes/${props.quizId}/questions`,
                    {
                        title,
                        type,
                        answer,
                        hint,
                        score: 10,
                    },
                    { throwException: 'showMessage' }
                );
                // console.log('createdQuestion', createdQuestion);
                if (type === 'SELECT') {
                    await remote.$patch(
                        `/course/admin/courses/0/lessons/0/quizzes/0/questions/${createdQuestion.id}/options`,
                        { options },
                        { throwException: 'showMessage' }
                    );
                }
            }
            const { questions } = await remote.$get(
                `/course/admin/courses/0/lessons/0/quizzes/${props.quizId}`
            );
            setQuestions(questions);
            // console.log('latestQuestions', questions);
        },
        [props]
    );

    const columns = [
        {
            title: '类型',
            dataIndex: 'type',
            key: 'type',
            render: type => (type === 'FILL' ? '填空题' : '选择题'),
        },
        {
            title: '题干',
            dataIndex: 'title',
            key: 'title',
            render: value => <Markdown content={value} />,
        },
        {
            title: '分值',
            dataIndex: 'score',
            key: 'score',
        },
        {
            title: '提示',
            dataIndex: 'hint',
            key: 'hint',
            render: value => <Markdown content={value} />,
        },
        {
            title: '答案',
            dataIndex: 'answer',
            key: 'answer',
            render: value =>
                Array.isArray(value)
                    ? value.map((item, index) => (
                          <Markdown
                              key={index}
                              content={item.replace(/\n/g, '\n\n')}
                          />
                      ))
                    : value,
        },
        {
            title: '操作',
            dataIndex: 'id',
            key: 'operations',
            render: (id, record) => {
                return (
                    <div>
                        {record.type === 'SELECT' && (
                            <Button onClick={() => setQuestionId(id)}>
                                查看选项
                            </Button>
                        )}
                        &nbsp;
                        <Popconfirm
                            title="确定要删除吗"
                            onConfirm={() => onDelete(id)}
                        >
                            <Button type="danger">删除</Button>
                        </Popconfirm>
                    </div>
                );
            },
        },
    ];

    return (
        <div>
            {submitCount ? (
                <Alert
                    message="警告"
                    description={`目前已有${submitCount}人作答，修改试卷内容将会导致意外结果`}
                    type="warning"
                    showIcon
                    style={{ marginBottom: '10px' }}
                />
            ) : null}
            <h3>
                题目列表{' '}
                <Button onClick={() => setVisible(true)}>创建题目</Button>
                &nbsp;
                <Upload
                    type="file"
                    accept=".md"
                    multiple={false}
                    showUploadList={false}
                    customRequest={e => {
                        const reader = new window.FileReader();
                        reader.readAsText(e.file);
                        reader.onload = e => {
                            onMarkdownLoad(e.currentTarget.result);
                        };
                    }}
                >
                    <Button>导入题目</Button>
                </Upload>
            </h3>
            <AdminTable
                columns={columns}
                datasource={questions}
                hidePagination
            />
            <AdminFormModal
                title="添加题目"
                visible={visible}
                onCancel={() => setVisible(false)}
                onSubmit={onCreate}
                formItems={formItems}
            />
            <Modal
                title="选择题选项"
                visible={!!questionId}
                onCancel={() => setQuestionId(null)}
                onOk={() => setQuestionId(null)}
            >
                <Options
                    options={
                        questionId
                            ? questions.find(o => o.id === questionId).options
                            : []
                    }
                    onChange={onOptionsChange}
                />
            </Modal>
        </div>
    );
}

export default AdminLessonQuiz;
