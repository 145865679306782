import { useCallback, useEffect, useState } from 'react';
import { remote } from '@boyuai/utils';

export function useAllAuthors() {
    const [allAuthors, setAllAuthors] = useState([]);
    const fetchAllAuthors = useCallback(() => {
        remote.$get(`/course/admin/authors`).then(authors => {
            setAllAuthors([...authors]);
        });
    }, []);
    useEffect(() => {
        fetchAllAuthors();
    }, [fetchAllAuthors]);
    return [allAuthors];
}
