import { useState, useCallback, useEffect, useRef } from 'react';

export default function useCountDown() {
    const [count, setCount] = useState(0);
    const counter = useRef(null);

    const countDown = useCallback(() => {
        if (count <= 0) {
            return;
        }
        setCount(prev => prev - 1);
    }, [count, setCount]);

    useEffect(() => {
        if (count > 0) {
            counter.current && clearTimeout(counter.current);
            counter.current = setTimeout(countDown, 1000);
        }
    }, [count, countDown]);

    return [count, setCount];
}
