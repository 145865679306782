import React, { useCallback, useState } from 'react';
import { Button, InputNumber, Modal, Upload, Table, message } from 'antd';
import { remote } from '@boyuai/utils';

const GlobalLessonBatchProcess = () => {
    const [loading, setLoading] = useState(false);
    const [limit, setLimit] = useState(10);
    const [tempLessons, setTempLessons] = useState([]); // 刚导入准备处理的知识点
    const [latestLessons, setLatestLessons] = useState([]); // 知识点查询
    const [createdLessons, setCreatedLessons] = useState([]); // 创建成功的知识点
    const [updatedLessons, setUpdatedLessons] = useState([]); // 更新成功的知识点

    // 确认或者取消导入知识点弹窗
    const onClose = useCallback(() => {
        if (loading) {
            message.info('处理课程中，请稍后关闭弹窗');
        } else {
            setLoading(false);
            setTempLessons([]);
        }
    }, [loading]);

    const fetchLatestLessons = useCallback(async () => {
        setLoading(true);
        const res = await remote.$get(
            '/course/admin/courses/lessons/latest',
            { limit },
            {
                throwException: 'showMessage',
            }
        );
        setLatestLessons(res.reverse());
        setLoading(false);
    }, [limit]);

    const batchCreate = useCallback(async lessons => {
        setLoading(true);
        remote
            .$post(
                '/course/admin/courses/lessons/batch',
                {
                    lessons: lessons.map(
                        ({ title, keywords, parentUuids, desc }) => ({
                            title,
                            keywords,
                            desc,
                            parentUuids,
                        })
                    ),
                },
                {
                    throwException: 'showMessage',
                }
            )
            .then(res => {
                setCreatedLessons(res);
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    }, []);

    const batchUpdate = useCallback(lessons => {
        setLoading(true);
        remote
            .$patch(
                '/course/admin/courses/lessons/batch',
                {
                    lessons: lessons.map(lesson => {
                        const {
                            title,
                            keywords,
                            uuid,
                            parentUuids,
                            desc,
                        } = lesson;
                        return {
                            title,
                            keywords,
                            uuid,
                            parentUuids,
                            desc,
                        };
                    }),
                },
                {
                    throwException: 'showMessage',
                }
            )
            .then(res => {
                setUpdatedLessons(res);
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    }, []);

    const handleFile = useCallback(f => {
        const reader = new window.FileReader();
        reader.readAsText(f.file);
        reader.onload = e => {
            try {
                const l = e.currentTarget.result.split('\n').map(item => {
                    const [
                        courseTitle,
                        title,
                        keywords,
                        uid,
                        parentUid,
                        uuid,
                        parentUuids,
                        tenMinutesUuid,
                        desc,
                        author,
                        shouldUpdate,
                    ] = item.split('\t');
                    return {
                        courseTitle,
                        title,
                        keywords,
                        uid,
                        parentUid,
                        uuid,
                        parentUuids: parentUuids
                            .split(',')
                            .filter(v => v && v !== ''),
                        tenMinutesUuid,
                        desc,
                        author,
                        shouldUpdate: shouldUpdate === '1',
                    };
                });
                setTempLessons(l);
            } catch (e) {
                message.error('文件格式错误');
            }
        };
    }, []);

    const renderLessons = useCallback(
        lessonList => (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                }}
            >
                <span
                    style={{
                        width: '45%',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                    }}
                >
                    {lessonList.map(lesson => (
                        <div key={lesson.uuid}>《{lesson.title}》</div>
                    ))}
                </span>
                <span style={{ width: '45%' }}>
                    {lessonList.map(lesson => (
                        <div key={lesson.uuid}>{lesson.uuid}</div>
                    ))}
                </span>
            </div>
        ),
        []
    );

    const renderLessonDep = useCallback(lessonList => {
        return (
            <div>
                {lessonList
                    .filter(
                        lesson =>
                            lesson.parentLessons && lesson.parentLessons.length
                    )
                    .map(lesson => (
                        <div key={lesson.uuid}>
                            <div>为《{lesson.title}》添加父节点</div>
                            <div style={{ marginBottom: 16 }}>
                                {lesson.parentLessons.map(p => (
                                    <span
                                        key={p.uuid}
                                        style={{
                                            fontStyle: 'Italic',
                                            marginRight: '1em',
                                        }}
                                    >
                                        《{p.title}》
                                    </span>
                                ))}
                            </div>
                        </div>
                    ))}
            </div>
        );
    }, []);

    const renderLoadedLessons = useCallback((title, lessonList) => {
        const columns = [
            { title: '课程名', dataIndex: 'courseTitle', key: 'courseTitle' },
            { title: '知识点名', dataIndex: 'title', key: 'title' },
            { title: '关键词', dataIndex: 'keywords', key: 'keywords' },
            {
                title: 'uuid',
                dataIndex: 'uuid',
                key: 'uuid',
            },
            {
                title: '父节点',
                dataIndex: 'parentUuids',
                key: 'parentUuids',
                render: l => l.map(p => <div key={p}>{p}</div>),
            },
        ];
        return (
            <Table
                title={() => title}
                rowKey="title"
                columns={columns}
                dataSource={lessonList}
                pagination={false}
            />
        );
    }, []);
    return (
        <div
            style={{
                marginBottom: 16,
                paddingBottom: 8,
                borderBottom: '2px solid #e9e9e9',
            }}
        >
            <Upload
                type="file"
                accept=".txt"
                multiple={false}
                showUploadList={false}
                customRequest={handleFile}
            >
                <Button type={'primary'}>全局批量插入/更新知识点</Button>
            </Upload>
            &nbsp; &nbsp;
            <Button type={'primary'} onClick={fetchLatestLessons}>
                查询最近插入的知识点
            </Button>
            <InputNumber
                defaultValue={10}
                min={1}
                onChange={v => setLimit(v)}
            />
            <Modal
                visible={tempLessons.length > 0}
                title={'导入的知识点'}
                width={1200}
                footer={
                    <div>
                        <Button type={'primary'} onClick={() => onClose()}>
                            关闭
                        </Button>
                    </div>
                }
                onCancel={() => onClose()}
            >
                <h3>共导入{tempLessons.length}个知识点</h3>
                <br />
                {renderLoadedLessons(
                    <h3>
                        待创建{tempLessons.filter(o => !o.uuid).length}
                        个&nbsp;&nbsp;
                        <Button
                            type={'primary'}
                            disabled={
                                loading ||
                                tempLessons.filter(o => !o.uuid).length === 0
                            }
                            onClick={() =>
                                batchCreate(tempLessons.filter(o => !o.uuid))
                            }
                        >
                            创建
                        </Button>
                    </h3>,
                    tempLessons.filter(o => !o.uuid)
                )}
                <br />
                {renderLoadedLessons(
                    <h3>
                        待更新{tempLessons.filter(o => o.uuid).length}
                        个&nbsp;&nbsp;
                        <Button
                            type={'primary'}
                            disabled={
                                loading ||
                                tempLessons.filter(o => o.uuid).length === 0
                            }
                            onClick={() =>
                                batchUpdate(tempLessons.filter(o => o.uuid))
                            }
                        >
                            更新
                        </Button>
                    </h3>,
                    tempLessons.filter(o => o.uuid)
                )}
            </Modal>
            <Modal
                visible={latestLessons.length > 0}
                title={`最近添加的${latestLessons.length}个知识点`}
                width={600}
                footer={
                    <div>
                        <Button
                            type={'primary'}
                            onClick={() => setLatestLessons([])}
                        >
                            关闭
                        </Button>
                    </div>
                }
                onCancel={() => setLatestLessons([])}
            >
                <div style={{ fontWeight: 'bold', fontStyle: 'italic' }}>
                    * 为方便复制，最下方为最新插入的
                </div>
                {renderLessons(latestLessons)}
            </Modal>
            <Modal
                visible={createdLessons.length > 0}
                title={'知识点批量创建结果'}
                width={600}
                footer={
                    <div>
                        <Button
                            type={'primary'}
                            onClick={() => setCreatedLessons([])}
                        >
                            关闭
                        </Button>
                    </div>
                }
                onCancel={() => setCreatedLessons([])}
            >
                <h3>添加的知识点</h3>
                {renderLessons(createdLessons)}
                <br />
                <h3>添加的知识点关系</h3>
                {renderLessonDep(createdLessons)}
            </Modal>
            <Modal
                visible={updatedLessons.length > 0}
                title={'知识点批量更新结果'}
                width={600}
                footer={
                    <div>
                        <Button
                            type={'primary'}
                            onClick={() => setUpdatedLessons([])}
                        >
                            关闭
                        </Button>
                    </div>
                }
                onCancel={() => setUpdatedLessons([])}
            >
                <h3>更新的知识点</h3>
                {renderLessons(updatedLessons)}
                <br />
                <h3>添加的知识点关系</h3>
                {renderLessonDep(updatedLessons)}
            </Modal>
        </div>
    );
};

export default GlobalLessonBatchProcess;
