import React, { useCallback, useState } from 'react';
import { message, Modal } from 'antd';
import { remote } from '@boyuai/utils';
import AuthorForm from './author-form';
import PropTypes from 'prop-types';

const AuthorFormModal = props => {
    const { author, onClose, onSubmit } = props;
    const [formRef, setFormRef] = useState(null);

    const saveFormRef = useCallback(node => {
        if (node !== null) {
            setFormRef(node);
        }
    }, []);

    return (
        <Modal
            title="课程作者"
            visible={true}
            onCancel={onClose}
            onOk={() => {
                formRef.validateFields(async (err, values) => {
                    if (!err) {
                        const { coverImage, name, title, content } = values;
                        const updatePayload = {
                            name,
                            title,
                            content,
                        };
                        if (coverImage) {
                            // 如果上传了头像
                            if (coverImage[0].status !== 'done') {
                                // 如果未上传完毕
                                message.info('请等待头像上传完毕');
                                return;
                            } else {
                                // 上传完毕
                                updatePayload.coverImage =
                                    coverImage[0].response;
                            }
                        }
                        if (author) {
                            await remote.$put(
                                `/course/admin/authors/${author.id}`,
                                {
                                    author: updatePayload,
                                },
                                {
                                    throwException: 'showMessage',
                                }
                            );
                        } else {
                            await remote.$post(
                                '/course/admin/authors',
                                {
                                    author: updatePayload,
                                },
                                {
                                    throwException: 'showMessage',
                                }
                            );
                        }
                        onSubmit();
                    }
                });
            }}
        >
            <AuthorForm ref={saveFormRef} author={author} />
        </Modal>
    );
};

AuthorFormModal.propTypes = {
    author: PropTypes.shape(),
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

export default AuthorFormModal;
