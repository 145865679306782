import React from 'react';

export default function InvitationGuide({ left, top }) {
    return (
        <div
            className="ui-qianren-guide"
            style={{
                position: 'absolute',
                left,
                top,
            }}
        >
            <img
                src="https://staticcdn.boyuai.com/materials/2020/08/01/PZV-0orVtZ1amHrbQGZh3.png!png"
                alt=""
                width="200px"
            />
        </div>
    );
}
