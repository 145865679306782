import React from 'react';
import CourseCard from './course-card';

export const renderCard = (
    course,
    showEnter = true,
    showPrice = false,
    isLastLine = false
) => {
    const { title, id, listImage, limitedFree, uuid, staticLink } = course;

    return (
        <CourseCard
            title={title}
            key={id}
            listImage={listImage}
            uuid={uuid}
            showEnter={showEnter}
            showPrice={showPrice}
            course={course}
            limitedFree={limitedFree}
            isLastLine={isLastLine}
            staticLink={staticLink}
        />
    );
};
