import React, { useRef, useState } from 'react';
import { Form, Input, InputNumber, Button, Switch, message } from 'antd';
import PhoneInput from 'antd-country-phone-input';
import { remote } from '@boyuai/utils';

export default function Recharge() {
    const formRef = useRef(null);
    const [phoneList, setPhoneList] = useState([]);
    const [usernameList, setUsernameList] = useState([]);
    const [resultList, setResultList] = useState([]);
    const handleSubmit = async values => {
        setResultList([]);
        const {
            phoneInput,
            usernameInput,
            value,
            isCredit,
            displayMessage,
        } = values;

        if (!phoneInput && !usernameInput) {
            message.error('请至少填入一名用户信息');
            return;
        }
        const payload = {
            value,
            isCredit,
            displayMessage,
        };
        if (phoneInput) {
            const { phone, code } = phoneInput;
            const list = phone
                .replace(/\s/g, ',')
                .split(',')
                .map(item => item.trim())
                .filter(function(item, index, arr) {
                    return arr.indexOf(item, 0) === index && item;
                });
            for (const item of list) {
                try {
                    const { userId } = await remote.$post(
                        '/course/admin/recharge',
                        { phone: item, phoneCode: code, ...payload },
                        {
                            throwException: 'showMessage',
                        }
                    );
                    message.success(`${item}充值成功`);
                    setResultList(prev => [
                        ...prev,
                        { code: code, phone: item, userId, status: 'success' },
                    ]);
                } catch (e) {
                    setResultList(prev => [
                        ...prev,
                        { code: code, phone: item, status: 'failure' },
                    ]);
                }
            }
        }
        if (usernameInput) {
            const list = usernameInput
                .replace(/\s/g, ',')
                .split(',')
                .map(item => item.trim())
                .filter(function(item, index, arr) {
                    return arr.indexOf(item, 0) === index && item;
                });
            for (const item of list) {
                try {
                    const { userId } = await remote.$post(
                        '/course/admin/recharge',
                        { username: item, ...payload },
                        {
                            throwException: 'showMessage',
                        }
                    );
                    message.success(`${item}充值成功`);
                    setResultList(prev => [
                        ...prev,
                        { username: item, userId, status: 'success' },
                    ]);
                } catch (e) {
                    setResultList(prev => [
                        ...prev,
                        { username: item, status: 'failure' },
                    ]);
                }
            }
        }
        formRef.current.resetFields();
    };
    return (
        <div>
            <Form onFinish={handleSubmit} ref={formRef}>
                <Form.Item
                    label="手机号（可通过“,”分割填写多个手机号）"
                    labelCol={{ span: 7 }}
                    wrapperCol={{ span: 12 }}
                    name="phoneInput"
                >
                    <PhoneInput
                        onChange={({ phone, code }) => {
                            setPhoneList(
                                phone
                                    ? phone
                                          .replace(/\s/g, ',')
                                          .split(',')
                                          .map(item => item.trim())
                                          .filter(function(item, index, arr) {
                                              return (
                                                  arr.indexOf(item, 0) ===
                                                      index && item
                                              );
                                          })
                                          .map(item => ({ phone: item, code }))
                                    : []
                            );
                        }}
                    />
                </Form.Item>
                <Form.Item
                    label="用户名（可通过“,”分割填写多个用户名）"
                    labelCol={{ span: 7 }}
                    wrapperCol={{ span: 12 }}
                    name="usernameInput"
                >
                    <Input
                        onChange={e => {
                            setUsernameList(
                                e.target.value
                                    .replace(/\s/g, ',')
                                    .split(',')
                                    .map(item => item.trim())
                                    .filter(function(item, index, arr) {
                                        return (
                                            arr.indexOf(item, 0) === index &&
                                            item
                                        );
                                    })
                            );
                        }}
                    />
                </Form.Item>
                <Form.Item
                    label="充值数量（注意单位是元！1元=100雨滴）"
                    labelCol={{ span: 7 }}
                    wrapperCol={{ span: 12 }}
                    name="value"
                    rules={[{ required: true }]}
                >
                    <InputNumber />
                </Form.Item>
                <Form.Item
                    label="是否雨滴"
                    labelCol={{ span: 7 }}
                    wrapperCol={{ span: 12 }}
                    name="isCredit"
                    valuePropName="checked"
                    initialValue={true}
                >
                    <Switch />
                </Form.Item>
                <Form.Item
                    label="显示信息"
                    labelCol={{ span: 7 }}
                    wrapperCol={{ span: 12 }}
                    name="displayMessage"
                    initialValue="系统赠送"
                >
                    <Input />
                </Form.Item>
                <Form.Item wrapperCol={{ span: 12, offset: 7 }}>
                    <Button type="primary" htmlType="submit">
                        充值
                    </Button>
                </Form.Item>
            </Form>

            <div>
                <div>当前输入手机号列表:</div>
                {phoneList.map(item => (
                    <div key={item.phone}>
                        +{item.code} {item.phone}
                    </div>
                ))}
            </div>
            <div>
                <div>当前输入用户名列表:</div>
                {usernameList.map(item => (
                    <div key={item}>{item}</div>
                ))}
            </div>
            <div>
                <div>充值结果：</div>
                {resultList.map(item => (
                    <div key={item.phone || item.username}>
                        {item.phone
                            ? `+${item.code} ${item.phone}`
                            : `${item.username}`}
                        {` (id: ${item.userId}) `}
                        <span
                            style={{
                                color:
                                    item.status === 'success' ? 'green' : 'red',
                            }}
                        >
                            {item.status}
                        </span>
                    </div>
                ))}
            </div>
        </div>
    );
}
