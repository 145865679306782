import { message } from 'antd';
import { getStorage } from '@boyuai/utils';
import { remote } from '@boyuai/utils';
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useReportEvent } from '@boyuai/utils';
import { isLogin } from '@boyuai/utils/es/remote';

const storage = getStorage('course-video-comment-notify');

const MESSAGE_KEY = 'comment-notify';

const openStartEndMessage = (msg, goToComment, onClose) => {
    message.open({
        content: (
            <span>
                {msg},{' '}
                <a href="//#" onClick={goToComment}>
                    去看看
                </a>
            </span>
        ),
        icon: (
            <img
                style={{ marginRight: '5px' }}
                src="https://staticcdn.boyuai.com/materials/2020/10/09/OR0ecO2rkFAbCS8o-zVRX.png!png"
                width="17px"
                alt="讨论"
            />
        ),
        duration: 10,
        key: MESSAGE_KEY,
        onClose,
    });
};

const openQuestionMessage = (msg, goToQuestion, onClose) => {
    message.open({
        content: (
            <span>
                {msg},{' '}
                <a href="//#" onClick={goToQuestion}>
                    去看看
                </a>
            </span>
        ),
        icon: (
            <img
                style={{ marginRight: '5px' }}
                src="https://staticcdn.boyuai.com/materials/2020/10/09/NvhGIKiWG5JUMFirkChTw.png!png"
                width="17px"
                alt="提问"
            />
        ),
        duration: 10,
        key: MESSAGE_KEY,
        onClose,
    });
};
const ClickType = {
    START: 1,
    END: 2,
    ONE_THIRD: 3,
};

export default function CommentNotify({
    videoUuid,
    comments,
    duration,
    currentTime,
    gotoComment,
}) {
    const [userCommentCount, setUserCommentCount] = useState(null);
    const reportEvent = useReportEvent();

    useEffect(() => {
        if (isLogin()) {
            remote
                .$get('/comment/user/count', { dayRange: 7 })
                .then(setUserCommentCount);
        }
    }, []);

    const goToDefaultComment = useCallback(
        (e, type) => {
            e.preventDefault();
            gotoComment('all');
            // 关闭message提示
            message.destroy(MESSAGE_KEY);
            reportEvent('video-comment-notification', {
                videoUuid,
                type,
            });
        },
        [gotoComment, reportEvent, videoUuid]
    );
    const goToQuestion = useCallback(
        e => {
            e.preventDefault();
            gotoComment('question');
            // 关闭message提示
            message.destroy(MESSAGE_KEY);
            reportEvent('video-comment-notification', {
                videoUuid,
                type: ClickType.ONE_THIRD,
            });
        },
        [gotoComment, reportEvent, videoUuid]
    );

    const fullscreenElement = document.fullscreenElement;

    const [startEndMsg, questionMsg, question, commentsLength] = useMemo(() => {
        const question = comments.filter(c => c.type === 'question').length;
        const note = comments.filter(c => c.type === 'note').length;
        const talk = comments.length - question - note; // 其他的都算talk
        const startEndMsg = [
            [talk, '讨论'],
            [note, '笔记', '篇'],
            [question, '提问'],
        ]
            .filter(c => c[0])
            .map(c => `${c[0]}${c[2] || '条'}${c[1]}`)
            .join('/');

        const firstQuestion = comments.find(c => c.type === 'question');
        const digest =
            firstQuestion?.text?.length <= 20
                ? firstQuestion?.text
                : firstQuestion?.text.substr(0, 20) + '...';
        const questionMsg = `“${digest}”${
            question > 1 ? '等' : ''
        }${question}条问答`;
        return [startEndMsg, questionMsg, question, comments.length];
    }, [comments]);

    const [messageConfig, setMessageConfig] = useState({
        type: null,
        msg: null,
    });

    useEffect(() => {
        if (fullscreenElement) {
            message.config({
                getContainer: () => {
                    return fullscreenElement;
                },
            });
        } else {
            message.config({
                getContainer: () => {
                    return document.body;
                },
            });
        }
        if (!messageConfig) {
            return;
        }
        message.destroy(MESSAGE_KEY);
        if (messageConfig?.type === 'startEnd') {
            openStartEndMessage(messageConfig.msg, goToDefaultComment, () =>
                setMessageConfig(null)
            );
        } else if (messageConfig?.type === 'question') {
            openQuestionMessage(messageConfig.msg, goToQuestion, () =>
                setMessageConfig(null)
            );
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fullscreenElement]);

    useEffect(() => {
        // 视频刚开始 + 用户本周无评论 + 讨论区有评论
        const allOnVideoStart =
            currentTime < 5 &&
            userCommentCount === 0 &&
            commentsLength > 0 &&
            !storage.get('all-on-video-start');
        // 视频结束前10秒 + 用户本周无评论 + 讨论区有评论
        const allOnVideoEnd =
            duration > 60 &&
            currentTime > duration - 10 &&
            userCommentCount === 0 &&
            commentsLength > 0 &&
            !storage.get('all-on-video-end');
        if (allOnVideoStart || allOnVideoEnd) {
            openStartEndMessage(
                startEndMsg,
                e =>
                    goToDefaultComment(
                        e,
                        allOnVideoStart ? ClickType.START : ClickType.END
                    ),
                () => setMessageConfig(null)
            );
            setMessageConfig({ type: 'startEnd', msg: startEndMsg });
            if (allOnVideoStart) {
                storage.set('all-on-video-start', true, { days: 1 });
            }
            if (allOnVideoEnd) {
                storage.set('all-on-video-end', true, { days: 1 });
            }
        }
        // 视频看到1/3处 + 提问数量大于1
        if (
            duration > 60 &&
            currentTime > duration / 3 &&
            currentTime < duration / 3 + 10 &&
            question &&
            !storage.get('question-on-video-1-3')
        ) {
            openQuestionMessage(questionMsg, goToQuestion, () =>
                setMessageConfig(null)
            );
            setMessageConfig({ type: 'question', msg: questionMsg });

            storage.set('question-on-video-1-3', true, { days: 1 });
        }
    }, [
        userCommentCount,
        startEndMsg,
        questionMsg,
        question,
        commentsLength,
        duration,
        currentTime,
        goToDefaultComment,
        goToQuestion,
    ]);

    return <div></div>;
}
