/** 创建go链接 **/
import React, { useCallback } from 'react';
import { Form, Input, Modal } from 'antd';
import { remote } from '@boyuai/utils';

const formItemLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 },
};

const GoFormModal = ({ visible, onOk, onCancel }) => {
    const [form] = Form.useForm();
    const onSubmit = useCallback(
        () =>
            form
                .validateFields()
                .then(res => {
                    const { url, remark } = res;
                    remote.$post('/go', { url, remark }).then(onOk);
                })
                .catch(err => {
                    console.log(err);
                }),
        [form, onOk]
    );
    return (
        <Modal
            title={'创建链接'}
            visible={visible}
            onCancel={onCancel}
            cancelText={'取消'}
            onOk={onSubmit}
            okText={'确认'}
        >
            <Form form={form} name="go">
                <Form.Item
                    key={'remark'}
                    name={'remark'}
                    label={'链接备注'}
                    rules={[{ required: true }]}
                    {...formItemLayout}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    key={'url'}
                    name={'url'}
                    label={'链接'}
                    rules={[{ required: true }]}
                    {...formItemLayout}
                >
                    <Input.TextArea rows={3} />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default GoFormModal;
