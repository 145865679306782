import React, { useCallback, useState, useMemo } from 'react';
import { Upload, Button, message, Switch, Alert } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { remote } from '@boyuai/utils';
import { CopyToClipboard } from 'react-copy-to-clipboard';

export default function AdminMaterials() {
    const MAX_UPLOAD_COUNT = 20;
    const [files, setFiles] = useState([]);
    const [isShowUploadList, setIsShowUploadList] = useState(false);
    const [compress, setCompress] = useState(true);
    const onChange = useCallback(info => {
        if (info.file.status === 'done') {
            message.success(`文件 ${info.file.name} 上传成功`);
        } else if (info.file.status === 'error') {
            message.error(`上传失败：${info.file.name}`);
        }
        const fileList = [...info.fileList].map(file => {
            if (file.response) {
                file.url = file.response.url;
            }
            return file;
        });
        setFiles(fileList);
    }, []);
    const fileLinks = useMemo(() => {
        if (!files.length) {
            return null;
        }
        return files.map(file => {
            if (!file.url)
                return {
                    fileName: file.name,
                };
            const link = file.url;
            if (
                process.env.NODE_ENV === 'production' &&
                compress &&
                /\.jpg$/.test(link)
            ) {
                return {
                    url: `${link}!jpg`,
                    fileName: file.name,
                };
            }
            if (
                process.env.NODE_ENV === 'production' &&
                compress &&
                /\.png$/.test(link)
            ) {
                return {
                    url: `${link}!png`,
                    fileName: file.name,
                };
            }
            return {
                url: link,
                fileName: file.name,
            };
        });
    }, [files, compress]);
    const beforeUpload = (_, fileList) => {
        if (fileList.length > MAX_UPLOAD_COUNT) {
            message.error(
                `每次最多上传${MAX_UPLOAD_COUNT}张图片/音频，请重新选择`
            );
            return false;
        }
    };
    return (
        <div>
            <Alert
                message={`单次最多上传${MAX_UPLOAD_COUNT}张图片/音频，请注意选取文件时的数量!!!`}
                style={{ marginBottom: 10 }}
            ></Alert>
            <Upload
                name="file"
                action={`${remote.API_ROOT}/common/user-assets`}
                headers={remote.getHeaders()}
                accept="image/*,.mp3"
                beforeUpload={beforeUpload}
                multiple={true}
                onChange={onChange}
                showUploadList={isShowUploadList}
                fileList={files}
                listType="picture"
            >
                <Button>
                    <UploadOutlined /> 点击上传图片/音频获取链接
                </Button>
            </Upload>
            <div style={{ padding: '10px' }}>
                <span>
                    <Switch checked={compress} onChange={setCompress} />{' '}
                    开启压缩
                </span>
                <span style={{ marginLeft: 20 }}>
                    <Switch
                        checked={isShowUploadList}
                        onChange={setIsShowUploadList}
                    />{' '}
                    预览文件
                </span>
            </div>
            {process.env.NODE_ENV !== 'production' && (
                <Alert message="测试服与正式服的资源不通用"></Alert>
            )}
            {fileLinks && fileLinks.length
                ? fileLinks.map(imageLink => {
                      return imageLink.url ? (
                          <div key={imageLink.url} style={{ margin: '5px 0' }}>
                              图片链接：
                              <CopyToClipboard
                                  text={imageLink.url}
                                  onCopy={() => message.success('已复制')}
                              >
                                  <span
                                      style={{
                                          color: 'blue',
                                          marginRight: '10px',
                                          cursor: 'pointer',
                                      }}
                                  >
                                      复制
                                  </span>
                              </CopyToClipboard>
                              {imageLink.url}
                          </div>
                      ) : (
                          <div style={{ margin: '5px 0' }}>
                              文件：{imageLink.fileName}上传失败，请重试
                          </div>
                      );
                  })
                : null}
        </div>
    );
}
