import React from 'react';
import styles from './payment-balance.module.less';

const ICON_CLOUD_BLUE =
    'https://staticcdn.boyuai.com/materials/2019/10/16/euU4DMZ8mSJTR2uFv-svG.jpg';
const ICON_CLOUD_RED =
    'https://staticcdn.boyuai.com/materials/2019/10/16/tBEpigMQIHWGtJoLLY0D6.jpg';

export default function PaymentBalance({ insufficient, value }) {
    return (
        <div className={styles['balance-detail']}>
            <img
                className={styles.cloud}
                src={insufficient ? ICON_CLOUD_RED : ICON_CLOUD_BLUE}
                alt="cloud"
            />
            <div className={styles.label}>云朵(余额)</div>
            <div>可用：</div>
            {insufficient ? (
                <div
                    style={{
                        color: 'red',
                    }}
                >
                    {value / 100}
                    （云朵不足）
                </div>
            ) : (
                <div
                    style={{
                        color: '#31AFF9',
                    }}
                >
                    {value / 100}
                    （云朵充足）
                </div>
            )}
        </div>
    );
}
