import { useCallback, useEffect, useState } from 'react';
import { remote } from '@boyuai/utils';

export const useUserActivities = (activityType, skip, take, phones) => {
    const [activities, setActivities] = useState([]);
    const [total, setTotal] = useState(0);
    const fetchActivities = useCallback(async () => {
        if (activityType) {
            const payload = {
                skip,
                take,
            };
            if (phones) {
                payload.phones = phones;
            }
            const res = await remote.$get(
                `/course/admin/activities/${activityType}`,
                payload
            );
            if (res) {
                const [list, count] = res;
                setActivities(list);
                setTotal(count);
            }
        }
    }, [activityType, skip, take, phones]);
    useEffect(() => {
        fetchActivities();
    }, [fetchActivities]);
    return [activities, total, fetchActivities];
};
