export default function CalculateActiveLesson() {
    let activeIndex = 0;
    const sidebarEle = document.querySelector('.sidebar-container ul');
    for (let i = 0; i < sidebarEle.childNodes.length; i++) {
        if (sidebarEle.childNodes[i].querySelector("[data-is-active='true']")) {
            activeIndex = i;
        }
    }
    return activeIndex;
}
