import React, { useState } from 'react';
import { withRouter, Link } from 'react-router-dom';
import CurrencyIntro from '@boyuai/currency-intro';
import CoursePrice from './course-price';
import AuthorIntro from '../../components/author-intro';
import BuyCourseButton from './buy-course-button';
import Card from '../../components/card';
import './intro-page.less';

const imgSrcMap = new Map([
    [
        'ElitesAI·机器学习',
        [
            // slogan
            'https://static-dev.boyuai.com/materials/2020/02/06/oH5OMHFQUBTH45767mL7S.png',
            // background
            'https://static-dev.boyuai.com/materials/2020/02/06/rWMemSfGkigHdRcYBQJDS.png',
            // intro
            'https://static-dev.boyuai.com/materials/2020/02/06/2euK5AGngCrqZTBWdCucW.png',
            // module
            'https://staticcdn.boyuai.com/materials/2020/02/19/VsyY2jk6oVlse_V7kJTDL.png!png',
            // custom
            'https://static-dev.boyuai.com/materials/2020/02/06/WvFRDJdktTtEma7XvrXs9.png',
        ],
    ],
    [
        'ElitesAI·机器学习基础',
        [
            // slogan
            'https://static-dev.boyuai.com/materials/2020/02/06/oH5OMHFQUBTH45767mL7S.png',
            // background
            'https://static-dev.boyuai.com/materials/2020/02/06/rWMemSfGkigHdRcYBQJDS.png',
            // intro
            'https://static-dev.boyuai.com/materials/2020/02/06/2euK5AGngCrqZTBWdCucW.png',
            // module
            'https://staticcdn.boyuai.com/materials/2020/02/19/l70URBnsKRusvq5D9pyZh.png!png',
            // custom
            'https://static-dev.boyuai.com/materials/2020/02/06/WvFRDJdktTtEma7XvrXs9.png',
        ],
    ],
    [
        'ElitesAI·机器学习进阶',
        [
            // slogan
            'https://static-dev.boyuai.com/materials/2020/02/06/oH5OMHFQUBTH45767mL7S.png',
            // background
            'https://static-dev.boyuai.com/materials/2020/02/06/rWMemSfGkigHdRcYBQJDS.png',
            // intro
            'https://static-dev.boyuai.com/materials/2020/02/06/2euK5AGngCrqZTBWdCucW.png',
            // module
            'https://staticcdn.boyuai.com/materials/2020/02/19/MnfhLDJGWgMadkbVIPl2n.png!png',
            // custom
            'https://static-dev.boyuai.com/materials/2020/02/06/WvFRDJdktTtEma7XvrXs9.png',
        ],
    ],
    [
        'ElitesAI·人工智能中的数学基础',
        [
            // slogan
            'https://static-dev.boyuai.com/materials/2020/02/06/6sl3kwyp5OtdyZ1iMsai9.png',
            // background
            'https://static-dev.boyuai.com/materials/2020/02/06/F4bIRPBUKEHx0iM_pt_1M.png',
            // intro
            'https://static-dev.boyuai.com/materials/2020/02/06/8wKTvvvdGHP04PqLEDWlr.png',
            // module
            'https://staticcdn.boyuai.com/materials/2020/02/14/ZA9BfWfhn7C4rl7PtooZG.png!png',
            // custom
            'https://static-dev.boyuai.com/materials/2020/02/06/j4_tgVICK5poFAkQTBwYx.png',
        ],
    ],
    [
        '人工智能思维创新课',
        [
            // slogan
            'https://staticcdn.boyuai.com/materials/2020/07/08/TjgptAyqP7jbbw08Bvdqe.jpg!jpg',
            // background
            'https://staticcdn.boyuai.com/materials/2020/07/08/PmALI-YcRe9v8MM_u-T-E.jpg!jpg',
            // intro
            'https://staticcdn.boyuai.com/materials/2020/07/08/sqWojTZNpGwQrCFGiV1ur.jpg!jpg',
            // module
            'https://staticcdn.boyuai.com/materials/2020/07/08/BtnCNAPHsvkXUa6kt8LC5.jpg!jpg',
        ],
    ],
    [
        'ElitesAI·强化学习',
        [
            // slogan
            'https://staticcdn.boyuai.com/materials/2020/07/20/1wZInTo57w994sIF-owY-.png!png',
            // background
            'https://staticcdn.boyuai.com/materials/2020/07/18/xsRw3u6SNAXPAmpO8Hx6f.png!png',
            // intro
            'https://staticcdn.boyuai.com/materials/2020/07/18/w-08Ga9QiR7QMV4BTGXx2.png!png',
            // module
            'https://staticcdn.boyuai.com/materials/2020/07/19/nhvPng1MUV3ym_eiX_m9-.png!png',
            'https://staticcdn.boyuai.com/materials/2020/07/18/gxXtOFz4z8ndoiFO9qqGQ.png!png',
            'https://staticcdn.boyuai.com/materials/2020/07/19/IpAKfdoch-Kg4xDs7iFFu.png!png',
        ],
    ],
    // ['ElitesAI·自然语言处理', [
    //     // slogan
    //     'https://static-dev.boyuai.com/materials/2020/02/06/4cWXQsmxCH_xdUCOdi7Aa.png',
    //     // background
    //     'https://static-dev.boyuai.com/materials/2020/02/06/jfIQOhmZMqS5Io-6A7doq.png',
    //     // intro
    //     'https://static-dev.boyuai.com/materials/2020/02/06/nY5-TGw6pK0TOkvnsd15K.png',
    //     // module
    //     'https://static-dev.boyuai.com/materials/2020/02/06/zx7cxW7MMbDTeX5KJTheR.png',
    //     // custom
    //     'https://static-dev.boyuai.com/materials/2020/02/06/z-7RughBvx9NaEjTAUbaP.png',
    // ]],
    // ['ElitesAI·深度学习', [
    //     // slogan
    //     'https://static-dev.boyuai.com/materials/2020/02/06/4cWXQsmxCH_xdUCOdi7Aa.png',
    //     // background
    //     'https://static-dev.boyuai.com/materials/2020/02/06/jfIQOhmZMqS5Io-6A7doq.png',
    //     // intro
    //     'https://static-dev.boyuai.com/materials/2020/02/06/nY5-TGw6pK0TOkvnsd15K.png',
    //     // module
    //     'https://static-dev.boyuai.com/materials/2020/02/06/zx7cxW7MMbDTeX5KJTheR.png',
    //     // custom
    //     'https://static-dev.boyuai.com/materials/2020/02/06/z-7RughBvx9NaEjTAUbaP.png',
    // ]],
    // ['经典方案', [
    //     // slogan
    //     'https://static-dev.boyuai.com/materials/2020/02/06/4cWXQsmxCH_xdUCOdi7Aa.png',
    //     // background
    //     'https://static-dev.boyuai.com/materials/2020/02/06/jfIQOhmZMqS5Io-6A7doq.png',
    //     // intro
    //     'https://static-dev.boyuai.com/materials/2020/02/06/nY5-TGw6pK0TOkvnsd15K.png',
    //     // module
    //     'https://static-dev.boyuai.com/materials/2020/02/06/zx7cxW7MMbDTeX5KJTheR.png',
    //     // custom
    //     'https://static-dev.boyuai.com/materials/2020/02/06/z-7RughBvx9NaEjTAUbaP.png',
    // ]],
]);

function IntroPage(props) {
    const [visible, setVisible] = useState(false);
    const { course, location } = props;
    const {
        title,
        coverImage,
        content,
        id,
        author = {},
        authors = [],
        lessons = [],
    } = course || {};
    const allAuthors = course
        ? [author, ...authors.map(item => item.author)]
        : [];
    const firstUnboughtLesson =
        lessons.length &&
        lessons.find(
            item =>
                item.lesson.videos &&
                item.lesson.videos.length &&
                !item.lesson.access &&
                item.lesson.price
        );
    const firstUnboughtLessonLink = firstUnboughtLesson
        ? `${location.pathname}/lesson/${firstUnboughtLesson.lesson.uuid}`
        : '';
    let introEle;
    const footer = (
        <div className="footer">
            <div className="text">
                <img
                    className="intro-text"
                    src="https://static-dev.boyuai.com/materials/2020/02/06/EzcTIndlr3scV75oaj03v.png"
                    alt="img"
                />
                <img
                    onClick={() => setVisible(true)}
                    className="button"
                    src="https://static-dev.boyuai.com/materials/2020/02/06/QWPMVfjcO_jvxjFxMDs2k.png"
                    alt="img"
                />
            </div>
            <img
                className="code"
                src="https://static-dev.boyuai.com/materials/2020/02/06/ygQ11WguSntzkmTlj0IOl.png"
                alt="img"
            />
        </div>
    );
    if (imgSrcMap.get(title)) {
        introEle = (
            <div className="img-wrapper" data-lesson-id={id}>
                {imgSrcMap.get(title).map((imgSrc, index) => (
                    <img src={imgSrc} alt="img" key={index} />
                ))}
                {footer}
            </div>
        );
    } else {
        introEle = (
            <>
                <Card title="课程介绍">
                    <p
                        className="course-content"
                        dangerouslySetInnerHTML={{ __html: content }}
                    ></p>
                </Card>
                <Card title="讲者介绍">
                    <div className="author-introduction">
                        {allAuthors.map(item => (
                            <AuthorIntro author={item} key={item.id} />
                        ))}
                    </div>
                </Card>
            </>
        );
    }

    const shouldHideCoursePrice = () => {
        return false;
        // return /\/course\/cZu18YmweLv10OeV/.test(window.location.pathname);
    };

    return (
        <div
            className="page-intro-wrapper"
            data-is-img={!!imgSrcMap.get(title)}
        >
            <CurrencyIntro
                visible={visible}
                onClose={() => setVisible(false)}
            />
            <Card title="课程信息">
                <div className="introduction">
                    <div className="cover-image">
                        {coverImage && <img src={coverImage} alt="课程封面" />}
                    </div>
                    <div className="course-info">
                        <h2>{title}</h2>
                        <p className="author">
                            主讲人：
                            {allAuthors.map(item => (
                                <span key={item.id}>
                                    {item.author ? item.author.name : item.name}{' '}
                                </span>
                            ))}
                        </p>
                        {course && (
                            <CoursePrice
                                course={course}
                                lightMode
                                shouldHideCoursePrice={shouldHideCoursePrice()}
                            />
                        )}
                        {/*{动手学的购买按钮不显示}*/}
                        {course && !shouldHideCoursePrice() && (
                            <div className="buy-buttons">
                                <BuyCourseButton course={course} />
                                {firstUnboughtLesson && (
                                    <Link
                                        className="buy-one"
                                        to={firstUnboughtLessonLink}
                                    >
                                        先购买一个试试
                                    </Link>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </Card>
            {introEle}
            {/* <Card title="知识图谱">
                <LessonsGraph uuid={uuid} />
            </Card> */}
        </div>
    );
}

export default withRouter(IntroPage);
