import React, { useEffect, useState } from 'react';
import { remote } from '@boyuai/utils';
import { Button } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import { Title } from './title';
import './ai.less';
import { renderCard } from './card';

const AICourses = () => {
    const [courses, setCourses] = useState([]);
    useEffect(() => {
        remote
            .$get('/course', {
                site: process.env.REACT_APP_SITE,
            })
            .then(data => {
                if (data?.[0]) {
                    setCourses(data?.[0]);
                }
            });
    }, []);

    const mainCourses = courses.filter(
        c => c?.isReady && c?.tags?.some(t => t === '核心课程')
    );
    const basicCourses = courses.filter(
        c => c?.isReady && c?.tags?.some(t => t === '基础课程')
    );
    const specialCourses = courses.filter(
        c => c?.isReady && c?.tags?.some(t => t === '专项课程')
    );
    const renderCourse = (c, i, arr) => {
        const listNum = arr.length;
        const isLastLine = i >= listNum - (listNum % 5) && i < listNum;
        return renderCard(c, true, false, isLastLine);
    };

    const renderCourseBlock = ({ courses, name, bg }) => {
        return (
            <React.Fragment key={name}>
                <div
                    className="course-category"
                    style={{
                        backgroundImage: `url(${bg})`,
                    }}
                >
                    <span>{name}</span>
                </div>
                <div
                    className="courses"
                    style={{
                        width: courses.length % 5 === 0 ? 750 : undefined,
                    }}
                >
                    {courses.map(renderCourse)}
                </div>
            </React.Fragment>
        );
    };

    return (
        <div id="ai" className="ai-courses">
            <img
                style={{
                    position: 'absolute',
                    top: -60,
                    right: 50,
                }}
                alt="top-right-decoration"
                src="https://staticcdn.boyuai.com/materials/2020/11/12/pFc1f0vP0g90FQ9768v_F.svg"
            />
            <Title
                title="人工智能微专业"
                intro="源于上海交大ACM班的人工智能专业课程，培养卓越AI算法工程师、研究员"
            />
            <div className="specs">
                <div className="spec">
                    <img
                        alt="ACM"
                        src="https://staticcdn.boyuai.com/materials/2020/11/12/HaLKWAR-9WaHRJfRXmSG7.svg"
                    />
                    <span>上交 ACM 班理论体系</span>
                </div>
                <div className="spec">
                    <img
                        alt="team"
                        src="https://staticcdn.boyuai.com/materials/2020/11/12/tJ0M3hWYEuaDpyEiea3nN.svg"
                    />
                    <span>顶尖高校教授团队</span>
                </div>
                <div className="spec">
                    <img
                        alt="individuality"
                        src="https://staticcdn.boyuai.com/materials/2020/11/12/A71v6KYYL39EWrbSS2u4l.svg"
                    />
                    <span>个性化学习路径</span>
                </div>
            </div>
            <div className="courses-wrapper">
                {[
                    {
                        name: '核心课程',
                        courses: mainCourses,
                        bg:
                            'https://staticcdn.boyuai.com/materials/2020/11/12/kNoCHkmLkdlQ5pNuBISEm.svg',
                    },
                    {
                        name: '基础课程',
                        courses: basicCourses,
                        bg:
                            'https://staticcdn.boyuai.com/materials/2020/11/12/dwwtvrdX6z7UPZFiIXFTc.svg',
                    },
                    {
                        name: '专项课程',
                        courses: specialCourses,
                        bg:
                            'https://staticcdn.boyuai.com/materials/2020/11/12/pe0H5NlNmSBvCBvXHDtxv.svg',
                    },
                ].map(renderCourseBlock)}
                <Button
                    className="full-plan-btn"
                    type="primary"
                    onClick={() =>
                        window.open(
                            'https://opendl.boyuai.com/website/boyu-ai-degree.pdf'
                        )
                    }
                >
                    查看完整培养方案
                    <ArrowRightOutlined />
                </Button>
            </div>
        </div>
    );
};

export { AICourses };
