import { useState, useEffect } from 'react';
import { remote } from '@boyuai/utils';

export default function useCoursePrice(uuid) {
    const [price, setPrice] = useState(0);
    useEffect(() => {
        if (uuid && remote.isLogin) {
            remote
                .$post(
                    `/course/${uuid}/buy`,
                    {},
                    { throwException: 'exceptLogin' }
                )
                .then(({ price }) => {
                    setPrice(price / 100);
                })
                .catch(e => {
                    if (e.response.data.message === '已经购买过该课程') {
                        setPrice(-1); // -1 代表已购买
                    } else {
                        throw e;
                    }
                });
        }
    }, [uuid]);
    return price;
}
