import React from 'react';
import { Title } from './title';
import './public.less';
import { renderCard } from './card';

const staticCourseList = [
    {
        title: '10 mins paper',
        intro: '人工智能领域论文解读，共享最强大脑',
        id: 1,
        listImage:
            'https://staticcdn.boyuai.com/materials/2020/11/16/ynzRzyWkRPoHsHeT_wiw3.svg',
        staticLink:
            'https://space.bilibili.com/447303411/channel/detail?cid=136884',
    },
    {
        title: 'ACM班学术节',
        intro: '交流思想、探讨学术',
        id: 2,
        listImage:
            'https://staticcdn.boyuai.com/materials/2020/11/16/tNLt69GGadP8uJUaht4Bx.svg',
        staticLink:
            'https://space.bilibili.com/447303411/channel/detail?cid=136883',
    },
    {
        title: '名师讲座',
        intro: '名校教授主题分享',
        id: 3,
        listImage:
            'https://staticcdn.boyuai.com/materials/2020/11/16/tDWKXHmClUCEK6a9MjvFj.svg',
        staticLink: 'https://space.bilibili.com/447303411',
    },
    {
        title: '数据分析直播课（即将上线）',
        intro: '3天带你手把手完成数据采集、分析、可视化实战项目术',
        id: 4,
        listImage:
            'https://staticcdn.boyuai.com/materials/2020/11/16/a7g9zUvI-A39f2f1Somdu.svg',
        staticLink: '/',
    },
];

const PublicClasses = () => {
    return (
        <div id="public-class" className="public-classes">
            <Title
                title="精品公开课"
                intro="专题讲座、论文精读、公益课程，饮水思源、共创共享"
            />
            <div className="courses">
                {staticCourseList.map((c, i, arr) => {
                    const listNum = arr.length;
                    const isLastLine =
                        i >= listNum - (listNum % 5) && i < listNum;
                    return renderCard(c, true, false, isLastLine);
                })}
            </div>
        </div>
    );
};

export { PublicClasses };
