import React, { useCallback, useEffect, useState } from 'react';
import { $get } from '@boyuai/utils/es/remote';
import { Select, Tabs, Pagination, Empty, Spin } from 'antd';
import './index.less';
import UserComment from './comment';

// $get('/comment/user/discussions', {offset: 0, limit: 19, type: 'all'});
// type = 'all' | 'talk' | 'question' | 'note' | 'question-reply' | 'question-talk' | 'other'
// 其中 'other' = 'question-reply' | 'question-talk'
// limit每页数量，offset偏置，取得序号为offset ~ offset + limit - 1的知识点
// 返回值[list, count]，
// comment.parent 父节点
// comment.replyCount 回复数量
// comment.likeCount 点赞数

const discussionTabs = [
    { key: 'all', title: '全部' },
    { key: 'question', title: '我的提问' },
    { key: 'question-reply', title: '我的回答' },
    { key: 'other', title: '其他' }, // 'question-reply' | 'question-talk' | 'note'
];

const likeTabs = [
    { key: 'all', title: '全部' },
    { key: 'questionDiscussion', title: '赞过的问答' }, // 'question' + 'question-reply' + 'question-talk'
    { key: 'note', title: '赞过的笔记' },
    { key: 'talk', title: '赞过的闲聊' },
];

const MY_DISCUSSIONS = '0';
const MY_LIKES = '1';

const PAGE_SIZE = 20;

export default function MyDiscussions() {
    const [current, setCurrent] = useState(MY_DISCUSSIONS); // 讨论/点赞
    const [loading, setLoading] = useState(false);

    const [discussionType, setDiscussionType] = useState('all');
    const [discussionOrder, setDiscussionOrder] = useState('createdAt');
    const [discussions, setDiscussions] = useState([]);
    const [discussionCount, setDiscussionCount] = useState(null);
    const [currentDiscussionPage, setCurrentDiscussionPage] = useState(1);

    const [likeType, setLikeType] = useState('all');
    // const [likeOrder, setLikeOrder] = useState('createdAt');
    const likeOrder = 'createdAt';
    const [likes, setLikes] = useState([]);
    const [likeCount, setLikeCount] = useState(null);
    const [currentLikePage, setCurrentLikePage] = useState(1);

    const fetchMyDiscussions = useCallback(async () => {
        setLoading(true);
        let [list, count] = await $get('/comment/user/discussions', {
            offset: (currentDiscussionPage - 1) * PAGE_SIZE,
            limit: PAGE_SIZE,
            type: discussionType,
            order: discussionOrder,
        });
        setLoading(false);
        // console.log('fetchMyDiscussions', [list, count]);
        setDiscussionCount(count);
        setDiscussions(list);
    }, [discussionType, discussionOrder, currentDiscussionPage]);

    const fetchMyLikes = useCallback(async () => {
        setLoading(true);
        let [list, count] = await $get('/comment/user/likes', {
            offset: (currentLikePage - 1) * PAGE_SIZE,
            limit: PAGE_SIZE,
            type: likeType,
            order: likeOrder,
        });
        setLoading(false);
        // console.log('fetchMyLikes', [list, count]);
        setLikeCount(count);
        setLikes(list);
    }, [likeType, likeOrder, currentLikePage]);

    const onUpdateCommentLike = useCallback(
        async (commentId, likeCount, likeLog) => {
            setLikes(l =>
                l.map(o => {
                    if (o.id === commentId) {
                        return {
                            ...o,
                            likeCount,
                            likeLog,
                        };
                    }
                    return o;
                })
            );
        },
        []
    );

    useEffect(() => {
        if (current === MY_DISCUSSIONS) {
            fetchMyDiscussions();
        }
    }, [current, fetchMyDiscussions]);

    useEffect(() => {
        if (current === MY_LIKES) {
            fetchMyLikes();
        }
    }, [current, fetchMyLikes]);

    let discussionEmptyText = (
        <>
            讨论区有三言两语的闲聊和层层叠叠的提问
            <br />
            以及如瀑布般的笔记
            <br />
            冲开少年淡淡的心事。
            <br />
            快在讨论区留下点什么吧~~
        </>
    );
    if (discussionType === 'question') {
        discussionEmptyText = '助教们都已整装待发，就等你提出问题了~~';
    } else if (discussionType === 'question-reply') {
        discussionEmptyText = '学完了知识点，何不试试帮助小伙伴们答疑解惑？';
    } else if (discussionType === 'other') {
        discussionEmptyText = (
            <>
                三言两语冲开你的淡淡疲倦，
                <br />
                快和小伙伴们互动交流吧~~
            </>
        );
    }

    let discussionEle;
    if (loading) {
        discussionEle = (
            <div className="loading">
                <Spin />
            </div>
        );
    } else if (discussionCount === 0) {
        discussionEle = (
            <Empty
                description={discussionEmptyText}
                style={{ margin: '150px 0' }}
            />
        );
    } else {
        discussionEle = discussions.map(item => (
            <UserComment
                key={item.id}
                comment={item}
                hideUser={true}
                onDelete={fetchMyDiscussions}
            />
        ));
    }

    let likeEmptyText = (
        <>
            我们不能因为夜幕的浩渺，
            <br />
            而否定了星辰的深刻，
            <br />
            就像我们不能因为生活的平淡，
            <br />
            而熄灭了内心闪烁的欲望。
            <br />
            快去讨论区点个赞吧~~
            <br />
        </>
    );
    if (likeType === 'questionDiscussion') {
        likeEmptyText = (
            <>
                你的一点微光，会给予他人莫大的力量。
                <br />
                试着给你欣赏的内容点个赞~~
            </>
        );
    } else if (likeType === 'note') {
        likeEmptyText = '没能得到你的赞，小伙伴们都觉得怀才不遇。';
    } else if (likeType === 'talk') {
        likeEmptyText = '“煎”和“熬”都是可以变美味的方式，点“赞”也是！';
    }

    let likeEle;
    if (loading) {
        likeEle = (
            <div className="loading">
                <Spin />
            </div>
        );
    } else if (likeCount === 0) {
        likeEle = (
            <Empty description={likeEmptyText} style={{ margin: '150px 0' }} />
        );
    } else {
        likeEle = likes.map(item => (
            <UserComment
                key={item.id}
                comment={item}
                isLike={true}
                onUpdateCommentLike={onUpdateCommentLike}
                onDelete={fetchMyLikes}
            />
        ));
    }

    return (
        <div className="page-user-discussion">
            <Tabs
                defaultActiveKey={MY_DISCUSSIONS}
                onChange={v => setCurrent(v)}
                size={'large'}
            >
                <Tabs.TabPane tab="我的讨论" key={MY_DISCUSSIONS}>
                    <Tabs
                        defaultActiveKey={MY_DISCUSSIONS}
                        onChange={v => {
                            setDiscussionType(v);
                            setCurrentDiscussionPage(1);
                        }}
                        tabPosition={'left'}
                    >
                        {discussionTabs.map(o => (
                            <Tabs.TabPane tab={o.title} key={o.key}>
                                <div
                                    style={{
                                        overflowY: 'scroll',
                                        maxHeight: 694,
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-between',
                                            padding: '0 16px 12px 16px',
                                            position: 'sticky',
                                            top: 0,
                                            zIndex: 99,
                                            backgroundColor: '#fff',
                                        }}
                                    >
                                        {/*<img
                                        src="https://staticcdn.boyuai.com/materials/2020/04/02/IJw9hNZsFLb6CEljAvBf7.png!png"
                                        alt={''}
                                    />*/}
                                        <Pagination
                                            showQuickJumper
                                            defaultCurrent={1}
                                            current={currentDiscussionPage}
                                            total={discussionCount}
                                            pageSize={PAGE_SIZE}
                                            onChange={setCurrentDiscussionPage}
                                        />
                                        <Select
                                            // size={'small'}
                                            style={{ width: 120 }}
                                            defaultValue={'createdAt'}
                                            onChange={v => {
                                                setDiscussionOrder(v);
                                                setCurrentDiscussionPage(1);
                                            }}
                                        >
                                            <Select.Option value={'createdAt'}>
                                                按发布时间
                                            </Select.Option>
                                            <Select.Option value={'likeCount'}>
                                                按点赞数
                                            </Select.Option>
                                            <Select.Option value={'replyCount'}>
                                                按回复数
                                            </Select.Option>
                                        </Select>
                                    </div>
                                    {discussionEle}
                                </div>
                            </Tabs.TabPane>
                        ))}
                    </Tabs>
                </Tabs.TabPane>
                <Tabs.TabPane tab="我的点赞" key={MY_LIKES}>
                    <Tabs
                        defaultActiveKey={MY_LIKES}
                        onChange={v => {
                            setLikeType(v);
                            setCurrentLikePage(1);
                        }}
                        tabPosition={'left'}
                    >
                        {likeTabs.map(o => (
                            <Tabs.TabPane tab={o.title} key={o.key}>
                                <div
                                    style={{
                                        overflowY: 'scroll',
                                        maxHeight: 694,
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-between',
                                            padding: '0 16px 12px 16px',
                                            position: 'sticky',
                                            top: 0,
                                            zIndex: 99,
                                            backgroundColor: '#fff',
                                        }}
                                    >
                                        <Pagination
                                            showQuickJumper
                                            defaultCurrent={1}
                                            current={currentLikePage}
                                            total={likeCount}
                                            pageSize={PAGE_SIZE}
                                            onChange={setCurrentLikePage}
                                        />
                                    </div>
                                    {/*<Select
                                    style={{ width: 200 }}
                                    defaultValue={'createdAt'}
                                    onChange={v => {
                                        setLikeOrder(v)
                                        setCurrentLikePage(1)
                                    }}
                                >
                                    <Select.Option value={'createdAt'}>
                                        按时间倒序
                                    </Select.Option>
                                    <Select.Option value={'likeCount'}>
                                        点赞数
                                    </Select.Option>
                                    <Select.Option value={'replyCount'}>
                                        回复数
                                    </Select.Option>
                                </Select>*/}
                                    {likeEle}
                                </div>
                            </Tabs.TabPane>
                        ))}
                    </Tabs>
                </Tabs.TabPane>
            </Tabs>
        </div>
    );
}
