import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { remote } from '@boyuai/utils';
import { Select, Button, Popconfirm, Popover } from 'antd';
import AdminTable from './table';
import AdminFormModal from './form-modal';
import { useCourseAuthors, useCourseLessons } from '../../hooks/courseHooks';
import Markdown from '../../components/markdown';
import LessonContent from './components/lesson-content';
import GlobalLessonBatchProcess from './components/global-lesson-batch-process';
import LessonBatchProcess from './components/lesson-batch-process';
import './lessons.less';

const formItems = [
    {
        label: '标题',
        name: 'title',
        type: 'input',
        required: true,
    },
    {
        label: '排序',
        name: 'order',
        type: 'number',
        required: true,
    },
    {
        label: 'desc',
        name: 'desc',
        type: 'textarea',
    },
];

const editFormItems = [
    {
        label: '标题',
        name: 'title',
        required: true,
    },
    {
        label: '排序',
        name: 'order',
        type: 'number',
        extra: (
            <span style={{ color: '#a9a9a9' }}>* 请注意不要输入重复序号</span>
        ),
        required: true,
    },
    {
        label: '关键词',
        name: 'keywords',
        type: 'tags',
        required: true,
    },
    {
        label: '飞书机器人token',
        name: 'larkRobotForQuestions',
        type: 'tags-new',
    },
    {
        label: '折扣',
        name: 'discount',
        type: 'number',
        required: true,
    },
];

export default function AdminLessons() {
    const [courses, setCourses] = useState([]);
    const [course, setCourse] = useState(); // 这是id
    const [courseUUID, setCourseUUID] = useState();
    const [authors, setAuthors] = useState([]);
    const [lessonModalVisible, setLessonModalVisible] = useState(false); // 添加知识点
    const [lessonEditModalVisible, setLessonEditModalVisible] = useState(false); // 编辑知识点
    const [authorModalVisible, setAuthorModalVisible] = useState(false);
    const [currentEditLesson, setCurrentEditLesson] = useState(null); // 编辑知识点时的原始lesson
    // todo: 改成hook
    const fetchCourses = useCallback(() => {
        remote.$get('/course/admin/courses').then(([courses]) => {
            setCourses(courses);
            let couseId = courses[0].id;
            let currentCourseUUID = courses[0].uuid;
            if (window.location.search) {
                const search = /courseId=(\d*)/.exec(window.location.search);
                if (search) {
                    couseId = Number(search[1]);
                    currentCourseUUID = courses.find(
                        course => course.id === couseId
                    ).uuid;
                }
            }
            setCourse(couseId);
            setCourseUUID(currentCourseUUID);
        });
    }, []);

    useEffect(() => fetchCourses(), [fetchCourses]);

    const fetchAuthors = useCallback(() => {
        remote.$get('/course/admin/authors').then(authors => {
            setAuthors(authors);
        });
    }, []);

    useEffect(() => fetchAuthors(), [fetchAuthors]);

    const [courseAuthors, refreshCourseAuthors] = useCourseAuthors(course);

    const [courseLessons, refreshCourseLessons] = useCourseLessons(course);

    const onSelectCourse = useCallback(id => {
        window.location.href = `${window.location.pathname}?courseId=${id}`;
    }, []);

    // 为course增加lesson知识点
    const onCreateLesson = useCallback(
        async data => {
            await remote.$post(
                `/course/admin/courses/${course}/lessons`,
                data,
                {
                    throwException: 'showMessage',
                }
            );
            setLessonModalVisible(false);
            refreshCourseLessons();
        },
        [course, refreshCourseLessons]
    );

    const onDeleteLesson = useCallback(
        async id => {
            await remote.$delete(
                `/course/admin/courses/0/lessons/${id}`,
                {},
                { throwException: 'showMessage' }
            );
            refreshCourseLessons();
        },
        [refreshCourseLessons]
    );

    // 编辑lesson
    const onEditLesson = useCallback(
        async data => {
            let {
                keywords,
                discount,
                title,
                order,
                larkRobotForQuestions,
            } = data;
            const { id } = currentEditLesson;
            await remote.$patch(
                `/course/admin/courses/${course}/lessons/${id}`,
                {
                    keywords: keywords.toString(),
                    discount,
                    title,
                    order,
                    larkRobotForQuestions,
                },
                { throwException: 'showMessage' }
            );
            setLessonEditModalVisible(false);
            refreshCourseLessons();
        },
        [course, currentEditLesson, refreshCourseLessons]
    );

    // 为course添加author
    const onCreateAuthor = useCallback(
        async data => {
            for (const authorId of data.authors) {
                await remote.$post(
                    `/course/admin/courses/${course}/authors`,
                    { authorId },
                    { throwException: 'showMessage' }
                );
            }
            setAuthorModalVisible(false);
            refreshCourseAuthors();
        },
        [course, refreshCourseAuthors]
    );

    // 解除author和course的绑定
    const onDeleteAuthor = useCallback(
        async id => {
            await remote.$delete(
                `/course/admin/courses/${course}/authors/${id}`,
                {},
                { throwException: 'showMessage' }
            );
            setCourse(null);
            fetchCourses();
        },
        [course, fetchCourses]
    );

    // 作者头像浮窗放大
    const popOverAuthorImage = useCallback(imgSrc => {
        return <img style={{ width: '160px' }} src={imgSrc} alt={imgSrc} />;
    }, []);

    const lessonColumns = [
        {
            title: '标题',
            fixed: 'left',
            dataIndex: 'title',
            key: 'title',
            width: 200,
            render: (title, record) => (
                <Link to={`/admin/lessons/${record.id}`}>{title}</Link>
            ),
        },
        {
            title: '关键词',
            dataIndex: 'keywords',
            key: 'keywords',
            width: 240,
            render: item => {
                return item
                    ? [
                          ...item
                              .split(',')
                              .map(keyword => (
                                  <div key={keyword}>{keyword}</div>
                              )),
                      ]
                    : null;
            },
        },
        {
            title: '知识点内容',
            dataIndex: 'id',
            key: 'lessonContents',
            width: 140,
            render: id => <LessonContent lessonId={id} />,
        },
        {
            title: '相关资源',
            dataIndex: 'desc',
            key: 'desc',
            width: 100,
            render: value =>
                value ? (
                    <Popover
                        content={<Markdown content={value} />}
                        title="相关资源"
                    >
                        <Button type="link">预览</Button>
                    </Popover>
                ) : (
                    '无'
                ),
        },
        {
            title: '创建时间',
            dataIndex: 'createdAt',
            key: 'createdAt',
            width: 140,
            render: data => moment(data).format('YYYY-MM-DD'),
        },
        {
            title: '排序',
            dataIndex: 'order',
            key: 'order',
            width: 140,
            sorter: (a, b) => a.order - b.order,
        },
        {
            title: '价格',
            dataIndex: 'price',
            key: 'price',
            width: 140,
            render: data => `${data}(${data / 100}元)`,
        },
        {
            title: '原价',
            dataIndex: 'originalPrice',
            key: 'originalPrice',
            width: 140,
            render: data => `${data}(${data / 100}元)`,
        },
        {
            title: '折扣',
            dataIndex: 'discount',
            key: 'discount',
            width: 140,
            render: data => `${data / 10}折`,
        },
        {
            title: '操作',
            fixed: 'right',
            dataIndex: 'id',
            key: 'operations',
            width: 180,
            render: (id, record) => (
                <div>
                    <Button
                        className="admin-lesson-operation-button"
                        type="primary"
                        onClick={() =>
                            window.open(
                                `${process.env.REACT_APP_SUB_DIRECTORY}/course/${courseUUID}/lesson/${record.uuid}`
                            )
                        }
                    >
                        前往
                    </Button>
                    <Popconfirm
                        title="确定要删除吗"
                        onConfirm={() => onDeleteLesson(id)}
                    >
                        <Button
                            type="danger"
                            className="admin-lesson-operation-button"
                        >
                            删除
                        </Button>
                    </Popconfirm>
                    <Button
                        className="admin-lesson-operation-button"
                        onClick={() => {
                            setLessonEditModalVisible(true);
                            setCurrentEditLesson(
                                courseLessons.filter(item => item.id === id)[0]
                            );
                        }}
                    >
                        编辑
                    </Button>
                </div>
            ),
        },
    ];

    const authorColumns = [
        {
            title: '照片',
            dataIndex: 'coverImage',
            key: 'coverImage',
            render: coverImage => (
                <Popover content={popOverAuthorImage(coverImage)}>
                    <img
                        style={{ width: '60px' }}
                        src={coverImage}
                        alt={coverImage}
                    />
                </Popover>
            ),
        },
        {
            title: '姓名',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '头衔',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: '简介',
            dataIndex: 'content',
            key: 'content',
        },
        {
            title: '操作',
            dataIndex: 'id',
            key: 'operations',
            render: id => (
                <Popconfirm
                    title="确定要删除吗"
                    onConfirm={() => onDeleteAuthor(id)}
                >
                    <Button type="danger">删除</Button>
                </Popconfirm>
            ),
        },
    ];
    if (!courses || !courses.length || !course) {
        return null;
    }
    return (
        <div>
            <div>
                <GlobalLessonBatchProcess />
                <h3>
                    <Select
                        value={course}
                        style={{ width: '300px', marginBottom: '10px' }}
                        onChange={onSelectCourse}
                    >
                        {courses.map(course => (
                            <Select.Option value={course.id} key={course.id}>
                                {course.title}
                                {course.hide ? (
                                    <span
                                        style={{
                                            color: '#F0765F',
                                        }}
                                    >
                                        （隐藏课程）
                                    </span>
                                ) : null}
                            </Select.Option>
                        ))}
                    </Select>
                    &nbsp;的知识点列表 &nbsp;
                    <Button onClick={() => setLessonModalVisible(true)}>
                        添加知识点
                    </Button>
                    &nbsp;&nbsp;
                    <LessonBatchProcess courseId={course} />
                </h3>
                <AdminFormModal
                    title="添加知识点"
                    visible={lessonModalVisible}
                    onCancel={() => setLessonModalVisible(false)}
                    onSubmit={onCreateLesson}
                    formItems={formItems}
                />
                {lessonEditModalVisible && (
                    <AdminFormModal
                        title="编辑知识点"
                        visible={true}
                        onCancel={() => setLessonEditModalVisible(false)}
                        onSubmit={onEditLesson}
                        formItems={editFormItems}
                        formValues={currentEditLesson}
                    />
                )}
                <AdminTable
                    data={courseLessons}
                    scroll={{ x: 1800, y: 460 }}
                    columns={lessonColumns}
                    expandable={{
                        expandedRowRender: lesson => (
                            <div>
                                飞书机器人列表：
                                {lesson.larkRobotForQuestions &&
                                lesson.larkRobotForQuestions.length
                                    ? lesson.larkRobotForQuestions.map(
                                          (larkRobot, index) => (
                                              <div key={index}>{larkRobot}</div>
                                          )
                                      )
                                    : '空'}
                            </div>
                        ),
                        rowExpandable: lesson =>
                            lesson.larkRobotForQuestions &&
                            lesson.larkRobotForQuestions.length,
                    }}
                    hidePagination
                />
            </div>
            <div style={{ marginTop: '20px' }}>
                <h3>
                    课程作者{' '}
                    <Button onClick={() => setAuthorModalVisible(true)}>
                        添加作者
                    </Button>
                </h3>
                <AdminFormModal
                    title="添加作者"
                    visible={authorModalVisible}
                    onCancel={() => setAuthorModalVisible(false)}
                    onSubmit={onCreateAuthor}
                    formItems={[
                        {
                            label: '作者',
                            name: 'authors',
                            type: 'select',
                            mode: 'multiple',
                            placeholder: '请选择课程作者',
                            datasource: [...authors].map(author => {
                                const res = {
                                    value: author.id,
                                    text: author.name,
                                    key: author.id,
                                };
                                for (let courseAuthor of courseAuthors) {
                                    if (courseAuthor.id === author.id) {
                                        return { ...res, isDisable: true };
                                    }
                                }
                                return { ...res, isDisable: false };
                            }),
                            required: true,
                        },
                    ]}
                />
                <AdminTable data={courseAuthors} columns={authorColumns} />
            </div>
        </div>
    );
}
