import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Spin, Empty } from 'antd';
import { $get } from '@boyuai/utils/es/remote';
import Comment from './comment';
import './index.less';

export default function CommentList({ type }) {
    const [comments, setComments] = useState(null);
    const [hasMore, setHasMore] = useState(false);
    const fetch = useCallback(
        (reload = false) => {
            if (!hasMore && !reload) {
                return;
            }
            const offset = reload ? 0 : comments ? comments.length : 0;
            $get(`/comment/user/${type}`, { offset }).then(([items, count]) => {
                setHasMore(items.length + offset < count);
                setComments(prevComments => {
                    if (prevComments && !reload) {
                        return [...prevComments, ...items];
                    }
                    return items;
                });
            });
        },
        [comments, type, hasMore]
    );

    useEffect(() => {
        setComments(null);
        fetch(true);
        // eslint-disable-next-line
    }, [type]);

    const commentsContent = useMemo(() => {
        if (!comments) {
            return null;
        }
        if (!comments.length) {
            return (
                <Empty
                    style={{ marginTop: '100px' }}
                    description={`暂无${type === 'note' ? '笔记' : '提问'}`}
                />
            );
        }
        return comments.map(comment => (
            <Comment
                key={comment.id}
                comment={comment}
                onDelete={() => fetch(true)}
            />
        ));
    }, [comments, fetch, type]);

    return (
        <div className="ui-user-comment-list">
            <Spin spinning={!comments}>
                {commentsContent}
                {hasMore && (
                    <div className="load-more" onClick={fetch}>
                        加载更多
                    </div>
                )}
            </Spin>
        </div>
    );
}
