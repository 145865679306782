import React, { useState, useCallback } from 'react';
import { Modal } from 'antd';
import Player from '@boyuai/player';
import './intro.less';

export default function CreditIntro({ visible, onClose }) {
    const [lessonVisible, setLessonVisible] = useState(false);
    const [courseVisible, setCourseVisible] = useState(false);
    const [showPlayerCover, setShowPlayerCover] = useState(true);
    const clearIntro = () => {
        setLessonVisible(false);
        setCourseVisible(false);
    };
    const play = useCallback(() => {
        setShowPlayerCover(false);
    }, []);
    const files = [
        {
            id: 2198,
            type: 'hd',
            createdAt: '2020-02-09T11:23:04.000Z',
            bitrate: 779,
            size: 151470472,
            duration: 1556,
            format: 'm3u8',
            width: 960,
            height: 540,
            url:
                'https://ali.videocdn.boyuai.com/customerTrans/4cdaa952dd4cbd36cd7ce45b45068842/386ccc0d-17075f80819-0006-8681-971-cb056.mp4',
        },
    ];
    return (
        <Modal
            visible={visible}
            onCancel={onClose}
            footer={null}
            width={1096}
            style={{ top: '0px' }}
            wrapClassName="activity-qianren-ends-intro-modal"
            destroyOnClose
            maskStyle={{ background: 'rgba(0,0,0,0.6)' }}
            closable={false}
        >
            <div className="activity-qianren-ends-intro-modal-main">
                <div className="background-bg" />
                <div className="background" />
                <img
                    onClick={onClose}
                    className="close-button"
                    src="https://staticcdn.boyuai.com/materials/2020/02/24/MPk_uBjcortZgs-EYMRKM.png!png"
                    alt="关闭"
                />
                {!showPlayerCover && (
                    <div className="play">
                        <Player
                            videos={files}
                            onGetInstance={player => player.play()}
                        />
                    </div>
                )}
                {showPlayerCover && (
                    <img
                        className="play-cover"
                        onClick={play}
                        alt="播放"
                        src="https://staticcdn.boyuai.com/materials/2020/02/23/ppwXmXw-8Ml_r7jU_vsEx.png!png"
                    />
                )}
                {lessonVisible && (
                    <div className="buy-lesson-menu">
                        <img
                            src="https://staticcdn.boyuai.com/materials/2020/02/24/j9P5zKIB2vSWJLQMx1gCE.png!png"
                            alt=""
                            width="185px"
                        />
                        <a
                            href="https://www.boyuai.com/elites/course/zDQMBV778Mu9td4p/lesson/KUUQ-S94Zqf9WG6JqHRxU"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <div>激活与损失函数</div>
                            <div>990雨滴</div>
                        </a>
                        <a
                            href="https://www.boyuai.com/elites/course/xVqhU42F5IDky94x/lesson/7p5LyKWAwBZg4KGOVatSW"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <div>深度强化学习</div>
                            <div>490雨滴</div>
                        </a>
                        <a
                            href="https://www.boyuai.com/elites/course/9SPUuFz3rvpkmbkn/lesson/ciXot1F4Nre5wqh0yEA5M"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <div>词嵌入展示</div>
                            <div>1290雨滴</div>
                        </a>
                        <a
                            href="https://www.boyuai.com/elites/course/jbXtxc2nxNSz8CWH/lesson/SJSI7NvnbXpSDC--g9U3m"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <div>协同过滤算法</div>
                            <div>1290雨滴</div>
                        </a>
                    </div>
                )}
                {courseVisible && (
                    <div className="buy-course-menu">
                        <img
                            src="https://staticcdn.boyuai.com/materials/2020/02/24/j9P5zKIB2vSWJLQMx1gCE.png!png"
                            alt=""
                            width="185px"
                        />
                        <a
                            href="https://www.boyuai.com/elites/course/zDQMBV778Mu9td4p"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <div>深度学习</div>
                            <div>讲师：严骏驰</div>
                        </a>
                        <a
                            href="https://www.boyuai.com/elites/course/9SPUuFz3rvpkmbkn"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <div>自然语言处理</div>
                            <div>讲师：屠可伟</div>
                        </a>
                        <a
                            href="https://www.boyuai.com/elites/course/xVqhU42F5IDky94x"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <div>强化学习</div>
                            <div>讲师：张伟楠</div>
                        </a>
                        <a
                            href="https://www.boyuai.com/elites/course/jbXtxc2nxNSz8CWH"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <div>信息检索</div>
                            <div>讲师：张伟楠</div>
                        </a>
                    </div>
                )}
                <img
                    onClick={() => setLessonVisible(true)}
                    className="buy-lesson"
                    src="https://staticcdn.boyuai.com/materials/2020/02/23/cib7GnV3VpFS3naQzXr2P.png!png"
                    alt="购买知识点"
                    width="200px"
                />
                <img
                    onClick={() => setCourseVisible(true)}
                    className="buy-course"
                    src="https://staticcdn.boyuai.com/materials/2020/02/24/d_qATe5yTcG5AEMpxCNZ3.png!png"
                    alt="购买知识点"
                    width="198px"
                />
                {(lessonVisible || courseVisible) && (
                    <div className="mask" onClick={clearIntro} />
                )}
                <img
                    className="buy-lesson-intro"
                    src="https://staticcdn.boyuai.com/materials/2020/02/24/stpcY6-aDzghfftLxvwiM.png!png"
                    alt="购买知识点"
                    width="316px"
                />
                <img
                    className="buy-course-intro"
                    src="https://staticcdn.boyuai.com/materials/2020/02/24/_863C72r5BDj70hAd_NWs.png!png"
                    alt="购买知识点"
                    width="316px"
                />
            </div>
        </Modal>
    );
}
