import React, { useCallback, useMemo, useState } from 'react';
import { remote } from '@boyuai/utils';
import { linkIcon } from '@boyuai/comment';
import { Button, Form, Input, message, Popconfirm, Popover, Table } from 'antd';
import AdminFormModal from '../form-modal';
import { usePublicVideos } from '../../../hooks/publicVideoHooks';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { debounce } from 'lodash';

const translateVideoFileType = str => {
    switch (str) {
        case 'hd':
            return '超清HD';
        case 'sd':
            return '高清SD';
        case 'ld':
            return '标清LD';
        default:
            return null;
    }
};

const formItems = [
    {
        label: '视频标题',
        name: 'title',
        required: true,
    },
    {
        label: '视频ID',
        name: 'vid',
        type: 'video',
        required: true,
    },
    {
        label: '封面图',
        name: 'cover',
        type: 'upload',
        required: false,
        endpoint: '/course/admin/coverImage',
    },
];

const editFormItem = [
    {
        label: '视频标题',
        name: 'title',
        required: true,
    },
    {
        label: '封面图',
        name: 'cover',
    },
];

const PAGE_SIZE = 20;

function AdminPublicVideos() {
    const [page, setPage] = useState(1);
    const [searchTitle, setSearchTitle] = useState('');
    const [visible, setVisible] = useState(false);
    const [currentEditVideo, setCurrentEditVideo] = useState(null);
    const [publicVideos, total, fetchPublicVideos] = usePublicVideos(
        page,
        PAGE_SIZE,
        searchTitle
    );
    const setSearchTitleDebounce = useMemo(
        () => debounce(setSearchTitle, 500),
        []
    );

    const onCreate = useCallback(
        async values => {
            const data = { ...values };
            const { cover } = values;
            if (cover && cover[0]) {
                // cover非必填
                if (cover.length > 1) {
                    return message.error('只能保留一张封面图');
                }
                data.cover = cover[0].response.url;
            }
            try {
                await remote.$post(`/course/admin/public-videos`, data, {
                    throwException: 'showMessage',
                });
                fetchPublicVideos();
                setVisible(false);
            } catch (e) {}
        },
        [fetchPublicVideos]
    );

    const onEdit = useCallback(
        async values => {
            await remote.$patch(
                `/course/admin/public-videos/${currentEditVideo.id}`,
                {
                    title: values.title,
                    cover: values.cover,
                },
                {
                    throwException: 'showMessage',
                }
            );
            fetchPublicVideos();
            setCurrentEditVideo(null);
        },
        [fetchPublicVideos, currentEditVideo]
    );

    const onDelete = useCallback(
        async id => {
            try {
                await remote.$delete(
                    `/course/admin/public-videos/${id}`,
                    {},
                    { throwException: 'showMessage' }
                );
                fetchPublicVideos();
            } catch (e) {}
        },
        [fetchPublicVideos]
    );

    const test = useCallback(vid => {
        remote.$get(`/common/public-videos/${vid}`);
    }, []);

    const columns = [
        {
            title: '视频标题',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'uuid',
            dataIndex: 'uuid',
            key: 'uuid',
        },
        {
            title: '封面图',
            dataIndex: 'cover',
            key: 'cover',
            render: url =>
                url ? (
                    <a href={url} target="_blank" rel="noopener noreferrer">
                        <img src={url} width="100px" alt="封面图" />
                    </a>
                ) : (
                    '无'
                ),
        },
        {
            title: '转码状态',
            dataIndex: 'files',
            key: 'files',
            render: files => {
                return files.length === 0
                    ? '正在转码'
                    : files.map(f => (
                          <div key={f.type}>
                              <Popover content={f.url}>
                                  <CopyToClipboard
                                      text={f.url}
                                      onCopy={() =>
                                          message.success(
                                              '视频地址已复制到剪贴板'
                                          )
                                      }
                                  >
                                      <Button type={'link'}>
                                          {translateVideoFileType(f.type)}{' '}
                                          &nbsp;
                                          <img
                                              src={linkIcon}
                                              alt="link"
                                              width={14}
                                          />
                                      </Button>
                                  </CopyToClipboard>
                              </Popover>
                          </div>
                      ));
            },
        },
        {
            title: '视频ID',
            dataIndex: 'vid',
            key: 'vid',
            render: vid => {
                if (!vid) {
                    return '暂无';
                }
                return (
                    <div>
                        <Button type="normal" onClick={() => test(vid)}>
                            查询
                        </Button>
                        <a
                            href={`https://vod.console.aliyun.com/#/media/video/detail/${vid}/video`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {vid}
                        </a>
                    </div>
                );
            },
        },
        {
            title: '操作',
            dataIndex: 'id',
            key: 'operations',
            render: (id, row) => (
                <div>
                    <Button
                        type="primary"
                        onClick={() => setCurrentEditVideo(row)}
                    >
                        编辑
                    </Button>
                    <Popconfirm
                        title="确定要删除吗"
                        onConfirm={() => onDelete(id)}
                    >
                        <Button type="danger">删除</Button>
                    </Popconfirm>
                </div>
            ),
        },
    ];

    return (
        <div>
            <h3>
                视频列表{' '}
                <Button onClick={() => setVisible(true)}>创建视频</Button>
            </h3>
            <Form.Item label="搜索视频">
                <Input
                    placeholder="输入视频标题"
                    onChange={e => setSearchTitleDebounce(e.target.value)}
                />
            </Form.Item>
            <Table
                dataSource={publicVideos}
                columns={columns}
                rowKey="id"
                pagination={{
                    total,
                    defaultPageSize: PAGE_SIZE,
                    current: page,
                    onChange: page => {
                        setPage(page);
                    },
                }}
            />
            {visible && (
                <AdminFormModal
                    title="添加公开视频"
                    visible={true}
                    onCancel={() => setVisible(false)}
                    onSubmit={onCreate}
                    formItems={formItems}
                />
            )}
            {currentEditVideo && (
                <AdminFormModal
                    title="编辑公开视频"
                    visible={true}
                    onCancel={() => setCurrentEditVideo(null)}
                    onSubmit={onEdit}
                    formItems={editFormItem}
                    formValues={currentEditVideo}
                />
            )}
        </div>
    );
}

export default AdminPublicVideos;
