import React, { useState } from 'react';
import moment from 'moment';
import _ from 'lodash';
import { Select, Empty } from 'antd';
import { EyeInvisibleOutlined } from '@ant-design/icons';
import FeedbackModal from '@boyuai/feedback';
import './my-lesson.less';
const { Option } = Select;

export default function LessonsContent({
    lessonArr,
    isFinished,
    onOrderChange,
    shouldFilter,
    onFilter,
    order,
    setReload,
}) {
    const [feedbackLessonUuid, setFeedbackLessonUuid] = useState(null);
    const onClickButton = (openUrl, uuid) => {
        if (openUrl) {
            window.open(openUrl);
        } else {
            setFeedbackLessonUuid(uuid);
        }
    };
    const onCloseFeedback = () => {
        setFeedbackLessonUuid(null);
        setReload(true);
    };
    const renderLessonCard = lesson => {
        const {
            title,
            uuid,
            boughtCount,
            expiresAt,
            progress,
            course,
            feedback,
        } = lesson;
        const { lastLearntAt, progressRate } = progress;
        const courseId = course.uuid;
        const lessonUrl = `${process.env.REACT_APP_SUB_DIRECTORY}/course/${courseId}/lesson/${uuid}`;
        let lastLearnedString;
        if (!lastLearntAt) {
            lastLearnedString = '暂未学习';
        } else {
            const lastLearnedDate = new Date(lastLearntAt);
            if (lastLearnedDate.getFullYear() < 2000) {
                lastLearnedString = '暂未学习';
            } else {
                lastLearnedString = lastLearnedDate.toISOString().slice(0, 10);
            }
        }
        let expireEle;
        if (!expiresAt) {
            expireEle = <div className="expires">暂未解锁</div>;
        } else {
            const distance = moment(expiresAt).diff(moment(), 'days');
            if (moment(expiresAt).year() >= 2090) {
                expireEle = (
                    <div className="expires">
                        有效期 <div>永久有效</div>
                    </div>
                );
            } else if (distance < 30) {
                expireEle = (
                    <div className="expires" data-red={true}>
                        剩余
                        <div>{`${distance}天`}</div>
                    </div>
                );
            } else {
                expireEle = (
                    <div className="expires">
                        有效期至
                        <div>{expiresAt}</div>
                    </div>
                );
            }
        }
        let unitType = 'video';
        let buttonString = '';
        let openUrl = lessonUrl;
        const { unitUUIDs, videos, jupyters, quizzes } = lesson;
        const firstUnlearnedUUID = unitUUIDs[0];
        if (_.find(videos, video => video.uuid === firstUnlearnedUUID)) {
            unitType = 'video';
        } else if (
            _.find(jupyters, jupyter => jupyter.uuid === firstUnlearnedUUID)
        ) {
            unitType = 'jupyter';
        } else if (_.find(quizzes, quiz => quiz.uuid === firstUnlearnedUUID)) {
            unitType = 'quiz';
        }
        if (progressRate === 0) {
            openUrl = `${process.env.REACT_APP_SUB_DIRECTORY}/course/${courseId}/${unitType}/${firstUnlearnedUUID}`;
            buttonString = '开始学习';
        } else if (progressRate > 0 && progressRate < 1) {
            openUrl = `${process.env.REACT_APP_SUB_DIRECTORY}/course/${courseId}/${unitType}/${firstUnlearnedUUID}`;
            buttonString = '继续学习';
        } else if (progressRate === 1) {
            buttonString = feedback ? '复习' : '评价';
            if (feedback === false) {
                openUrl = null;
            }
        }
        const learnButton = (
            <div
                className="start-learn"
                onClick={() => onClickButton(openUrl, uuid)}
            >
                {buttonString}
            </div>
        );
        return (
            <div className="lesson-card" key={uuid}>
                <div className="title-wrapper">
                    <div className="tag">知识点</div>
                    <div
                        className="title"
                        onClick={() => window.open(lessonUrl)}
                    >
                        {title}
                    </div>
                </div>
                <div className="info-wrapper">
                    <div className="last-learned">
                        最近学习时间
                        <div>{lastLearnedString}</div>
                    </div>
                    {expireEle}
                    <div className="bought-count">
                        学习人数
                        <div>{boughtCount}</div>
                    </div>
                </div>
                {learnButton}
                {progressRate === 1 && feedbackLessonUuid === uuid && (
                    <FeedbackModal
                        visible={true}
                        onClose={onCloseFeedback}
                        uuid={feedbackLessonUuid}
                    />
                )}
            </div>
        );
    };

    const renderLessons = lessonArr.map(lesson => renderLessonCard(lesson));

    const toggleFilter = () => {
        onFilter(!shouldFilter);
    };
    const renderOperation = () => {
        const orderWrapper = (
            <div className="order-wrapper">
                <Select value={order} onChange={onOrderChange}>
                    <Option value={'lastLearntAt'}>最近学习</Option>
                    <Option value={'expiresAt'}>有效期</Option>
                    <Option value={'boughtCount'}>学习人数</Option>
                </Select>
            </div>
        );

        const filterWrapper = (
            <div
                className="filter-wrapper"
                onClick={toggleFilter}
                data-filter={shouldFilter}
            >
                <EyeInvisibleOutlined />
                {isFinished ? '未评价' : '视频完成但练习未完成'}
            </div>
        );

        return (
            <div className="operation-wrapper">
                {orderWrapper}
                {filterWrapper}
            </div>
        );
    };

    const noLessonText = isFinished ? (
        <>
            有些目标努力会实现，
            <br />
            有些目标中途会忘记。
            <br />
            只要你愿意一直往前走，伯禹帮你一起努力！
            <br />
            赶快去学习知识点吧~
            <br />
        </>
    ) : (
        '暂无内容'
    );

    const noLesson = (
        <div className="no-lesson">
            <Empty style={{ margin: '150px 0' }} description={noLessonText} />
        </div>
    );

    return (
        <div
            className="content-wrapper"
            data-no-lesson={lessonArr.length === 0}
        >
            {renderOperation()}
            {lessonArr.length === 0 ? noLesson : renderLessons}
        </div>
    );
}
