export const ADMIN_ROOT_PATH = '/admin';

export const FORM_LAYOUT = {
    labelCol: { span: 8 },
    wrapperCol: { span: 8 },
};

export const FORM_TAIL_LAYOUT = {
    wrapperCol: { offset: 8, span: 8 },
};
