import React, { Component } from 'react';
import { Carousel } from 'antd';
import Footer from 'src/components/footer';
import LiangZiWeiBanner from './liangziwei-banner';
import CreditSystem from './banners/credit-system';
import { PythonCourses } from './python';
import { AICourses } from './ai';
import { PublicClasses } from './public';
import { AboutBoyu } from './about-boyu';

import './index.less';

export default class Home extends Component {
    carouselRef = React.createRef(null);

    state = {
        autoplaySpeed: 7000,
    };

    prev = () => {
        this.carouselRef.current.prev();
    };

    next = () => {
        this.carouselRef.current.next();
    };

    render() {
        return (
            <div className="home-v2">
                {this.renderCarouselItems()}
                <PythonCourses />
                <AICourses />
                <PublicClasses />
                <AboutBoyu />
                <Footer />
            </div>
        );
    }

    renderCarouselItems() {
        return (
            <Carousel
                autoplay
                autoplaySpeed={this.state.autoplaySpeed}
                speed={1000}
                className="home-carousel-box"
                ref={this.carouselRef}
            >
                <div className="carousel-item">
                    <img
                        className="bg"
                        src="https://staticcdn.boyuai.com/user-assets/14410/KHdzfyuPpNjaRcQfHAsJgM/1440x535.jpg!jpg"
                        alt=""
                    />
                    <div className="main">
                        <div className="main-content">
                            {this.renderControl(267)}
                        </div>
                    </div>
                </div>
                <CreditSystem control={this.renderControl(267)} />
                <LiangZiWeiBanner control={this.renderControl(267)} />
                <div className="carousel-item">
                    <img
                        className="bg"
                        src="https://staticcdn.boyuai.com/materials/2019/12/21/TbhSFqraVlQYx8CwUeVSQ.jpg"
                        alt=""
                    />
                    <div className="main">
                        <div className="main-content">
                            <img
                                className="man"
                                src="https://static-dev.boyuai.com/materials/2019/12/20/DLLxtO-pSfuYX-Ng20xkk.png"
                                alt=""
                                width="390px"
                                height="381px"
                                style={{
                                    position: 'absolute',
                                    right: '0px',
                                    bottom: '90px',
                                }}
                            />
                            <img
                                src="https://staticcdn.boyuai.com/materials/2020/01/12/0fxH5etxBZ6esLaf2p7mU.png"
                                alt=""
                                width="600px"
                                height="auto"
                                style={{
                                    position: 'absolute',
                                    left: '0px',
                                    bottom: '210px',
                                }}
                            />
                            <a
                                className="enter"
                                style={{
                                    position: 'absolute',
                                    left: '0px',
                                    bottom: '160px',
                                }}
                                href="#ai"
                            >
                                <div className="circle">
                                    <img
                                        src="https://static-dev.boyuai.com/materials/2019/12/20/E6te0j3hjeeLPQrDcBgiz.png"
                                        alt=""
                                        width="15px"
                                        height="12px"
                                    />
                                </div>
                                <div className="text">前往Elites系列课程</div>
                            </a>
                            {this.renderControl(267)}
                        </div>
                    </div>
                </div>
                <div className="carousel-item">
                    <img
                        className="bg"
                        src="https://static-dev.boyuai.com/materials/2019/12/20/KbQqGosMO1DKv_nCAEHee.jpg"
                        alt=""
                    />
                    <div className="main">
                        <div className="main-content">
                            <img
                                src="https://static-dev.boyuai.com/materials/2019/12/20/9r5473aTyEO_DUm70eQHS.png"
                                alt=""
                                width="590px"
                                height="142px"
                                style={{
                                    position: 'absolute',
                                    left: '0px',
                                    bottom: '210px',
                                }}
                            />
                            <a
                                href="https://space.bilibili.com/447303411/channel/detail?cid=136884"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="enter transparent"
                                style={{
                                    position: 'absolute',
                                    left: '0px',
                                    bottom: '160px',
                                }}
                            >
                                <div className="circle">
                                    <img
                                        src="https://static-dev.boyuai.com/materials/2019/12/20/E6te0j3hjeeLPQrDcBgiz.png"
                                        alt=""
                                        width="15px"
                                        height="12px"
                                    />
                                </div>
                                <div className="text space">前往学习</div>
                            </a>
                            {this.renderControl(267)}
                        </div>
                    </div>
                </div>
                <div className="carousel-item">
                    <img
                        className="bg"
                        src="https://static-dev.boyuai.com/materials/2019/12/20/cZ5WJcSbKqD0dXQbRXqQf.jpg"
                        alt=""
                    />
                    <div className="main">
                        <div className="main-content">
                            <img
                                src="https://static-dev.boyuai.com/materials/2019/12/20/j2l-CItEZ3FZ5mHtUxbp3.png"
                                alt=""
                                width="567px"
                                height="149px"
                                style={{
                                    position: 'absolute',
                                    left: '0px',
                                    bottom: '210px',
                                }}
                            />
                            <a
                                className="enter"
                                style={{
                                    position: 'absolute',
                                    left: '0px',
                                    bottom: '160px',
                                }}
                                href="#ai"
                            >
                                <div className="circle">
                                    <img
                                        src="https://static-dev.boyuai.com/materials/2019/12/20/E6te0j3hjeeLPQrDcBgiz.png"
                                        alt=""
                                        width="15px"
                                        height="12px"
                                    />
                                </div>
                                <div className="text space">查看详情</div>
                            </a>
                            {this.renderControl(267)}
                        </div>
                    </div>
                </div>
            </Carousel>
        );
    }
    renderControl(bottom) {
        return (
            <>
                <div
                    className="control left"
                    onClick={this.prev}
                    style={{ bottom: `${bottom}px` }}
                >
                    <img
                        alt="prev"
                        src="https://staticcdn.boyuai.com/materials/2019/12/22/G-V1HJ-8xSz-ihfolw0KZ.png"
                        width="28px"
                        height="28px"
                    />
                    <img
                        alt="prev"
                        src="https://staticcdn.boyuai.com/materials/2019/12/22/ull9DAxXkYWaI3S8FqTam.png"
                        width="28px"
                        height="28px"
                    />
                </div>
                <div
                    className="control right"
                    onClick={this.next}
                    style={{ bottom: `${bottom}px` }}
                >
                    <img
                        alt="prev"
                        src="https://staticcdn.boyuai.com/materials/2019/12/22/G-V1HJ-8xSz-ihfolw0KZ.png"
                        width="28px"
                        height="28px"
                    />
                    <img
                        alt="prev"
                        src="https://staticcdn.boyuai.com/materials/2019/12/22/ull9DAxXkYWaI3S8FqTam.png"
                        width="28px"
                        height="28px"
                    />
                </div>
            </>
        );
    }
}
