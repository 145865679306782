import React, { useCallback, useEffect, useState } from 'react';
import { remote } from '@boyuai/utils';
import moment from 'moment';
import AdminTable from '../table';
import { useRouteMatch } from 'react-router-dom';

export default function AdminPromotionCodeLogs() {
    const match = useRouteMatch('/admin/promotions/logs/:codeId');
    const codeId = match?.params?.codeId;
    const [promotionCodeLogs, setPromotionCodeLogs] = useState([]);
    const [total, setTotal] = useState(0);

    const fetchPromotionCodeLogs = useCallback(async () => {
        const res = await remote.$get(
            '/promotions/logs',
            { codeId },
            { hideLoading: true }
        );
        if (res) {
            const [list, count] = res;
            setPromotionCodeLogs(list);
            setTotal(count);
        }
    }, [codeId]);

    useEffect(() => {
        fetchPromotionCodeLogs();
    }, [fetchPromotionCodeLogs]);

    return (
        <>
            <div>总数：{total}</div>
            <AdminTable
                data={promotionCodeLogs}
                columns={[
                    {
                        title: 'id',
                        dataIndex: 'id',
                        key: 'id',
                    },
                    {
                        title: 'openId',
                        dataIndex: 'openId',
                        key: 'openId',
                    },
                    {
                        title: '扫码时的微信信息',
                        dataIndex: 'detail',
                        key: 'detail',
                        render: detail =>
                            detail && <div>昵称：{detail.nickname}</div>,
                    },
                    {
                        title: 'event',
                        dataIndex: 'event',
                        key: 'event',
                    },
                    {
                        title: '扫码时间',
                        dataIndex: 'createdAt',
                        key: 'createdAt',
                        render: createdAt =>
                            createdAt &&
                            moment(createdAt).format('YYYY-MM-DD HH:mm:ss'),
                    },
                    {
                        title: '渠道',
                        dataIndex: ['code', 'title'],
                        key: 'codeTitle',
                    },
                ]}
            />
        </>
    );
}
