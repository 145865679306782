import React from 'react';
import { Link } from 'react-router-dom';
import { Result, Button } from 'antd';
import './index.less';

export default function ErrorPage() {
    return (
        <Result
            status="404"
            title="404"
            subTitle="您访问的页面不存在"
            extra={
                <Link to="/">
                    <Button type="primary">回到主页</Button>
                </Link>
            }
        />
    );
}
