import React, { useEffect, useState } from 'react';
import { Progress, Modal, Pagination } from 'antd';
import {
    CheckCircleFilled,
    UserOutlined,
    CaretRightOutlined,
} from '@ant-design/icons';
import { $get } from '@boyuai/utils/es/remote';
import AuthorIntro from '@boyuai/author-intro';
import '@boyuai/author-intro/dist/index.css';
import './my-progress.less';

const RunningPNG =
    'https://staticcdn.boyuai.com/materials/2020/11/19/9J6zJk8UXF3chCmwmnGy8.png';
const UnlockPNG =
    'https://staticcdn.boyuai.com/materials/2020/11/19/sgk9zd9TyQoPR87LlqQoX.png';
const PAGE_SIZE = 20;

export default function MyProgress() {
    const [myLessons, setMyLessons] = useState([]);
    const [lessonCount, setLessonCount] = useState(0);
    const [authorVisible, setAuthorVisible] = useState(false);
    const [authorInfo, setAuthorInfo] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    useEffect(() => {
        $get('/course/my/courses', {
            offset: (currentPage - 1) * PAGE_SIZE,
            limit: PAGE_SIZE,
            site: process.env.REACT_APP_SITE,
        }).then(response => {
            setMyLessons(response.courses);
            setLessonCount(response.count);
        });
    }, [currentPage]);
    const onClickAuthor = author => {
        setAuthorInfo(author);
        setAuthorVisible(true);
    };
    const renderCourseCard = course => {
        if (!course) {
            return null;
        }
        const { progress, uuid, coverImage, title, author } = course;
        const {
            total, // 含即将上线知识点
            boughtCount, // 含即将上线知识点
            learntCount, // 不含即将上线知识点
            boughtButNotLearntCount, // 不含即将上线知识点
            nextLesson,
        } = progress;
        const courseUrl = `${process.env.REACT_APP_SUB_DIRECTORY}/course/${uuid}`;
        const boughtPercent = Math.round(
            (boughtCount / total + Number.EPSILON) * 100
        );
        const learntPercent = Math.round(
            (learntCount / total + Number.EPSILON) * 100
        );

        let learnButton;
        if (learntCount < total) {
            // < 100%
            // 根据是否有nextLesson选择跳转到课程页还是知识点页
            const url = nextLesson
                ? `${process.env.REACT_APP_SUB_DIRECTORY}/course/${uuid}/lesson/${nextLesson.uuid}`
                : `${process.env.REACT_APP_SUB_DIRECTORY}/course/${uuid}/`;
            if (learntCount === 0) {
                // 0%——按钮显示开始学习
                learnButton = (
                    <div
                        className="learn-button"
                        onClick={() => window.open(url)}
                    >
                        <img src={RunningPNG} alt="" />
                        开始学习
                    </div>
                );
            } else {
                // [0%,100%] 根据已购和已学的数量选择显示继续学习或者解锁更多
                learnButton =
                    boughtButNotLearntCount === 0 ? (
                        <div
                            className="learn-button"
                            onClick={() => window.open(url)}
                        >
                            <img src={UnlockPNG} alt="" />
                            解锁更多
                        </div>
                    ) : (
                        <div
                            className="learn-button"
                            onClick={() => window.open(url)}
                        >
                            <CaretRightOutlined />
                            继续学习
                        </div>
                    );
            }
        } else if (learntCount === total) {
            // 100%
            learnButton = (
                <div
                    className="finish-learn learn-button"
                    onClick={() => window.open(courseUrl)}
                >
                    <CheckCircleFilled />
                    已完成
                </div>
            );
        }

        return (
            <div className="progress-item" key={uuid}>
                <img
                    className="cover"
                    src={coverImage}
                    onClick={() => window.open(courseUrl)}
                    alt="author"
                />
                <div className="content-wrapper">
                    <div className="title">
                        <span onClick={() => window.open(courseUrl)}>
                            {title}
                        </span>
                    </div>
                    <div
                        className="author"
                        onClick={() => onClickAuthor(author)}
                    >
                        <UserOutlined />
                        {author.name}
                    </div>
                    <div className="progress-wrapper">
                        <Progress
                            percent={boughtPercent}
                            successPercent={learntPercent}
                            showInfo={false}
                        />
                        <div className="number">{`${learntPercent}%`}</div>
                    </div>
                    {learnButton}
                </div>
            </div>
        );
    };
    const barInfo = (
        <div className="bar-info">
            <span>进度条说明</span>
            <div className="wrapper">
                <div className="circle" data-status={0} />
                已学习
            </div>
            <div className="wrapper">
                <div className="circle" data-status={1} />
                已解锁未学习
            </div>
            <div className="wrapper">
                <div className="circle" data-status={2} />
                未解锁
            </div>
        </div>
    );
    return (
        <div className="my-progress-wrapper">
            {barInfo}
            <div className="items">
                {myLessons.map(course => renderCourseCard(course))}
            </div>
            <div className="pagination">
                <Pagination
                    showQuickJumper
                    defaultCurrent={1}
                    current={currentPage}
                    total={lessonCount}
                    pageSize={PAGE_SIZE}
                    onChange={setCurrentPage}
                />
            </div>
            <Modal
                title="作者介绍"
                visible={authorVisible}
                footer={null}
                onCancel={() => setAuthorVisible(false)}
            >
                <AuthorIntro key={authorInfo.id} author={authorInfo} />
            </Modal>
        </div>
    );
}
