import React, { Component } from 'react';
import SearchCourse from '@boyuai/search-course';
import remote from '@boyuai/utils/es/remote';
import { BaseHeader, HeaderFeedback, BaseHeaderRight } from '@boyuai/header';
import './index.less';

export default class Header extends Component {
    render() {
        return (
            <BaseHeader
                className="layout-header"
                leftContent={
                    <div style={{ width: 464 }}>
                        <SearchCourse
                            autoFocus={false}
                            dropdownClassName="header-dropdown"
                            onBlur={() => this.setState({ showSearch: false })}
                        />
                    </div>
                }
                rightContent={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {remote.isLogin() && <HeaderFeedback />}
                        <BaseHeaderRight />
                    </div>
                }
            />
        );
    }
}
