import React, { useEffect, useState, useCallback } from 'react';
import { $get } from '@boyuai/utils/es/remote';
import { Select, Pagination, Empty, Spin } from 'antd';
import './my-notes.less';
import UserComment from '../my-discussions/comment';

const PAGE_SIZE = 20;

export default function MyNotes() {
    const [currentNotePage, setCurrentNotePage] = useState(1);
    const [notes, setNotes] = useState([]);
    const [loading, setLoading] = useState(false);
    const [noteCount, setNoteCount] = useState(null);
    const [noteOrder, setNoteOrder] = useState(null);
    const fetchMyNotes = useCallback(async () => {
        setLoading(true);
        let [list, count] = await $get('/comment/user/discussions', {
            offset: (currentNotePage - 1) * PAGE_SIZE,
            limit: PAGE_SIZE,
            type: 'note',
            order: noteOrder,
        });
        setNoteCount(count);
        setNotes(list);
        setLoading(false);
    }, [noteOrder, currentNotePage]);

    useEffect(() => {
        fetchMyNotes();
    }, [fetchMyNotes]);

    let noteContent;
    if (loading) {
        noteContent = (
            <div className="loading">
                <Spin />
            </div>
        );
    } else if (noteCount === 0) {
        noteContent = (
            <Empty
                description={
                    <>
                        笔记更像是相册，回忆时会让你有满满的收获，
                        <br />
                        快记录下你的笔记吧！
                    </>
                }
                style={{ margin: '150px 0' }}
            />
        );
    } else {
        noteContent = notes.map(item => (
            <UserComment
                key={item.id}
                comment={item}
                hideUser={true}
                onDelete={fetchMyNotes}
            />
        ));
    }

    return (
        <div className="my-notes-wrapper">
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    padding: '0 16px 12px 16px',
                    // position: 'sticky',
                    top: 0,
                    zIndex: 99,
                    backgroundColor: '#fff',
                }}
            >
                {/*<img
                src="https://staticcdn.boyuai.com/materials/2020/04/02/IJw9hNZsFLb6CEljAvBf7.png!png"
                alt={''}
            />*/}
                <Select
                    // size={'small'}
                    style={{ width: 120 }}
                    defaultValue={'createdAt'}
                    onChange={v => setNoteOrder(v)}
                >
                    <Select.Option value={'createdAt'}>
                        按发布时间
                    </Select.Option>
                    <Select.Option value={'likeCount'}>按点赞数</Select.Option>
                    <Select.Option value={'replyCount'}>按回复数</Select.Option>
                </Select>
            </div>
            {noteContent}
            <div style={{ padding: '20px 10px 0' }}>
                <Pagination
                    showQuickJumper
                    defaultCurrent={1}
                    current={currentNotePage}
                    total={noteCount}
                    pageSize={PAGE_SIZE}
                    onChange={setCurrentNotePage}
                />
            </div>
        </div>
    );
}
