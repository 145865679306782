import React, { useState, useMemo, useCallback, useRef } from 'react';
import { Input, Button, message } from 'antd';
import PropTypes from 'prop-types';
import PhoneInput from 'antd-country-phone-input';
import { $post } from '@boyuai/utils/es/remote';
import useCountDown from './count-down';
import Captcha from './captcha';

import './form.less';

const mobileIcon =
    'https://staticcdn.boyuai.com/materials/2020/12/31/nfRj7LNrca-3fVUEJyrwJ.png';
const keyIcon =
    'https://staticcdn.boyuai.com/materials/2020/12/31/ITHEFuIhzx_qmwNlBkEKN.png';

export default function LoginForm({
    onLogin,
    label,
    referralCode,
    enableSign = true,
}) {
    const [countryCode, setCountryCode] = useState(86); // 国家电话号码，如86
    const [phone, setPhone] = useState(''); // 用户输入的手机号码
    const [countryShort, setCountryShort] = useState(null); // 国家英文简称，如CN
    const [code, setCode] = useState(''); // 用户输入的短信验证码
    const [countDown, startCountdown] = useCountDown(); // 倒计时
    const [captchaReady, setCaptchaReady] = useState(false); // 验证码准备
    const captchaRef = useRef(null);
    const codeInputRef = useRef(null);
    const postDataRef = useRef(null);

    const codeLabel = useMemo(() => {
        return countDown > 0 ? `${countDown}秒后重试` : '发送验证码';
    }, [countDown]);

    const isPhoneValid = useMemo(() => {
        if (countryCode === 86) {
            return /^1\d{10}$/.test(phone);
        }
        return /^\d+$/.test(phone);
    }, [phone, countryCode]);

    const isCodeValid = useMemo(() => {
        return code.length === 6 || code.length === 11;
    }, [code]);

    const onPhoneChange = value => {
        setPhone(value.phone);
        setCountryCode(Number(value.code));
        setCountryShort(value.short);
    };

    const sendCode = useCallback(
        async data => {
            try {
                // 从验证码那里过来的时候，不知道为什么存在state里面的phone会没掉
                // 所以搞一个postDataRef存一下
                const captcha = data || captchaRef.current.getCaptcha();
                await $post(
                    '/auth/login/phone/code',
                    {
                        phone: data ? postDataRef.current.phone : phone,
                        countryCode: data
                            ? postDataRef.current.countryCode
                            : countryCode,
                        captcha,
                    },
                    { throwException: true }
                );
                captchaRef.current.handleNext();
                message.success('验证码已发送');
                startCountdown(60);
            } catch (e) {
                if (e.response.data && e.response.data.captchaResult) {
                    postDataRef.current = { phone, countryCode };
                    captchaRef.current.handleNext(
                        e.response.data.captchaResult
                    );
                } else {
                    let errorMessage = e.response.data;
                    if (e.response.data.message) {
                        errorMessage = e.response.data.message;
                    }
                    message.error(errorMessage);
                }
            }
        },
        [phone, countryCode, startCountdown]
    );

    const login = useCallback(async () => {
        try {
            const auth = await $post(
                '/auth/login/phone',
                { phone, code, countryCode, referralCode },
                { throwException: true }
            );
            onLogin && onLogin(auth);
        } catch (e) {
            if (e.response) {
                let errorMessage = e.response.data;
                if (e.response.data.message) {
                    errorMessage = e.response.data.message;
                }
                message.error(errorMessage);
            } else {
                throw e;
            }
        }
    }, [phone, code, countryCode, referralCode, onLogin]);

    const onPressNumEnter = () => {
        if (isPhoneValid && countDown === 0) {
            sendCode();
            codeInputRef.current && codeInputRef.current.focus();
        }
    };

    const onEnter = () => {
        if (isPhoneValid && isCodeValid) {
            login();
        }
    };

    return (
        <div className="ui-login-form">
            <div className="form-row">
                <PhoneInput
                    bordered={false}
                    value={{ code: countryCode, phone, short: countryShort }}
                    onChange={onPhoneChange}
                    inputProps={{
                        suffix: <img src={mobileIcon} alt="mobile" />,
                        placeholder: '请输入手机号',
                        onPressEnter: onPressNumEnter,
                    }}
                />
            </div>
            <div className="form-row">
                <Input
                    onChange={e => setCode(e.target.value)}
                    value={code}
                    bordered={false}
                    onPressEnter={onEnter}
                    ref={codeInputRef}
                    placeholder="请输入验证码"
                    suffix={<img src={keyIcon} alt="key" />}
                />
                <Button
                    loading={!captchaReady}
                    disabled={!isPhoneValid || countDown > 0}
                    onClick={() => sendCode()}
                    data-is-disabled={!isPhoneValid || countDown > 0}
                >
                    {codeLabel}
                </Button>
            </div>
            <Captcha
                ref={captchaRef}
                onFinish={() => setCaptchaReady(true)}
                onSuccess={sendCode}
            />
            <Button
                disabled={!isPhoneValid || !isCodeValid}
                className="login-button"
                onClick={login}
                data-is-disable={!isPhoneValid || !isCodeValid}
            >
                {label || '登录'}
            </Button>
            {enableSign && (
                <div className="agreement">
                    注册即代表您同意
                    <a href="https://www.boyuai.com/page/terms"> 用户协议 </a>与
                    <a href="https://www.boyuai.com/page/privacy"> 隐私条款 </a>
                </div>
            )}
        </div>
    );
}

LoginForm.propTypes = {
    onLogin: PropTypes.func,
    label: PropTypes.string,
    referralCode: PropTypes.string,
    enableSign: PropTypes.bool,
};
