import React, { useRef } from 'react';
import { Form, Input, Button, message } from 'antd';
import PhoneInput from 'antd-country-phone-input';
import { remote } from '@boyuai/utils';

export default function BuyCourse() {
    const formRef = useRef(null);
    const handleSubmit = async values => {
        const { phoneInput = {}, uuid } = values;
        const { phone, code } = phoneInput;
        await remote.$post(
            '/course/admin/quick-buy-course',
            { phone, code, uuid },
            { throwException: 'showMessage' }
        );
        message.success('购买成功');
        formRef.current.resetFields();
    };
    return (
        <div>
            <Form onFinish={handleSubmit} ref={formRef}>
                <Form.Item
                    label="手机号"
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 18 }}
                    name="phoneInput"
                    rules={[{ required: true }]}
                >
                    <PhoneInput />
                </Form.Item>
                <Form.Item
                    label="课程UUID"
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 18 }}
                    name="uuid"
                    rules={[{ required: true }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item wrapperCol={{ span: 18, offset: 6 }}>
                    <Button type="primary" htmlType="submit">
                        快速购买
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
}
