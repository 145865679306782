import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import Card from '../../components/card';
import { remote } from '@boyuai/utils';
import _ from 'lodash';
// import Quiz from './quiz';
import Quiz from '../../components/quiz';
import { Skeleton, Button } from 'antd';
import './quiz-page.less';
import CalculateActiveLesson from './access-error';
import {
    changeQuizAnswersToObjectForm,
    changeQuizAnswersToArrayForm,
} from '../../utils';

class QuizPage extends Component {
    state = {
        loading: false,
        title: null,
        content: null,
        questions: [],
        log: null,
        showDetail: false,
        author: null,
    };
    get courseUuid() {
        return this.props.match.params.uuid;
    }
    componentDidMount() {
        this.fetch();
    }
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            this.fetch();
        }
    }
    async fetch() {
        this.setState({ loading: true, showDetail: false });
        const uuid = this.props.match.params.quizuuid;
        try {
            const {
                title,
                content,
                questions,
                log,
                learningLog,
                author,
                updatedAt,
            } = await remote.$get(
                `/course/quizzes/${uuid}`,
                {},
                { hideLoading: true, throwException: 'showMessage' }
            );
            this.setState({
                loading: false,
                title,
                content,
                questions,
                log,
                learningLog,
                showDetail: /showDetail/.test(window.location.href),
                author,
                updatedAt,
            });
        } catch (e) {
            if (
                _.get(e, 'response.data') === '知识点尚未购买' ||
                _.get(e, 'response.data.message') === '知识点尚未购买'
            ) {
                const { lessons } = await remote.$get(
                    `/course/${this.courseUuid}`,
                    {},
                    { hideLoading: true }
                );
                const lessonUuid = lessons[CalculateActiveLesson()].lesson.uuid;
                window.location.href = `${process.env.REACT_APP_SUB_DIRECTORY}/course/${this.courseUuid}/lesson/${lessonUuid}`;
            }
        }
    }
    onSubmit = async answerObj => {
        const answers = changeQuizAnswersToObjectForm(answerObj);
        const uuid = this.props.match.params.quizuuid;
        // 去掉结尾的手动输入\n字符
        answers.forEach(item => {
            if (Array.isArray(item.answer)) {
                item.answer.forEach((line, index) => {
                    item.answer[index] = line.replace(/\\n$/, '');
                });
            }
        });
        await remote.$post(
            `/course/quizzes/${uuid}/answer`,
            { answers },
            { throwException: 'showMessage' }
        );
        window.location.href = window.location.pathname + '?showDetail';
    };
    showDetail = () => this.setState({ showDetail: true });
    hideDetail = () => this.setState({ showDetail: false });
    render() {
        const {
            showDetail,
            loading,
            title,
            questions,
            log,
            learningLog,
            updatedAt,
        } = this.state;
        const selectCount = questions.reduce(
            (p, item) => p + (item.type === 'SELECT' ? 1 : 0),
            0
        );
        const fillCount = questions.reduce(
            (p, item) => p + (item.type === 'FILL' ? 1 : 0),
            0
        );
        let status = 'pending';
        if (learningLog) {
            if (moment(learningLog.updatedAt).isBefore(moment(updatedAt))) {
                status = 'update';
            } else {
                status = 'finished';
            }
        }
        if (showDetail) {
            return (
                <Quiz
                    title={title}
                    questions={questions}
                    onSubmit={this.onSubmit}
                    values={
                        log && log.answer
                            ? changeQuizAnswersToArrayForm(
                                  JSON.parse(log.answer)
                              )
                            : null
                    }
                    onGoBack={this.hideDetail}
                    quizUpdated={status === 'update'}
                />
            );
        }
        return (
            <Skeleton loading={loading}>
                <div className="quiz-page">
                    <Card
                        title="小测信息"
                        authors={[this.state.author]}
                        isShowCourseTitle={true}
                    >
                        <h2>{title}</h2>
                        <p>
                            {selectCount}选择题，{fillCount}填空题
                        </p>
                        <p>
                            当前状态：
                            {status === 'finished' && (
                                <span className="status-success">已完成</span>
                            )}
                            {status === 'pending' && (
                                <span className="status-warning">未完成</span>
                            )}
                            {status === 'update' && (
                                <span className="status-success">有更新</span>
                            )}
                        </p>
                        {status === 'pending' && (
                            <Button type="primary" onClick={this.showDetail}>
                                开始
                            </Button>
                        )}
                        {status === 'update' && (
                            <Button type="primary" onClick={this.showDetail}>
                                开始
                            </Button>
                        )}
                        {status === 'finished' && (
                            <Button type="primary" onClick={this.showDetail}>
                                查看
                            </Button>
                        )}
                    </Card>
                </div>
            </Skeleton>
        );
    }
}

export default withRouter(QuizPage);
