import React, { useEffect, useState, useCallback } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { remote } from '@boyuai/utils';
import { Breadcrumb, Button } from 'antd';
import Videos from './videos';
import Codes from './codes';
import Quizzes from './quizzes';
import Jupyters from './jupyters';

function AdminLesson(props) {
    const [lesson, setLesson] = useState();

    const fetch = useCallback(() => {
        const { lessonId } = props.match.params;
        remote
            .$get(`/course/admin/courses/0/lessons/${lessonId}`)
            .then(lesson => {
                setLesson(lesson);
            });
    }, [props.match.params]);

    useEffect(() => fetch(), [fetch]);

    const onGoBack = useCallback(() => {
        window.history.back();
    }, []);

    if (!lesson) {
        return null;
    }
    return (
        <div>
            <Breadcrumb>
                <Breadcrumb.Item>
                    <Link to="/admin/lessons">内容管理</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>{lesson.title}</Breadcrumb.Item>
                <Breadcrumb.Item>
                    <Button onClick={onGoBack}>返回</Button>
                </Breadcrumb.Item>
            </Breadcrumb>
            <Videos
                datasource={lesson.videos}
                lessonId={lesson.id}
                lessonTitle={lesson.title}
                onChange={fetch}
            />
            <Codes
                datasource={lesson.codes}
                lessonId={lesson.id}
                lessonTitle={lesson.title}
                onChange={fetch}
            />
            <Quizzes
                datasource={lesson.quizzes}
                lessonId={lesson.id}
                lessonTitle={lesson.title}
                onChange={fetch}
            />
            <Jupyters
                datasource={lesson.jupyters}
                lessonId={lesson.id}
                lessonTitle={lesson.title}
                onChange={fetch}
            />
        </div>
    );
}

export default withRouter(AdminLesson);
