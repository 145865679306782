export const ENTITY_NAME = '题目';
export const API_BASE_URL = '/quiz-questions';
export const DETAIL_TAB_LIST = [{ key: 'basic', tab: '基本信息' }];
export const FORM_LAYOUT = {
    labelCol: { span: 6 },
    wrapperCol: { span: 12 },
};
export const FORM_TAIL_LAYOUT = {
    wrapperCol: { offset: 6, span: 12 },
};

export const QuestionType = {
    SELECT: 'SELECT',
    FILL: 'FILL',
};
export const QuestionTypeToCN = {
    [QuestionType.SELECT]: '选择题',
    [QuestionType.FILL]: '填空题',
};
export const QuestionDifficultyToCN = {
    1: '易',
    2: '偏易',
    3: '适中',
    4: '偏难',
    5: '难',
};
export const QuestionOptionIndexToEN = {
    1: 'A',
    2: 'B',
    3: 'C',
    4: 'D',
    5: 'E',
    6: 'F',
};
