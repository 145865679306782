import React from 'react';
import { Button } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import { Title } from './title';
import { renderCard } from './card';

import './python.less';

const staticCourseList = [
    {
        title: '动手学Python',
        intro:
            '你将结合丰富有趣的案例对python语法基础和计算思维进行学习实践，为后续的AI应用课程打下基础。',
        id: `s1`,
        listImage:
            'https://staticcdn.boyuai.com/user-assets/14398/WpkevhFo6krghpnaBxUGgB/课程封面-动手学Python@3x.png!png',
        staticLink: 'https://mp.weixin.qq.com/s/kKdQpIdbzWFrg_xP2Mw2VQ',
    },
    {
        title: '玩转数据科学与人工智能实用库',
        intro:
            '通过文本、图像、数据挖掘领域案例，手把手教你掌握数据科学与AI六大经典Python实用库与项目实战。',
        id: `s2`,
        listImage:
            'https://staticcdn.boyuai.com/user-assets/14398/rVQJNxBCYcx8dUX9Ws8YFg/课程封面-玩转数据科学与人工智能实用库@3x.png!png',
        staticLink: 'https://mp.weixin.qq.com/s/HrGI8kor26Q3gFg0Bh-JfA',
    },
];

const PythonCourses = () => {
    return (
        <div id="data-analysis" className="python-courses">
            <img
                style={{
                    position: 'absolute',
                    top: 48,
                    left: 40,
                }}
                alt="top-left-decoration"
                src="https://staticcdn.boyuai.com/materials/2020/11/12/pFc1f0vP0g90FQ9768v_F.svg"
            />
            <Title
                title="Python 数据分析"
                intro="专为零基础、跨专业设计，16周掌握数据分析技能"
            />
            <div className="specs">
                <div className="spec">
                    <img
                        alt="teahcer"
                        src="https://staticcdn.boyuai.com/materials/2020/11/12/oSkIc304iwNFhKIN7jPN1.svg"
                    />
                    <span>上交博士全职教研</span>
                </div>
                <div className="spec">
                    <img
                        alt="platform"
                        src="https://staticcdn.boyuai.com/materials/2020/11/12/ocdRj0OLbscE7CUShuTRA.svg"
                    />
                    <span>智能实践学习平台</span>
                </div>
                <div className="spec">
                    <img
                        alt="cases"
                        src="https://staticcdn.boyuai.com/materials/2020/11/12/c7U9Ni552i4hqONWkMgNq.svg"
                    />
                    <span>实用场景案例教学</span>
                </div>
            </div>
            <div className="courses-wrapper">
                <div className="courses">
                    {staticCourseList.map((c, i, arr) => {
                        const listNum = arr.length;
                        const isLastLine =
                            i >= listNum - (listNum % 5) && i < listNum;
                        return renderCard(
                            c,
                            Boolean(c.staticLink),
                            false,
                            isLastLine
                        );
                    })}
                </div>
                <div className="upcoming">即将上线，敬请期待</div>
                <div className="upcoming-courses">
                    <img
                        alt="ai"
                        src="https://staticcdn.boyuai.com/materials/2020/11/12/fW7Wq2zBJSF4AQv7o_Kh6.svg"
                    />
                    <img
                        alt="crawler"
                        src="https://staticcdn.boyuai.com/materials/2020/11/12/rOXDl6lgWCXfoH1n4t1vQ.svg"
                    />
                    <img
                        alt="excel"
                        src="https://staticcdn.boyuai.com/materials/2020/11/12/8zLTyW-V-oITgKNecXf3a.svg"
                    />
                </div>
                <Button
                    className="full-plan-btn"
                    type="primary"
                    onClick={() =>
                        window.open(
                            'https://opendl.boyuai.com/website/boyu-python-data-analysisi.pdf'
                        )
                    }
                >
                    查看完整培养方案
                    <ArrowRightOutlined />
                </Button>
            </div>
        </div>
    );
};

export { PythonCourses };
