import React, { useCallback, useEffect, useState } from 'react';
import { remote } from '@boyuai/utils';
import { Button, Popover, Input, message } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import AdminTable from '../table';
import AuthorFormModal from './author-form-modal';

export default function AdminAuthors() {
    const [authors, setAuthors] = useState([]);
    const [createModalVisible, setCreateModalVisible] = useState(false);
    const [editModalVisibleId, setEditModalVisibleId] = useState(null);
    const [searchText, setSearchText] = useState('');
    const [searchColumn, setSearchColumn] = useState('');
    const [searchInputRef, setSearchInputRef] = useState(null);
    const saveSearchInputRef = useCallback(node => {
        if (node !== null) {
            setSearchInputRef(node);
        }
    }, []);

    const fetchAuthors = useCallback(async () => {
        const res = await remote.$get(
            '/course/admin/authors',
            {},
            { hideLoading: true }
        );
        setAuthors(res);
    }, []);

    useEffect(() => {
        fetchAuthors();
    }, [fetchAuthors]);

    const onSubmit = useCallback(async () => {
        await fetchAuthors();
        message.success('更新作者信息成功');
        setEditModalVisibleId(null);
    }, [fetchAuthors]);

    const onAdd = useCallback(async () => {
        await fetchAuthors();
        message.success('创建作者成功');
        setCreateModalVisible(false);
    }, [fetchAuthors]);

    const handleSearch = useCallback((selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchColumn(dataIndex);
    }, []);

    const handleReset = useCallback(clearFilters => {
        clearFilters();
        setSearchText('');
    }, []);

    const getColumnSearchProps = useCallback(
        dataIndex => ({
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
            }) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={saveSearchInputRef}
                        value={selectedKeys[0]}
                        onChange={e =>
                            setSelectedKeys(
                                e.target.value ? [e.target.value] : []
                            )
                        }
                        onPressEnter={() =>
                            handleSearch(selectedKeys, confirm, dataIndex)
                        }
                        style={{
                            width: 188,
                            marginBottom: 8,
                            display: 'block',
                        }}
                    />
                    <Button
                        type="primary"
                        onClick={() =>
                            handleSearch(selectedKeys, confirm, dataIndex)
                        }
                        icon="search"
                        size="small"
                        style={{ width: 90, marginRight: 8 }}
                    >
                        搜索
                    </Button>
                    <Button
                        onClick={() => handleReset(clearFilters)}
                        size="small"
                        style={{ width: 90 }}
                    >
                        重置
                    </Button>
                </div>
            ),
            filterIcon: filtered => (
                <SearchOutlined
                    style={{ color: filtered ? '#1890ff' : undefined }}
                />
            ),
            onFilter: (value, record) =>
                record[dataIndex]
                    .toString()
                    .toLowerCase()
                    .includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: visible => {
                if (visible) {
                    setTimeout(() => searchInputRef.select());
                }
            },
            render: text =>
                searchColumn === dataIndex ? (
                    <Highlighter
                        highlightStyle={{
                            backgroundColor: '#ffc069',
                            padding: 0,
                        }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text.toString()}
                    />
                ) : (
                    text
                ),
        }),
        [
            saveSearchInputRef,
            searchColumn,
            searchText,
            searchInputRef,
            handleReset,
            handleSearch,
        ]
    );

    const renderEdit = useCallback(
        id => (
            <>
                {editModalVisibleId === id && (
                    <AuthorFormModal
                        author={authors.filter(item => item.id === id)[0]}
                        onClose={() => setEditModalVisibleId(null)}
                        onSubmit={onSubmit}
                    />
                )}
                <Button
                    type="primary"
                    onClick={() => setEditModalVisibleId(id)}
                >
                    编辑
                </Button>
            </>
        ),
        [authors, editModalVisibleId, onSubmit]
    );

    return (
        <>
            {createModalVisible && (
                <AuthorFormModal
                    onClose={() => setCreateModalVisible(false)}
                    onSubmit={onAdd}
                />
            )}
            <Button
                style={{ margin: 8 }}
                type="primary"
                onClick={() => setCreateModalVisible(true)}
            >
                创建课程作者
            </Button>
            <AdminTable
                data={authors}
                columns={[
                    {
                        title: '照片',
                        dataIndex: 'coverImage',
                        key: 'coverImage',
                        render: coverImage => (
                            <Popover
                                content={
                                    <img
                                        style={{ width: '160px' }}
                                        src={coverImage}
                                        alt="作者照片"
                                    />
                                }
                            >
                                <img
                                    style={{ width: '60px' }}
                                    src={coverImage}
                                    alt={coverImage}
                                />
                            </Popover>
                        ),
                    },
                    {
                        title: '姓名',
                        dataIndex: 'name',
                        key: 'name',
                        ...getColumnSearchProps('name'),
                    },
                    {
                        title: '头衔',
                        dataIndex: 'title',
                        key: 'title',
                        ...getColumnSearchProps('title'),
                    },
                    {
                        title: '简介',
                        dataIndex: 'content',
                        key: 'content',
                        width: 500,
                        ...getColumnSearchProps('content'),
                    },
                    {
                        title: '操作',
                        dataIndex: 'id',
                        key: 'operations',
                        render: renderEdit,
                    },
                ]}
            />
        </>
    );
}
