import React, { useCallback, useState } from 'react';
import { remote } from '@boyuai/utils';
import { downloadCSV, getPercentage } from '../utils';
import { message, Modal, Progress } from 'antd';
import moment from 'moment';

export function useBBYStat(userIds, lessons) {
    const [lock, setLock] = useState(false);
    const [progress, setProgress] = useState(0);
    const [count, setCount] = useState(0);

    const fetchAll = useCallback(async () => {
        if (lock || lessons.length === 0) {
            return;
        }
        setLock(true);
        let statToBeExport = [];
        setCount(userIds.length * lessons.length); // 总数
        lessons.sort((a, b) => a.order - b.order);
        for (const userId of userIds) {
            let statOfUser = [];
            for (const lesson of lessons) {
                const res = await remote.$get(
                    `/learn/admin/stat/${userId}/lessons/${lesson.id}`,
                    {
                        startDate: moment(0).format('YYYYMMDD'),
                        endDate: moment().format('YYYYMMDD'),
                    }
                );
                if (res) {
                    statOfUser = [...statOfUser, res];
                    // 进度
                    setProgress(v => v + 1);
                } else {
                    message.error('获取失败，请重试');
                    return;
                }
            }
            statToBeExport = [...statToBeExport, statOfUser];
        }
        downloadCSV(
            statToBeExport,
            '用户名,手机号,开始时间,结束时间,正确数,总题数,正确率,开始时间,结束时间,正确数,总题数,正确率,开始时间,结束时间,正确数,总题数,正确率,开始时间,结束时间,代码通过,运行次数,开始时间,结束时间,代码通过,运行次数\n',
            row => {
                let formattedRow = `${row[0].name}, ${row[0].phone || 0},`;
                for (let i = 0; i < 5; i++) {
                    // 只有一个step
                    const step =
                        row[i].steps.length === 0 ? null : row[i].steps[0];
                    if (i < 3) {
                        if (!step) {
                            formattedRow += ',,,,,';
                            continue;
                        }
                        formattedRow += `${step.startedAt ||
                            ''},${step.finishedAt || ''},${
                            step.quizAnswers ? step.quizRightCount : ''
                        },${
                            step.quizAnswers
                                ? Object.keys(step.quizAnswers).length
                                : ' '
                        },${
                            step.quizAnswers
                                ? getPercentage(
                                      step.quizRightCount,
                                      Object.keys(step.quizAnswers).length
                                  )
                                : ''
                        },`;
                    } else {
                        if (!step) {
                            formattedRow += ',,,,';
                            continue;
                        }
                        formattedRow += `${step.startedAt ||
                            ''},${step.finishedAt || ''},${
                            step.finishedAt ? 1 : 0
                        },${
                            step.finishedAt ? step.runsBeforeSuccess : step.runs
                        },`;
                    }
                }
                formattedRow += '\n';
                return formattedRow;
            }
        );
        // console.log(statToBeExport);
        setLock(false);
        setProgress(0);
        setCount(0);
    }, [lock, lessons, userIds]);

    const modal = (
        <Modal visible={lock} footer={null}>
            <div style={{ textAlign: 'center', width: '100%', height: 300 }}>
                <Progress
                    type="circle"
                    width={250}
                    percent={getPercentage(progress, count) * 100}
                    format={() => `${progress} / ${count}`}
                />
                <div>请勿关闭浏览器，读条完毕自动下载csv</div>
                <div>windows用记事本打开另存为ANSI格式即可正常阅读</div>
            </div>
        </Modal>
    );
    return [fetchAll, modal];
}
