import React, { useEffect, useState } from 'react';
import {
    Form,
    Input,
    Button,
    InputNumber,
    message,
    Radio,
    Slider,
    Space,
    Switch,
} from 'antd';
import { AdminSearch } from '@boyuai/admin-kit';
import { Editor } from '@boyuai/markdown';
import { usePrevious } from '@boyuai/utils';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
// import { FORM_LAYOUT, FORM_TAIL_LAYOUT } from '../../config';
import { createDetail, updateDetail } from '../services';
import {
    QuestionDifficultyToCN,
    QuestionType,
    QuestionTypeToCN,
    QuestionOptionIndexToEN,
    FORM_LAYOUT,
    FORM_TAIL_LAYOUT,
} from '../config';

const SuperAdminForm = ({ detail, onSuccess, formLayout, formTailLayout }) => {
    const [form] = Form.useForm();
    const [type, setType] = useState();

    useEffect(() => {
        if (!detail) return;
        setType(detail.type);
    }, [detail]);

    // 切换设定好的题型时清空答案
    const prevType = usePrevious(type);
    useEffect(() => {
        if (prevType === undefined) {
            return;
        }
        form.setFields([{ name: 'answer', value: null }]);
    }, [form, prevType, type]);

    const onFinish = values => {
        const payloadWIP = {
            ...values,
            difficulty: Math.floor(values.difficulty / 25) + 1,
            tags: values.tags || [],
        };
        if (values.answerOptions && type === QuestionType.SELECT) {
            Object.values(values.answerOptions).forEach((option, index) => {
                const optionId = index + 1;
                payloadWIP[`option${optionId}`] = option.value;
                if (option.isAnswer) {
                    payloadWIP.answer = optionId.toString();
                }
            });
        }

        if (!detail?.id) {
            createDetail(payloadWIP).then(data => {
                if (!data) return;
                message.success('创建成功');
                onSuccess && onSuccess(data);
            });

            return;
        }

        updateDetail(detail.id, payloadWIP).then(data => {
            if (!data) return;
            message.success('保存成功');
            onSuccess && onSuccess(data);
        });
    };

    return (
        <Form
            {...(formLayout || FORM_LAYOUT)}
            form={form}
            initialValues={{
                ...detail,
                user: detail
                    ? `${detail.user?.realName || detail.user?.name}`
                    : null,
                difficulty: detail ? (detail.difficulty - 1) * 25 : 50,
                tags: detail?.tags?.map?.(t => ({
                    id: t.id,
                    displayName: t.title,
                })),
            }}
            onValuesChange={changedValues => {
                // console.log(changedValues)
                if (changedValues.type) {
                    setType(changedValues.type);
                }
                const changedAnswerOptions = changedValues.answerOptions;
                // console.log(changedAnswerOptions)
                // 条目数量变化、选项内容变化、选项正确性变化的值各不相同
                if (changedAnswerOptions) {
                    // 目前不支持多选题，设置正确答案时需要将其他选项置为错误
                    const answerOptionsWIP = form
                        .getFieldValue('answerOptions')
                        .map(option => ({ ...option, isAnswer: false }));
                    let shouldUpdate;
                    Object.keys(changedAnswerOptions).forEach(index => {
                        const option = changedAnswerOptions[index];
                        if (!option) {
                            return;
                        }
                        if (!('isAnswer' in option)) {
                            return;
                        }
                        shouldUpdate = true;
                        const isAnswer = option.isAnswer;
                        answerOptionsWIP[index].isAnswer = isAnswer;
                    });
                    if (shouldUpdate) {
                        form.setFields([
                            { name: 'answerOptions', value: answerOptionsWIP },
                        ]);
                    }
                }
            }}
            onFinish={onFinish}
        >
            {detail && (
                <Form.Item label="作者" name="user">
                    <Input disabled />
                </Form.Item>
            )}
            <Form.Item
                label="题型"
                name="type"
                rules={[{ required: true, message: '请选择题型' }]}
            >
                <Radio.Group>
                    <Radio.Button value={QuestionType.SELECT}>
                        {QuestionTypeToCN[QuestionType.SELECT]}
                    </Radio.Button>
                    <Radio.Button value={QuestionType.FILL}>
                        {QuestionTypeToCN[QuestionType.FILL]}
                    </Radio.Button>
                </Radio.Group>
            </Form.Item>
            <Form.Item label="题干" name="title" rules={[{ required: true }]}>
                <Editor defaultValue={detail?.title} />
            </Form.Item>
            <Form.Item label="标签" name="tags">
                <AdminSearch type="quizQuestionTag" mode="multiple" />
            </Form.Item>
            <Form.Item
                label="难度"
                name="difficulty"
                rules={[{ required: true }]}
            >
                <Slider
                    step={25}
                    marks={{
                        0: QuestionDifficultyToCN[1],
                        25: QuestionDifficultyToCN[2],
                        50: QuestionDifficultyToCN[3],
                        75: QuestionDifficultyToCN[4],
                        100: QuestionDifficultyToCN[5],
                    }}
                />
            </Form.Item>
            <Form.Item label="分数" name="score">
                <InputNumber min={0} precision={0} />
            </Form.Item>
            {type === QuestionType.FILL && (
                <Form.Item
                    label="答案"
                    name="answer"
                    rules={[{ required: true }]}
                >
                    <Input />
                </Form.Item>
            )}
            {type === QuestionType.SELECT && (
                <Form.List
                    name="answerOptions"
                    initialValue={
                        detail
                            ? Object.keys(detail)
                                  .filter(key => key.startsWith('option'))
                                  .map(optionKey => ({
                                      value: detail[optionKey],
                                      isAnswer:
                                          detail.answer?.toString() ===
                                          optionKey.slice(-1),
                                  }))
                                  .filter(option => option.value)
                            : [1, 2, 3, 4].map(() => ({
                                  value: '',
                                  isAnswer: false,
                              }))
                    }
                    rules={[
                        {
                            validator: (_, answerOptions) => {
                                if (
                                    !answerOptions ||
                                    answerOptions.length < 2
                                ) {
                                    return Promise.reject(
                                        '请至少添加 2 个答案选项'
                                    );
                                }
                                const hasAtLeastOneAnswer = answerOptions.reduce(
                                    (prev, current) => {
                                        if (typeof prev === 'boolean') {
                                            return current.isAnswer || prev;
                                        }
                                        return (
                                            current.isAnswer || prev.isAnswer
                                        );
                                    }
                                );
                                if (hasAtLeastOneAnswer) {
                                    return Promise.resolve();
                                }
                                return Promise.reject('请选择答案');
                            },
                        },
                    ]}
                >
                    {(fields, { add, remove }, { errors }) => {
                        // console.log(fields)
                        return (
                            <>
                                {fields.map((field, index) => (
                                    <Form.Item
                                        key={field.key}
                                        label={`选项 ${
                                            QuestionOptionIndexToEN[index + 1]
                                        }`}
                                    >
                                        <Space
                                            align="baseline"
                                            style={{ display: 'flex' }}
                                        >
                                            <Form.Item
                                                {...field}
                                                noStyle
                                                name={[field.name, 'value']}
                                                fieldKey={[
                                                    field.fieldKey,
                                                    'value',
                                                ]}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: '请输入答案',
                                                    },
                                                ]}
                                            >
                                                <Input.TextArea autoSize />
                                            </Form.Item>
                                            <Form.Item
                                                {...field}
                                                noStyle
                                                name={[field.name, 'isAnswer']}
                                                fieldKey={[
                                                    field.fieldKey,
                                                    'isAnswer',
                                                ]}
                                                valuePropName="checked"
                                            >
                                                <Switch
                                                    checkedChildren="正确"
                                                    unCheckedChildren="错误"
                                                />
                                            </Form.Item>
                                            {index > 1 && (
                                                <MinusCircleOutlined
                                                    onClick={() =>
                                                        remove(field.name)
                                                    }
                                                />
                                            )}
                                        </Space>
                                    </Form.Item>
                                ))}
                                <Form.Item
                                    {...FORM_TAIL_LAYOUT}
                                    extra="选项支持 Markdown"
                                >
                                    <Button
                                        type="dashed"
                                        disabled={
                                            form.getFieldValue('answerOptions')
                                                ?.length >= 6
                                        }
                                        onClick={() => add()}
                                        block
                                        icon={<PlusOutlined />}
                                    >
                                        添加答案选项
                                    </Button>
                                    <Form.ErrorList errors={errors} />
                                </Form.Item>
                            </>
                        );
                    }}
                </Form.List>
            )}
            <Form.Item label="提示" name="hint" extra="供用户查看的提示">
                <Editor defaultValue={detail?.hint} />
            </Form.Item>
            <Form.Item label="备注" name="comment" extra="供选题人查看的备注">
                <Input />
            </Form.Item>
            <Form.Item {...(formTailLayout || FORM_TAIL_LAYOUT)}>
                <Button
                    type="primary"
                    htmlType="submit"
                    style={{ width: '100%' }}
                >
                    提交
                </Button>
            </Form.Item>
        </Form>
    );
};

export default SuperAdminForm;
