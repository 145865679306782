import { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Markdown from '../../components/markdown';
import Comment from '@boyuai/comment';
import './index.less';
import { UserContextModule, remote } from '@boyuai/utils';
import { message } from 'antd';

function generateMdText(problem) {
    if (!problem) return;
    const {
        title,
        description,
        output_description,
        input_description,
        samples,
    } = problem;
    return `
### ${title}

#### 题目描述

${description}

#### 输入描述

${input_description}

#### 输出描述

${output_description}

#### 输入样例

\`\`\`
${samples[0].input}
\`\`\`

#### 输出样例

\`\`\`
${samples[0].output}
\`\`\`
        `;
}

export default function OJ() {
    const { id } = useParams();
    const commentKey = `OJ-COMMENT-${id}`;
    const [problem, setProblem] = useState(undefined);
    const context = useContext(UserContextModule.UserContext);

    useEffect(() => {
        message.loading('题目加载中...', 0);
        remote
            .$get(`/jupyterhub/oj-problems/${id}`)
            .then(res => {
                if (res) {
                    setProblem(res);
                } else if (!isNaN(+id)) {
                    remote
                        .$get(`/jupyterhub/oj-problems-by-real-id/${id}`)
                        .then(setProblem);
                }
            })
            .then(() => {
                message.destroy();
            });
    }, [id]);

    return (
        <div className="oj-wrapper">
            {problem ? (
                <>
                    <div className="contents">
                        <Markdown content={generateMdText(problem)} />
                    </div>
                    <div className="comment-wrapper">
                        <Comment
                            commentKey={commentKey}
                            routePrefix={`oj/${id}`}
                            user={context.user}
                        />
                    </div>
                </>
            ) : (
                <div>题目读取失败</div>
            )}
        </div>
    );
}
