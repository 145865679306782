import { useState, useEffect } from 'react';
import { remote } from '@boyuai/utils';

export default function useCourse(uuid) {
    const [course, setCourse] = useState(0);
    useEffect(() => {
        if (uuid) {
            remote.$get(`/course/${uuid}`).then(setCourse);
        }
    }, [uuid]);
    return course;
}
