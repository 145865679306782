import React, { useState, useEffect } from 'react';
import { Table } from 'antd';
import moment from 'moment';
import { $get } from '@boyuai/utils/es/remote';

export default function WalletLog(props) {
    const [logs, setLogs] = useState([]);

    useEffect(() => {
        $get('/wallet/logs').then(data => setLogs(data || []));
    }, [props.balance, props.credit]);

    const columns = [
        {
            title: '时间',
            dataIndex: 'createdAt',
            key: 'createdAt',
            className: 'display-time',
            render: time => moment(time).format('YYYY-MM-DD HH:mm:ss'),
        },
        {
            title: '类型',
            dataIndex: 'consumedType',
            key: 'type',
            className: 'display-type',
            render: type => type,
        },
        {
            title: '数量',
            dataIndex: 'consumedNum',
            key: 'value',
            className: 'display-value',
            render: value => {
                const style = { color: value < 0 ? '#FF001B' : '#1890FF' };
                return (
                    <div style={style}>
                        {(value >= 0 ? '+' : '-') + Math.abs(value / 100)}
                    </div>
                );
            },
        },
        {
            title: '明细',
            dataIndex: 'displayMessage',
            key: 'displayMessage',
            className: 'display-message',
            render: message => message,
        },
    ];

    const displayLogs = logs.map(log => {
        const { value, credit, displayMessage, createdAt, id } = log;
        const consumedNum = value || credit;
        const consumedType = value ? '云朵' : '雨滴';
        return { consumedNum, consumedType, displayMessage, createdAt, id };
    });

    return <Table rowKey="id" columns={columns} dataSource={displayLogs} />;
}
