import React, { Component } from 'react';
import { remote } from '@boyuai/utils';
import { Switch, Route } from 'react-router-dom';
import SideBar from './sidebar';
import VideoPage from './video-page';
import CodePage from './code-page';
import QuizPage from './quiz-page';
import JupyterPage from './jupyter-page';
import ErrorPage from '../error';
import { CourseProvider } from '../../context/courseContext';
import LessonPage from './lesson-page';
import IntroPage from './intro-page';
import './index.less';
// import JoinClassModal from '../../components/join-class-modal';
import { isLogin } from '@boyuai/utils/es/remote';
import { useReportEvent } from '@boyuai/utils';
import { LeftCircleOutlined, DownCircleOutlined } from '@ant-design/icons';
// import QianRen from "../../components/qianren";
// import QianrenFinishiedModal from '../../components/qianren-finished-modal';

// import LessonsGraph from './lessons-graph';

class Course extends Component {
    firstMountTimer = null;
    secondMountTimer = null;
    state = {
        course: null,
        isCollapsed: false,
        onboardingStep: 0,
    };
    pathname = null;
    get shouldOnboardingRender() {
        // return !localStorage.getItem('onboardingRendered') && this.isVideoPage;
        return false;
    }
    get shouldCommentOnboardingRender() {
        // 没有渲染过并且有评价按钮
        return !localStorage.getItem('commentRendered');
    }
    get isMobile() {
        return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    }
    get shouldCommentOnboardingShow() {
        return (
            !this.isMobile &&
            this.state.shouldRenderMask &&
            document.querySelector('.feedback-button') &&
            (this.state.onboardingStep === 3 ||
                ((!this.shouldOnboardingRender || !this.isVideoPage) &&
                    this.shouldCommentOnboardingRender))
        );
    }
    get isVideoPage() {
        return window.location.pathname.indexOf('video') > 0;
    }
    // get shouldJoinClassRender() {
    //     // 希望全体重新弹窗则将数字+1
    //     // join-class-modal里面也要改
    //     const currentRenderedTime = '1';
    //     return (
    //         remote.isLogin() &&
    //         (!localStorage.getItem('joinClassRenderedTime') || // 没弹过
    //             localStorage.getItem('joinClassRenderedTime') !==
    //                 currentRenderedTime) && // 弹过的强制再弹一次
    //         !navigator.userAgent.match(/(iPhone|iPod|iPad|Android|ios)/i)
    //     );
    // }
    componentDidMount() {
        window.scrollTo(0, 0);
        this.fetch();
        if (this.shouldOnboardingRender || this.shouldCommentOnboardingRender) {
            this.firstMountTimer = setTimeout(() => {
                const videoInfoEle = document.querySelector('.video-info-tabs');
                const commentAreaEle = document.querySelector(
                    '.editor-wrapper'
                );
                // 渲染评论框引导
                if (this.shouldOnboardingRender && this.isVideoPage) {
                    if (videoInfoEle && commentAreaEle) {
                        this.loadAndScroll(videoInfoEle);
                    } else {
                        this.secondMountTimer = setInterval(() => {
                            const videoInfoEle = document.querySelector(
                                '.video-info-tabs'
                            );
                            const commentAreaEle = document.querySelector(
                                '.editor-wrapper'
                            );
                            if (videoInfoEle && commentAreaEle) {
                                this.loadAndScroll(videoInfoEle, true);
                            }
                        }, 100);
                    }
                }

                if (
                    (!this.shouldOnboardingRender || !this.isVideoPage) &&
                    this.shouldCommentOnboardingRender
                ) {
                    const feedbackEle = document.querySelector(
                        '.feedback-button'
                    );
                    feedbackEle && this.loadAndScroll();
                }
            }, 1500);
        }
    }
    componentDidUpdate() {
        if (
            this.pathname &&
            this.pathname !== this.props.history.location.pathname
        ) {
            window.scrollTo(0, 0);
            // 切换路由后需要重新获取大纲信息
            this.fetch();
            if (
                this.shouldOnboardingRender ||
                this.shouldCommentOnboardingRender
            ) {
                this.firstMountTimer = setTimeout(() => {
                    const videoInfoEle = document.querySelector(
                        '.video-info-tabs'
                    );
                    const commentAreaEle = document.querySelector(
                        '.editor-wrapper'
                    );
                    // 渲染评论框引导
                    if (this.shouldOnboardingRender && this.isVideoPage) {
                        if (videoInfoEle && commentAreaEle) {
                            this.loadAndScroll(videoInfoEle);
                        } else {
                            this.secondMountTimer = setInterval(() => {
                                const videoInfoEle = document.querySelector(
                                    '.video-info-tabs'
                                );
                                const commentAreaEle = document.querySelector(
                                    '.editor-wrapper'
                                );
                                if (videoInfoEle && commentAreaEle) {
                                    this.loadAndScroll(videoInfoEle, true);
                                }
                            }, 100);
                        }
                    }

                    if (
                        (!this.shouldOnboardingRender || !this.isVideoPage) &&
                        this.shouldCommentOnboardingRender
                    ) {
                        const feedbackEle = document.querySelector(
                            '.feedback-button'
                        );
                        feedbackEle && this.loadAndScroll();
                    }
                }, 1500);
            }
        }
        this.pathname = this.props.history.location.pathname;
    }
    loadAndScroll = (videoInfoEle, shouldClearInterval) => {
        shouldClearInterval && clearInterval(this.secondMountTimer);
        videoInfoEle &&
            window.scrollTo(0, videoInfoEle.getBoundingClientRect().top - 180);
        document.body.style.overflow = 'hidden';
        this.setState({ shouldRenderMask: true });
    };
    componentWillUnmount() {
        clearTimeout(this.firstMountTimer);
        clearInterval(this.secondMountTimer);
    }
    get isNeedToBuy() {
        const { course } = this.state;
        return !!course && course.lessons.some(item => !item.lesson.access);
    }
    get firstUnBoughtLessonLink() {
        const { course } = this.state;
        const { uuid } = this.props.match.params;
        if (!course) {
            return null;
        }
        const lesson = course.lessons.find(
            item =>
                item.lesson.videos &&
                item.lesson.videos.length &&
                !item.lesson.access &&
                item.lesson.price
        );
        if (!lesson) {
            return null;
        }
        return `/course/${uuid}/lesson/${lesson.lesson.uuid}`;
    }
    async fetch() {
        const { uuid } = this.props.match.params;
        try {
            const course = await remote.$get(`/course/${uuid}`);
            if (!isLogin()) {
                // 未登录用户拉取是否观看过视频
                const logs = await this.props.reportEvent(
                    'video-finish',
                    {},
                    { fetch: true }
                );
                const videoUUIDs = logs
                    .map(log => log.videoUUID)
                    .filter(Boolean);
                course.lessons.forEach(lesson => {
                    lesson.lesson.videos.forEach(video => {
                        if (videoUUIDs.includes(video.uuid)) {
                            video.learningLog = true;
                        }
                    });
                });
            }
            this.setState({ course });
        } catch (e) {
            this.setState({ isError: true });
        }
    }
    onToggleCollapse = () => {
        this.setState(state => ({ isCollapsed: !state.isCollapsed }));
    };
    render() {
        if (!this.state.shouldRenderMask && !this.isMobile) {
            document.body.style.overflow = 'scroll';
        }
        const { uuid } = this.props.match.params;
        const { course, isCollapsed, isError } = this.state;
        const { lessons = [] } = course || {};
        if (isError) {
            return <ErrorPage />;
        }
        return (
            <div className="page-course">
                {this.state.shouldRenderMask &&
                    !this.isMobile &&
                    this.renderOnboardingMask()}
                {this.shouldCommentOnboardingShow && (
                    <div className="feedback-mask-tip">
                        <LeftCircleOutlined />
                        评价一下已学过的知识点吧！
                        <br />
                        获取100雨滴奖励，被选为精华更可额外获得200雨滴
                    </div>
                )}
                {/*{this.shouldJoinClassRender &&*/}
                {/*    process.env.REACT_APP_SITE === 'elites' && (*/}
                {/*        <JoinClassModal />*/}
                {/*    )}*/}
                <div className={this.isMobile ? undefined : 'course-container'}>
                    <SideBar
                        items={lessons}
                        uuid={uuid}
                        onToggleCollapse={this.onToggleCollapse}
                        isCollapsed={isCollapsed}
                        course={course}
                    />
                    <CourseProvider value={this.state.course}>
                        <div className="main">
                            {/*{ /\/course\/cZu18YmweLv10OeV/.test(window.location.pathname)&& <QianRen/> }*/}
                            {/*{/\/course\/cZu18YmweLv10OeV/.test(
                                window.location.pathname
                            ) && <QianrenFinishiedModal />}*/}
                            <Switch>
                                <Route path="/course/:uuid/lesson/:lessonuuid">
                                    <LessonPage />
                                </Route>
                                <Route path="/course/:uuid/video/:videouuid">
                                    <VideoPage
                                        isSideBarCollapsed={isCollapsed}
                                    />
                                </Route>
                                <Route path="/course/:uuid/code/:codeuuid">
                                    <CodePage />
                                </Route>
                                <Route path="/course/:uuid/quiz/:quizuuid">
                                    <QuizPage />
                                </Route>
                                <Route path="/course/:uuid/jupyter/:jupyteruuid">
                                    <JupyterPage />
                                </Route>
                                <Route path="/course/:uuid" exact>
                                    <IntroPage course={course} />
                                </Route>
                            </Switch>
                        </div>
                    </CourseProvider>
                </div>
            </div>
        );
    }

    renderOnboardingMask() {
        let commentAreaStyle,
            filterStyle,
            feedbackStyle,
            currentLearningStyle = {};
        const coursePageEle = document.querySelector('.page-course');
        const commentAreaEle = document.querySelector('.editor-wrapper');
        const filterEle = document.querySelector(
            '.comment-list .ant-card-head'
        );
        const feedbackEle = document.querySelector('.feedback-button');
        const currentLearning = document.querySelector(
            "li[data-is-active='true']"
        );

        const pageTop = coursePageEle.getBoundingClientRect().top;
        if (commentAreaEle) {
            const {
                height,
                width,
                top,
                left,
            } = commentAreaEle.getBoundingClientRect();
            commentAreaStyle = {
                height: height + 10,
                width: width + 10,
                top: top - pageTop - 5,
                left: left - 5,
            };
        }

        if (filterEle) {
            const {
                height,
                width,
                top,
                left,
            } = filterEle.getBoundingClientRect();
            filterStyle = {
                height: height + 10,
                width: width + 10,
                top: top - pageTop - 5,
                left: left - 5,
            };
        }

        if (currentLearning && currentLearning.parentElement) {
            const {
                height,
                width,
                top,
                left,
            } = currentLearning.parentElement.parentElement.getBoundingClientRect();
            currentLearningStyle = {
                height: height + 10,
                width: width + 10,
                top: top - pageTop - 5,
                left: left - 5,
            };
        }

        if (this.shouldCommentOnboardingShow) {
            const titleEle = feedbackEle.parentElement.parentElement;
            const { height, width, left } = titleEle.getBoundingClientRect();
            feedbackStyle = {
                height: height + 10,
                width: width + 10,
                top: 140,
                left: left - 5,
            };
            setTimeout(() => {
                document.querySelector('.sidebar').scrollTop =
                    titleEle.offsetTop - 60;
            }, 400);
        }
        return (
            <div className="onboarding-mask" onClick={this.setOnboardingStep}>
                <div
                    className="comment-mask"
                    style={commentAreaStyle}
                    data-is-visible={
                        !this.shouldCommentOnboardingShow &&
                        this.state.onboardingStep === 0
                    }
                >
                    <div className="mask-tip">
                        <DownCircleOutlined />
                        <div>
                            你可以在讨论区里发表笔记、提问、闲聊
                            <br />
                            主讲老师与助教会对提问进行解答
                            <br />
                            还可以获取雨滴奖励哦！
                        </div>
                    </div>
                </div>
                <div
                    className="filter-mask"
                    style={filterStyle}
                    data-is-visible={this.state.onboardingStep === 1}
                >
                    <div className="mask-tip">
                        <DownCircleOutlined />
                        不同的筛选方式显示你想看的发言类型
                    </div>
                </div>
                <div
                    className="current-learning-mask"
                    style={currentLearningStyle}
                    data-is-visible={this.state.onboardingStep === 2}
                >
                    <div className="mask-tip">
                        <LeftCircleOutlined />
                        完成知识点的全部单元可进行评价并获取雨滴奖励
                    </div>
                </div>
                <div
                    className="feedback-mask"
                    style={feedbackStyle}
                    data-is-visible={this.shouldCommentOnboardingShow}
                ></div>
            </div>
        );
    }

    setOnboardingStep = () => {
        if (
            (!this.shouldOnboardingRender || !this.isVideoPage) &&
            this.shouldCommentOnboardingRender
        ) {
            this.setState({ shouldRenderMask: false });
            localStorage.setItem('commentRendered', true);
            return;
        }
        const { onboardingStep } = this.state;
        if (onboardingStep === 2) {
            window.scrollTo(0, 0);
        }
        if (
            onboardingStep < 2 ||
            (onboardingStep === 2 &&
                document.querySelector('.feedback-button') &&
                this.shouldCommentOnboardingRender)
        ) {
            this.setState({ onboardingStep: this.state.onboardingStep + 1 });
        } else {
            this.setState({ shouldRenderMask: false });
            localStorage.setItem('onboardingRendered', true);
            onboardingStep === 3 &&
                localStorage.setItem('commentRendered', true);
        }
    };
}

function withEventReporter(Component) {
    return function WrappedComponent(props) {
        const reportEvent = useReportEvent();
        return <Component {...props} reportEvent={reportEvent} />;
    };
}

export default withEventReporter(Course);
