import React, { useEffect, useCallback, useState, useMemo } from 'react';
import { Button, Modal, Popover, Spin } from 'antd';
import moment from 'moment';
import { remote } from '@boyuai/utils';
import QRCode from 'qrcode';
import Guide from './guide';
import Explanation from './explanation';
import Pending from './pending';

const TITLE_QIANREN =
    'https://staticcdn.boyuai.com/materials/2020/04/30/i-eDj-F_v54vlHuQJQB07.png!png';
const TITLE_NOT_QIANREN =
    'https://staticcdn.boyuai.com/materials/2020/02/29/vGNiz_0s-ArA7sCTx4-94.png!png';
const THANK_QIANREN =
    'https://staticcdn.boyuai.com/materials/2020/04/30/9SMgSLwTSs0NiJ-uuJPHz.png!png';
const THANK_NOT_QIANREN =
    'https://staticcdn.boyuai.com/materials/2020/02/29/LHGT1T8MVD5i9t4xI8UJW.png!png';

const CHALLENGE_OFF =
    'https://staticcdn.boyuai.com/materials/2020/03/01/LaXOoelZQVhvY5thYIP57.png!png';
const CHALLENGE_ON =
    'https://staticcdn.boyuai.com/materials/2020/03/01/qQRmw9yWYrrlUhRmLgbyX.png!png';
const UNLOCK_OFF =
    'https://staticcdn.boyuai.com/materials/2020/03/01/l9J_gLb7lgdKe5irR1e-o.png!png';
const UNLOCK_ON =
    'https://staticcdn.boyuai.com/materials/2020/03/01/PX8TXD5jnisRRdk1Qcm9Y.png!png';
const CHECK =
    'https://staticcdn.boyuai.com/materials/2020/03/01/Xj9zg57MkgNaAK-Vem__e.png!png';

const translateDate = formattedDate => {
    return formattedDate
        .split('-')
        .map((item, index) => {
            if (index === 0) {
                return `${parseInt(item)}月`;
            }
            if (index === 1) {
                return `${parseInt(item)}日`;
            }
            return item;
        })
        .join('');
};

const translateReward = item => {
    const contentList = item.content.split(' ');
    return (
        <span className="activity-qianren-laxin-modal-content-box-reward">
            {translateDate(moment(item.createdAt).format('MM-DD'))}{' '}
            {/*12月29日*/}
            {item.link ? (
                <div
                    className="activity-qianren-laxin-modal-content-box-reward-text"
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                >
                    {contentList[0]}
                    {contentList[1]}
                    <Button
                        type={'link'}
                        style={{ padding: 'unset', height: 20, fontSize: 12 }}
                        onClick={() => {
                            window.open(
                                item.link.replace(
                                    'https://www.boyuai.com/elites',
                                    process.env.REACT_APP_SUB_DIRECTORY
                                )
                            );
                        }}
                    >
                        <div className="activity-qianren-laxin-modal-content-box-reward-link">
                            {contentList[2]}
                        </div>
                    </Button>
                    {/*<a
                        href={item.link.replace(
                            'https://www.boyuai.com/elites/',
                            process.env.REACT_APP_SUB_DIRECTORY
                        )}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <div className="activity-qianren-laxin-modal-content-box-reward-link">
                            {contentList[2]}
                        </div>
                    </a>*/}
                    {contentList[3]}
                </div>
            ) : (
                item.content
            )}
        </span>
    );
};

export default function Modal2({
    visible,
    onClose,
    activity,
    onActivityChange,
    pending,
}) {
    // console.log(activity);
    const isQianren = true; // 全部开放第二阶段
    // const isQianren = !activity.content.isQianren;
    const [qrcode, setQrcode] = useState(null);
    const [loading, setLoading] = useState(false);
    const fetch = useCallback(async () => {
        setLoading(true);
        await remote.$get('/user/public/referral');
        const publicToken = await remote.$get('/user/public/token');
        const site = process.env.REACT_APP_ENV === 'production' ? '' : '.dev';
        const image = await QRCode.toDataURL(
            `https://share${site}.boyuai.com/register/${publicToken}`
        );
        setQrcode(image);
        setLoading(false);
    }, []);
    useEffect(() => {
        if (visible) {
            fetch();
        }
        // eslint-disable-next-line
    }, [visible]);
    const onQrcodeClicked = useCallback(async () => {
        if (!activity.content.modal2Guided) {
            // onActivityChange(prev => ({
            //     ...prev,
            //     content: {
            //         ...prev.content,
            //         modal2Guided: true,
            //     },
            // }));
            const act = await remote.$patch(
                `/course/activities/${activity.id}/content`,
                { content: { modal2Guided: true } },
                { hideLoading: true }
            );
            onActivityChange(act);
        }
    }, [onActivityChange, activity]);
    // const [progress, setProgress] = useState(0);
    // useEffect(() => {
    //     setInterval(() => setProgress(i => i + 1), 2000);
    // }, []);
    const progress = useMemo(() => {
        let p = 0;
        if (activity && activity.content && activity.content.users) {
            p =
                activity.content.users.filter(user => !user.active).length +
                activity.content.users.filter(user => user.active).length * 3;
        }
        if (isQianren) {
            return p <= 6 ? p : 6;
        }
        return p <= 3 ? p : 3;
    }, [activity, isQianren]);

    const reversedUsers = useMemo(() => {
        if (activity.content.users) {
            const l = [...activity.content.users];
            l.reverse();
            return l;
        } else {
            return [];
        }
    }, [activity.content.users]);

    const reversedRewards = useMemo(() => {
        if (activity.content.rewards) {
            const l = [...activity.content.rewards];
            l.reverse();
            return l;
        } else {
            return [];
        }
    }, [activity.content.rewards]);

    return (
        <Modal
            wrapClassName="activity-laxin-20200501-modal"
            visible={visible}
            footer={null}
            onCancel={onClose}
            title={null}
            width={890}
        >
            <img
                onClick={() => onClose()}
                className="close-button"
                src="https://staticcdn.boyuai.com/materials/2020/02/24/MPk_uBjcortZgs-EYMRKM.png!png"
                alt="关闭"
            />
            <div className="activity-qianren-laxin-modal-content">
                <img
                    className="activity-qianren-laxin-modal-content-title"
                    src={isQianren ? TITLE_QIANREN : TITLE_NOT_QIANREN}
                    // alt="春暖花开"
                    alt=""
                    style={{
                        top: 62,
                        left: 60,
                        width: 240,
                    }}
                />
                <img
                    className="activity-qianren-laxin-modal-content-thanks"
                    src={isQianren ? THANK_QIANREN : THANK_NOT_QIANREN}
                    // alt="感谢有你"
                    alt=""
                    style={{
                        top: 70,
                        right: 150,
                        width: 320,
                    }}
                />
                {pending ? (
                    <Pending />
                ) : (
                    <Spin spinning={loading}>
                        <div
                            className="activity-qianren-laxin-modal-content-box"
                            style={{
                                top: 188 - 24,
                            }}
                        >
                            <div className="activity-qianren-laxin-modal-content-box-title">
                                邀请进度
                            </div>
                            <div className="activity-qianren-laxin-modal-content-box-progress">
                                <div className="activity-qianren-laxin-modal-content-box-progress-track">
                                    <div
                                        className="activity-qianren-laxin-modal-content-box-progress-bar"
                                        style={{
                                            width:
                                                progress === 0
                                                    ? 0
                                                    : progress === 1
                                                    ? isQianren
                                                        ? '34px'
                                                        : '60px'
                                                    : `${((progress - 1) *
                                                          100) /
                                                          (isQianren
                                                              ? 5
                                                              : 2)}%`,
                                        }}
                                    />
                                </div>
                                <div
                                    className="activity-qianren-laxin-modal-content-box-progress-check"
                                    data-checked={progress >= 1}
                                    style={{ marginLeft: 0 }}
                                >
                                    <img src={CHECK} alt="" />
                                </div>
                                <div
                                    className="activity-qianren-laxin-modal-content-box-progress-check"
                                    data-checked={progress >= 2}
                                >
                                    <img src={CHECK} alt="" />
                                </div>
                                <img
                                    src={
                                        progress >= 3
                                            ? CHALLENGE_ON
                                            : CHALLENGE_OFF
                                    }
                                    alt={'挑战开始'}
                                    width={'80px'}
                                    height={'80px'}
                                />
                                {isQianren && (
                                    <>
                                        <div
                                            className="activity-qianren-laxin-modal-content-box-progress-check"
                                            data-checked={progress >= 4}
                                        >
                                            <img src={CHECK} alt="" />
                                        </div>
                                        <div
                                            className="activity-qianren-laxin-modal-content-box-progress-check"
                                            data-checked={progress >= 5}
                                        >
                                            <img src={CHECK} alt="" />
                                        </div>
                                        <img
                                            src={
                                                progress >= 6
                                                    ? UNLOCK_ON
                                                    : UNLOCK_OFF
                                            }
                                            alt={'未解锁'}
                                            width={'80px'}
                                            height={'80px'}
                                        />
                                    </>
                                )}
                            </div>
                            <span
                                style={{
                                    width: 230,
                                    position: 'absolute',
                                    bottom: -8,
                                    right: -50,
                                    fontSize: 10,
                                    color: 'rgba(255,255,255,0.6)',
                                    height: 0,
                                }}
                            >
                                *&nbsp;&nbsp;请刷新页面查看最新邀请进度
                            </span>
                        </div>
                        <div
                            className="activity-qianren-laxin-modal-content-box"
                            style={{
                                top: 368 - 24,
                                width: 380,
                                padding: '26px 20px 8px 20px',
                                height: 160,
                            }}
                        >
                            <div className="activity-qianren-laxin-modal-content-box-title">
                                邀请详情
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                }}
                            >
                                <Popover
                                    placement="top"
                                    onClick={onQrcodeClicked}
                                    trigger="click"
                                    content={
                                        <div>
                                            <img
                                                src={qrcode}
                                                alt="二维码"
                                                width="200px"
                                                height="200px"
                                            />
                                        </div>
                                    }
                                >
                                    <img
                                        src={qrcode}
                                        alt="邀请二维码"
                                        width="80px"
                                        height="80px"
                                        style={{
                                            position: 'relative',
                                            cursor: 'pointer',
                                            borderRadius: 8,
                                            margin: '5px 0 0 5px',
                                        }}
                                    />
                                    {!activity.content.modal2Guided && (
                                        <Guide left={-86} top={50} />
                                    )}
                                </Popover>
                                <span
                                    className="scroll-without-bar-shell"
                                    style={{
                                        marginLeft: 12,
                                        color: 'white',
                                        fontSize: 12,
                                        height: 90,
                                    }}
                                >
                                    <div className="scroll-without-bar-container">
                                        {reversedUsers.length === 0 ? (
                                            <div style={{ height: 15 }}>
                                                暂无邀请用户
                                            </div>
                                        ) : null}
                                        {reversedUsers
                                            .filter(item => item.active)
                                            .map(item => (
                                                <div
                                                    key={item.phone}
                                                    style={{ height: 15 }}
                                                >
                                                    您邀请的 {item.phone}{' '}
                                                    用户成为活跃用户
                                                </div>
                                            ))}
                                        {reversedUsers.map(item => (
                                            <div
                                                key={item.phone}
                                                style={{ height: 15 }}
                                            >
                                                {translateDate(
                                                    moment(
                                                        item.createdAt
                                                    ).format('MM-DD')
                                                )}{' '}
                                                成功邀请{item.phone}的用户
                                            </div>
                                        ))}
                                    </div>
                                </span>
                            </div>
                            <div
                                className="activity-qianren-laxin-modal-content-box-content"
                                style={{
                                    fontSize: 12,
                                    fontWeight: 'bold',
                                    marginTop: 8,
                                    lineHeight: '14px',
                                }}
                            >
                                您的
                                <span
                                    style={{
                                        color: '#FF8585',
                                    }}
                                >
                                    邀请二维码
                                </span>
                                ，扫码生成学习海报
                                <br />
                                新用户通过此海报扫码注册即为邀请成功
                            </div>
                        </div>
                        <div
                            className="activity-qianren-laxin-modal-content-box"
                            style={{
                                top: 368 - 24,
                                right: 0,
                                width: 400,
                                height: 160,

                                padding: '22px 14px 8px 14px',
                            }}
                        >
                            <div className="activity-qianren-laxin-modal-content-box-title">
                                获得奖励
                            </div>
                            <div
                                style={{
                                    lineHeight: '20px',
                                    color: 'white',
                                    width: 372,
                                }}
                            >
                                {activity.state >= 1 &&
                                activity.content.signExpiresAt ? (
                                    <span className="activity-qianren-laxin-modal-content-box-reward">
                                        {/*{translateDate(
                                                moment(
                                                    activity.state1CompletedAt
                                                ).format('MM-DD')
                                            )}{' '}*/}
                                        已开启打卡挑战，您可在
                                        <span
                                            style={{
                                                color: '#FF8585',
                                                fontWeight: 'bold',
                                                margin: '0 4px',
                                            }}
                                        >
                                            {translateDate(
                                                moment(
                                                    activity.content
                                                        .signExpiresAt
                                                ).format('MM-DD')
                                            )}{' '}
                                        </span>
                                        前每日打卡解锁知识点
                                    </span>
                                ) : (
                                    <div
                                        style={{
                                            color: 'white',
                                            fontSize: 12,
                                        }}
                                    >
                                        暂无奖励
                                    </div>
                                )}
                            </div>
                            <div
                                className="scroll-without-bar-shell"
                                style={{
                                    color: 'white',
                                    fontSize: 12,
                                    height: 110,
                                    width: 375,
                                }}
                            >
                                <div className="scroll-without-bar-container">
                                    {reversedRewards.map((item, index) => (
                                        <div
                                            key={index}
                                            style={{
                                                // lineHeight: '20px',
                                                width: 372,
                                            }}
                                        >
                                            {translateReward(item)}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </Spin>
                )}
                <Popover
                    placement="topRight"
                    content={
                        <div style={{ width: 780 }}>
                            <Explanation />
                        </div>
                    }
                >
                    <div
                        className="activity-qianren-laxin-modal-content-explanation"
                        style={{ fontSize: 14, fontWeight: 'normal' }}
                    >
                        查看活动详情
                    </div>
                </Popover>
            </div>
        </Modal>
    );
}
