import React, { useEffect, useState } from 'react';
import { Alert, Switch } from 'antd';
import { remote } from '@boyuai/utils';

export default function JupyterHub({ form }) {
    const [enable, setEnable] = useState(null);
    useEffect(() => {
        remote.$get('/jupyterhub/gpu').then(data => setEnable(data.value));
    }, []);
    return (
        <div>
            <Alert message="关闭GPU后，所有已开启的GPU Notebook都将被关闭" />
            {enable !== null && (
                <div style={{ marginTop: '16px' }}>
                    GPU服务器：
                    <Switch
                        checked={enable}
                        onChange={value => {
                            setEnable(value);
                            remote.$post('/jupyterhub/gpu', {
                                enable: value,
                            });
                        }}
                    />
                </div>
            )}
        </div>
    );
}
