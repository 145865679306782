import { useCallback, useEffect, useState } from 'react';
import { remote } from '@boyuai/utils';

export const useEvents = (eventKey, skip, take, startDate, endDate) => {
    const [events, setEvents] = useState([]);
    const [total, setTotal] = useState(0);
    const fetchEvents = useCallback(async () => {
        if (eventKey) {
            const payload = {
                skip,
                take,
            };
            if (startDate) {
                payload.startDate = startDate.format('YYYYMMDD');
            }
            if (endDate) {
                payload.endDate = endDate.format('YYYYMMDD');
            }
            const res = await remote.$get(`/events/admin/${eventKey}`, payload);
            if (res) {
                const [list, count] = res;
                setEvents(list);
                setTotal(count);
            }
        }
    }, [eventKey, skip, take, startDate, endDate]);
    useEffect(() => {
        fetchEvents();
    }, [fetchEvents]);
    return [events, total, fetchEvents];
};
