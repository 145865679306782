import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Spin } from 'antd';
import './trinket.less';

export default class Trinket extends Component {
    static propTypes = {
        hash: PropTypes.string.isRequired,
    };
    render() {
        const { hash } = this.props;
        return (
            <div className="ui-trinket">
                <Spin spinning>
                    <div className="fake-iframe" />
                </Spin>
                <iframe
                    title="代码练习"
                    src={`https://trinket.io/embed/python/${hash}`}
                    width="100%"
                    height="356"
                    frameBorder="0"
                    marginWidth="0"
                    marginHeight="0"
                ></iframe>
            </div>
        );
    }
}
