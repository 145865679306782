import '@boyuai/admin-kit/dist/index.css';
import '@boyuai/markdown/dist/index.css';

import React, {
    useState,
    useEffect,
    useLayoutEffect,
    useContext,
    useMemo,
} from 'react';
import { isArray } from 'lodash';
import { Switch, Route, Link, useRouteMatch } from 'react-router-dom';
import { useHistory } from 'react-router';
import { Card, Menu, Spin } from 'antd';
import { UserContextModule } from '@boyuai/utils';
import Lessons from './lessons';
import Lesson from './lesson';
import Recharge from './recharge';
import Redeem from './redeem';
import Comments from './comments';
import Materials from './materials';
import BuyCourse from './buy-course';
import Authors from './admin-author/authors';
import Video from './video';
import JupyterHub from './jupyterhub';
import PublicVideos from './admin-public-video/public-videos';
import './index.less';
import UserStatistic from './user-statistic';
import AdminAddCourseForUser from './add-course-for-user';
import AdminCreateUsers from './create-users';
import AdminEvents from './admin-events';
import AdminBBYStat from './bby-stat';
import {
    TableOutlined,
    UserOutlined,
    MessageOutlined,
    YoutubeOutlined,
    WalletOutlined,
    CloudUploadOutlined,
    SettingOutlined,
    BulbOutlined,
    QrcodeOutlined,
    ScanOutlined,
    WechatOutlined,
    MenuOutlined,
    CommentOutlined,
    AlignCenterOutlined,
} from '@ant-design/icons';
import UserRole from './user-role';
import UserLabel from './user-label';
import AdminActivities from './admin-activity';
import AdminGo from './admin-go';
import AdminPromotionCodes from './admin-promotions/promotion-codes';
import AdminPromotionCodeLogs from './admin-promotions/promotion-code-logs';
import AdminAddCourseForUserLog from './add-course-for-user-log';
import AdminFuwuhaoAutoReply from './fuwuhao/auto-reply';
import AdminFuwuhaoMenu from './fuwuhao/menu';
import AdminQuizQuestions from './quiz-questions';
import AdminQuizQuestionTags from './quiz-question-tags';
import QuizGeneral from './quiz-general';
const { UserContext } = UserContextModule;

const menus = [
    { key: 'lessons', icon: TableOutlined, title: '内容管理', role: 'editor' },
    { key: 'authors', icon: UserOutlined, title: '作者管理', role: 'editor' },
    {
        key: 'comments',
        icon: MessageOutlined,
        title: '评论管理',
        role: 'commentManager',
    },
    { key: 'video', icon: YoutubeOutlined, title: '视频修复', role: 'admin' },
    {
        key: 'materials',
        icon: CloudUploadOutlined,
        title: '图片/音频上传',
        role: ['imageAdmin', 'editor'],
    },
    {
        key: 'quiz-general',
        icon: AlignCenterOutlined,
        title: '文本转quiz',
        role: ['imageAdmin', 'editor'],
    },
    {
        key: 'jupyterhub',
        icon: SettingOutlined,
        title: 'JupyterHub',
        role: 'jupyterAdmin',
    },
    {
        key: 'public-video',
        icon: YoutubeOutlined,
        title: '波波鱼视频',
        role: 'editor',
    },
    {
        key: 'activity-tools',
        icon: SettingOutlined,
        title: '活动工具',
        children: [
            // fixme: 为什么9.29的更新把redeem从菜单删掉了？
            // {
            //     key: 'redeem',
            //     icon: WalletOutlined,
            //     title: '生成兑换码',
            //     role: 'finance',
            // },
            {
                key: 'recharge',
                icon: WalletOutlined,
                title: '快速充值',
                role: 'finance',
            },
            {
                key: 'buy-course',
                icon: WalletOutlined,
                title: '快速购买课程',
                role: 'finance',
            },
            {
                key: 'add-course-for-user',
                icon: WalletOutlined,
                title: '批量开通课程',
                role: 'finance',
            },
            {
                key: 'add-course-for-user-log',
                icon: WalletOutlined,
                title: '批量开通课程记录',
                role: 'finance',
            },
            {
                key: 'create-users',
                icon: UserOutlined,
                title: '批量注册用户',
                role: 'admin',
            },
            {
                key: 'user-activities',
                icon: SettingOutlined,
                title: '活动进度管理',
                role: 'admin',
            },
            {
                key: 'events',
                icon: BulbOutlined,
                title: '事件查询',
                role: 'admin',
            },
            {
                key: 'go',
                icon: BulbOutlined,
                title: '邮箱链接点击记录',
                role: 'admin',
            },
            {
                key: 'user-statistic',
                icon: UserOutlined,
                title: '用户数据',
                role: 'courseStatistic',
            },
            {
                key: 'bby-stat',
                icon: UserOutlined,
                title: '波波鱼数据',
                role: 'learnStatistic',
            },
        ],
    },
    {
        key: 'promotions',
        icon: QrcodeOutlined,
        title: '渠道二维码',
        children: [
            {
                key: 'codes',
                icon: SettingOutlined,
                title: '二维码管理',
                role: 'courseStatistic',
            },
            {
                key: 'logs',
                icon: ScanOutlined,
                title: '扫码记录查询',
                role: 'courseStatistic',
            },
        ],
    },
    {
        key: 'user-role',
        icon: UserOutlined,
        title: '用户权限',
        role: 'admin',
    },
    {
        key: 'user-label',
        icon: UserOutlined,
        title: '用户标签',
        role: 'finance',
    },
    {
        key: 'fuwuhao',
        icon: WechatOutlined,
        title: '微信服务号',
        children: [
            {
                key: 'menu',
                icon: MenuOutlined,
                title: '菜单管理',
                role: 'admin',
            },
            {
                key: 'auto-reply',
                icon: CommentOutlined,
                title: '自动回复',
                role: 'admin',
            },
        ],
    },
    {
        key: 'quiz-questions',
        icon: SettingOutlined,
        title: '题目管理',
        role: 'quiz',
    },
    {
        key: 'quiz-question-tags',
        icon: SettingOutlined,
        title: '题目标签管理',
        role: 'editor',
    },
];

export default function AdminPageRoutes() {
    const match = useRouteMatch('/admin/:selectedKey/:subSelectedKey?');
    const selectedKey = match && match.params && match.params.selectedKey;
    const subSelectedKey = match && match.params && match.params.subSelectedKey;
    const [current, setCurrent] = useState('courses');
    const history = useHistory();
    const user = useContext(UserContext).user;

    const displayMenus = useMemo(() => {
        const handleMenuAccess = (menuRole, userRoles) => {
            if (!menuRole) {
                return true;
            }
            if (typeof menuRole === 'string') {
                return userRoles.includes(menuRole);
            } else if (isArray(menuRole)) {
                for (let i = 0; i <= menuRole.length; i++) {
                    if (userRoles.includes(menuRole[i])) {
                        return true;
                    }
                }
            }
            return false;
        };
        if (user && user.roles) {
            return menus
                .map(menu => {
                    if (!menu.children) {
                        return menu;
                    } else {
                        return {
                            ...menu,
                            children: menu.children.filter(item =>
                                handleMenuAccess(item.role, user.roles)
                            ),
                        };
                    }
                })
                .filter(menu =>
                    menu.children
                        ? menu.children.length
                        : handleMenuAccess(menu.role, user.roles)
                );
        } else {
            return [];
        }
    }, [user]);

    useEffect(() => {
        if (user && user.id && displayMenus.length === 0) {
            window.location.href = process.env.REACT_APP_SUB_DIRECTORY || '/';
        }
    }, [user, displayMenus]);

    useLayoutEffect(() => {
        const active = menus.find(menu => {
            return (
                menu.key === selectedKey &&
                (!menu.children ||
                    menu.children.find(item => item.key === subSelectedKey))
            );
        });
        if (!active) {
            if (displayMenus[0]) {
                if (displayMenus[0].children) {
                    if (displayMenus[0].children[0]) {
                        history.push(
                            `/admin/${displayMenus[0].key}/${displayMenus[0].children[0].key}`
                        );
                    }
                    return;
                }
                history.push(`/admin/${displayMenus[0].key}`);
                return;
            }
            return;
        }
        const { roles } = user;
        if (!roles || roles.length === 0) {
            return;
        }

        if (typeof active.role === 'string') {
            if (roles.includes(active.role)) {
                if (current !== active.key) {
                    setCurrent(active.key);
                }
                return;
            }
        } else if (isArray(active.role)) {
            for (let i = 0; i <= active.role.length; i++) {
                if (roles.includes(active.role[i])) {
                    if (current !== active.key) {
                        setCurrent(active.key);
                    }
                    return;
                }
            }
        }
    }, [current, displayMenus, history, selectedKey, subSelectedKey, user]);

    const handleMenuClick = e => setCurrent(e.key);

    if (!user || displayMenus.length === 0) {
        return <Spin spinning />;
    }

    return (
        <div className="page-admin ui-layout-container">
            <div className="admin-sidebar">
                <Card title="管理后台">
                    <Menu
                        mode="vertical"
                        onClick={handleMenuClick}
                        selectedKeys={[selectedKey, subSelectedKey]}
                    >
                        {displayMenus.map(item => {
                            if (!item.children) {
                                return (
                                    <Menu.Item key={item.key}>
                                        <Link
                                            to={`/admin/${
                                                item.key
                                            }${item.search || ''}`}
                                        >
                                            <item.icon />
                                            {item.title}
                                        </Link>
                                    </Menu.Item>
                                );
                            }

                            return (
                                <Menu.SubMenu
                                    key={item.key}
                                    icon={<item.icon />}
                                    title={item.title}
                                >
                                    {item.children.map(child => (
                                        <Menu.Item key={child.key}>
                                            <child.icon />
                                            <Link
                                                to={`/admin/${item.key}/${child.key}`}
                                            >
                                                {child.title}
                                            </Link>
                                        </Menu.Item>
                                    ))}
                                </Menu.SubMenu>
                            );
                        })}
                    </Menu>
                </Card>
            </div>
            <div className="main">
                <Switch>
                    <Route exact path="/admin/lessons" component={Lessons} />
                    <Route exact path="/admin/authors" component={Authors} />
                    <Route path="/admin/lessons/:lessonId" component={Lesson} />
                    <Route path="/admin/quiz-general" component={QuizGeneral} />
                    <Route path="/admin/materials" component={Materials} />
                    <Route path="/admin/comments" component={Comments} />
                    <Route exact path="/admin/video" component={Video} />
                    <Route
                        exact
                        path="/admin/jupyterhub"
                        component={JupyterHub}
                    />
                    <Route
                        exact
                        path="/admin/public-video"
                        component={PublicVideos}
                    />
                    <Route
                        exact
                        path="/admin/activity-tools/recharge"
                        component={Recharge}
                    />
                    <Route
                        exact
                        path="/admin/activity-tools/buy-course"
                        component={BuyCourse}
                    />
                    <Route
                        path="/admin/activity-tools/add-course-for-user"
                        component={AdminAddCourseForUser}
                    />
                    <Route
                        path="/admin/activity-tools/add-course-for-user-log"
                        component={AdminAddCourseForUserLog}
                    />
                    <Route
                        exact
                        path="/admin/activity-tools/redeem"
                        component={Redeem}
                    />
                    <Route
                        path="/admin/activity-tools/create-users"
                        component={AdminCreateUsers}
                    />
                    <Route
                        path="/admin/activity-tools/go"
                        component={AdminGo}
                    />
                    <Route
                        path="/admin/activity-tools/user-activities"
                        component={AdminActivities}
                    />
                    <Route
                        path="/admin/activity-tools/events"
                        component={AdminEvents}
                    />
                    <Route
                        path="/admin/activity-tools/user-statistic"
                        component={UserStatistic}
                    />
                    <Route
                        path="/admin/activity-tools/bby-stat"
                        component={AdminBBYStat}
                    />
                    <Route
                        path="/admin/promotions/codes"
                        component={AdminPromotionCodes}
                    />
                    <Route
                        path="/admin/promotions/logs/:codeId?"
                        component={AdminPromotionCodeLogs}
                    />
                    <Route path="/admin/user-role" component={UserRole} />
                    <Route path="/admin/user-label" component={UserLabel} />
                    <Route
                        path="/admin/fuwuhao/auto-reply"
                        component={AdminFuwuhaoAutoReply}
                    />
                    <Route
                        path="/admin/fuwuhao/menu"
                        component={AdminFuwuhaoMenu}
                    />
                    <Route
                        path="/admin/quiz-questions"
                        component={AdminQuizQuestions}
                    />
                    <Route
                        path="/admin/quiz-question-tags"
                        component={AdminQuizQuestionTags}
                    />
                </Switch>
            </div>
        </div>
    );
}
