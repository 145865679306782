import React, { useLayoutEffect, useState } from 'react';
import { Carousel } from 'antd';
import { Title } from './title';
import './about-boyu.less';

const WIDTH = 888;
const ARROW_SIZE = 54;

function NextArrow(props) {
    const { className, style, onClick, windowWidth } = props;
    return (
        <div
            className={className}
            style={{
                ...style,
                right: `${windowWidth / 2 - WIDTH / 2 - ARROW_SIZE - 62}px`,
            }}
            onClick={onClick}
        >
            <img
                alt="next"
                src="https://staticcdn.boyuai.com/materials/2020/11/13/DU1FCCvrme_WbG9edZLrs.svg"
            />
        </div>
    );
}

function PrevArrow(props) {
    const { className, style, onClick, windowWidth } = props;
    return (
        <div
            className={className}
            style={{
                ...style,
                left: `${windowWidth / 2 - WIDTH / 2 - ARROW_SIZE - 62}px`,
            }}
            onClick={onClick}
        >
            <img
                alt="prev"
                src="https://staticcdn.boyuai.com/materials/2020/11/13/fUzgGXh9eNoPDk8v2PhTZ.svg"
            />
        </div>
    );
}

const AboutBoyu = () => {
    const [size, setSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });
    function updateWindowSize() {
        setSize({ width: window.innerWidth, height: window.innerHeight });
    }
    useLayoutEffect(() => {
        window.onresize = updateWindowSize;
    }, []);

    return (
        <div id="about" className="about-boyu">
            <Title
                title="关于伯禹"
                intro="Make AI Education for All，传播优质AI教育资源，赋能全社会"
            />
            <img
                className="teacher"
                alt="founder"
                src="https://staticcdn.boyuai.com/user-assets/6074/wdQ1nzYJiN5jnpjS6beeWM/创始人.png"
                style={{ marginBottom: 120 }}
            />
            <Title title="师资" intro="专业全职团队+资深业界大咖，匠心教育" />
            <img
                className="teacher"
                alt="teahcer"
                src="https://staticcdn.boyuai.com/user-assets/14398/6tnd7roFgjSkkrmED6971z/教研团队.png"
                style={{ marginTop: 48, marginBottom: 80 }}
            />
            <img
                className="teacher"
                alt="teahcer"
                src="https://staticcdn.boyuai.com/user-assets/14398/hewY66fNYsZ2khpEYSUayg/20230214-152932.png!png"
                style={{ marginBottom: 120 }}
            />
            <Title
                title="技术"
                intro="用编程与AI技术，为你打造更好的编程与AI学习体验"
            />
            <Carousel
                arrows
                nextArrow={<NextArrow windowWidth={size.width} />}
                prevArrow={<PrevArrow windowWidth={size.width} />}
                draggable
                infinite={false}
                centerPadding={`${(size.width - WIDTH) / 2 - 12}px`}
                centerMode
                pauseOnDotsHover
                pauseOnFocus
                pauseOnHover
            >
                <div>
                    <div className="carousel-item">
                        <img
                            alt="platform"
                            src="https://staticcdn.boyuai.com/materials/2020/11/13/gapG9pgN7YWbz91cZ09q3.png"
                        />
                        <div className="carousel-item-title">智能教学平台</div>
                    </div>
                </div>
                <div>
                    <div className="carousel-item">
                        <img
                            alt="roadmap"
                            src="https://staticcdn.boyuai.com/materials/2020/11/13/TstWrT67lKBw0KZoD_bds.png"
                        />
                        <div className="carousel-item-title">
                            个性化学习路径
                        </div>
                    </div>
                </div>
                <div>
                    <div className="carousel-item">
                        <img
                            alt="communication"
                            src="https://staticcdn.boyuai.com/materials/2020/11/13/4OatDcjUI61UnRjV5L3XU.png"
                        />
                        <div className="carousel-item-title">
                            深度交流、共创共享
                        </div>
                    </div>
                </div>
            </Carousel>
        </div>
    );
};

export { AboutBoyu };
