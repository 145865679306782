import React from 'react';
import './index.less';

export default function GiftCard() {
    const bgStyle = {
        backgroundImage:
            'url(https://staticcdn.boyuai.com/materials/2020/01/21/-KnPlziLFqwnh06AO6irk.png)',
    };

    const getBgStyle = url => {
        return {
            backgroundImage: `url(${url})`,
        };
    };
    return (
        <div className="page-gift" style={bgStyle}>
            <div
                className="img-wrapper"
                style={getBgStyle(
                    'https://staticcdn.boyuai.com/materials/2020/01/21/hHd3rBmm9GRqcIMXnC1Px.png'
                )}
            >
                <img
                    src="https://staticcdn.boyuai.com/materials/2020/01/21/aktU96CAf67qlThZAdBLf.png"
                    alt=""
                />
            </div>
            <div
                className="img-wrapper"
                style={getBgStyle(
                    'https://staticcdn.boyuai.com/materials/2020/01/21/76o8MhWWNMa4zBJQcsg0C.png'
                )}
            >
                <img
                    src="https://staticcdn.boyuai.com/materials/2020/01/21/TGqk6gR6d-VIOLzyijlkK.png"
                    alt=""
                />
            </div>
            <div
                className="img-wrapper"
                style={getBgStyle(
                    'https://staticcdn.boyuai.com/materials/2020/01/21/9fbuS-NXYEKCh3yw6LJ6h.png'
                )}
            >
                <img
                    src="https://staticcdn.boyuai.com/materials/2020/01/21/_ERJN6aDOfcyUUvhm4JDL.png"
                    alt=""
                />
            </div>
            <div
                className="img-wrapper"
                style={getBgStyle(
                    'https://staticcdn.boyuai.com/materials/2020/01/21/EUBx09YkRW-S72ien7FEd.png'
                )}
            >
                <img
                    src="https://staticcdn.boyuai.com/materials/2020/01/21/FY7W-huSnGDAV3mDpx7Ln.png"
                    alt=""
                />
            </div>
        </div>
    );
}
