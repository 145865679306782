import React, { useCallback } from 'react';
import { Form, Input, message, Modal, Select } from 'antd';
import { remote } from '@boyuai/utils';
import PropTypes from 'prop-types';

const colConfig = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 },
};

const PromotionCodeFormModal = props => {
    const { promotionCode, onClose, onSubmit } = props;
    const isCreate = !!promotionCode;
    const [formRef] = Form.useForm();

    const onOk = useCallback(
        async values => {
            const { title, type, config } = values;
            const payload = {
                title,
            };
            if (type) {
                // 作编辑用时不会有type
                payload.type = type;
            }
            try {
                payload.config = JSON.parse(config);
            } catch (e) {
                message.error('config必须是JSON');
                return;
            }
            if (isCreate) {
                await remote.$patch(
                    `/promotions/${promotionCode.id}`,
                    payload,
                    {
                        throwException: 'showMessage',
                    }
                );
            } else {
                await remote.$post('/promotions', payload, {
                    throwException: 'showMessage',
                });
            }
            onSubmit();
        },
        [isCreate, onSubmit, promotionCode]
    );
    return (
        <Modal
            title={`${isCreate ? '创建' : '编辑'}渠道二维码`}
            visible={true}
            onCancel={onClose}
            onOk={() => {
                formRef.validateFields().then(onOk);
            }}
        >
            <Form form={formRef}>
                {/*创建时才有这一项*/}
                {!promotionCode && (
                    <Form.Item
                        label={'服务号'}
                        {...colConfig}
                        name="type"
                        initialValue={
                            promotionCode ? promotionCode.title : null
                        }
                        rules={[{ required: true }]}
                    >
                        <Select
                            placeholder={'请选择服务号'}
                            options={[
                                { value: 'fuwuhao', label: '伯禹教育' },
                                { value: 'fuwuhao-boxiaoyu', label: '博小鱼' },
                            ]}
                        />
                    </Form.Item>
                )}
                <Form.Item
                    label={'标题'}
                    {...colConfig}
                    name="title"
                    initialValue={promotionCode ? promotionCode.title : null}
                    rules={[{ required: true }]}
                >
                    <Input placeholder={'请输入标题'} maxLength={30} />
                </Form.Item>
                <Form.Item
                    label={'设定'}
                    {...colConfig}
                    name="config"
                    initialValue={
                        promotionCode
                            ? JSON.stringify(promotionCode.config)
                            : null
                    }
                    // rules={[{ required: true }]}
                >
                    <Input.TextArea
                        placeholder={'请输入设定（json格式）'}
                        rows={5}
                        maxLength={2000}
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
};

PromotionCodeFormModal.propTypes = {
    promotionCode: PropTypes.shape(),
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

export default PromotionCodeFormModal;
