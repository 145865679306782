import React, { useCallback, useEffect, useState } from 'react';
import { remote } from '@boyuai/utils';
import { Spin, Button, message } from 'antd';
import './index.less';

export default function RecallReward(props) {
    const { uuid, from } = props.match.params;
    const [cnt, setCnt] = useState(5);
    const [success, setSuccess] = useState(0);
    useEffect(() => {
        // 尝试更新领取奖励
        remote
            .$post(
                `/course/activities/credit/teaching-skill-reward/${uuid}/${from}`
            )
            .then(res => {
                if (res && res.success) {
                    message.success(`获得5000雨滴奖励`);
                    setSuccess(1);
                } else {
                    if (!remote.isLogin()) {
                        setSuccess(-2);
                    } else {
                        setSuccess(-1);
                    }
                }
            });
    }, [uuid, from]);
    useEffect(() => {
        if (success !== 0) {
            const intervalId = setInterval(() => {
                setCnt(item => item - 1);
            }, 1000);
            return () => {
                clearInterval(intervalId);
            };
        }
    }, [success]);
    const jumpToHomePage = useCallback(() => {
        if (success === 1) {
            window.location.href = `${process.env.REACT_APP_SUB_DIRECTORY}/?anchor=core-courses`;
        } else {
            window.location.href = `${process.env.REACT_APP_SUB_DIRECTORY}/`;
        }
    }, [success]);
    useEffect(() => {
        if (cnt === 0) {
            jumpToHomePage();
        }
    }, [cnt, jumpToHomePage]);
    return (
        <Spin spinning={success === 0}>
            <div className="page-recall-reward ui-layout-container">
                {success === 0 && (
                    <div className="recall-reward-counter">
                        <div>领取中</div>
                    </div>
                )}
                {success === 1 && (
                    <div className="recall-reward-counter">
                        {/*<Result status="success" />*/}
                        <div>恭喜你获得5000雨滴奖励</div>
                        <div>去兑换你感兴趣的课程吧</div>
                        <div>{cnt}秒后跳转到首页</div>
                    </div>
                )}
                {success === -1 && (
                    <div className="recall-reward-counter">
                        {/*<Result status="error" />*/}
                        <div>领取失败，{cnt}秒后跳转到首页</div>
                    </div>
                )}
                {success === -2 && (
                    <>
                        <div className="recall-reward-counter">
                            {/*<Result status="error" />*/}
                            <div>领取失败，请登录</div>
                        </div>
                    </>
                )}
                <div className="recall-reward-jump">
                    <Button onClick={jumpToHomePage}>进入首页</Button>
                </div>
            </div>
        </Spin>
    );
}
