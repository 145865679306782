import { useCallback, useEffect, useState } from 'react';
import { remote } from '@boyuai/utils';

export const useAddUserCourseLog = tag => {
    const [logs, setLogs] = useState([]);
    const fetchLogs = useCallback(async () => {
        const [list] = await remote.$get(
            '/course/admin/add-course-for-user-logs',
            {
                tag,
            }
        );
        setLogs(list);
    }, [tag]);
    useEffect(() => {
        fetchLogs();
    }, [fetchLogs]);
    return [logs];
};
