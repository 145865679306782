import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import { remote } from '@boyuai/utils';

function AdminTable(props) {
    const [datasourse, setDatasource] = useState([]);
    const [page, setPage] = useState(1);

    useEffect(() => {
        if (props.fetchUrl) {
            remote.$get(props.fetchUrl).then(data => {
                setDatasource(data);
            });
        } else {
            setDatasource(props.data);
            setPage(1);
        }
    }, [props.fetchUrl, props.data]);

    return (
        <Table
            dataSource={props.datasource || datasourse}
            columns={props.columns}
            expandable={props.expandable}
            rowKey={props.rowKey ? props.rowKey : 'id'}
            scroll={props.scroll}
            pagination={
                props.hidePagination
                    ? false
                    : {
                          current: page,
                          onChange: page => {
                              setPage(page);
                          },
                      }
            }
        />
    );
}

AdminTable.propTypes = {
    data: PropTypes.array,
    fetchUrl: PropTypes.string,
    columns: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    expandable: PropTypes.any,
    hidePagination: PropTypes.bool,
    scroll: PropTypes.shape({ x: PropTypes.number, y: PropTypes.number }),
};

export default AdminTable;
