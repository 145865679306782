import React, { useEffect } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

import List from './list/index';
import { UPDATE_PATH, ModuleProvider, useModuleDispatch } from './hooks';

const SuperAdminWrapper = () => {
    return (
        <ModuleProvider>
            <SuperAdmin />
        </ModuleProvider>
    );
};

function SuperAdmin() {
    const { path } = useRouteMatch();
    const dispatch = useModuleDispatch();
    useEffect(() => dispatch({ type: UPDATE_PATH, payload: path }), [
        dispatch,
        path,
    ]);

    return (
        <Switch>
            <Route path={path} exact>
                <List />
            </Route>
        </Switch>
    );
}

export default SuperAdminWrapper;
