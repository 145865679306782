/**
 * Login 已使用统一项目进行实现，这边的代码用来兼容旧的功能
 */
import React, { Component } from 'react';
import { Modal } from 'antd';
import PropTypes from 'prop-types';
import qs from 'qs';
import { login } from '@boyuai/utils/es/remote';
import UserInfo from '@boyuai/user-info';
import LoginForm from './form';
import './index.less';

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

const QRCode =
    'https://staticcdn.boyuai.com/materials/2020/12/31/UuV7OB8i4ikvI1MaaXR1f.jpg';
const TaQrCode =
    'https://staticcdn.boyuai.com/materials/2020/12/31/3YCgk4ON6MO0D93pTGrs-.png';
const logoHome =
    'https://staticcdn.boyuai.com/materials/2020/12/31/xwHcdX57XAVSIw54FYAQ5.png';
const logoCourse =
    'https://staticcdn.boyuai.com/materials/2020/12/31/Mp3WbPrTwHhL4MUGOQu3F.png';
const logoPaper =
    'https://staticcdn.boyuai.com/materials/2020/12/31/GeTqg0x6rn3W8pVIhmF8D.png';
const logoPlayground =
    'https://staticcdn.boyuai.com/materials/2020/12/31/OSn5ymIbRnT14bcsIn-f3.png';
const background =
    'https://staticcdn.boyuai.com/materials/2020/12/31/DC1cBeQq2m7ZI0E77Npk5.png';

const nameMap = new Map([
    [
        '人工智能学院',
        {
            logoSrc: logoHome,
            logoEng: '',
        },
    ],
    [
        '学习平台',
        {
            logoSrc: logoCourse,
            logoEng: 'Boyu Learning Platform',
        },
    ],
    [
        '学堂',
        {
            logoSrc: logoPaper,
            logoEng: 'Boyu Open Class',
        },
    ],
    [
        '实践平台',
        {
            logoSrc: logoPlayground,
            logoEng: 'Boyu Practice Platform',
        },
    ],
]);

export default class Login extends Component {
    static LoginForm = LoginForm;
    static propTypes = {
        name: PropTypes.string.isRequired,
        accessRole: PropTypes.string,
    };
    state = {
        infoVisible: false,
    };
    componentDidMount() {
        let redirectUrl;
        // 优先从query读取
        const query = qs.parse(window.location.search.replace(/^\?/, ''));
        if (query.redirect) {
            redirectUrl = query.redirect;
        }
        if (!redirectUrl) {
            // 从localStorage读取跳转地址（其他地方可以直接指定登录后的跳转）
            redirectUrl = localStorage.getItem('login-redirect');
        }
        if (redirectUrl) {
            this.redirectUrl = redirectUrl;
            localStorage.removeItem('login-redirect');
        } else if (!/\/login$/.test(window.location.pathname)) {
            this.redirectUrl = window.location.href;
        }
    }
    onLogin = async auth => {
        const { roles } = auth;
        if (this.props.accessRole) {
            if (!roles.find(role => role === this.props.accessRole)) {
                this.setState({ phone: '', code: '' });
                const titleStr =
                    this.props.name === '学习平台'
                        ? '尚未对外开放，如需观看课程请添加伯禹课程助手小鱼'
                        : '暂未对外开放，请关注伯禹公众号了解最新信息';
                Modal.info({
                    title: `${this.props.name}${titleStr}`,
                    content: (
                        <img
                            style={{ height: '400px', width: '400px' }}
                            src={
                                this.props.name === '学习平台'
                                    ? TaQrCode
                                    : QRCode
                            }
                            alt="qr-code"
                        />
                    ),
                    width: 520,
                });
                return;
            }
        }
        login(auth.token);
        if (auth.isNew) {
            this.setState({ infoVisible: true });
        } else {
            this.goHome();
        }
    };
    goHome = () => {
        window.location.href =
            this.redirectUrl || process.env.REACT_APP_SUB_DIRECTORY || '/';
    };
    render() {
        const { infoVisible } = this.state;
        const info = nameMap.get(this.props.name);
        const { logoSrc, logoEng } = info;
        return (
            <div
                className="page-login"
                style={{ backgroundImage: `url(${background})` }}
                data-is-mobile={isMobile}
            >
                <div className="main">
                    <div className="logo-wrapper">
                        <img src={logoSrc} alt="logo" />
                    </div>
                    <div className="main-wrapper">
                        <div className="logo-name">
                            <div className="name-zh">{`伯禹${this.props.name}`}</div>
                            <div className="name-eng">{logoEng}</div>
                        </div>
                        <LoginForm onLogin={this.onLogin} />
                    </div>
                </div>
                {infoVisible && (
                    <UserInfo
                        visible={true}
                        onCancel={this.goHome}
                        onSubmit={this.goHome}
                    />
                )}
            </div>
        );
    }
}
