import React from 'react';

export default function CiteComment({ comment, commentLink }) {
    if (commentLink) {
        return (
            <a target="_blank" rel="noopener noreferrer" href={commentLink}>
                <div className="parent">
                    “
                    <div className="parent-content">
                        {comment && comment.text
                            ? comment.text
                            : '**评论已删除**'}
                    </div>
                    ”
                </div>
            </a>
        );
    }
    return (
        <div className="parent">
            “
            <div className="parent-content">
                {comment && comment.text ? comment.text : '**评论已删除**'}
            </div>
            ”
        </div>
    );
}
