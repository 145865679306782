import React, { forwardRef } from 'react';
import { Form, Input, InputNumber, Select } from 'antd';
import Upload from './components/upload';
import UploadHtml from './components/upload-html';
import Video from './components/video';

const AdminForm = forwardRef((props, ref) => {
    const renderFormField = item => {
        let fieldProps = {};
        let field = <Input placeholder={item.placeholder} />;
        if (
            props.formValues &&
            (props.formValues[item.name] || props.formValues[item.name] === 0)
        ) {
            fieldProps.initialValue = props.formValues[item.name];
        }
        let extra = item.extra;
        let rule = {};
        if (item.type === 'number') {
            field = <InputNumber />;
        } else if (item.type === 'textarea') {
            field = <Input.TextArea rows={3} placeholder={item.placeholder} />;
        } else if (item.type === 'uploadHtml') {
            fieldProps = UploadHtml.fieldProps;
            field = <UploadHtml endpoint={item.endpoint} />;
        } else if (item.type === 'upload') {
            fieldProps = Upload.fieldProps;
            // fieldProps.initialValue = [
            //     {
            //         uid: '-1',
            //         name: 'image.png',
            //         status: 'done',
            //         url: props.formValues[item.name],
            //         response: { url: props.formValues[item.name] },
            //     },
            // ];
            field = <Upload endpoint={item.endpoint} />;
        } else if (item.type === 'video') {
            rule = Video.rule;
            field = <Video />;
        } else if (item.type === 'select') {
            field = (
                <Select
                    mode={item.mode}
                    placeholder={item.placeholder}
                    allowClear={!item.required}
                >
                    {item.datasource.map(option => (
                        <Select.Option
                            key={option.value}
                            value={option.value}
                            disabled={option.isDisable}
                        >
                            {option.text}
                        </Select.Option>
                    ))}
                </Select>
            );
        } else if (item.type === 'tags') {
            field = <Select mode="tags" style={{ width: '100%' }} />;
            // 设置原值
            fieldProps.initialValue =
                props.formValues && props.formValues[item.name]
                    ? props.formValues[item.name].split(',')
                    : [];
            // 规范输入（英文逗号识别为分割，去除所有空格，去重，无论单选多选都返回数组）
            fieldProps.normalize = value => {
                return value
                    .toString()
                    .replace(/\s/g, '')
                    .split(',')
                    .filter(function(item, index, arr) {
                        //当前元素，在原始数组中的第一个索引==当前索引值，否则返回当前元素
                        return arr.indexOf(item, 0) === index && item;
                    });
            };
        } else if (item.type === 'tags-new') {
            // 默认为null, 存为数组
            field = <Select mode="tags" style={{ width: '100%' }} />;
            // 设置原值
            fieldProps.initialValue = props.formValues
                ? props.formValues[item.name]
                : [];
            // 规范输入（英文逗号识别为分割，去除所有空格，去重，无论单选多选都返回数组）
            fieldProps.normalize = value => {
                return value
                    .toString()
                    .replace(/\s/g, '')
                    .split(',')
                    .filter(function(item, index, arr) {
                        //当前元素，在原始数组中的第一个索引==当前索引值，否则返回当前元素
                        return arr.indexOf(item, 0) === index && item;
                    });
            };
        }
        return (
            <Form.Item
                key={item.name}
                label={item.label}
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                extra={extra}
                name={item.name}
                rules={[
                    {
                        ...rule,
                        required: item.required,
                    },
                ]}
                {...fieldProps}
            >
                {field}
            </Form.Item>
        );
    };
    return <Form ref={ref}>{props.formItems.map(renderFormField)}</Form>;
});

export default AdminForm;
