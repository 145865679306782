import React /*, { useContext } */ from 'react';
import { Route } from 'react-router-dom';
import Help from '@boyuai/help';
import '@boyuai/help/dist/index.css';

import ServiceSVG from '../../../assets/images/service.svg';
import WechatSVG from '../../../assets/images/wechat.svg';

export default function CourseHelper({ right, bottom }) {
    // const {
    //     user: { id: userId },
    //     weChatNickname,
    // } = useContext(UserContextModule.UserContext);
    const helpMenu = (
        <Help
            right={right || 40}
            bottom={bottom || 20}
            isSVG={true}
            menus={[
                {
                    title: '帮助与咨询',
                    imageUrl:
                        'https://staticcdn.boyuai.com/user-assets/6074/zePihAGUKe41gf9zifvYHz/老师二维码.png',
                    icon: ServiceSVG,
                    width: 18,
                    height: 16,
                    description: '请添加伯禹小鱼助手微信号',
                    className: 'xiaoyu-contact-button',
                },
                {
                    imageUrl:
                        'https://staticcdn.boyuai.com/course/material/qrcode_for_gh_fa3b230c54b6_258.jpg',
                    icon: WechatSVG,
                    width: 21,
                    height: 17,
                    title: '公众号',
                    description: '微信扫码关注伯禹公众号',
                },
            ]}
        />
    );
    return <Route path="/" exact render={() => <>{helpMenu}</>} />;
}
