import React from 'react';
import styles from './not-for-sell.module.less';

export function NotForSell() {
    return (
        <div className={styles['not-for-sell']}>
            <div>本课程不对外开放，如需学习请添加伯禹小鱼助手微信咨询</div>
            <div>
                <img
                    src="https://staticcdn.boyuai.com/user-assets/6074/zePihAGUKe41gf9zifvYHz/老师二维码.png"
                    alt="小鱼助手"
                />
            </div>
        </div>
    );
}
