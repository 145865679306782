import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { remote } from '@boyuai/utils';
import moment from 'moment';
import Modal1 from './modal1';
import Modal2 from './modal2';
import Modal3 from './modal3';
import CommonModal from '../activity-components/common-modal/';
import { useRouteMatch } from 'react-router-dom';
import './index.less';

let fetched = false;

// 本次活动content字段说明
// popped 是否完成首次弹窗
// course 选择的课程
// lessons 等待打卡的课程
// users 邀请的用户 phone 手机号 createdAt 时间 active 是否活跃用户
// rewards 获得的奖励 createdAt 时间 content 内容
// modal2Guided 弹窗二小手是否使用

// 本次活动state说明
// 1 完成了第一个邀请条件
// 2 完成了第二个邀请条件

export default function Boyu4th({ activity: _activity }) {
    const courseMatch = useRouteMatch('/course/:uuid');
    const homeMatch = useRouteMatch('/');
    // const courseUUID = match && match.params ? match.params.uuid : null;
    const isCourse = courseMatch && courseMatch.isExact;
    const isHome = homeMatch && homeMatch.isExact;

    const [modalVisible, setModalVisible] = useState(false);
    const [pending, setPending] = useState(false); // 刷新进度的接口耗时严重，需要一个loading
    const [createdActivity, setCreatedActivity] = useState(null);
    const activity = createdActivity || _activity;
    // console.log(activity);

    useEffect(() => {
        if (!activity.userId && remote.isLogin()) {
            // 生成一份自己的
            remote
                .$post(
                    `/course/activities`,
                    { fromActivityId: activity.id },
                    { hideLoading: true }
                )
                .then(setCreatedActivity);
        }
    }, [activity]);
    useEffect(() => {
        if (!activity.userId && (isHome || isCourse)) {
            // 未登录也可以弹窗
            const localNextPopDate = localStorage.getItem(
                'boyu-4th-nextPopDate'
            );
            if (!localNextPopDate) {
                // 还没有下一个弹窗时间，即从未弹窗，则设置下一个弹窗时间在7天后，并弹窗
                localStorage.setItem(
                    'boyu-4th-nextPopDate',
                    moment()
                        .add(3, 'd')
                        .format('YYYY-MM-DD')
                );
                setModalVisible(true);
            } else if (moment().isAfter(moment(localNextPopDate))) {
                // 到达下一个弹窗时间，则设置下一个弹窗时间在7天后，并弹窗
                localStorage.setItem(
                    'boyu-4th-nextPopDate',
                    moment()
                        .add(7, 'd')
                        .format('YYYY-MM-DD')
                );
                setModalVisible(true);
            }
            const currentPopTime = '1'; // 更改为从未用过的字符串则可实现重新弹窗
            const popped =
                localStorage.getItem('boyu-4th-popped') === currentPopTime; // 是否弹窗了
            if (!popped) {
                localStorage.setItem('boyu-4th-popped', currentPopTime);
                setModalVisible(true);
            }
            return;
        }
        if (activity.userId && (isHome || isCourse)) {
            if (!activity.content.popped) {
                // 没有弹过窗
                remote.$patch(
                    `/course/activities/${activity.id}/content`,
                    {
                        content: {
                            popped: true, // 标记弹窗
                            nextPopDate: moment()
                                .add(3, 'd')
                                .format('YYYY-MM-DD'), // 设置下次弹窗时间
                        },
                    },
                    { hideLoading: true }
                );
                // 首次强制弹窗
                setModalVisible(true);
            } else if (
                !activity.content.modal1CourseButtonClickedAt &&
                moment().isAfter(moment(activity.content.nextPopDate))
            ) {
                // 没点过领取课程按钮 且 到了弹窗时间
                remote.$patch(
                    `/course/activities/${activity.id}/content`,
                    {
                        content: {
                            nextPopDate: moment()
                                .add(7, 'd')
                                .format('YYYY-MM-DD'), // 设置下次弹窗时间
                        },
                    },
                    { hideLoading: true }
                );
                // 强制弹窗
                setModalVisible(true);
            }
        }
    }, [activity, isHome, isCourse]);

    // 更新详细信息：刷新邀请进度（比较耗时，所以不必要的时候就不请求了）
    useEffect(() => {
        if (fetched) {
            return;
        }
        if (
            !activity.userId ||
            !activity.content.course ||
            !activity.content.modal1InviteButtonClicked
        ) {
            return;
        }
        if (activity.state === 1 && !activity.content.isQianren) {
            return; // 普通用户只能进行到1，满足第一个邀请条件
        }
        if (activity.state === 2) {
            return; // 满足第二个邀请条件
        }
        fetched = true; // 确保只获取一次
        setPending(true);
        remote
            .$get(
                `/course/activities/${activity.id}`,
                {},
                { hideLoading: true }
            )
            .then(value => {
                setCreatedActivity(value);
                setPending(false);
            });
    }, [activity]);

    const modal3Visible = useMemo(() => {
        if (!activity) {
            return false;
        }
        if (!activity.content.modal2StartNoticePopped) {
            return false;
        }
        if (activity.state < 1) {
            return false;
        }
        // 今天签到过了
        if (
            activity.content.signLogs &&
            activity.content.signLogs.find(log =>
                moment(log.createdAt).isAfter(moment().startOf('day'))
            )
        ) {
            return false;
        }
        // 签没了
        if (
            activity.content.signLogs &&
            activity.content.signLogs.length >= activity.content.lessons.length
        ) {
            return false;
        }
        // 活动已结束
        if (
            activity.content.signExpiresAt &&
            moment(activity.content.signExpiresAt).isBefore(moment())
        ) {
            return false;
        }
        // 活动已结束
        if (activity.endAt && moment(activity.endAt).isBefore(moment())) {
            return false;
        }
        return true;
    }, [activity]);

    // 开启打卡挑战的弹窗关闭
    const onStartNoticePopped = useCallback(async () => {
        if (!activity.content.modal2StartNoticePopped) {
            const act = await remote.$patch(
                `/course/activities/${activity.id}/content`,
                { content: { modal2StartNoticePopped: true } },
                { hideLoading: true }
            );
            setCreatedActivity(act);
        }
    }, [activity]);

    return (
        <div className="activity-boyu-4th">
            <div className="float-button" onClick={() => setModalVisible(true)}>
                <img
                    src="https://staticcdn.boyuai.com/materials/2020/08/01/Fh2NWbljX5IT4W1vG2qb4.png!png"
                    alt=""
                    width={107}
                    height={100}
                />
            </div>
            {!activity.content.course && (
                <Modal1
                    pending={pending}
                    visible={modalVisible}
                    onClose={() => setModalVisible(false)}
                    activity={activity}
                    onActivityChange={setCreatedActivity}
                />
            )}
            {activity.content.course && (
                <Modal2
                    pending={pending}
                    visible={modalVisible}
                    onClose={() => setModalVisible(false)}
                    activity={activity}
                />
            )}
            {modal3Visible && (
                <Modal3
                    activity={activity}
                    onActivityChange={setCreatedActivity}
                />
            )}
            {!activity.content.modal2StartNoticePopped &&
            activity.content.modal1InviteButtonClicked &&
            activity.state >= 1 ? (
                <CommonModal
                    visible={true}
                    onCancel={onStartNoticePopped}
                    title={<span>&nbsp;挑战开始！</span>}
                    hint={'您已开启打卡挑战，每日登录可以依次解锁知识点'}
                    onOk={onStartNoticePopped}
                    okText={'马上打卡'}
                    buttonVisible={true}
                    imgSrc={
                        'https://staticcdn.boyuai.com/materials/2020/06/17/H_tJKRXaOF5lCS0BBp_cQ.png!png'
                    }
                />
            ) : null}
        </div>
    );
}
