import React, { Component } from 'react';
import { Modal, Tooltip } from 'antd';
import { withRouter, Link } from 'react-router-dom';
import moment from 'moment';
import { UserContextModule } from '@boyuai/utils';
import { remote } from '@boyuai/utils';
import BuyCourse from './buy-course';

import SidebarIcon from '../../assets/images/sidebar.png';
import SidebarPracticeIcon from '../../assets/images/sidebar-practice.png';
import FeedbackModal from './feedback';
import {
    RightOutlined,
    DownOutlined,
    CheckCircleFilled,
    ClockCircleFilled,
    LockOutlined,
} from '@ant-design/icons';

export default withRouter(
    class SideBarCard extends Component {
        cardRef = React.createRef();
        pathname = null;
        static contextType = UserContextModule.UserContext;
        state = {
            fold: false,
            buyVisible: false,
            feedbackVisible: false,
            feedbackResult: null, // 本次评价结果（用于快速更新“评价”按钮的显示与否）
            buyType: null, // 正在购买的类型（video/code/...）
            buyUUID: null, // 正在购买的uuid
        };
        get nextLessonUUIDArray() {
            return this.getUUIDFromLesson(this.props.nextCourseLessons);
        }
        get currentLessonUUIDArray() {
            return this.getUUIDFromLesson(this.props.lesson);
        }
        get isAtCurrentCard() {
            return this.uuid === this.props.lesson.uuid;
        }
        get isAtNextCardTitle() {
            return this.uuid === this.props.nextCourseLessons.uuid;
        }
        get uuid() {
            const { pathname } = this.props.history.location;
            return pathname.match(/\/([^/]*)$/)[1];
        }
        componentDidMount() {
            const { pathname } = this.props.history.location;
            this.scrollToCurrent(pathname);
        }
        componentDidUpdate(prevProps) {
            if (this.props.isFold !== prevProps.isFold) {
                this.setState({ fold: this.props.isFold });
            }
            // 废弃功能：
            // 这里无法捕捉到pathname前后的变化，估计是因为整个location是一个引用，所以导致update前后指向的都是同一个
            // 所以通过this.pathname做一个记录
            // if (
            //     this.pathname &&
            //     this.pathname !== this.props.history.location.pathname
            // ) {
            //     this.scrollToCurrent(this.props.history.location.pathname);
            // }
            // this.pathname = this.props.history.location.pathname;
        }
        getUUIDFromPathname(pathname) {
            return pathname.match(/\/([^/]*)$/)[1];
        }
        getUUIDFromLesson(lesson) {
            const {
                videos = [],
                codes = [],
                quizzes = [],
                jupyters = [],
            } = lesson;
            const idArr = [];
            [videos, codes, quizzes, jupyters].forEach(item => {
                if (item.length > 0) {
                    item.forEach(info => {
                        idArr.push(info.uuid);
                    });
                }
            });
            return idArr;
        }
        scrollToCurrent(pathname) {
            // 新页面的 uuid
            const newPageUUID = this.getUUIDFromPathname(pathname);
            // 新页面的 uuid 在下一个知识点中的 index
            const idxNext = this.nextLessonUUIDArray.indexOf(newPageUUID);
            // 新页面的 uuid 在当前知识点中的 index，用于处理用户点击第一个知识点的情况
            const idxCurrent = this.currentLessonUUIDArray.indexOf(newPageUUID);

            const sidebarTitleHeight = 60;
            const cardTitleHeight = 47;
            const listItemHeight = 50;

            // 定位到后一个知识点的N-2个单元
            const gotoNextPreviousTwoUnit = () => {
                if (this.cardRef.current) {
                    const { offsetTop, clientHeight } = this.cardRef.current;
                    // 定位到下一个知识点的第N-2个单元
                    const nextOffsetTop =
                        clientHeight +
                        offsetTop +
                        cardTitleHeight +
                        (idxNext - 3) * listItemHeight;
                    this.props.setScrollHeight(nextOffsetTop);
                }
            };

            // 定位到当前知识点的N-2个单元
            const gotoPreviousTwoUnit = () => {
                if (this.cardRef.current) {
                    const { offsetTop } = this.cardRef.current;
                    // 定位到下一个知识点的第N-2个单元
                    const nextOffsetTop =
                        offsetTop +
                        cardTitleHeight +
                        (idxCurrent - 3) * listItemHeight;
                    this.props.setScrollHeight(nextOffsetTop);
                }
            };

            // 定位到当前知识点的Title
            const goToCardTitle = () => {
                if (this.cardRef.current) {
                    const { offsetTop } = this.cardRef.current;
                    this.props.setScrollHeight(offsetTop - sidebarTitleHeight);
                }
            };

            // 定位到后一个知识点的Title
            const goToNextCardTitle = () => {
                if (this.cardRef.current) {
                    const { offsetTop, clientHeight } = this.cardRef.current;
                    this.props.setScrollHeight(
                        offsetTop + clientHeight - sidebarTitleHeight
                    );
                }
            };

            if (idxNext >= 0) {
                // 在下一个知识点
                if (idxNext >= 4) {
                    // 单元在下一个知识点中的排序N>=5
                    gotoNextPreviousTwoUnit();
                } else {
                    //单元在下一个知识点中的排序N<5时
                    // 若当前知识点处于展开状态，且单元数量>3，则定位到下一个知识点（而非上一知识点）
                    if (
                        this.state.fold ||
                        this.currentLessonUUIDArray.length <= 3
                    ) {
                        goToCardTitle();
                    } else {
                        goToNextCardTitle();
                    }
                }
            } else if (this.props.idx === 0 && idxCurrent >= 0) {
                // 在第一个知识点
                if (idxCurrent >= 4) {
                    gotoPreviousTwoUnit();
                } else {
                    goToCardTitle();
                }
            } else if (this.isAtNextCardTitle) {
                // 如果是知识点的title，跳转到知识点
                if (
                    this.state.fold ||
                    this.currentLessonUUIDArray.length <= 3
                ) {
                    goToCardTitle();
                } else {
                    goToNextCardTitle();
                }
            }
        }
        toggleFold = () => this.setState(state => ({ fold: !state.fold }));
        onBuyCourseCancel = (success = false) => {
            if (success) {
                const { buyType, buyUUID } = this.state;
                this.goToDetail(buyType, buyUUID);
            } else {
                this.setState({ buyVisible: false });
            }
        };
        onFeedbackOpen = e => {
            e.stopPropagation();
            this.setState({ feedbackVisible: true });
        };
        onFeedbackCancel = result => {
            this.setState({ feedbackVisible: false });
            if (result) {
                this.setState({ feedbackResult: result });
            }
        };
        onClick = (type, value) => {
            const { lesson, uuid } = this.props;
            const isGuest = !remote.isLogin();
            if (lesson.access || !lesson.price) {
                // 有权限(免费或已购买)
                if (isGuest && !['video'].includes(type)) {
                    // 除了视频外，其他的需要登录后才能查看
                    Modal.confirm({
                        content: '代码实践和课后练习需要登录后才可查看',
                        onOk: () => {
                            localStorage.setItem(
                                'login-redirect',
                                `${process.env.REACT_APP_SUB_DIRECTORY}/course/${uuid}/${type}/${value.uuid}`
                            );
                            remote.requestLogin();
                        },
                        okText: '立即登录',
                    });
                } else {
                    this.goToDetail(type, value.uuid);
                }
            } else {
                const { uuid: courseUUID } = this.props;
                this.props.history.push(
                    `/course/${courseUUID}/lesson/${lesson.uuid}`
                );
            }
        };
        goToDetail = (type, uuid) => {
            const { uuid: courseUUID } = this.props;
            this.props.history.push(`/course/${courseUUID}/${type}/${uuid}`);
        };
        renderLessonTitle(title, isShow) {
            if (isShow && title.length > 8) {
                return (
                    <Tooltip title={title}>{`${title.slice(0, 8)}...`}</Tooltip>
                );
            }

            if (title.length > 10) {
                return (
                    <Tooltip title={title}>{`${title.slice(
                        0,
                        10
                    )}...`}</Tooltip>
                );
            }
            return <div>{title}</div>;
        }
        render() {
            const { lesson, packageOnly, uuid: courseUUID } = this.props;
            const {
                videos = [],
                codes = [],
                quizzes = [],
                jupyters = [],
            } = lesson;
            const {
                fold,
                buyVisible,
                feedbackVisible,
                feedbackResult,
            } = this.state;

            const hasVideos =
                videos.length > 0 || courseUUID === 'bSk7yT7rCa7prYhi'; // 有视频的判断引入数据结构课程的例外
            let lessonLearned = true;
            [videos, codes, quizzes, jupyters].forEach(group => {
                group.forEach(item => {
                    if (!item.learningLog) {
                        lessonLearned = false;
                    }
                });
            });
            const items = [
                ...videos.map(v => ({ type: 'video', value: v })),
                ...codes.map(v => ({ type: 'code', value: v })),
                ...quizzes.map(v => ({ type: 'quiz', value: v })),
                ...jupyters.map(v => ({ type: 'jupyter', value: v })),
            ].sort((a, b) => a.value.order - b.value.order);
            const isShowComment =
                lesson.access &&
                hasVideos &&
                lessonLearned &&
                !feedbackResult &&
                !lesson.feedback;
            const formattedTitle = lesson.title.replace('《动手学》：', '');
            return (
                <li key={lesson.id} ref={this.cardRef}>
                    <div
                        className="title"
                        data-not-ready={!hasVideos}
                        data-at-current={this.isAtCurrentCard}
                    >
                        <div className="title-wrapper">
                            <img
                                src={
                                    /^代码实践/.test(lesson.title)
                                        ? SidebarPracticeIcon
                                        : SidebarIcon
                                }
                                alt="sidebar"
                            />
                            <Tooltip title={formattedTitle}>
                                <div
                                    className="title-text"
                                    data-has-videos={hasVideos}
                                >
                                    <Link
                                        to={`/course/${courseUUID}/lesson/${lesson.uuid}`}
                                    >
                                        {formattedTitle}
                                    </Link>
                                </div>
                            </Tooltip>
                        </div>
                        <div className="info-wrapper">
                            {isShowComment && (
                                <span
                                    className="feedback-button"
                                    onClick={this.onFeedbackOpen}
                                >
                                    评价
                                </span>
                            )}
                            {hasVideos ? (
                                fold ? (
                                    <RightOutlined
                                        className="fold-button"
                                        onClick={this.toggleFold}
                                    />
                                ) : (
                                    <DownOutlined
                                        className="fold-button"
                                        onClick={this.toggleFold}
                                    />
                                )
                            ) : (
                                <span className="coming-soon">即将上线</span>
                            )}
                        </div>
                    </div>
                    {hasVideos && !fold && (
                        <ul>
                            {items.map(({ type, value }) => (
                                <li
                                    key={value.uuid}
                                    data-is-forbidden={
                                        !!(!lesson.access && lesson.price)
                                    }
                                    data-is-active={this.uuid === value.uuid}
                                >
                                    <div
                                        onClick={() =>
                                            this.onClick(type, value)
                                        }
                                        className="course-item"
                                    >
                                        <div className="course-item-title">
                                            {type === 'video' &&
                                                (value.miniTitle || '课程学习')}
                                            {type === 'code' &&
                                                (value.miniTitle || '代码实践')}
                                            {type === 'quiz' &&
                                                (value.miniTitle || '课后练习')}
                                            {type === 'jupyter' &&
                                                (value.miniTitle || 'Jupyter')}
                                        </div>
                                        {this.renderStatusBadge(
                                            lesson,
                                            value,
                                            type
                                        )}
                                    </div>
                                </li>
                            ))}
                        </ul>
                    )}
                    <BuyCourse
                        visible={buyVisible}
                        onClose={this.onBuyCourseCancel}
                        uuid={packageOnly ? courseUUID : lesson.uuid}
                        open
                        isLesson={!packageOnly}
                    />
                    <FeedbackModal
                        visible={feedbackVisible}
                        onClose={this.onFeedbackCancel}
                        uuid={lesson.uuid}
                    />
                </li>
            );
        }
        renderStatusBadge(lesson, value, type) {
            if (type === 'video' && !value.vid) {
                return null;
            }
            // 已购买，显示已完成、未完成
            if (lesson.access) {
                if (value.learningLog) {
                    // quiz有可能更新了
                    if (type === 'quiz') {
                        if (
                            moment(value.updatedAt).isAfter(
                                moment(value.learningLog.updatedAt)
                            )
                        ) {
                            return (
                                <div className="learn-status success">
                                    <img
                                        src="https://staticcdn.boyuai.com/materials/2020/03/05/M5iFkGqXSHjiCu3CZKyPy.png!png"
                                        alt="有更新"
                                    />
                                    有更新
                                </div>
                            );
                        }
                    }
                    return (
                        <div className="learn-status">
                            <CheckCircleFilled
                                style={{
                                    color: '#31AFF9',
                                }}
                            />
                            已完成
                        </div>
                    );
                }
                return (
                    <div className="learn-status pending">
                        <ClockCircleFilled
                            style={{
                                color: '#FFBD6D',
                            }}
                        />
                        未完成
                    </div>
                );
            }
            // 免费知识点
            if (!lesson.price) {
                if (value.learningLog) {
                    return (
                        <div className="learn-status">
                            <CheckCircleFilled
                                style={{
                                    color: '#31AFF9',
                                }}
                            />
                            已完成
                        </div>
                    );
                }
                if (type === 'quiz') {
                    return (
                        <div className="learn-status pending">
                            <ClockCircleFilled
                                style={{
                                    color: '#FFBD6D',
                                }}
                            />
                            未完成
                        </div>
                    );
                }
                return <div className="learn-status">免费学习</div>;
            }
            return (
                <div className="learn-status lock">
                    <LockOutlined />
                </div>
            );
        }
    }
);
