import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import { useBBYStat } from '../../hooks/bby-stat-hooks';
import { remote } from '@boyuai/utils';

export default function AdminBBYStat() {
    const [lessons, setLessons] = useState([]);
    const [fetch, modal] = useBBYStat(
        process.env.REACT_APP_ENV === 'production'
            ? [
                  6844,
                  6824,
                  6822,
                  6866,
                  6655,
                  6756,
                  6795,
                  6794,
                  6818,
                  6842,
                  6816,
                  6771,
                  6860,
                  6797,
                  6874,
                  6868,
                  6823,
                  6837,
                  6843,
                  6827,
                  6855,
                  6829,
                  5912,
                  6870,
                  6863,
                  6798,
                  6587,
                  6859,
                  6825,
                  5616,
                  6807,
                  6808,
                  6862,
                  6875,
                  6864,
                  5390,
                  6836,
                  6831,
                  6850,
                  6793,
                  6854,
                  6878,
                  6861,
                  6847,
                  6701,
                  6865,
                  6759,
                  6873,
                  6811,
                  6820,
                  6839,
                  6817,
                  6877,
                  6528,
                  6872,
                  6803,
                  6813,
                  6841,
                  6814,
                  6845,
                  129,
                  6788,
                  6802,
                  6775,
                  6805,
                  6789,
                  6792,
                  6774,
                  6809,
                  6806,
                  6869,
                  6853,
                  6838,
                  6851,
                  6815,
                  6856,
                  6833,
                  6787,
                  6819,
                  6812,
                  6867,
                  6830,
                  6848,
                  6846,
                  6801,
                  6796,
                  6821,
                  6826,
                  6857,
                  6879,
                  6790,
                  6828,
                  6840,
                  5791,
                  6800,
                  6832,
                  6834,
                  6858,
                  6791,
                  6876,
                  6810,
                  6871,
                  6849,
                  6835,
                  6852,
                  6762,
                  6799,
                  6804,
              ]
            : [371, 4],
        lessons
    );

    useEffect(() => {
        remote.$get('/learn/admin/courses').then(res => {
            const lessonsOfCourse21 = res.find(({ id }) => id === 22);
            if (lessonsOfCourse21 && lessonsOfCourse21.lessons) {
                setLessons(lessonsOfCourse21.lessons);
            }
        });
    }, []);
    return (
        <div>
            <div>
                course22的单元列表
                {lessons.map(l => (
                    <div key={l.id}>
                        {l.id}.&nbsp;{l.title}
                    </div>
                ))}
            </div>
            <Button onClick={fetch}>获取</Button>
            {modal}
        </div>
    );
}
