import React from 'react';
import './title.less';

const Title = ({ title, intro }) => {
    return (
        <div className="home-title-wrapper">
            <div className="home-title">{title}</div>
            <div className="home-intro">{intro}</div>
        </div>
    );
};

export { Title };
