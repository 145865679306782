import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Popover } from 'antd';
import moment from 'moment';
import {
    ExclamationCircleFilled,
    QuestionCircleOutlined,
} from '@ant-design/icons';
import CurrencyIntro from '@boyuai/currency-intro';
import Recharge from '@boyuai/recharge';
import DisplayCard from '@boyuai/display-card';
import '@boyuai/display-card/dist/index.css';
import Log from './log';
import './account-balance.less';

const cloud =
    'https://staticcdn.boyuai.com/materials/2020/11/19/9vTcDhcXyzaJ1KKdGo8_4.png';
const cloudImg =
    'https://staticcdn.boyuai.com/materials/2019/10/21/S-6fCTE6mqCPeuQfkXjd8.jpg';
const rainImg =
    'https://staticcdn.boyuai.com/materials/2019/10/21/MQ79R7_d89Km1TAOQB5OA.jpg';

export default class AccountBalance extends Component {
    static propTypes = {
        balance: PropTypes.number,
        credit: PropTypes.number,
    };

    state = {
        recharge: false,
        currencyIntroVisible: false,
    };

    render() {
        return (
            <div className="account-balance">
                {this.renderBalanceDetail()}
                {this.renderLogDetail()}
                <Recharge
                    visible={this.state.recharge}
                    onCancel={this.onRechargeCancel}
                />
            </div>
        );
    }

    renderBalanceDetail() {
        const { balance, credit } = this.props;
        return (
            <div className="balance-group">
                <div className="cloud-wrapper">
                    <div className="balance-label">
                        <img
                            src={cloud}
                            alt="云朵"
                            width="26px"
                            height="23px"
                        />
                        当前剩余云朵
                        {this.renderQuestionMark('云朵')}
                    </div>
                    <div className="balance">
                        {this.displayNumber(balance)}
                        <img src={cloudImg} alt="cloud" />
                    </div>
                    <div
                        className="recharge"
                        onClick={() => this.setState({ recharge: true })}
                    >
                        立即充值
                    </div>
                </div>
                <div className="balance-wrapper">
                    <div className="balance-label">
                        <img
                            src={cloud}
                            alt="雨滴"
                            width="26px"
                            height="23px"
                        />
                        当前剩余雨滴
                        {this.renderQuestionMark('雨滴')}
                        <CurrencyIntro
                            visible={this.state.currencyIntroVisible}
                            onClose={() =>
                                this.setState({ currencyIntroVisible: false })
                            }
                            default={this.state.defaultPane}
                        />
                    </div>
                    <div className="balance">
                        {this.displayNumber(credit)}
                        <img src={rainImg} alt="rain" />
                    </div>
                    <div
                        className="recharge"
                        onClick={() => {
                            this.setState({
                                currencyIntroVisible: true,
                                defaultPane: '0',
                            });
                        }}
                    >
                        如何获取
                    </div>
                </div>
            </div>
        );
    }

    renderLogDetail() {
        const { balance, credit, creditExpire } = this.props;
        const hint = creditExpire?.length ? (
            <div className="credit-expire-hint">
                <div className="warning">
                    <ExclamationCircleFilled style={{ color: '#F79345' }} />
                </div>
                <div className="content">
                    你有
                    {creditExpire
                        .map(
                            item =>
                                `${Math.floor(
                                    item.value / 100
                                )}雨滴将在${moment(item.expiresAt).format(
                                    'MM月DD日'
                                )}过期`
                        )
                        .join('、')}
                    ，请尽快兑换使用～
                </div>
            </div>
        ) : null;
        return (
            <DisplayCard title="账户明细" hint={hint}>
                <Log balance={balance} credit={credit} />
            </DisplayCard>
        );
    }

    renderQuestionMark(name) {
        const defaultPane = name === '雨滴' ? '0' : '1';
        return (
            <div className="question-mark-wrapper">
                <Popover
                    placement="bottomRight"
                    content={
                        <div
                            className="header-question-mark-popover"
                            onClick={() =>
                                this.setState({ currencyIntroVisible: true })
                            }
                        >
                            什么是{name}？
                        </div>
                    }
                    title={null}
                    trigger="hover"
                >
                    <div className="question-mark">
                        <QuestionCircleOutlined
                            onClick={() =>
                                this.setState({
                                    currencyIntroVisible: true,
                                    defaultPane,
                                })
                            }
                        />
                    </div>
                </Popover>
            </div>
        );
    }

    onRechargeCancel = (success = false) => {
        // 内部完成了余额刷新
        this.setState({ recharge: false });
    };

    displayNumber = value => {
        return typeof value === 'number' ? value / 100 : '--';
    };
}
