import React from 'react';

import LiangZiWeiBannerLink from './linagziwei-banner-link';

const LiangZiWeiBanner = ({ control }) => {
    return (
        <div className="carousel-item">
            <img
                className="bg"
                src="https://staticcdn.boyuai.com/materials/2020/06/30/anPCZDW3s9qNiD1cd089r.png!png"
                alt="background"
            />

            <div className="main">
                <div className="main-content">
                    <img
                        src="https://staticcdn.boyuai.com/materials/2020/06/30/ZP0eRfcOy53M8vdv2mfdL.png!png"
                        alt="伯禹教育 × 量子位匠心打造，智能时代，Python与AI系列课程"
                        className="liangzi-title"
                    />
                    <div className="liangzi-slogan">去创造，而不是被取代</div>
                    <div className="liangzi-courses">
                        <img
                            src="https://staticcdn.boyuai.com/materials/2020/06/30/nRcwVGqKgkcISo_MhiBIp.png!png"
                            alt="首发课程"
                            className="liangzi-subtitle"
                        />
                        <LiangZiWeiBannerLink
                            href="https://appqzmyxwod2249.h5.xiaoeknow.com/v1/course/text/i_5f34ad26e4b075dc42ad7cbe?type=2"
                            text="了解入门课程"
                            desc={
                                <>
                                    基础入门：
                                    <br />
                                    《动手学Python》
                                </>
                            }
                        />
                        <LiangZiWeiBannerLink
                            href="https://www.boyuai.com/learn"
                            text="了解进阶课程"
                            comingSoon
                            desc={
                                <>
                                    应用进阶：
                                    <br />
                                    《玩转数据科学与人工智能实用库》
                                </>
                            }
                        />
                        <img
                            src="https://staticcdn.boyuai.com/materials/2020/06/30/BB6kIjhY3vWIY5maRjwTF.png!png"
                            alt="创新实践平台"
                            className="liangzi-subtitle"
                        />
                        <LiangZiWeiBannerLink
                            href="https://www.boyuai.com/learn"
                            text="前往实践平台"
                            desc={
                                <>
                                    交互实践、AI老师、趣味闯关
                                    <br />
                                    Python与AI还可以这么学？
                                </>
                            }
                        />
                    </div>
                    {control}
                </div>
            </div>
        </div>
    );
};

export default LiangZiWeiBanner;
