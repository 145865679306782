import React, { useEffect, useState } from 'react';
import UserQuiz from '../../../components/quiz'; //直接用用户的Quiz
import { message } from 'antd';
import { remote } from '@boyuai/utils';

const QuizPreview = props => {
    const [title, setTitle] = useState('');
    const [questions, setQuestions] = useState([]);
    useEffect(() => {
        if (props.quizId) {
            remote
                .$get(
                    `/course/admin/courses/0/lessons/0/quizzes/${props.quizId}`
                )
                .then(quiz => {
                    setTitle(quiz.title);
                    setQuestions(
                        quiz.questions.map(o => ({ ...o, answer: null }))
                    );
                });
        }
        return () => {
            setQuestions([]);
            setTitle('');
        };
    }, [props.quizId]);
    return (
        <UserQuiz
            title={title}
            questions={questions}
            onSubmit={() => message.info('预览状态不会提交')}
            log={null}
            onGoBack={() => props.onClose()}
        />
    );
};
export default QuizPreview;
