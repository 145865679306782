import React, { useState, useCallback, useMemo } from 'react';
import BuyCourse from './buy-course';
import './buy-course-button.less';
import useCoursePrice from './hooks/course-price';
import useCourse from './hooks/course';

const IDiscount =
    'https://staticcdn.boyuai.com/materials/2019/12/01/HNKeOyVQwR2C3e1dKy98L.jpg';

export default function BuyCourseButton({
    course,
    courseUUID,
    skin = 'normal',
    skipRemainPrice = false,
    label,
}) {
    const uuid = course ? course.uuid : courseUUID;
    const remainPrice = useCoursePrice(skipRemainPrice ? null : uuid);
    const [visible, setVisible] = useState(false);
    const buyCourse = useCallback(() => setVisible(true), []);
    const onBuyCourseCancel = useCallback(
        success => {
            if (success) {
                window.location.reload();
            } else {
                setVisible(false);
            }
        },
        [setVisible]
    );
    const fetchedCourse = useCourse(course ? null : courseUUID);
    const targetCourse = useMemo(() => {
        if (course) {
            return course;
        }
        return fetchedCourse;
    }, [course, fetchedCourse]);
    const buttonLabel = useMemo(() => {
        if (label) {
            return label;
        }
        return targetCourse.forSell
            ? targetCourse.packageOnly
                ? '购买课程'
                : '购买全部知识点'
            : '咨询';
    }, [label, targetCourse]);
    if (!skipRemainPrice && (remainPrice === -1 || remainPrice === 0)) {
        return null;
    }
    if (!targetCourse) {
        return null;
    }
    return (
        <div className="course-package-buy" data-skin={skin}>
            <button onClick={buyCourse}>
                {buttonLabel}
                {targetCourse.forSell &&
                    !targetCourse.packageOnly &&
                    targetCourse.packageDiscount < 100 && (
                        <div className="discount-flag">
                            <img
                                src={IDiscount}
                                alt=""
                                width="27px"
                                height="43px"
                            />
                            <div>{targetCourse.packageDiscount / 10}</div>
                            <div>折</div>
                        </div>
                    )}
            </button>
            <BuyCourse
                visible={visible}
                onClose={onBuyCourseCancel}
                uuid={targetCourse.uuid}
                open={targetCourse.forSell}
                course={targetCourse}
            />
        </div>
    );
}
