import React, { useState } from 'react';
import CurrencyIntro from '@boyuai/currency-intro';

const CreditSystem = ({ control }) => {
    const [currencyIntroVisible, setCurrencyIntroVisible] = useState(false);
    return (
        <div className="carousel-item">
            <CurrencyIntro
                visible={currencyIntroVisible}
                onClose={() => setCurrencyIntroVisible(false)}
            />
            <img
                className="bg"
                src="https://staticcdn.boyuai.com/materials/2020/09/29/NqyxCFo0nI1igyFlo3hqb.png!png"
                alt="background"
            />

            <div className="main">
                <div className="main-content">
                    <img
                        src="https://staticcdn.boyuai.com/materials/2020/09/29/pN45kG5Ki1itJYTPcrRur.png!png"
                        alt="雨滴获取攻略"
                        width={960}
                        style={{
                            position: 'absolute',
                            right: '-240px',
                            bottom: '30px',
                        }}
                    />
                    <img
                        src="https://staticcdn.boyuai.com/materials/2020/09/29/RAZdvTokxs1UTPS--r63K.png!png"
                        alt="积分体系 全面升级"
                        width={260}
                        style={{
                            position: 'absolute',
                            left: '0px',
                            top: '0px',
                        }}
                    />
                    <div
                        className="enter transparent"
                        style={{
                            position: 'absolute',
                            left: '0px',
                            bottom: '168px',
                            width: 160,
                            backgroundColor: '#fff',
                        }}
                        onClick={() => setCurrencyIntroVisible(true)}
                    >
                        <div
                            className="circle"
                            style={{ backgroundColor: '#0D7DFC' }}
                        >
                            <img
                                src="https://static-dev.boyuai.com/materials/2019/12/20/E6te0j3hjeeLPQrDcBgiz.png"
                                alt=""
                                width="15px"
                                height="12px"
                            />
                        </div>
                        <div
                            className="text"
                            style={{
                                color: '#3A3A3A',
                                fontWeight: 'bold',
                            }}
                        >
                            我要赚取雨滴
                        </div>
                    </div>
                    {control}
                </div>
            </div>
        </div>
    );
};

export default CreditSystem;
