import React, { useEffect, useState } from 'react';
import { Link, useRouteMatch, useHistory } from 'react-router-dom';
import { Breadcrumb, Card, Steps } from 'antd';
import { useModulePath } from '../hooks';
import { DETAIL_TAB_LIST, ENTITY_NAME } from '../config';
import { fetchDetail } from '../services';
import SuperAdminForm from './form';
import { ADMIN_ROOT_PATH } from '../../config';

const SuperAdminDetail = () => {
    const history = useHistory();
    const match = useRouteMatch();
    const path = useModulePath();
    const [activeTabKey, setTabKey] = useState(DETAIL_TAB_LIST[0].key);
    const [detail, setDetail] = useState();

    useEffect(() => {
        if (!match.params.id) return;
        fetchDetail(match.params.id).then(data => {
            setDetail(data);
        });
    }, [match.params.id]);

    const steps = [
        {
            ...DETAIL_TAB_LIST[0],
            title: '填写基本信息',
            content: (
                <SuperAdminForm
                    detail={detail}
                    onSuccess={() => {
                        if (match.params.id) return;
                        history.goBack();
                    }}
                />
            ),
        },
        {
            key: 'finish',
            title: '完成添加',
        },
    ];

    const currentStep = steps.filter(step => step.key === activeTabKey)[0];
    const currentStepIndex = DETAIL_TAB_LIST.findIndex(
        step => step.key === activeTabKey
    );

    return (
        <>
            <Breadcrumb style={{ marginBottom: 16 }}>
                <Breadcrumb.Item>
                    <Link to={ADMIN_ROOT_PATH}>管理后台</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <Link to={path}>{ENTITY_NAME}列表</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    {match.params.id ? detail?.title : `添加${ENTITY_NAME}`}
                </Breadcrumb.Item>
            </Breadcrumb>
            <Card
                loading={match.params.id ? !detail : false}
                activeTabKey={activeTabKey}
                tabList={match.params.id ? DETAIL_TAB_LIST : []}
                onTabChange={key => setTabKey(key)}
                title={
                    !match.params.id && (
                        <Steps
                            progressDot
                            current={currentStepIndex}
                            style={{ padding: '16px 16px 0' }}
                        >
                            {steps.map(item => (
                                <Steps.Step
                                    key={item.title}
                                    title={item.title}
                                />
                            ))}
                        </Steps>
                    )
                }
            >
                {currentStep?.content}
            </Card>
        </>
    );
};

export default SuperAdminDetail;
