import React from 'react';

export default function Guide({ left, top }) {
    return (
        <div
            className="ui-qianren-guide"
            style={{
                position: 'absolute',
                left,
                top,
            }}
        >
            <img
                src="https://staticcdn.boyuai.com/materials/2020/02/22/BVParQ3-DKgeKcBNRYZZ0.png!png"
                alt=""
                width="74px"
            />
        </div>
    );
}
