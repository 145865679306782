import React, { useCallback, useEffect, useState } from 'react';
import { remote } from '@boyuai/utils';
import { downloadCSV, getPercentage } from '../utils';
import { message, Modal, Progress } from 'antd';

export function useUserStatistic(
    skip,
    take,
    userIds,
    names,
    phones,
    startDate,
    endDate,
    courseIds
) {
    const [users, setUsers] = useState([]);
    const [count, setCount] = useState(0);
    const fetch = useCallback(async () => {
        const payload = {
            skip,
            take,
            startDate,
            endDate,
            userIds: userIds && userIds.length ? userIds.join(',') : null,
            names: names && names.length ? names.join(',') : null,
            phones: phones && phones.length ? phones.join(',') : null,
            courseIds:
                courseIds && courseIds.length ? courseIds.join(',') : null,
        };
        if (!userIds) {
            delete payload.userIds;
        }
        if (!names) {
            delete payload.names;
        }
        if (!phones) {
            delete payload.phones;
        }
        if (!courseIds) {
            delete payload.courseIds;
        }
        if (!startDate) {
            delete payload.startDate;
        }
        if (!endDate) {
            delete payload.endDate;
        }
        const res = await remote.$get(`/course/admin/user-statistic`, payload);
        if (res) {
            const [list, count] = res;
            setUsers(list);
            setCount(count);
        }
    }, [skip, take, userIds, names, phones, startDate, endDate, courseIds]);
    useEffect(() => {
        fetch();
    }, [fetch]);
    return [users, count, fetch];
}

const BATCH_SIZE = 100;
export function useAllUserStatistic() {
    const [lock, setLock] = useState(false);
    const [progress, setProgress] = useState(0);
    const [count, setCount] = useState(0);

    const fetchAll = useCallback(
        async (userIds, names, phones, startDate, endDate, courseIds) => {
            if (lock) {
                return;
            }
            setLock(true);
            let statToBeExport = [];
            let statCount = 0;
            let cursor = 0;
            const payload = {
                skip: cursor,
                take: BATCH_SIZE,
                startDate,
                endDate,
                userIds: userIds && userIds.length ? userIds.join(',') : null,
                names: names && names.length ? names.join(',') : null,
                phones: phones && phones.length ? phones.join(',') : null,
                courseIds:
                    courseIds && courseIds.length ? courseIds.join(',') : null,
            };
            if (!userIds) {
                delete payload.userIds;
            }
            if (!names) {
                delete payload.names;
            }
            if (!phones) {
                delete payload.phones;
            }
            if (!courseIds) {
                delete payload.courseIds;
            }
            if (!startDate) {
                delete payload.startDate;
            }
            if (!endDate) {
                delete payload.endDate;
            }
            // 第一次
            const res = await remote.$get(
                `/course/admin/user-statistic`,
                payload
            );
            if (res) {
                [statToBeExport, statCount] = res;
            }
            // statCount = 1000; // 开发测试用
            setCount(statCount);
            while (cursor + BATCH_SIZE < statCount) {
                cursor += BATCH_SIZE;
                // 第一次
                payload.skip = cursor;
                const res = await remote.$get(
                    `/course/admin/user-statistic`,
                    payload
                );
                if (res) {
                    const [list] = res;
                    statToBeExport = [...statToBeExport, ...list];
                    // 进度
                    setProgress(
                        cursor + BATCH_SIZE > statCount
                            ? statCount
                            : cursor + BATCH_SIZE
                    );
                } else {
                    message.error('获取失败，请重试');
                    return;
                }
            }
            downloadCSV(
                statToBeExport,
                '用户名, 国家号, 手机号, 学习知识点数, quiz答对数, quiz总数, quiz正确率, 笔记数\n',
                row =>
                    `${row.name}, ${row.countryCode || 0}, ${row.phone || 0}, ${
                        row.learnedLessons ? row.learnedLessons.length : 0
                    },${
                        row.accuracy
                            ? `${row.accuracy[0]},${
                                  row.accuracy[1]
                              },${getPercentage(
                                  row.accuracy[0],
                                  row.accuracy[1]
                              )}`
                            : `0,0,0`
                    },${row.notes ? row.notes.length : 0}\n`
            );
            setLock(false);
            setProgress(0);
            setCount(0);
        },
        [lock]
    );

    const modal = (
        <Modal visible={lock} footer={null} closable={false}>
            <div style={{ textAlign: 'center', width: '100%', height: 300 }}>
                <Progress
                    type="circle"
                    width={250}
                    percent={getPercentage(progress, count) * 100}
                    format={() => `${progress} / ${count}`}
                />
                <div>请勿关闭浏览器，读条完毕自动下载csv</div>
                <div>windows用记事本打开另存为ANSI格式即可正常阅读</div>
            </div>
        </Modal>
    );
    return [fetchAll, modal];
}
