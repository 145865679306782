import React, { useCallback, useState } from 'react';
import { remote } from '@boyuai/utils';
import { Button, InputNumber, Select } from 'antd';

function AdminCreateUsers() {
    const [countryCode, setCountryCode] = useState(86);
    const [phones, setPhones] = useState([]);

    const [existUserList, setExistUserList] = useState([]);
    const [createdUserList, setCreatedUserList] = useState([]);
    const createUsers = useCallback(async () => {
        const res = await remote.$post('/course/admin/create-users', {
            countryCode,
            phones,
        });
        // console.log(res);
        if (res) {
            setExistUserList(res.existUserList);
            setCreatedUserList(res.createdUserList);
        }
    }, [countryCode, phones]);
    return (
        <div style={{ marginTop: '20px' }}>
            <Button type={'primary'} onClick={createUsers}>
                批量创建用户
            </Button>
            <div>
                国家号
                <InputNumber
                    value={countryCode}
                    onChange={v => setCountryCode(v)}
                />
            </div>
            <Select
                placeholder={'手机号'}
                mode="tags"
                style={{ width: '100%' }}
                value={phones}
                allowClear={true}
                onChange={value => {
                    const formattedValue = value
                        .toString()
                        .replace(/\s/g, '')
                        .split(',')
                        .filter(function(item, index, arr) {
                            //当前元素，在原始数组中的第一个索引==当前索引值，否则返回当前元素
                            return arr.indexOf(item, 0) === index && item;
                        });
                    setPhones(formattedValue.map(Number));
                }}
            />
            <div>
                创建失败的手机号（已注册）
                {existUserList.map(({ phone, id }, index) => (
                    <div key={index}>
                        +{countryCode}&nbsp;{phone}，{id}
                    </div>
                ))}
            </div>
            <div>
                创建成功的手机号
                {createdUserList.map(({ countryCode, phone, id }, index) => (
                    <div key={index}>
                        +{countryCode}&nbsp;{phone}，{id}
                    </div>
                ))}
            </div>
        </div>
    );
}

export default AdminCreateUsers;
