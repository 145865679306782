import React, { useCallback, useState } from 'react';
import { Button, Upload, message } from 'antd';
import { remote } from '@boyuai/utils';

const LessonBatchProcess = ({ courseId }) => {
    const [loading, setLoading] = useState(false);
    // const [uuids, setUuids] = useState([]); // 刚导入准备处理的知识点

    const batchInsert = useCallback(
        uuids => {
            setLoading(true);
            remote
                .$post(
                    `/course/admin/courses/${courseId}/lesson-batch`,
                    {
                        uuids,
                    },
                    {
                        throwException: 'showMessage',
                    }
                )
                .then(() => {
                    message.success('添加成功，请刷新查看');
                    setLoading(false);
                })
                .catch(() => {
                    setLoading(false);
                });
        },
        [courseId]
    );

    const handleFile = useCallback(
        f => {
            const reader = new window.FileReader();
            reader.readAsText(f.file);
            reader.onload = e => {
                try {
                    const l = e.currentTarget.result
                        .split('\n')
                        .map(item => item.trim())
                        .filter(item => item !== '');
                    batchInsert(l);
                } catch (e) {
                    message.error('文件格式错误');
                }
            };
        },
        [batchInsert]
    );

    return (
        <Upload
            type="file"
            accept=".txt"
            multiple={false}
            showUploadList={false}
            customRequest={handleFile}
        >
            <Button disabled={loading}>为课程批量添加知识点</Button>
        </Upload>
    );
};

export default LessonBatchProcess;
