import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { remote } from '@boyuai/utils';
import { Button, Select, Table, Pagination, Input } from 'antd';

const PAGE_SIZE = 20;

const { Option } = Select;

const UserLabel = () => {
    const [state, setState] = useState({
        users: [],
        count: PAGE_SIZE,
        current: 1,
    });
    const [filter, setFilter] = useState({
        userSource: null,
        userType: null,
    });
    const [query, setQuery] = useState({
        limit: PAGE_SIZE,
        offset: 0,
        current: 1,
    });
    const [searchInput, setSearchInput] = useState();
    const [labelOptions, setLabelOptions] = useState([]);
    const [labelLoading, setLabelLoading] = useState(false);

    useEffect(() => {
        const { current, ...restQuery } = query;
        remote.$get('/admin/user-labels', restQuery).then(res => {
            if (!res) {
                setState({ users: [], current: 1, count: 0 });
                return;
            }
            setState({ users: res[0], current: current, count: res[1] });
        });
        remote.$get('/admin/labels').then(res => {
            setLabelOptions(res);
        });
    }, [query]);

    const updatePage = useCallback(newPage => {
        setQuery(pre => ({
            ...pre,
            offset: (newPage - 1) * PAGE_SIZE,
            current: newPage,
        }));
    }, []);

    const updateQuery = useCallback(newQuery => {
        setQuery({
            ...newQuery,
            limit: PAGE_SIZE,
            offset: 0,
            current: 1,
        });
    }, []);

    const queryInput = useCallback(
        query => {
            setFilter({
                userSource: null,
                userType: null,
            });
            updateQuery(query);
        },
        [updateQuery]
    );

    const init = useCallback(() => {
        setSearchInput(null);
        setFilter({
            userSource: null,
            userType: null,
        });
        updateQuery({});
    }, [updateQuery]);

    useEffect(() => {
        init();
    }, [init]);

    const columns = useMemo(() => {
        const onDeleteLabel = (name, userId, type) => {
            const label = labelOptions.find(
                l => l.name === name && l.type === type
            );
            if (!label) return;
            setLabelLoading(true);

            remote
                .$delete(`/admin/users/${userId}/labels/${label.id}`)
                .then(() => {
                    setState(pre => ({
                        ...pre,
                        users: pre.users.map(u => {
                            if (u.id === userId) {
                                return {
                                    ...u,
                                    labels: u.labels.filter(
                                        l => l.id !== label.id
                                    ),
                                };
                            }
                            return u;
                        }),
                    }));
                    setLabelLoading(false);
                });
        };
        const onAddLabel = (name, userId, type) => {
            const label = labelOptions.find(
                l => l.name === name && l.type === type
            );
            setLabelLoading(true);
            remote
                .$post(`/admin/users/${userId}/labels`, {
                    name,
                    type,
                })
                .then(res => {
                    if (!label) {
                        setLabelOptions(pres => [...pres, res]);
                    }
                    setState(pre => ({
                        ...pre,
                        users: pre.users.map(u => {
                            if (u.id === userId) {
                                return {
                                    ...u,
                                    labels: [...u.labels, res],
                                };
                            }
                            return u;
                        }),
                    }));
                    setLabelLoading(false);
                });
        };
        return [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
            },
            {
                title: '昵称',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: '电话',
                dataIndex: 'phone',
                key: 'phone',
                render: (value, { countryCode }) => {
                    return `+${countryCode} ${value}`;
                },
            },
            {
                title: '用户类别',
                key: 'userType',
                filters: labelOptions
                    .filter(l => l.type === 'userType')
                    .map(l => ({
                        text: l.name,
                        value: l.id,
                    })),
                filteredValue: filter.userType,
                onFilter: () => true,
                render: (_, { labels, id: userId }) => {
                    const type = 'userType';
                    const userTypeLabels = labels.filter(l => l.type === type);
                    const userTypeOptions = labelOptions.filter(
                        l => l.type === type
                    );
                    return (
                        <Select
                            mode="tags"
                            style={{ width: 250 }}
                            value={userTypeLabels.map(l => l.name)}
                            onDeselect={value =>
                                onDeleteLabel(value, userId, type)
                            }
                            onSelect={value => onAddLabel(value, userId, type)}
                            loading={labelLoading}
                            disabled={labelLoading}
                        >
                            {userTypeOptions.map(l => (
                                <Option value={l.name} key={l.name}>
                                    {l.name}
                                </Option>
                            ))}
                        </Select>
                    );
                },
            },
            {
                title: '用户来源',
                key: 'userSource',
                filters: labelOptions
                    .filter(l => l.type === 'userSource')
                    .map(l => ({
                        text: l.name,
                        value: l.id,
                    })),
                onFilter: () => true,
                filteredValue: filter.userSource,
                render: (_, { labels, id: userId }) => {
                    const type = 'userSource';
                    const userTypeLabels = labels.filter(l => l.type === type);
                    const userSourceOptions = labelOptions.filter(
                        l => l.type === type
                    );
                    return (
                        <Select
                            mode="tags"
                            style={{ width: 250 }}
                            value={userTypeLabels.map(l => String(l.name))}
                            onDeselect={value =>
                                onDeleteLabel(value, userId, type)
                            }
                            onSelect={value => onAddLabel(value, userId, type)}
                            loading={labelLoading}
                            disabled={labelLoading}
                        >
                            {userSourceOptions.map(l => (
                                <Option value={l.name} key={l.name}>
                                    {l.name}
                                </Option>
                            ))}
                        </Select>
                    );
                },
            },
        ];
    }, [labelOptions, labelLoading, filter]);

    return (
        <div style={{ marginTop: '20px' }}>
            <h3 style={{ marginBottom: 16 }}>用户权限管理</h3>
            <Button style={{ marginBottom: 8 }} onClick={() => init()}>
                显示全部
            </Button>
            <Input
                allowClear
                style={{ marginBottom: 8 }}
                placeholder="输入 ID / 姓名 / 手机号搜索用户"
                value={searchInput}
                onChange={e => {
                    setSearchInput(e.target.value);
                }}
            />
            <Button
                style={{ marginRight: 4 }}
                onClick={() => queryInput({ id: searchInput })}
            >
                搜索 ID
            </Button>
            <Button
                style={{ marginRight: 4 }}
                onClick={() => queryInput({ name: searchInput })}
            >
                搜索昵称
            </Button>
            <Button onClick={() => queryInput({ phone: searchInput })}>
                搜索手机号
            </Button>
            <Table
                style={{ margin: '16px 0' }}
                rowKey="id"
                columns={columns}
                dataSource={state.users}
                pagination={false}
                onChange={(_pagination, filters, _sorter, extra) => {
                    if (extra.action !== 'filter') return;
                    setFilter(filters);
                    setSearchInput(null);
                    const labels = Object.entries(filters)
                        .filter(f => f[1] !== null)
                        .map(f => f[1])
                        .join();
                    if (labels) {
                        updateQuery({ labels });
                    } else {
                        updateQuery({});
                    }
                }}
            />
            <Pagination
                defaultPageSize={PAGE_SIZE}
                showSizeChanger={false}
                total={state.count}
                current={state.current}
                onChange={updatePage}
            />
        </div>
    );
};

export default UserLabel;
