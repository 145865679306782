import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { remote } from '@boyuai/utils';
import {
    Button,
    Checkbox,
    Table,
    Pagination,
    Input,
    message,
    Form,
} from 'antd';

const PAGE_SIZE = 20;
let filteredRoles = [];
let query = {};

const UserRole = () => {
    const [state, setState] = useState({
        users: [],
        count: PAGE_SIZE,
        current: 1,
    });
    const [searchInput, setSearchInput] = useState();
    const [filteredInfo, setFilteredInfo] = useState({});

    const fetchData = (newQuery, newPage) => {
        query = {
            ...newQuery,
            limit: PAGE_SIZE,
            offset: (newPage - 1) * PAGE_SIZE,
        };

        remote.$get('/admin/users', query).then(res => {
            if (!res) {
                setState({ users: [], current: 1, count: 0 });
                return;
            }
            setState({ users: res[0], current: newPage, count: res[1] });
        });
    };

    const search = value => {
        fetchData({ ...query, query: value }, 1);
    };

    const init = () => {
        setSearchInput(null);
        setFilteredInfo({});
        fetchData({}, 1);
    };

    useEffect(() => {
        init();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const changeRole = (userId, role, value) => {
        remote.$patch(`/admin/users/${userId}`, { [role]: value }).then(res => {
            if (!res) return;
            message.success('修改成功');
            fetchData(query, state.current);
        });
    };

    const renderRoleCheckBox = (value, userId, role) => (
        <Checkbox
            checked={value}
            onChange={e => changeRole(userId, role, e.target.checked)}
        />
    );

    const filterObject = {
        filters: [
            {
                text: '仅显示有权限的人',
                value: 1,
            },
        ],
        filterMultiple: false,
    };

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: '昵称',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '电话',
            dataIndex: 'phone',
            key: 'phone',
            render: (value, { countryCode }) => {
                return `+${countryCode} ${value}`;
            },
        },
        {
            ...filterObject,
            filteredValue: filteredInfo.roleImageAdmin || null,
            title: '图片制作者',
            dataIndex: 'roleImageAdmin',
            key: 'roleImageAdmin',
            render: (value, record) =>
                renderRoleCheckBox(value, record.id, 'roleImageAdmin'),
        },
        {
            ...filterObject,
            filteredValue: filteredInfo.roleQuiz || null,
            title: '题库管理员',
            dataIndex: 'roleQuiz',
            key: 'roleQuiz',
            render: (value, record) =>
                renderRoleCheckBox(value, record.id, 'roleQuiz'),
        },
        {
            ...filterObject,
            filteredValue: filteredInfo.roleEditor || null,
            title: '课程制作者',
            dataIndex: 'roleEditor',
            key: 'roleEditor',
            render: (value, record) =>
                renderRoleCheckBox(value, record.id, 'roleEditor'),
        },
        {
            ...filterObject,
            filteredValue: filteredInfo.roleEditor || null,
            title: '实践平台使用权限',
            dataIndex: 'rolePlayground',
            key: 'rolePlayground',
            render: (value, record) =>
                renderRoleCheckBox(value, record.id, 'rolePlayground'),
        },
        {
            ...filterObject,
            filteredValue: filteredInfo.roleCommentManager || null,
            title: '评论管理员',
            dataIndex: 'roleCommentManager',
            key: 'roleCommentManager',
            render: (value, record) =>
                renderRoleCheckBox(value, record.id, 'roleCommentManager'),
        },
        {
            ...filterObject,
            filteredValue: filteredInfo.roleFinance || null,
            title: '订单管理员',
            dataIndex: 'roleFinance',
            key: 'roleFinance',
            render: (value, record) =>
                renderRoleCheckBox(value, record.id, 'roleFinance'),
        },
        {
            ...filterObject,
            filteredValue: filteredInfo.roleLearnStatistic || null,
            title: '波波鱼课程运营',
            dataIndex: 'roleLearnStatistic',
            key: 'roleLearnStatistic',
            render: (value, record) =>
                renderRoleCheckBox(value, record.id, 'roleLearnStatistic'),
        },
        {
            ...filterObject,
            filteredValue: filteredInfo.roleErrorStatistic || null,
            title: '波波鱼错误统计',
            dataIndex: 'roleErrorStatistic',
            key: 'roleErrorStatistic',
            render: (value, record) =>
                renderRoleCheckBox(value, record.id, 'roleErrorStatistic'),
        },
        {
            ...filterObject,
            filteredValue: filteredInfo.roleCourseStatistic || null,
            title: '学习平台课程运营',
            dataIndex: 'roleCourseStatistic',
            key: 'roleCourseStatistic',
            render: (value, record) =>
                renderRoleCheckBox(value, record.id, 'roleCourseStatistic'),
        },
        {
            ...filterObject,
            filteredValue: filteredInfo.roleAdmin || null,
            title: '超级管理员',
            dataIndex: 'roleAdmin',
            key: 'roleAdmin',
            render: (value, record) =>
                renderRoleCheckBox(value, record.id, 'roleAdmin'),
        },
    ];

    return (
        <div style={{ marginTop: '20px' }}>
            <h3 style={{ marginBottom: 16 }}>用户权限管理</h3>
            <Form layout="inline">
                <Form.Item>
                    <Input
                        allowClear
                        style={{ marginBottom: 8, width: 300 }}
                        placeholder="输入 ID / 姓名 / 手机号搜索用户"
                        value={searchInput}
                        onChange={e => {
                            setSearchInput(e.target.value);
                        }}
                    />
                </Form.Item>
                <Form.Item>
                    <Button
                        htmlType="submit"
                        onClick={() => search(searchInput)}
                    >
                        搜索
                    </Button>
                </Form.Item>
                <Form.Item>
                    <Button onClick={() => init()}>重置</Button>
                </Form.Item>
            </Form>
            <Table
                style={{ margin: '16px 0' }}
                rowKey="id"
                columns={columns}
                dataSource={state.users}
                pagination={false}
                onChange={(_pagination, filters, _sorter, extra) => {
                    if (extra.action !== 'filter') return;
                    const newFilteredRoles = Object.entries(filters)
                        .filter(f => f[1] !== null)
                        .map(f => f[0]);
                    const diff = _.differenceWith(
                        newFilteredRoles,
                        filteredRoles,
                        _.isEqual
                    );
                    filteredRoles = diff;
                    const newFileredInfo = {};
                    for (const key of filteredRoles) {
                        newFileredInfo[key] = ['1'];
                    }
                    setFilteredInfo(newFileredInfo);
                    fetchData({ ...query, role: filteredRoles[0] }, 1);
                }}
            />
            <Pagination
                defaultPageSize={PAGE_SIZE}
                showSizeChanger={false}
                total={state.count}
                current={state.current}
                onChange={newPage => {
                    fetchData(query, newPage);
                }}
            />
        </div>
    );
};

export default UserRole;
