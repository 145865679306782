import React, { useCallback, useState } from 'react';
import { Input, Modal, Row, Col, Spin, message } from 'antd';
import { remote } from '@boyuai/utils';
import PropTypes from 'prop-types';

const CommentBanModal = props => {
    const { commentItem, onClose } = props;
    const [days, setDays] = useState(1);
    const [loading, setLoading] = useState(false);
    const ban = useCallback(async () => {
        setLoading(true);
        await remote.$post('/comment/ban', {
            userId: commentItem.userId,
            days,
        });
        message.success(`${commentItem.userName}被禁言${days}天`);
        onClose();
    }, [days, commentItem, onClose]);
    return (
        <Modal
            title={`禁言`}
            visible={true}
            onCancel={onClose}
            onOk={() => ban()}
        >
            <Spin spinning={loading}>
                <Row
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: 8,
                    }}
                >
                    <Col span={4}>
                        <label>禁言用户：</label>
                    </Col>
                    <Col span={20}>{commentItem.userName}</Col>
                </Row>
                <Row
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: 8,
                    }}
                >
                    <Col span={4}>
                        <label>禁言天数：</label>
                    </Col>
                    <Col span={4}>
                        <Input
                            size={'large'}
                            type={'number'}
                            min={1}
                            defaultValue={1}
                            onChange={e => setDays(e.target.value)}
                        />
                    </Col>
                </Row>
            </Spin>
        </Modal>
    );
};

CommentBanModal.propTypes = {
    commentItem: PropTypes.shape(),
    onClose: PropTypes.func,
};

export default CommentBanModal;
