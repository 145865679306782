import React from 'react';
import { Modal, Button, Spin } from 'antd';
import './common-modal.less';

export default function CommonModal({
    visible,
    loading,
    imgSrc,
    title,
    hint,
    buttonVisible,
    okText,
    onOk,
    onCancel,
    wrapContent,
}) {
    return (
        <Modal
            wrapClassName="ui-common-modal"
            visible={visible}
            onCancel={() => onCancel()}
            title={null}
            footer={null}
            width={450}
        >
            <Spin spinning={!!loading}>
                <img
                    onClick={() => onCancel()}
                    className="close-button"
                    src="https://staticcdn.boyuai.com/materials/2020/08/01/U0eGfF6-XzSu3NdTrJvbU.png!png"
                    alt="关闭"
                />
                {wrapContent ? (
                    wrapContent
                ) : (
                    <div className="common-modal-container">
                        <img
                            src={imgSrc}
                            alt={''}
                            width={'100px'}
                            height={'100px'}
                        />

                        <div className="common-modal-container-title">
                            {title}
                        </div>
                        <div className="common-modal-container-hint">
                            {hint}
                        </div>
                        {buttonVisible && (
                            <Button
                                className="common-modal-container-button"
                                onClick={() => onOk()}
                            >
                                {okText}
                            </Button>
                        )}
                    </div>
                )}
            </Spin>
        </Modal>
    );
}
