import React, { useCallback, useEffect, useState } from 'react';
import { remote } from '@boyuai/utils';
import { Button, Popover, message } from 'antd';
import AdminTable from '../table';
import PromotionCodeFormModal from './promotion-code-form-modal';
import { Link } from 'react-router-dom';

export default function AdminPromotionCodes() {
    const [promotionCodes, setPromotionCodes] = useState([]);
    // const [total, setTotal] = useState(0);
    const [createModalVisible, setCreateModalVisible] = useState(false);
    const [editModalVisibleId, setEditModalVisibleId] = useState(null);

    const fetchPromotionCodes = useCallback(async () => {
        const res = await remote.$get('/promotions', {}, { hideLoading: true });
        if (res) {
            const [list] = res;
            setPromotionCodes(list);
            // setTotal(count);
        }
    }, []);

    useEffect(() => {
        fetchPromotionCodes();
    }, [fetchPromotionCodes]);

    const onEdit = useCallback(async () => {
        await fetchPromotionCodes();
        message.success('更新成功');
        setEditModalVisibleId(null);
    }, [fetchPromotionCodes]);

    const onCreate = useCallback(async () => {
        await fetchPromotionCodes();
        message.success('创建成功');
        setCreateModalVisible(false);
    }, [fetchPromotionCodes]);

    const renderOperations = useCallback(
        id => (
            <>
                {editModalVisibleId === id && (
                    <PromotionCodeFormModal
                        promotionCode={promotionCodes.find(
                            item => item.id === id
                        )}
                        onClose={() => setEditModalVisibleId(null)}
                        onSubmit={onEdit}
                    />
                )}
                <Button
                    type="primary"
                    onClick={() => setEditModalVisibleId(id)}
                >
                    编辑渠道二维码
                </Button>
                &nbsp;&nbsp;
                <Link to={`/admin/promotions/logs/${id}`}>
                    <Button type="primary">查看扫码结果</Button>
                </Link>
            </>
        ),
        [promotionCodes, editModalVisibleId, onEdit]
    );

    return (
        <>
            {createModalVisible && (
                <PromotionCodeFormModal
                    onClose={() => setCreateModalVisible(false)}
                    onSubmit={onCreate}
                />
            )}
            <Button
                style={{ margin: 8 }}
                type="primary"
                onClick={() => setCreateModalVisible(true)}
            >
                创建渠道二维码
            </Button>
            <AdminTable
                data={promotionCodes}
                columns={[
                    {
                        title: 'id',
                        dataIndex: 'id',
                        key: 'id',
                    },
                    {
                        title: '二维码',
                        dataIndex: 'image',
                        key: 'image',
                        render: image => (
                            <Popover
                                content={
                                    <img
                                        style={{ width: '160px' }}
                                        src={image}
                                        alt="二维码"
                                    />
                                }
                            >
                                <img
                                    style={{ width: '60px' }}
                                    src={image}
                                    alt={image}
                                />
                            </Popover>
                        ),
                    },
                    {
                        title: '头衔',
                        dataIndex: 'title',
                        key: 'title',
                    },
                    {
                        title: '设定',
                        dataIndex: 'config',
                        key: 'config',
                        render: JSON.stringify,
                    },
                    {
                        title: '操作',
                        dataIndex: 'id',
                        key: 'operations',
                        render: renderOperations,
                    },
                ]}
            />
        </>
    );
}
