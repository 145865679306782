import React, { Component } from 'react';
import { AliyunVidInput } from '@boyuai/admin-kit';

export default class AdminFormItemVideo extends Component {
    static rule = { required: true };

    render() {
        return <AliyunVidInput {...this.props} />;
    }
}
