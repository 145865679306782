import React from 'react';
import './index.less';
export default function Loading() {
    return (
        <div className="ui-activity-loading">
            <img
                className="loading"
                src="https://staticcdn.boyuai.com/materials/2020/03/03/JmAry9PRUfribtW1HZWO9.png!png"
                alt=""
            />
            <img
                className="sakura"
                src="https://staticcdn.boyuai.com/materials/2020/03/03/S8SoZhmeP5lLPpqpI1PLd.png!png"
                alt=""
            />
        </div>
    );
}
