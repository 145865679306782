import React, { PureComponent } from 'react';
import { NavLink } from 'react-router-dom';
import { Modal } from 'antd';
import AuthorIntro from '../../components/author-intro';
import { CourseContext } from '../../context/courseContext';
import './index.less';

import youthAI from '../../assets/images/youthai.png';
import IBoughtCount from '../../assets/images/bought-count.png';
import IBought from '../../assets/images/bought.png';

const removeDuplicateAuthors = authors => {
    const ids = authors.map(author => author.id);
    return authors.filter((author, index) => {
        return ids.indexOf(author.id) === index;
    });
};

export default class Card extends PureComponent {
    static contextType = CourseContext;
    bodyRef = React.createRef();
    state = {
        visible: false,
        fold: false,
    };

    componentDidMount() {
        if (this.bodyRef && this.bodyRef.current) {
            // 相关资源高度大与 100px 时才默认折叠
            const height = this.bodyRef.current.getBoundingClientRect().height;
            if (height > 100) {
                this.setState({ fold: true });
            }
        }
    }

    render() {
        const {
            title,
            style,
            authors,
            titleLink,
            isShowCourseTitle,
            optionalEle,
            bought,
            boughtCount,
            hasExpiredAt,
            enableFold,
        } = this.props;
        const { fold } = this.state;
        const avaiAuthors = removeDuplicateAuthors(
            authors ? authors.filter(Boolean) : []
        );
        return (
            <div className="ui-card" style={style}>
                <div className="ui-card-header">
                    <div className="ui-card-title">
                        {titleLink ? (
                            <NavLink to={titleLink}>{title}</NavLink>
                        ) : (
                            title
                        )}
                        {bought
                            ? this.renderBought()
                            : hasExpiredAt && this.renderExpired(hasExpiredAt)}
                        {enableFold && (
                            <span
                                className="fold-title"
                                onClick={() => this.setState({ fold: !fold })}
                            >
                                {fold ? '查看' : '折叠'}
                            </span>
                        )}
                    </div>
                    <div className="ui-card-option">
                        {boughtCount > 0 && this.renderBoughtCount(boughtCount)}
                        {isShowCourseTitle && this.renderCourseTitle()}
                        {avaiAuthors.length > 0 &&
                            this.renderAuthor(avaiAuthors)}
                        {avaiAuthors.length > 0 &&
                            this.renderModal(avaiAuthors)}
                    </div>
                    {optionalEle}
                </div>
                {this.renderCardBody()}
            </div>
        );
    }

    renderAuthor(authors) {
        return (
            <div className="header-author">
                <div className="images">
                    {authors.map((author, index) => (
                        <div className="img-wrapper" key={author.id}>
                            <img
                                src={author.coverImage}
                                alt="avatar"
                                style={{ zIndex: 100 - index }}
                            />
                        </div>
                    ))}
                </div>
                <div className="author-names">
                    {authors.map(author => author.name).join('、')}
                </div>
                <div
                    className="info"
                    onClick={() => this.setState({ visible: true })}
                >
                    查看详情
                </div>
            </div>
        );
    }

    renderModal(authors) {
        return (
            <Modal
                title="作者介绍"
                visible={this.state.visible}
                footer={null}
                onCancel={() => this.setState({ visible: false })}
            >
                {authors.map(author => (
                    <AuthorIntro key={author.id} author={author} />
                ))}
            </Modal>
        );
    }

    renderBoughtCount(count) {
        return (
            <div className="header-count">
                <img src={IBoughtCount} alt="count" />
                <div>{count}人已学习</div>
            </div>
        );
    }

    renderBought() {
        const willExpire =
            this.props.boughtExpiresAt !== null &&
            parseInt(this.props.boughtExpiresAt.slice(0, 4)) <= 2090;
        return (
            <div className="header-bought">
                <img src={IBought} alt="count" />
                <div>
                    已购买
                    {willExpire
                        ? `，有效期至${this.props.boughtExpiresAt}`
                        : null}
                </div>
            </div>
        );
    }

    renderExpired(hasExpiredAt) {
        return (
            <div
                className="header-bought"
                style={{ backgroundColor: '#EBEBEB', color: '#777777' }}
            >
                <div>{`已于${hasExpiredAt}过期，需要重新购买`}</div>
            </div>
        );
    }

    renderCourseTitle() {
        if (this.context) {
            const { title } = this.context;
            return (
                <div className="course-title">
                    <img src={youthAI} alt="youthAI" />
                    <div>{title}</div>
                </div>
            );
        }
    }

    renderCardBody() {
        if (this.props.enableFold && this.state.fold) {
            return null;
        }
        return (
            <div className="ui-card-body" ref={this.bodyRef}>
                {this.props.children}
            </div>
        );
    }
}
