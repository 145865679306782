import React, { useState, useCallback } from 'react';
import { Input } from 'antd';
import { remote } from '@boyuai/utils';

export default function Redeem() {
    const [value, setValue] = useState(null);
    const [code, setCode] = useState('');

    const onGenerateRedeem = useCallback(async () => {
        if (Number(value) >= 0) {
            const codeResult = await remote.$post(
                '/wallet/redeem',
                { value },
                { throwException: 'showMessage' }
            );
            if (codeResult) {
                setCode(codeResult);
            }
        }
    }, [value]);
    return (
        <div>
            <Input
                placeholder="输入雨滴值"
                value={value}
                onChange={e => setValue(e.target.value)}
            />
            <div onClick={onGenerateRedeem} style={{ cursor: 'pointer' }}>
                点我生成兑换码
            </div>
            <div>{code}</div>
        </div>
    );
}
