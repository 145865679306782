import React, { useState, useEffect, useCallback } from 'react';
import { Modal, Spin, Result, Radio, Button } from 'antd';
import CurrencyIntro from '@boyuai/currency-intro';
import Recharge from '@boyuai/recharge';
import Fapiao from '@boyuai/fapiao';
import { remote } from '@boyuai/utils';
import PropTypes from 'prop-types';
import CoursePrice from '../course-price';
import useCoursePrice from '../hooks/course-price';
import { NotForSell } from './not-for-sell';
import PaymentBalance from './payment-balance';
import PaymentCredit from './payment-credit';
import './index.less';
import PaymentRechargeBuy from './payment-recharge-buy';

const PAYMENT_TYPE_BALANCE = 0;
const PAYMENT_TYPE_CREDIT = 1;
const PAYMENT_TYPE_RECHARGE_BUY = 2;

export default function BuyCourse({
    visible,
    onClose,
    uuid,
    open,
    isLesson,
    course,
    courseUuid,
}) {
    const [loading, setLoading] = useState(true);
    const [submitting, setSubmitting] = useState(false);
    const [price, setPrice] = useState(0);
    const [originalPrice, setOriginalPrice] = useState(0);
    const [credit, setCredit] = useState(0);
    const [balance, setBalance] = useState(0);
    const [typeOfPayment, setTypeOfPayment] = useState(PAYMENT_TYPE_BALANCE);
    const [success, setSuccess] = useState(false);
    const [rechargeVisible, setRechargeVisible] = useState(false);
    const [chargeAndBuy, setChargeAndBuy] = useState(false); // 直接充值购买
    const [currencyIntroVisible, setCurrencyIntroVisible] = useState(false);
    const [forSell, setForSell] = useState(true);
    const [balanceInsufficient, setBalanceInsufficient] = useState(false);
    const [creditInsufficient, setCreditInsufficient] = useState(false);
    const [counter, setCounter] = useState(-1);
    const [error, setError] = useState(null);
    const [jumpPath, setJumpPath] = useState(null);
    const coursePrice = useCoursePrice(visible && open && !isLesson && uuid);

    const fetch = useCallback(async () => {
        try {
            setLoading(true);
            let priceTemp = 0;
            let originalPriceTemp = 0;
            if (isLesson) {
                const priceData = await remote.$post(
                    `/course/lessons/${uuid}/buy`,
                    {},
                    { throwException: 'exceptLogin' }
                );
                priceTemp = priceData[0];
                originalPriceTemp = priceData[1];
            } else {
                priceTemp =
                    Math.floor(
                        coursePrice * (course.packageDiscount / 100) * 10
                    ) * 10; // 保留1位小数
            }
            const { value, credit } = await remote.$get(
                '/wallet',
                {},
                { throwException: 'exceptLogin' }
            );
            setBalanceInsufficient(priceTemp > value);
            setCreditInsufficient(priceTemp * 100 > credit);
            // 默认选择
            if (priceTemp <= value) {
                // 云朵够 选中云朵
                setTypeOfPayment(PAYMENT_TYPE_BALANCE);
            } else if (priceTemp * 100 <= credit) {
                // 云朵不够雨滴够 选中雨滴
                setTypeOfPayment(PAYMENT_TYPE_CREDIT);
            } else {
                // 都不够 选中充值
                setTypeOfPayment(PAYMENT_TYPE_RECHARGE_BUY);
            }
            setPrice(priceTemp);
            setOriginalPrice(originalPriceTemp);
            setBalance(value);
            setCredit(credit);
            setLoading(false);
        } catch (e) {
            setLoading(false);
            if (e?.response?.data?.message === '课程无法购买') {
                setForSell(false);
            } else {
                setError(e?.response?.data?.message);
            }
        }
    }, [uuid, isLesson, course, coursePrice]);

    useEffect(() => {
        if (!open) {
            setForSell(false);
        }
        if (!visible || !open) {
            return;
        }
        fetch();
    }, [visible, fetch, open]);

    useEffect(() => {
        if (visible) {
            setPrice(0);
            setBalance(0);
            setSuccess(false);
            setError(null);
        }
    }, [visible]);

    useEffect(() => {
        if (success) {
            setCounter(3);
        }
    }, [success]);

    const close = useCallback(() => {
        if (isLesson) {
            onClose(success, jumpPath);
        } else {
            onClose(success, null);
        }
    }, [success, onClose, isLesson, jumpPath]);

    useEffect(() => {
        if (counter > 0) {
            setTimeout(() => setCounter(counter - 1), 1000);
        }
        if (counter === 0) {
            close();
        }
    }, [counter, close]);

    const buy = useCallback(async () => {
        setSubmitting(true);
        const { lesson } = await remote.$post(
            isLesson
                ? `/course/lessons/${uuid}/buy/confirm`
                : `/course/${uuid}/buy/confirm`,
            { price, useCredit: typeOfPayment === PAYMENT_TYPE_CREDIT },
            { throwException: 'showMessage' }
        );
        setSubmitting(false);
        setSuccess(true);
        if (isLesson && lesson) {
            let minOrderItem = null;
            let minOrder = null;
            lesson.videos.forEach(item => {
                if (minOrder === null || item.order < minOrder) {
                    minOrder = item.order;
                    minOrderItem = { type: 'video', ...item };
                }
            });
            lesson.codes.forEach(item => {
                if (minOrder === null || item.order < minOrder) {
                    minOrder = item.order;
                    minOrderItem = { type: 'code', ...item };
                }
            });
            lesson.quizzes.forEach(item => {
                if (minOrder === null || item.order < minOrder) {
                    minOrder = item.order;
                    minOrderItem = { type: 'quiz', ...item };
                }
            });
            lesson.jupyters.forEach(item => {
                if (minOrder === null || item.order < minOrder) {
                    minOrder = item.order;
                    minOrderItem = { type: 'jupyter', ...item };
                }
            });
            setJumpPath(
                `/course/${courseUuid}/${minOrderItem.type}/${minOrderItem.uuid}`
            );
        }
    }, [uuid, price, isLesson, typeOfPayment, courseUuid]);

    const onRechargeClose = useCallback(
        success => {
            if (success) {
                fetch();
            }
            setRechargeVisible(false);
        },
        [fetch]
    );

    const rechargeAndBuy = () => {
        setChargeAndBuy(true);
        setRechargeVisible(true);
    };

    const onCharged = () => {
        if (chargeAndBuy) {
            buy();
        }
    };

    return (
        <>
            <Modal
                title={isLesson ? '知识点购买' : '购买全部知识点'}
                visible={visible}
                footer={null}
                onCancel={close}
                width={640}
            >
                {forSell ? (
                    <Spin spinning={loading}>
                        {error && <Result status="error" title={error} />}
                        {!error && success && (
                            <Result
                                className="buy-course-result"
                                status="success"
                                title="购买成功"
                                extra={
                                    <div className="result-jump">
                                        {counter}秒后自动跳转
                                    </div>
                                }
                            />
                        )}
                        {!error && !success && (
                            <div className="ui-buy-course">
                                {price ? (
                                    <>
                                        {isLesson ? (
                                            <div className="title-bar">
                                                <div>知识点费用</div>
                                                <div className="price">
                                                    {originalPrice !== 0 && (
                                                        <div className="price-original">
                                                            {originalPrice /
                                                                100}
                                                        </div>
                                                    )}
                                                    <div>{price / 100}云朵</div>
                                                    <div className="price-or">
                                                        或
                                                    </div>
                                                    {originalPrice !== 0 && (
                                                        <div className="price-original">
                                                            {originalPrice}
                                                        </div>
                                                    )}
                                                    <div>{price}雨滴</div>
                                                </div>
                                            </div>
                                        ) : (
                                            <CoursePrice
                                                course={course}
                                                lightMode
                                            />
                                        )}
                                        <Radio.Group
                                            className="balance-bar"
                                            onChange={e =>
                                                setTypeOfPayment(e.target.value)
                                            }
                                            value={typeOfPayment}
                                        >
                                            <div>选择支付方式</div>
                                            <div className="payment-options">
                                                <Radio
                                                    className="payment-option"
                                                    value={PAYMENT_TYPE_BALANCE}
                                                >
                                                    <PaymentBalance
                                                        insufficient={
                                                            balanceInsufficient
                                                        }
                                                        value={balance}
                                                    />
                                                </Radio>
                                                <Radio
                                                    className="payment-option"
                                                    value={PAYMENT_TYPE_CREDIT}
                                                >
                                                    <PaymentCredit
                                                        insufficient={
                                                            creditInsufficient
                                                        }
                                                        value={credit}
                                                    />
                                                </Radio>
                                                <Radio
                                                    className="payment-option"
                                                    value={
                                                        PAYMENT_TYPE_RECHARGE_BUY
                                                    }
                                                >
                                                    <PaymentRechargeBuy />
                                                </Radio>
                                            </div>
                                        </Radio.Group>
                                        <div className="footer">
                                            {typeOfPayment ===
                                                PAYMENT_TYPE_BALANCE &&
                                                balanceInsufficient && (
                                                    <div className="recharge-hint">
                                                        余额不足，请充值
                                                        {!isLesson && (
                                                            <div>
                                                                你也可以点击左侧大纲中的知识点进行单个购买
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                            {typeOfPayment ===
                                                PAYMENT_TYPE_CREDIT &&
                                                creditInsufficient && (
                                                    <div className="recharge-hint">
                                                        雨滴不足，请获取雨滴
                                                        {!isLesson && (
                                                            <div>
                                                                你也可以点击左侧大纲中的知识点进行单个购买
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                            <Spin spinning={submitting}>
                                                {typeOfPayment ===
                                                    PAYMENT_TYPE_BALANCE &&
                                                    balanceInsufficient && (
                                                        <Button
                                                            className="buy"
                                                            onClick={() => {
                                                                setRechargeVisible(
                                                                    true
                                                                );
                                                                setChargeAndBuy(
                                                                    false
                                                                );
                                                            }}
                                                        >
                                                            立即充值
                                                        </Button>
                                                    )}
                                                {typeOfPayment ===
                                                    PAYMENT_TYPE_BALANCE &&
                                                    balanceInsufficient && (
                                                        <div
                                                            style={{
                                                                fontSize: 12,
                                                                marginTop: 8,
                                                            }}
                                                        >
                                                            <Fapiao>
                                                                申请发票
                                                            </Fapiao>
                                                        </div>
                                                    )}
                                                {typeOfPayment ===
                                                    PAYMENT_TYPE_CREDIT &&
                                                    creditInsufficient && (
                                                        <Button
                                                            className="buy"
                                                            onClick={() =>
                                                                setCurrencyIntroVisible(
                                                                    true
                                                                )
                                                            }
                                                        >
                                                            获取雨滴
                                                        </Button>
                                                    )}
                                                {((typeOfPayment ===
                                                    PAYMENT_TYPE_BALANCE &&
                                                    !balanceInsufficient) ||
                                                    (typeOfPayment ===
                                                        PAYMENT_TYPE_CREDIT &&
                                                        !creditInsufficient)) && (
                                                    <Button
                                                        className="buy"
                                                        onClick={buy}
                                                    >
                                                        确认购买
                                                    </Button>
                                                )}
                                                {typeOfPayment ===
                                                    PAYMENT_TYPE_RECHARGE_BUY && (
                                                    <Button
                                                        className="buy"
                                                        onClick={rechargeAndBuy}
                                                    >
                                                        确认购买
                                                    </Button>
                                                )}
                                            </Spin>
                                        </div>
                                    </>
                                ) : null}
                            </div>
                        )}
                    </Spin>
                ) : (
                    <NotForSell />
                )}
            </Modal>
            <Recharge
                visible={rechargeVisible}
                onCancel={onRechargeClose}
                price={price}
                amount={chargeAndBuy ? price / 100 : 0}
                onPaid={onCharged}
            />
            <CurrencyIntro
                visible={currencyIntroVisible}
                onClose={() => setCurrencyIntroVisible(false)}
            />
        </>
    );
}

BuyCourse.propTypes = {
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    uuid: PropTypes.string.isRequired,
    courseUuid: PropTypes.string,
    open: PropTypes.bool, // 如果为false，则预先关闭，不请求内容
    isLesson: PropTypes.bool, // 默认为购买课程(course)，true则为购买知识点(lesson)
};
