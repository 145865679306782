import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Table, Card, Breadcrumb, Button, Space, Modal, Input } from 'antd';
import { Link } from 'react-router-dom';

import { ENTITY_NAME } from '../config';
import { createDetail, fetchList, updateDetail } from '../services';
import { ADMIN_ROOT_PATH } from '../../config';
import { useAntdTableSearch } from '../../hooks/useAntdTableSearch';

const SuperAdminList = () => {
    const newTagRef = useRef('');
    const editTagRef = useRef('');
    const [loading, setLoading] = useState(true);
    const [list, setList] = useState([]);
    const refreshList = useCallback(() => {
        setLoading(true);
        fetchList().then(data => {
            setList(data);
            setLoading(false);
        });
    }, []);
    useEffect(() => {
        refreshList();
    }, [refreshList]);

    const titleSearchProps = useAntdTableSearch('title');

    return (
        <>
            <Breadcrumb style={{ marginBottom: 16 }}>
                <Breadcrumb.Item>
                    <Link to={ADMIN_ROOT_PATH}>管理后台</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>{ENTITY_NAME}列表</Breadcrumb.Item>
            </Breadcrumb>
            <Card>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: 16,
                    }}
                >
                    <Space>
                        <div />
                    </Space>
                    <Space>
                        <Button
                            type="primary"
                            onClick={() =>
                                Modal.confirm({
                                    title: `添加${ENTITY_NAME}`,
                                    content: (
                                        <Input
                                            placeholder={`请输入${ENTITY_NAME}名称`}
                                            onChange={e =>
                                                (newTagRef.current =
                                                    e.target.value)
                                            }
                                        />
                                    ),
                                    onOk: () => {
                                        createDetail({
                                            title: newTagRef.current,
                                        }).then(() => {
                                            refreshList();
                                            newTagRef.current = '';
                                        });
                                    },
                                })
                            }
                        >
                            添加{ENTITY_NAME}
                        </Button>
                    </Space>
                </div>
                <Table
                    rowKey="id"
                    dataSource={list}
                    loading={loading}
                    pagination={{
                        pageSize: 9,
                        showQuickJumper: true,
                        showSizeChanger: false,
                    }}
                    columns={[
                        { title: 'ID', key: 'id', dataIndex: 'id' },
                        {
                            title: '标签名称',
                            key: 'id',
                            dataIndex: 'title',
                            sorter: (a, b) => {
                                if (a.title < b.title) {
                                    return -1;
                                }
                                if (a.title > b.title) {
                                    return 1;
                                }
                                return 0;
                            },
                            ...titleSearchProps,
                        },
                        {
                            title: '操作',
                            key: 'id',
                            render: (_, record) => {
                                return (
                                    <div>
                                        <Button
                                            type="link"
                                            style={{ paddingLeft: 0 }}
                                            onClick={() => {
                                                Modal.confirm({
                                                    title: `修改${ENTITY_NAME}`,
                                                    content: (
                                                        <Input
                                                            placeholder={`请输入${ENTITY_NAME}名称`}
                                                            defaultValue={
                                                                record.title
                                                            }
                                                            onChange={e =>
                                                                (editTagRef.current =
                                                                    e.target.value)
                                                            }
                                                        />
                                                    ),
                                                    onOk: () => {
                                                        updateDetail(
                                                            record.id,
                                                            {
                                                                title:
                                                                    editTagRef.current,
                                                            }
                                                        ).then(() => {
                                                            refreshList();
                                                            editTagRef.current =
                                                                '';
                                                        });
                                                    },
                                                });
                                            }}
                                        >
                                            编辑
                                        </Button>
                                    </div>
                                );
                            },
                        },
                    ]}
                />
            </Card>
        </>
    );
};

export default SuperAdminList;
