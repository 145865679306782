import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'antd';
import Trinket from './trinket';
import Markdown from '../../components/markdown';
import './code-page-card.less';
import { UploadOutlined, DownOutlined } from '@ant-design/icons';

export default class CodePageCard extends Component {
    static propTypes = {
        title: PropTypes.string,
        codeTitle: PropTypes.string.isRequired,
        trinket: PropTypes.string.isRequired,
        onCollapseChange: PropTypes.func,
        question: PropTypes.string,
        answer: PropTypes.string,
    };
    static defaultProps = {
        title: '',
        learned: false,
        onCollapseChange: () => {},
        question: null,
        answer: null,
    };
    state = { collapsed: true };
    toggleCollapse = () => {
        const value = !this.state.collapsed;
        this.setState({ collapsed: value });
        this.props.onCollapseChange(value);
    };
    render() {
        const { title, codeTitle, trinket, question, answer } = this.props;
        return (
            <div className="ui-code-page-card">
                <div className="title" onClick={this.toggleCollapse}>
                    <div className="title-main">{title}</div>
                    {this.state.collapsed ? (
                        <DownOutlined />
                    ) : (
                        <UploadOutlined />
                    )}
                </div>
                {!this.state.collapsed && (
                    <>
                        <div className="subtitle">题干</div>
                        <div className="card-markdown">
                            <Markdown content={codeTitle} />
                        </div>
                        <Trinket hash={trinket} />
                        {question && (
                            <>
                                <div className="subtitle">思考题</div>
                                <div className="card-markdown">
                                    <Markdown content={question} />
                                </div>
                                <Collapse>
                                    <Collapse.Panel header="思考题答案" key="1">
                                        <Markdown content={answer} />
                                    </Collapse.Panel>
                                </Collapse>
                            </>
                        )}
                    </>
                )}
            </div>
        );
    }
}
