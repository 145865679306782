import React, { useEffect } from 'react';
import { Form, Input, Modal, InputNumber } from 'antd';

const formItemLayout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 20 },
};

const EventFormModal = ({
    title,
    visible,
    onOk,
    onCancel,
    initialValues,
    formItems,
}) => {
    const [form] = Form.useForm();

    // 初值
    useEffect(() => {
        if (initialValues) {
            form.resetFields();
            form.setFieldsValue(initialValues);
        }
    }, [initialValues, form]);

    return (
        <Modal
            title={title}
            visible={visible}
            width={800}
            onCancel={onCancel}
            cancelText={'取消'}
            onOk={() =>
                form
                    .validateFields()
                    .then(onOk)
                    .catch(err => {
                        console.log(err);
                    })
            }
            okText={'确认'}
        >
            <Form form={form} name="questionnaire" autoComplete="off">
                {formItems.map(item => (
                    <Form.Item
                        key={item.key}
                        name={item.name}
                        label={item.label}
                        {...formItemLayout}
                    >
                        {item.type === 'number' ? (
                            <InputNumber disabled={item.disabled} />
                        ) : (
                            <Input disabled={item.disabled} />
                        )}
                    </Form.Item>
                ))}
            </Form>
        </Modal>
    );
};

export default EventFormModal;
