import React, { useState, useEffect, useCallback } from 'react';
import { Modal, Spin, Result, Button } from 'antd';
import { remote } from '@boyuai/utils';
import QRCode from 'qrcode';
import './index.less';
import { isMobile } from 'src/utils';

const IButton =
    'https://staticcdn.boyuai.com/materials/2019/12/01/1tfI-UxUH8z9dqISKWU-e.jpg';
const I1 =
    'https://staticcdn.boyuai.com/materials/2019/12/01/5L9hRnYjtnIZBrE8QN0pR.jpg';
const I2 =
    'https://staticcdn.boyuai.com/materials/2019/12/01/OE3bpY6lpGaIb4abfWdP7.jpg';

export default function Referral() {
    const [visible, setVisible] = useState(false);
    const [status, setStatus] = useState(0);
    const [qrcode, setQrcode] = useState(null);
    const fetch = useCallback(async () => {
        const lessons = await remote.$post('/course/update-public-data');
        if (!lessons) {
            return setStatus(2);
        }
        await remote.$get('/user/public/referral');
        const publicToken = await remote.$get('/user/public/token');
        const site = process.env.REACT_APP_ENV === 'production' ? '' : '.dev';
        const image = await QRCode.toDataURL(
            `https://share${site}.boyuai.com/register/${publicToken}`
        );
        setQrcode(image);
        setStatus(1);
    }, []);
    useEffect(() => {
        if (visible) {
            fetch();
        }
    }, [visible, fetch]);
    const goToBuy = useCallback(
        () =>
            (window.location.href = `${process.env.REACT_APP_SUB_DIRECTORY}/course/5ICEBwpbHVwwnK3C/lesson/iHK7RYth6W0ImIgwsJ0cd`),
        []
    );
    if (isMobile()) return null;
    return (
        <div className="ui-referral">
            <div onClick={() => setVisible(true)}>
                <img
                    className="number"
                    src="https://staticcdn.boyuai.com/materials/2020/04/12/-MZvedzB4XrCB98cieXdl.png!png"
                    width="74px"
                    height="74px"
                    alt="1200"
                />
                {/*<img className="number" src="https://staticcdn.boyuai.com/materials/2020/01/23/ZDZGY4PV08hEjhzYlrnvN.png" width="74px" height="74px" alt="2000" />*/}
                <img src={IButton} alt="邀请注册" width="120px" height="44px" />
                <img className="i1" src={I1} alt="" width="9px" height="12px" />
                <img className="i2" src={I2} alt="" width="6px" height="7px" />
            </div>
            <Modal
                visible={visible}
                onCancel={() => setVisible(false)}
                footer={null}
                width="579px"
            >
                <div className="ui-referral-modal">
                    <Spin spinning={status === 0}>
                        <img
                            className="background"
                            src="https://staticcdn.boyuai.com/materials/2019/12/01/MNVpZvmAaQtYMhxXX3HUM.jpg"
                            alt=""
                        />
                        {status === 2 ? (
                            <Result
                                title="请先解锁知识点"
                                subTitle="点击课程大纲中的知识点即可购买"
                                extra={
                                    <Button type="primary" onClick={goToBuy}>
                                        去试试吧
                                    </Button>
                                }
                                status="error"
                                style={{ paddingTop: '140px' }}
                            />
                        ) : (
                            <div className="content">
                                <div className="title">
                                    <div
                                        style={{
                                            fontSize: 24,
                                            marginBottom: 8,
                                        }}
                                    >
                                        邀请好友赚雨滴，解锁更多知识点
                                    </div>
                                    <div style={{ fontSize: 16 }}>
                                        扫码查收你的学习成就海报，分享给好友吧
                                    </div>
                                    {/*查收你的学习成就海报，邀请好友赚雨滴*/}
                                </div>
                                <img src={qrcode} alt="二维码" />
                                <div className="hint">请使用微信扫描二维码</div>
                            </div>
                        )}
                    </Spin>
                </div>
            </Modal>
        </div>
    );
}
