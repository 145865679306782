import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Tabs } from 'antd';
import Comment from '@boyuai/comment';
import { UserContextModule } from '@boyuai/utils';
import FuwuhaoBindModal from '@boyuai/fuwuhao-bind-modal';
import { isMobile } from 'src/utils';
import AuthorIntro from '../../components/author-intro';
import Markdown from '../../components/markdown';

import './info-tab.less';

const { TabPane } = Tabs;

export default class InfoTab extends Component {
    state = {
        enableBindModal: false,
    };
    static propTypes = {
        videoDetails: PropTypes.object.isRequired,
        uuid: PropTypes.string.isRequired,
        commentUuid: PropTypes.string.isRequired,
        onCommentsFetch: PropTypes.func,
        getTypeChangeRef: PropTypes.func,
    };
    static contextType = UserContextModule.UserContext;

    componentWillUnmount() {
        clearTimeout(this.timer);
    }

    render() {
        if (isMobile()) return null;
        return (
            <div
                className="video-info-tabs"
                data-video-empty={!this.props.videoDetails.vid}
            >
                <Tabs defaultActiveKey="comment">{this.renderTabPane()}</Tabs>
            </div>
        );
    }

    renderTabPane() {
        const tabInfoArr = [
            {
                key: 'comment',
                title: '讨论区',
                func: () => this.renderComment(),
            },
            {
                key: 'desc',
                title: '相关资源',
                func: () => this.renderVideoDesc(),
            },
            {
                key: 'author',
                title: '作者介绍',
                func: () => this.renderAuthorIntro(),
            },
            // { key: 'feedback', title: '评价与反馈', func: () => this.renderFeedback() },
        ];
        const { videoDetails } = this.props;
        return tabInfoArr.map(info => {
            const { key, title, func } = info;
            if (videoDetails[key] || key === 'comment') {
                return (
                    <TabPane tab={title} key={key}>
                        {func()}
                    </TabPane>
                );
            }
            return null;
        });
    }

    renderVideoDesc() {
        const { desc, title } = this.props.videoDetails;
        return (
            <div className="desc-wrapper">
                <div className="title">{title}</div>
                <Markdown content={desc} />
            </div>
        );
    }

    renderAuthorIntro() {
        const { author } = this.props.videoDetails;
        return (
            <div className="author-wrapper">
                {author && <AuthorIntro author={author} />}
            </div>
        );
    }

    renderFeedback() {
        return null;
    }

    onCommentSubmit = async comment => {
        if (comment.type === 'question') {
            this.setState({ enableBindModal: true });
        }
    };

    onCloseModal = () => {
        this.setState({
            enableBindModal: false,
        });
    };

    renderComment() {
        const { enableBindModal } = this.state;
        return (
            <div className="comment-wrapper">
                <Comment
                    commentKey={this.props.uuid}
                    routePrefix={`course/${this.props.commentUuid}/video`}
                    onFetch={this.props.onCommentsFetch}
                    onSubmit={this.onCommentSubmit}
                    getTypeChangeRef={this.props.getTypeChangeRef}
                    user={this.context.user}
                />
                {enableBindModal && (
                    <FuwuhaoBindModal
                        userId={this.context.user.id}
                        text={'及时查收提问解答'}
                        ignoreStorage={true}
                        onCancel={() =>
                            this.setState({ enableBindModal: false })
                        }
                    />
                )}
            </div>
        );
    }
}
