import React from 'react';
import Quiz from '@boyuai/quiz';
import { ArrowLeftOutlined } from '@ant-design/icons';
import './index.less';

// course平台通用的quiz组件，在@buyuai/quiz的基础上包装了一下
export default function QuizInCourse(props) {
    const {
        title,
        questions,
        values,
        lock,
        onSubmit,
        onGoBack,
        quizUpdated, // 问题有更新，需要重新提交
    } = props;
    return (
        <div className="ui-course-quiz">
            <div className="nav-bar" onClick={onGoBack}>
                <h2>
                    <ArrowLeftOutlined />
                    {title}
                </h2>
            </div>
            <div className={'quiz-body'}>
                <Quiz
                    disableLocalCheck={true} // 避免点提交的时候在刷新前显示答案
                    questions={questions}
                    values={values}
                    lock={lock}
                    onSubmit={onSubmit}
                    allowResubmit={quizUpdated}
                />
            </div>
        </div>
    );
}
