import { LinkOutlined, WechatOutlined } from '@ant-design/icons';
import { Popover } from 'antd';
import React from 'react';
import './index.less';

export default function Footer() {
    return (
        <div className="footer-wrapper">
            <div className="footer">
                <img
                    className="logo"
                    alt="logo"
                    src="https://staticcdn.boyuai.com/materials/2020/11/13/olSukwuPlpx-3fP_UZ9Qs.svg"
                />
                <div className="contacts">
                    <Popover
                        overlayClassName="home-contact-popover"
                        content={
                            <>
                                <img
                                    alt="wechat"
                                    src="https://staticcdn.boyuai.com/materials/2020/11/13/NoaSayjnq4yyy5p_HQAIO.svg"
                                />
                                <div>
                                    扫描二维码
                                    <br />
                                    关注微信公众号
                                </div>
                            </>
                        }
                    >
                        <div className="contact">
                            <span>公众号</span>
                            <WechatOutlined />
                        </div>
                    </Popover>
                    <div
                        className="contact"
                        onClick={() =>
                            window.open(
                                'https://mp.weixin.qq.com/s/fPRC4u6dNtxYRRoz_Ipr5g'
                            )
                        }
                    >
                        <span>加入我们</span>
                        <LinkOutlined />
                    </div>
                    <Popover
                        overlayClassName="home-contact-popover"
                        content={
                            <>
                                <img
                                    alt="wechat"
                                    src="https://staticcdn.boyuai.com/user-assets/6074/zePihAGUKe41gf9zifvYHz/老师二维码.png"
                                />
                                <div>
                                    洽谈商务合作
                                    <br />
                                    微信联系江老师
                                </div>
                            </>
                        }
                    >
                        <div className="contact">
                            <span>商务合作</span>
                            <WechatOutlined />
                        </div>
                    </Popover>
                    <Popover
                        overlayClassName="home-contact-popover"
                        content={
                            <>
                                <img
                                    alt="wechat"
                                    src="https://staticcdn.boyuai.com/user-assets/6074/zePihAGUKe41gf9zifvYHz/老师二维码.png"
                                />
                                <div>
                                    组织采购
                                    <br />
                                    微信联系江老师
                                </div>
                            </>
                        }
                    >
                        <div className="contact">
                            <span>组织采购</span>
                            <WechatOutlined />
                        </div>
                    </Popover>
                </div>

                <div className="copyright">
                    ©{new Date().getFullYear()} boyuai.com 版权所有
                    <a
                        href="https://beian.miit.gov.cn/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        沪ICP备18044415号
                    </a>
                    <a
                        href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011202011260"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        沪公网安备31011202011260号
                    </a>
                    <a
                        href="https://beian.miit.gov.cn/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        沪B2-20200532
                    </a>
                    <a
                        href="https://xyt.xcc.cn/getpcInfo?sn=1838439586334703616&language=CN&certType=8&url=*.boyuai.com"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                            position: 'relative',
                            display: 'inline-block',
                            height: 38,
                        }}
                    >
                        <div
                            style={{
                                width: '100%',
                                height: '100%',
                                position: 'absolute',
                                top: 0,
                                left: 0,
                            }}
                        ></div>
                        <embed
                            src="https://program.xinchacha.com/web/1838439586334703616=*.boyuai.com.svg"
                            width="103"
                            height="38"
                            type="image/svg+xml"
                            pluginspage="//www.adobe.com/svg/viewer/install/"
                        />
                    </a>
                </div>
            </div>
        </div>
    );
}
