import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { remote } from '@boyuai/utils';
import Card from '../../components/card';
import CodeCard from './code-page-card';
import { Skeleton, Alert } from 'antd';
import _ from 'lodash';
import './code-page.less';
import CalculateActiveLesson from './access-error';
import { CaretDownOutlined, MenuOutlined } from '@ant-design/icons';

class CodePage extends Component {
    state = {
        codeDetails: null,
        learned: [],
    };
    componentDidMount() {
        this.fetch();
    }
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            this.fetch();
        }
    }
    get courseUuid() {
        return this.props.match.params.uuid;
    }
    async fetch() {
        this.setState({ codeDetails: null, learned: [] }); // 先设置为空，避免卡了上一个视频的播放进度
        const uuid = this.props.match.params.codeuuid;
        try {
            const codeDetails = await remote.$get(
                `/course/codes/${uuid}`,
                {},
                { hideLoading: true, throwException: 'showMessage' }
            );
            this.setState({ codeDetails });
        } catch (e) {
            if (
                _.get(e, 'response.data') === '知识点尚未购买' ||
                _.get(e, 'response.data.message') === '知识点尚未购买'
            ) {
                const { lessons } = await remote.$get(
                    `/course/${this.courseUuid}`,
                    {},
                    { hideLoading: true }
                );
                const lessonUuid = lessons[CalculateActiveLesson()].lesson.uuid;
                window.location.href = `${process.env.REACT_APP_SUB_DIRECTORY}/course/${this.courseUuid}/lesson/${lessonUuid}`;
            }
        }
    }
    get learned() {
        const { codeDetails } = this.state;
        return !!(codeDetails && codeDetails.learningLog);
    }
    get groups() {
        const { codeDetails } = this.state;
        if (!codeDetails.content) {
            return [];
        }
        const groups = [];
        codeDetails.content.forEach(item => {
            if (item.type === 'trinket') {
                groups[groups.length - 1].code = item.value;
            } else if (item.type === 'question') {
                groups[groups.length - 1].question = item.value;
            } else if (item.type === 'answer') {
                groups[groups.length - 1].answer = item.value;
            } else {
                groups.push({
                    title: item.value,
                });
            }
        });
        return groups;
    }
    onCollapseChange = (index, value) => {
        if (this.learned) {
            return;
        }
        const learned = this.state.learned;
        if (!value) {
            if (!learned.includes(index)) {
                this.setState({ learned: [...learned, index] });
                if (learned.length === this.groups.length - 1) {
                    const uuid = this.props.match.params.codeuuid;
                    remote.isLogin() &&
                        remote.$post(
                            `/course/learningLog`,
                            { uuid },
                            { hideLoading: true }
                        );
                }
            }
        }
    };
    render() {
        const { codeDetails } = this.state;
        const { title, content, author } = codeDetails || {};
        return (
            <Skeleton loading={!codeDetails}>
                <div className="code-page">
                    <Card
                        title={title}
                        authors={[author]}
                        isShowCourseTitle={true}
                    >
                        <Alert
                            message={
                                <div>
                                    点击
                                    <CaretDownOutlined />
                                    Run运行代码，
                                    点击Instructions可查看参考代码， 点击左上角{' '}
                                    <MenuOutlined /> 中的Reset可重置代码
                                </div>
                            }
                            type="info"
                            showIcon
                            style={{
                                marginBottom: '16px',
                                display: 'inline-block',
                            }}
                        />
                    </Card>
                    {content && this.renderContent()}
                </div>
            </Skeleton>
        );
    }
    renderContent() {
        return this.groups.map((item, index) => (
            <CodeCard
                key={index}
                title={`第${index + 1}题`}
                codeTitle={item.title}
                trinket={item.code}
                question={item.question}
                answer={item.answer}
                onCollapseChange={e => this.onCollapseChange(index, e)}
            />
        ));
    }
}

export default withRouter(CodePage);
