import React, {
    useState,
    useEffect,
    useCallback,
    useMemo,
    useContext,
} from 'react';
import { Modal, Spin, Popover } from 'antd';
import { UserContextModule } from '@boyuai/utils';
import { useQrcode } from '@boyuai/qrcode';
import { remote } from '@boyuai/utils';
import Guide from './guide';
import Intro from './intro';
import './index.less';

export default function QianrenEnds({ activity }) {
    const [modalVisible, setModalVisible] = useState(false);
    const [introVisible, setIntroVisible] = useState(false);
    const [creditGot, setCreditGot] = useState(false);
    const [token, setToken] = useState(null);
    const [localGuide, setLocalGuide] = useState(0);
    const site = process.env.REACT_APP_ENV === 'production' ? '' : '.dev';
    const certImage = useQrcode(
        token && `https://share${site}.boyuai.com/qianren-cert/${token}`,
        { width: '100px', height: '100px' }
    );
    const serviceImage = (
        <img
            src="https://staticcdn.boyuai.com/materials/2020/02/24/cYS9qDrUfDx2trvAUVzl-.jpg!jpg"
            alt="二维码"
            width="100px"
        />
    );
    const tagsImage = useQrcode(
        token && `https://share${site}.boyuai.com/qianren-tags/${token}`,
        { width: '100px', height: '100px' }
    );
    const context = useContext(UserContextModule.UserContext);
    useEffect(() => {
        if (!activity.content.poped) {
            // 生成一份自己的
            remote.$patch(
                `/course/activities/${activity.id}/content`,
                { content: { poped: true } },
                { hideLoading: true }
            );
            // 首次强制弹窗
            setModalVisible(true);
        }
    }, [activity]);
    useEffect(() => {
        remote.$get('/user/public/token').then(setToken);
    }, []);

    const updateGuide = useCallback(
        value => {
            const guide = (localGuide || activity.content.guide) | value;
            setLocalGuide(guide);
            remote.$patch(
                `/course/activities/${activity.id}/content`,
                { content: { guide } },
                { hideLoading: true }
            );
        },
        [activity, localGuide]
    );

    const getCredit = useCallback(async () => {
        setCreditGot(true);
        updateGuide(0b1);
        setIntroVisible(true);
        setModalVisible(false);
        await remote.$patch(
            `/course/activities/${activity.id}/state`,
            { state: 1 },
            { hideLoading: true }
        );
        const { value, credit } = await remote.$get('/wallet');
        context.updateBalance && context.updateBalance(Number(value));
        context.updateCredit && context.updateCredit(Number(credit));
    }, [activity, context, updateGuide]);
    const canGetCredit = !activity.state && !creditGot;

    const guideLeft = useMemo(() => {
        const guide = localGuide || activity.content.guide || 0;
        if ((guide & 0b001) === 0) {
            return 300;
        }
        if ((guide & 0b010) === 0) {
            return 110;
        }
        if ((guide & 0b100) === 0) {
            return 380;
        }
        return null;
    }, [activity, localGuide]);
    const guideTop = useMemo(() => {
        const guide = localGuide || activity.content.guide || 0;
        if ((guide & 0b001) === 0) {
            return 300;
        }
        if ((guide & 0b010) === 0) {
            return 400;
        }
        if ((guide & 0b100) === 0) {
            return 400;
        }
        return null;
    }, [activity, localGuide]);
    const showIntro = useCallback(() => {
        updateGuide(0b001);
        setModalVisible(false);
        setIntroVisible(true);
    }, [updateGuide]);
    return (
        <div className="activity-qianren-ends">
            <div className="float-button" onClick={() => setModalVisible(true)}>
                <img
                    src="https://staticcdn.boyuai.com/materials/2020/02/22/CDgk6QJMXO7ZfEoHQ8V2B.png!png"
                    alt="大礼包"
                />
            </div>
            <Modal
                visible={modalVisible}
                onCancel={() => setModalVisible(false)}
                footer={null}
                width={776}
                wrapClassName="activity-qianren-ends-modal"
                maskStyle={{ background: 'rgba(0,0,0,0.6)' }}
                closable={false}
            >
                <div className="activity-qianren-ends-modal-main">
                    <div className="background-bg" />
                    <div className="background" />
                    <img
                        onClick={() => setModalVisible(false)}
                        className="close-button"
                        src="https://staticcdn.boyuai.com/materials/2020/02/24/MPk_uBjcortZgs-EYMRKM.png!png"
                        alt="关闭"
                    />
                    <div className="main-text">
                        恭喜你{activity.content.isGroup ? '和你的小伙伴' : ''}
                        ，完成了14天挑战《动手学深度学习》的学习任务，获得{' '}
                        {activity.content.credit} 雨滴完课奖励！
                    </div>
                    {activity.content.hbCredit ? (
                        <div className="hubei-text">
                            湖北学员特别福利：
                            <br />
                            {activity.content.hbCredit} 雨滴奖励
                        </div>
                    ) : (
                        <div className="hubei-text-mask" />
                    )}
                    <Popover
                        onClick={() => updateGuide(0b10)}
                        trigger="click"
                        content={
                            <Spin spinning={!token}>
                                <div className="activity-qianren-ends-modal-qrcodes">
                                    <div style={{ marginRight: '50px' }}>
                                        {certImage}
                                        <div>微信扫码查看</div>
                                    </div>
                                    <div>
                                        {serviceImage}
                                        <div>获取实体证书</div>
                                    </div>
                                </div>
                            </Spin>
                        }
                    >
                        <div className="get-cert">
                            <img
                                src="https://staticcdn.boyuai.com/materials/2020/02/23/B8hzTXZbothYebYr0lz-6.png!png"
                                alt=""
                            />
                        </div>
                    </Popover>
                    <Popover
                        onClick={() => updateGuide(0b100)}
                        trigger="click"
                        content={
                            <Spin spinning={!token}>
                                <div className="activity-qianren-ends-modal-qrcodes">
                                    {tagsImage}
                                </div>
                            </Spin>
                        }
                    >
                        <div className="view-tags">
                            <img
                                src="https://staticcdn.boyuai.com/materials/2020/02/23/V2samFjaNqtyYsIUdS22T.png!png"
                                alt=""
                            />
                        </div>
                    </Popover>
                    {canGetCredit && (
                        <div className="get-credit" onClick={getCredit}>
                            <img
                                src="https://staticcdn.boyuai.com/materials/2020/02/23/F0ZnHB_uB0mjGFEp20y1f.png!png"
                                alt=""
                            />
                        </div>
                    )}
                    {!canGetCredit && (
                        <div className="get-credit" onClick={showIntro}>
                            <img
                                src="https://staticcdn.boyuai.com/materials/2020/02/23/TB-39azRSAiK22g3DnLl7.png!png"
                                alt=""
                            />
                        </div>
                    )}
                    {guideTop && (
                        <Guide top={`${guideTop}px`} left={`${guideLeft}px`} />
                    )}
                </div>
            </Modal>
            <Intro
                visible={introVisible}
                onClose={() => {
                    setIntroVisible(false);
                    setModalVisible(true);
                }}
            />
        </div>
    );
}
