import React, {
    useContext,
    useCallback,
    useState,
    useEffect,
    useRef,
} from 'react';
import { UserContextModule } from '@boyuai/utils';
import { remote } from '@boyuai/utils';
import { Button } from 'antd';
import { withRouter } from 'react-router-dom';
import Carousel from '@brainhubeu/react-carousel';
import MobileTip from '../../assets/images/mobile-tip.png';
import Login from '../../components/login';
import '@brainhubeu/react-carousel/lib/style.css';

import './register.less';

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

const ILogo =
    'https://staticcdn.boyuai.com/materials/2019/11/29/Aqa9bxvjrCi8Dx4_NATt3.jpg';
const IContinue =
    'https://staticcdn.boyuai.com/materials/2019/12/03/mtBES2klj945d03TaObd4.jpg';
const IBackground1 =
    'https://staticcdn.boyuai.com/materials/2019/11/29/Dih8TUfAGsMewVsGdV_-E.jpg';
const IBackground3 =
    'https://staticcdn.boyuai.com/materials/2019/11/29/0aufPA9v72rPdllapFW1r.jpg';
const IUser =
    'https://staticcdn.boyuai.com/materials/2019/11/29/Q9t3l6wyyzQbPJiFFqZKu.jpg';
const IDrop =
    'https://staticcdn.boyuai.com/materials/2019/11/29/EO-d-Ql6AJXAetuYqo5Hl.jpg';
const ISite =
    'https://staticcdn.boyuai.com/materials/2019/12/01/IvWobmpTuL2Ll3NBLE_V4.jpg';
const ILoginLogo =
    'https://staticcdn.boyuai.com/materials/2019/12/01/cFliPChSRj24udkdep0O4.jpg';
const IBackground6 =
    'https://staticcdn.boyuai.com/materials/2019/12/01/2mXtSF8rsda1UTTDHt9Zq.jpg';
const IRegisterSuccess =
    'https://staticcdn.boyuai.com/materials/2019/12/01/p6O3l0Nx_PEOJRcWTo2V9.jpg';
const IRegisterFailed =
    'https://staticcdn.boyuai.com/materials/2019/12/01/CBO4xsLOvM-oUwv7YnF8E.jpg';
const IRegisterButton =
    'https://staticcdn.boyuai.com/materials/2019/12/02/mpVNbbEGxgx1bXj_5fmfY.jpg';

function Register({ match }) {
    const { uuid } = match.params;
    const context = useContext(UserContextModule.UserContext);
    const { phone } = context.user;
    const [reward, setReward] = useState(0);
    const [finish, setFinish] = useState(false);
    const [error, setError] = useState(false);
    const registerButtonRef = useRef(null);
    const [slideWidth, setSlideWidth] = useState(300);
    const onLogout = () => {
        remote.logout();
        window.location.reload();
    };

    const onLogin = useCallback(auth => {
        localStorage.removeItem('useMobile');
        remote.login(auth.token);
        setFinish(true);
        if (auth.rewardCredit) {
            setReward(auth.rewardCredit);
        }
    }, []);

    const useMobile = useCallback(() => {
        localStorage.setItem('useMobile', true);
        window.location.href = process.env.REACT_APP_SUB_DIRECTORY || '/';
    }, []);

    const onGoToBottom = useCallback(() => {
        window.scrollTo(0, document.body.scrollHeight);
    }, []);

    const onContinue = useCallback(() => {
        window.scrollTo(0, window.innerHeight);
    }, []);

    const goToLogin = useCallback(() => {
        remote.requestLogin();
    }, []);

    useEffect(() => {
        window.addEventListener('scroll', e => {
            if (!registerButtonRef.current) {
                return;
            }
            if (
                window.scrollY >
                document.body.scrollHeight - 1.5 * window.innerHeight
            ) {
                registerButtonRef.current.style.display = 'none';
            } else {
                registerButtonRef.current.style.display = 'block';
            }
        });
    }, []);

    const adjustSlideWidth = useCallback(() => {
        const width = window.innerWidth;
        if (width <= 500 && width >= 350) {
            setSlideWidth(260);
        } else if (width < 350) {
            setSlideWidth(230);
        } else {
            setSlideWidth(400);
        }
    }, []);

    useEffect(() => {
        adjustSlideWidth();
        window.addEventListener('resize', adjustSlideWidth);
        return () => window.removeEventListener('resize', adjustSlideWidth);
    }, [adjustSlideWidth]);

    useEffect(() => {
        if (!uuid) {
            setError(true);
        } else {
            remote.$get(`/user/referral/${uuid}`).then(result => {
                if (!result) {
                    setError(true);
                } else {
                    setError(false);
                }
            });
        }
    }, [uuid]);

    if (error) {
        return (
            <div
                className="page-register-result"
                style={{ backgroundImage: `url(${MobileTip})` }}
            >
                <div className="result">
                    <img
                        src={IRegisterFailed}
                        alt="雨滴"
                        width="74px"
                        height="74px"
                    />
                </div>
                <div className="text">邀请二维码已过期</div>
                <div className="text">无法获取雨滴</div>
                <div className="use-mobile" onClick={goToLogin}>
                    继续注册
                </div>
            </div>
        );
    }

    if (finish) {
        const continueContent = isMobile ? (
            <>
                <div className="text">请使用电脑端访问 boyuai.com/elites</div>
                <div className="text small">
                    Please continue with a desktop browser
                </div>
                <div className="use-mobile" onClick={useMobile}>
                    仍然使用移动端访问
                </div>
            </>
        ) : (
            <>
                <div className="go-home">
                    <a href={`${process.env.REACT_APP_SUB_DIRECTORY}/`}>
                        进入网站
                    </a>
                </div>
            </>
        );
        if (reward) {
            return (
                <div
                    className="page-register-result"
                    style={{ backgroundImage: `url(${MobileTip})` }}
                >
                    <div className="result">
                        <img
                            src={IRegisterSuccess}
                            alt="雨滴"
                            width="74px"
                            height="74px"
                        />
                    </div>
                    <div className="text">恭喜注册成功，获取了{reward}雨滴</div>
                    <div className="text">5000雨滴约可购买10个知识点</div>
                    <div className="text">
                        完善个人信息可另外获得最高3800雨滴
                    </div>
                    {continueContent}
                </div>
            );
        }
        return (
            <div
                className="page-register-result"
                style={{ backgroundImage: `url(${MobileTip})` }}
            >
                <div className="result">
                    <img
                        src={IRegisterFailed}
                        alt="雨滴"
                        width="74px"
                        height="74px"
                    />
                </div>
                <div className="text">您已经注册过啦</div>
                {continueContent}
            </div>
        );
    }

    return (
        <div className="page-register">
            <div className="main">
                <div className="section section1">
                    <img src={IBackground1} alt="bg" className="background" />
                    <div className="content">
                        <div className="logo">
                            <img src={ILogo} alt="伯禹人工智能学院" />
                        </div>
                        <div className="space" />
                        <h1>伯禹人工智能学院</h1>
                        <h2>打造AI领域人才教育与交流平台</h2>
                        <h2>——深度、权威、个性化</h2>
                        <div className="continue" onClick={onContinue}>
                            <img src={IContinue} alt="继续" />
                        </div>
                    </div>
                </div>
                <div className="section section2">
                    <h2>
                        <span>深度</span>-交大ACM班理论体系
                    </h2>
                    <Carousel
                        clickToChange
                        slidesPerPage={2}
                        centered
                        infinite
                        itemWidth={slideWidth}
                        keepDirectionWhenDragging
                    >
                        <div className="box">
                            <img
                                src="https://staticcdn.boyuai.com/materials/2019/12/04/Eoeigzdua3yAOdI_3-bdH.jpg"
                                alt="shadow"
                                className="shadow"
                            />
                            <img
                                src="https://staticcdn.boyuai.com/materials/2019/12/03/MTbYpNStmdVfR0k7v7N8Z.jpg"
                                alt=""
                            />
                        </div>
                        <div className="box">
                            <img
                                src="https://staticcdn.boyuai.com/materials/2019/12/04/Eoeigzdua3yAOdI_3-bdH.jpg"
                                alt="shadow"
                                className="shadow"
                            />
                            <img
                                src="https://staticcdn.boyuai.com/materials/2019/12/04/UC1xkTOUhMy6Yk3GlCQOB.jpg"
                                alt=""
                            />
                        </div>
                        <div className="box">
                            <img
                                src="https://staticcdn.boyuai.com/materials/2019/12/04/Eoeigzdua3yAOdI_3-bdH.jpg"
                                alt="shadow"
                                className="shadow"
                            />
                            <img
                                src="https://staticcdn.boyuai.com/materials/2019/12/04/kFwway6wwgQdnaC1nrn7f.jpg"
                                alt=""
                            />
                        </div>
                        <div className="box">
                            <img
                                src="https://staticcdn.boyuai.com/materials/2019/12/04/Eoeigzdua3yAOdI_3-bdH.jpg"
                                alt="shadow"
                                className="shadow"
                            />
                            <img
                                src="https://staticcdn.boyuai.com/materials/2019/12/04/fiLIe3KcMzDhY6O89mvFR.jpg"
                                alt=""
                            />
                        </div>
                        <div className="box">
                            <img
                                src="https://staticcdn.boyuai.com/materials/2019/12/04/Eoeigzdua3yAOdI_3-bdH.jpg"
                                alt="shadow"
                                className="shadow"
                            />
                            <img
                                src="https://staticcdn.boyuai.com/materials/2019/12/04/zvCIFJ8KRb2TTgeV8isGj.jpg"
                                alt=""
                            />
                        </div>
                        <div className="box">
                            <img
                                src="https://staticcdn.boyuai.com/materials/2019/12/04/Eoeigzdua3yAOdI_3-bdH.jpg"
                                alt="shadow"
                                className="shadow"
                            />
                            <img
                                src="https://staticcdn.boyuai.com/materials/2019/12/04/btEbqQhXaWtNCkzJWkTrh.jpg"
                                alt=""
                            />
                        </div>
                        <div className="box">
                            <img
                                src="https://staticcdn.boyuai.com/materials/2019/12/04/Eoeigzdua3yAOdI_3-bdH.jpg"
                                alt="shadow"
                                className="shadow"
                            />
                            <img
                                src="https://staticcdn.boyuai.com/materials/2019/12/04/arkTARjRw-qMXBZ67pEdP.jpg"
                                alt=""
                            />
                        </div>
                    </Carousel>
                </div>
                <div className="section section3">
                    <img src={IBackground3} alt="bg" className="background" />
                    <div className="content">
                        <h2>
                            <span>权威</span>-高校科研团队课程打造
                        </h2>
                        <Carousel
                            clickToChange
                            slidesPerPage={2}
                            centered
                            infinite
                            itemWidth={slideWidth}
                            keepDirectionWhenDragging
                        >
                            <div className="box">
                                <img
                                    src="https://staticcdn.boyuai.com/materials/2019/12/04/b7YrRvTYjyUkFmiFBheE_.jpg"
                                    alt="shadow"
                                    style={{
                                        bottom: '-21px',
                                        top: 'unset',
                                        left: '2px',
                                    }}
                                    className="shadow"
                                />
                                <img
                                    src="https://staticcdn.boyuai.com/materials/2019/12/03/Zp1_Yt7neV1XT0RsS3Q1P.jpg"
                                    alt="俞勇"
                                />
                            </div>
                            <div className="box">
                                <img
                                    src="https://staticcdn.boyuai.com/materials/2019/12/04/b7YrRvTYjyUkFmiFBheE_.jpg"
                                    alt="shadow"
                                    style={{
                                        bottom: '-21px',
                                        top: 'unset',
                                        left: '2px',
                                    }}
                                    className="shadow"
                                />
                                <img
                                    src="https://staticcdn.boyuai.com/materials/2019/12/03/sWbXKTT7jkLik09q578wT.jpg"
                                    alt="张伟楠"
                                />
                            </div>
                            <div className="box">
                                <img
                                    src="https://staticcdn.boyuai.com/materials/2019/12/04/b7YrRvTYjyUkFmiFBheE_.jpg"
                                    alt="shadow"
                                    style={{
                                        bottom: '-21px',
                                        top: 'unset',
                                        left: '2px',
                                    }}
                                    className="shadow"
                                />
                                <img
                                    src="https://staticcdn.boyuai.com/materials/2019/12/03/QvKx38bTuoFSY337yR5WA.jpg"
                                    alt="严骏驰"
                                />
                            </div>
                            <div className="box">
                                <img
                                    src="https://staticcdn.boyuai.com/materials/2019/12/04/b7YrRvTYjyUkFmiFBheE_.jpg"
                                    alt="shadow"
                                    style={{
                                        bottom: '-21px',
                                        top: 'unset',
                                        left: '2px',
                                    }}
                                    className="shadow"
                                />
                                <img
                                    src="https://staticcdn.boyuai.com/materials/2019/12/04/_MODmgzSIC87JrPiZDbD1.jpg"
                                    alt="屠可伟"
                                />
                            </div>
                        </Carousel>
                    </div>
                </div>
                <div className="section section3-1">
                    <div className="content">
                        <h2>
                            <span>个性化</span>-打破课程固定路径
                        </h2>
                        <Carousel
                            clickToChange
                            slidesPerPage={2}
                            centered
                            infinite
                            itemWidth={slideWidth}
                            keepDirectionWhenDragging
                        >
                            <div className="box">
                                <img
                                    src="https://staticcdn.boyuai.com/materials/2019/12/04/ixTD9Ht4wL8s16xy60sw8.jpg"
                                    alt="shadow"
                                    className="shadow"
                                />
                                <img
                                    src="https://staticcdn.boyuai.com/materials/2019/12/03/NJKGwYOS08cXVQ1d_9sPq.jpg"
                                    alt="1"
                                />
                            </div>
                            <div className="box">
                                <img
                                    src="https://staticcdn.boyuai.com/materials/2019/12/04/ixTD9Ht4wL8s16xy60sw8.jpg"
                                    alt="shadow"
                                    className="shadow"
                                />
                                <img
                                    src="https://staticcdn.boyuai.com/materials/2019/12/03/5sURTdYdY4pwWXA4ZA9lk.jpg"
                                    alt="2"
                                />
                            </div>
                            <div className="box">
                                <img
                                    src="https://staticcdn.boyuai.com/materials/2019/12/04/ixTD9Ht4wL8s16xy60sw8.jpg"
                                    alt="shadow"
                                    className="shadow"
                                />
                                <img
                                    src="https://staticcdn.boyuai.com/materials/2019/12/03/pjk0ccPAZgEH3k4UAIMvr.jpg"
                                    alt="3"
                                />
                            </div>
                        </Carousel>
                    </div>
                </div>
                <div className="section section4">
                    <h2>内测学员点评</h2>
                    <div className="comment">
                        <div className="author">
                            <img src={IUser} alt="用户评价" />
                            <span>武大应用统计硕士</span>
                        </div>
                        <p>
                            一门课程的学习最忌讳知识散乱、不成体系。回想以前自学ML的经历，常常会陷入单个理论或者算法的钻研当中，而忽视了不同方法之间的比较和联系，颇有些“只见树木、不见森林”的无奈。楠哥的课程结构清晰明了，能够让听者快速建立完整的知识体系，在此基础上的进一步学习也就有了坚定的主线；另外，配套的代码实践和详解简直是渣渣调包侠真正精进技艺的福音。
                        </p>
                        <p>
                            值得一提的是，伯禹提供了一个供大家交流沟通的优质平台，看到其他同学的脑洞问题和理论助教的耐心解答，常感收获甚多。
                        </p>
                    </div>
                    <div className="comment">
                        <div className="author">
                            <img src={IUser} alt="用户评价" />
                            <span>量化交易从业者</span>
                        </div>
                        <p>
                            有幸参与了伯禹的AI课程内测，跟随行业顶级的专家学习受益匪浅。伯禹的课程体系十分优秀，能够直接明了的统观学科全貌，能够深入到每一种方法、算法的本质，其历史、发展过程、优劣势也讲解的清楚明了。除了课程本身，伯禹还提供了讨论区方便提问答疑，这里面的内容也很丰富，也得以从中参考其它同学的想法，有时会从不一样的角度让自己的理解精进一步。伯禹的学习氛围也很好，以知识为本，在学习的过程中能感受到同学们的学习热情，让我自身也受到了激励。
                        </p>
                    </div>
                    <div className="comment">
                        <div className="author">
                            <img src={IUser} alt="用户评价" />
                            <span>浙大ML硕士</span>
                        </div>
                        <p>
                            很高兴可以参与伯禹AI课程的内测。课程的知识脉络很清楚，相比课堂录制型的网课，可以更好的掌握概念。PPT制作以及张伟楠老师的讲课都很好，课程中也会对涉及到的公式进行推导，有助于加深理解。在课程中穿插的代码教学有助于从实践中理解理论知识，搭配的视频讲解很详细，即使是python
                            0基础也能看懂并学习ML相关库的使用。另外，在学习中碰到问题的话，也都有相应的助教可以进行答疑解惑。
                        </p>
                    </div>
                </div>
                <div className="section section5">
                    <h2>一起学邀请码特权,注册领取</h2>
                    <h2 className="drop">5000雨滴</h2>
                    <div className="drop-box">
                        <div className="left">
                            <img src={IDrop} alt="雨滴" />
                        </div>
                        <div className="right">5,000</div>
                    </div>
                    <div className="drop-desc">
                        *雨滴是伯禹教育旗下平台-“伯禹学习平台”的通用货币。
                    </div>
                </div>
                <div className="section section6">
                    <img src={IBackground6} alt="bg" className="background" />
                    <div className="login-form-wrapper">
                        <img className="logo" src={ILoginLogo} alt="LOGO" />
                        <div className="site-title">
                            <img
                                src={ISite}
                                alt="伯禹学习平台"
                                width="190px"
                                height="49px"
                            />
                        </div>
                        <div className="login-form">
                            {phone ? (
                                <div>
                                    <div className="user-phone">
                                        当前登陆账号为{phone}
                                    </div>
                                    <Button
                                        className="logout-button"
                                        onClick={onLogout}
                                        type="primary"
                                    >
                                        注销
                                    </Button>
                                </div>
                            ) : (
                                <Login.LoginForm
                                    label="注册"
                                    referralCode={uuid}
                                    onLogin={onLogin}
                                />
                            )}
                        </div>
                    </div>
                </div>
                <div
                    className="register-button"
                    onClick={onGoToBottom}
                    ref={registerButtonRef}
                >
                    <img src={IRegisterButton} alt="注册" />
                </div>
            </div>
        </div>
    );
}

export default withRouter(Register);
