import React, { useEffect, useMemo, useState } from 'react';
import {
    Button,
    Table,
    Pagination,
    DatePicker,
    Select,
    Popconfirm,
} from 'antd';
import {
    useAllUserStatistic,
    useUserStatistic,
} from '../../hooks/userStatisticHooks';
import { useAdminCourses } from '../../hooks/courseHooks';

const PAGE_SIZE = 10;
const columns = [
    {
        title: '用户名',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: '电话',
        dataIndex: 'phone',
        key: 'phone',
        render: (phone, { countryCode }) => {
            return `+${countryCode} ${phone}`;
        },
    },
    {
        title: '学习知识点',
        dataIndex: 'learnedLessons',
        key: 'learnedLessons',
        render: learnedLessons => (learnedLessons ? learnedLessons.length : 0),
    },
    {
        title: 'quiz正确率',
        dataIndex: 'accuracy',
        key: 'accuracy',
        render: accuracy =>
            accuracy[1] ? (accuracy[0] / accuracy[1]).toFixed(2) : 0,
    },
    {
        title: '笔记数',
        dataIndex: 'notes',
        key: 'notes',
        render: notes => (notes ? notes.length : 0),
    },
];

const UserStatistic = () => {
    const [page, setPage] = useState(1);
    const [userIds, setUserIds] = useState([]);
    const [names, setNames] = useState([]);
    const [phones, setPhones] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [selectedCourseIds, setSelectedCourseIds] = useState([]); // 这是id
    const skip = useMemo(() => PAGE_SIZE * (page - 1), [page]);

    // 课程列表
    const [courses] = useAdminCourses();
    // 分页数据
    const [users, count] = useUserStatistic(
        skip,
        PAGE_SIZE,
        userIds.length ? userIds : null,
        names.length ? names : null,
        phones.length ? phones : null,
        startDate ? startDate.format('YYYY-MM-DD') : null,
        endDate ? endDate.format('YYYY-MM-DD') : null,
        selectedCourseIds.length ? selectedCourseIds : null
    );
    // 全量数据，导出成表
    const [fetchAllUsers, modal] = useAllUserStatistic();

    useEffect(() => {
        if (Math.ceil(count / PAGE_SIZE) === 0) {
            setPage(1);
        } else if (Math.ceil(count / PAGE_SIZE) < page) {
            setPage(Math.ceil(count / PAGE_SIZE));
        }
    }, [page, count]);

    return (
        <div style={{ marginTop: '20px' }}>
            <h3>学习平台用户数据</h3>
            <div>
                以下三个条件按照从上到下优先级，只有一个有效
                <Select
                    placeholder={'用户id'}
                    mode="tags"
                    style={{ width: '100%' }}
                    value={userIds}
                    onChange={value => {
                        const formattedValue = value
                            .toString()
                            .replace(/\s/g, '')
                            .split(',')
                            .filter(function(item, index, arr) {
                                //当前元素，在原始数组中的第一个索引==当前索引值，否则返回当前元素
                                return arr.indexOf(item, 0) === index && item;
                            });
                        setUserIds(formattedValue.map(Number));
                    }}
                />
                <Select
                    placeholder={'用户名'}
                    mode="tags"
                    style={{ width: '100%' }}
                    value={names}
                    onChange={value => {
                        const formattedValue = value
                            .toString()
                            .replace(/\s/g, '')
                            .split(',')
                            .filter(function(item, index, arr) {
                                //当前元素，在原始数组中的第一个索引==当前索引值，否则返回当前元素
                                return arr.indexOf(item, 0) === index && item;
                            });
                        setNames(formattedValue);
                    }}
                />
                <Select
                    placeholder={'手机号'}
                    mode="tags"
                    style={{ width: '100%' }}
                    value={phones}
                    onChange={value => {
                        const formattedValue = value
                            .toString()
                            .replace(/\s/g, '')
                            .split(',')
                            .filter(function(item, index, arr) {
                                //当前元素，在原始数组中的第一个索引==当前索引值，否则返回当前元素
                                return arr.indexOf(item, 0) === index && item;
                            });
                        setPhones(formattedValue);
                    }}
                />
                {/*<AdminForm ref={saveFormRef} formItems={formItems} />*/}
                <DatePicker
                    value={startDate}
                    placeholder={'起始日期'}
                    format={'YYYY-MM-DD'}
                    onChange={m => {
                        setStartDate(m);
                    }}
                />
                &nbsp;——&nbsp;
                <DatePicker
                    value={endDate}
                    placeholder={'截止日期'}
                    format={'YYYY-MM-DD'}
                    onChange={m => {
                        setEndDate(m);
                    }}
                />
                <Select
                    value={selectedCourseIds}
                    mode={'multiple'}
                    placeholder={'查询课程（可多选）'}
                    style={{ width: '300px', marginBottom: '10px' }}
                    onChange={v => {
                        setSelectedCourseIds(v);
                    }}
                >
                    {courses.map(course => (
                        <Select.Option value={course.id} key={course.id}>
                            {course.title}
                            {course.hide ? (
                                <span
                                    style={{
                                        color: '#F0765F',
                                    }}
                                >
                                    （隐藏课程）
                                </span>
                            ) : null}
                        </Select.Option>
                    ))}
                </Select>
                <div>
                    <h4>（查询条件/日期/课程均可空，表示不作限制）</h4>
                    <div style={{ textAlign: 'right' }}>
                        <Popconfirm
                            title="确定要全量导出吗"
                            onConfirm={() =>
                                fetchAllUsers(
                                    userIds && userIds.length ? userIds : null,
                                    names && names.length ? names : null,
                                    phones && phones.length ? phones : null,
                                    startDate
                                        ? startDate.format('YYYY-MM-DD')
                                        : null,
                                    endDate
                                        ? endDate.format('YYYY-MM-DD')
                                        : null,
                                    selectedCourseIds.length
                                        ? selectedCourseIds
                                        : null
                                )
                            }
                        >
                            <Button type="primary">
                                按照当前条件全量查询并导出（大概需要5-10分钟时间）
                            </Button>
                        </Popconfirm>
                        {modal}
                    </div>
                </div>
            </div>
            <Table
                columns={columns}
                dataSource={users}
                pagination={false}
                rowKey={'id'}
            />
            <Pagination
                showQuickJumper
                defaultCurrent={1}
                current={page}
                total={count}
                pageSize={PAGE_SIZE}
                onChange={setPage}
            />
        </div>
    );
};

export default UserStatistic;
