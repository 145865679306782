import React, { useCallback, useEffect, useRef, useState } from 'react';
import { remote } from '@boyuai/utils';
import { Button, Form, Input, InputNumber, message, Select } from 'antd';
import PhoneInput from 'antd-country-phone-input';

function AdminAddCourseForUser() {
    const formRef = useRef(null);
    const [phoneList, setPhoneList] = useState([]);
    const [usernameList, setUsernameList] = useState([]);
    const [resultList, setResultList] = useState([]);
    const [courseList, setCourseList] = useState([]);
    useEffect(() => {
        (async () => {
            message.loading('加载中...', 0);
            const courseList = await remote.$get('/course');
            setCourseList(courseList?.[0] || []);
            message.destroy();
        })();
    }, []);
    const handleSubmit = useCallback(async values => {
        setResultList([]);
        const { phoneInput, usernameInput, days, tag, courseUUID } = values;
        if (!phoneInput && !usernameInput) {
            message.error('请至少填入一名用户信息');
            return;
        }
        if (phoneInput) {
            const { phone, code } = phoneInput;
            const list = phone
                .replace(/\s/g, ',')
                .split(',')
                .map(item => item.trim())
                .filter(function(item, index, arr) {
                    return arr.indexOf(item, 0) === index && item;
                });
            for (const item of list) {
                await new Promise(resolve => {
                    remote
                        .$post(
                            '/course/admin/add-course-for-user',
                            {
                                tag: (tag && tag.trim()) || null,
                                courseUUID,
                                days,
                                countryCode: code,
                                phone: item,
                            },
                            {
                                throwException: true,
                            }
                        )
                        .then(({ userId }) => {
                            message.success(`+${code} ${item}课程开通成功`);
                            setResultList(prev => [
                                ...prev,
                                {
                                    code,
                                    phone: item,
                                    userId,
                                    status: 'success',
                                },
                            ]);
                            resolve();
                        })
                        .catch(e => {
                            setResultList(prev => [
                                ...prev,
                                {
                                    code,
                                    phone: item,
                                    status: 'failure',
                                    message: e.response?.data?.message,
                                },
                            ]);
                            resolve();
                        });
                });
            }
        }
        if (usernameInput) {
            const list = usernameInput
                .replace(/\s/g, ',')
                .split(',')
                .map(item => item.trim())
                .filter(function(item, index, arr) {
                    return arr.indexOf(item, 0) === index && item;
                });
            for (const item of list) {
                await new Promise(resolve => {
                    remote
                        .$post(
                            '/course/admin/add-course-for-user',
                            {
                                tag: (tag && tag.trim()) || null,
                                courseUUID,
                                days,
                                username: item,
                            },
                            {
                                throwException: true,
                            }
                        )
                        .then(({ userId }) => {
                            message.success(`${item}课程开通成功`);
                            setResultList(prev => [
                                ...prev,
                                { username: item, userId, status: 'success' },
                            ]);
                            resolve();
                        })
                        .catch(e => {
                            setResultList(prev => [
                                ...prev,
                                {
                                    username: item,
                                    status: 'failure',
                                    message: e.response?.data?.message,
                                },
                            ]);
                            resolve();
                        });
                });
            }
        }
        formRef.current.resetFields();
    }, []);
    return (
        <div style={{ marginTop: '20px' }}>
            <Form onFinish={handleSubmit} ref={formRef}>
                <Form.Item
                    label="手机号（可通过“,”分割填写多个手机号）"
                    labelCol={{ span: 7 }}
                    wrapperCol={{ span: 12 }}
                    name="phoneInput"
                >
                    <PhoneInput
                        onChange={({ phone, code }) => {
                            setPhoneList(
                                phone
                                    ? phone
                                          .replace(/\s/g, ',')
                                          .split(',')
                                          .map(item => item.trim())
                                          .filter(function(item, index, arr) {
                                              return (
                                                  arr.indexOf(item, 0) ===
                                                      index && item
                                              );
                                          })
                                          .map(item => ({ phone: item, code }))
                                    : []
                            );
                        }}
                    />
                </Form.Item>
                <Form.Item
                    label="用户名（可通过“,”分割填写多个用户名）"
                    labelCol={{ span: 7 }}
                    wrapperCol={{ span: 12 }}
                    name="usernameInput"
                >
                    <Input
                        onChange={e => {
                            setUsernameList(
                                e.target.value
                                    .replace(/\s/g, ',')
                                    .split(',')
                                    .map(item => item.trim())
                                    .filter(function(item, index, arr) {
                                        return (
                                            arr.indexOf(item, 0) === index &&
                                            item
                                        );
                                    })
                            );
                        }}
                    />
                </Form.Item>
                <Form.Item
                    label="批次标签"
                    labelCol={{ span: 7 }}
                    wrapperCol={{ span: 12 }}
                    name="tag"
                >
                    <Input placeholder={'不输入则不查重'} />
                </Form.Item>
                <Form.Item
                    label="开通课程"
                    labelCol={{ span: 7 }}
                    wrapperCol={{ span: 12 }}
                    name="courseUUID"
                    rules={[{ required: true }]}
                >
                    <Select
                        showSearch
                        filterOption={(input, option) =>
                            (option?.label ?? '')
                                .toLowerCase()
                                .includes(input.toLowerCase())
                        }
                        style={{ width: 300 }}
                        options={courseList.map((course, index) => ({
                            label: `${index + 1}. ${course.title}(id: ${
                                course.id
                            })`,
                            value: course.uuid,
                        }))}
                    />
                </Form.Item>
                <Form.Item
                    label="开通时限"
                    labelCol={{ span: 7 }}
                    wrapperCol={{ span: 12 }}
                    name="days"
                    initialValue={365}
                    rules={[{ required: true }]}
                >
                    <InputNumber min={1} />
                </Form.Item>
                <Form.Item wrapperCol={{ span: 12, offset: 7 }}>
                    <Button type="primary" htmlType="submit">
                        添加课程
                    </Button>
                </Form.Item>
            </Form>

            <div>
                <div>当前输入手机号列表:</div>
                {phoneList.map(item => (
                    <div key={item.phone}>
                        +{item.code} {item.phone}
                    </div>
                ))}
            </div>
            <div>
                <div>当前输入用户名列表:</div>
                {usernameList.map(item => (
                    <div key={item}>{item}</div>
                ))}
            </div>
            <div>
                <div>充值结果：</div>
                {resultList.map(item => (
                    <div
                        style={{ display: 'flex' }}
                        key={item.phone || item.username}
                    >
                        <div
                            style={{
                                minWidth: 150,
                                marginRight: 20,
                            }}
                        >
                            {item.phone
                                ? `+${item.code} ${item.phone}`
                                : `${item.username}`}
                            {` (id: ${item.userId}) `}
                        </div>
                        <span
                            style={{
                                color:
                                    item.status === 'success' ? 'green' : 'red',
                            }}
                        >
                            {item.status}&nbsp;&nbsp;{item.message}
                        </span>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default AdminAddCourseForUser;
