/**
 * CRUDL API
 */
import { remote } from '@boyuai/utils';
import { message } from 'antd';
import { API_BASE_URL } from './config';

export const fetchList = () => {
    return remote.$get(API_BASE_URL).then(data => {
        if (!data) return [];
        return data;
    });
};

export const createDetail = payload => {
    return remote.$post(API_BASE_URL, payload);
};

export const fetchDetail = id => {
    return remote.$get(`${API_BASE_URL}/${id}`);
};

export const updateDetail = (id, payload) => {
    return remote.$patch(`${API_BASE_URL}/${id}/title`, payload);
};

export const deleteDetail = id => {
    message.info('暂未提供删除功能');
    return Promise.resolve();
    // return remote.$delete(`${API_BASE_URL}/${id}`, null, {
    //     throwException: 'showMessage',
    // });
};
