import { useCallback, useEffect, useState } from 'react';
import { remote } from '@boyuai/utils';

export function usePublicVideos(page, limit, title) {
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const fetch = useCallback(() => {
        remote
            .$get(`/course/admin/public-videos`, {
                limit: limit,
                offset: (page - 1) * limit,
                title,
            })
            .then(([publicVideos, count]) => {
                setData([...publicVideos]);
                setTotal(count);
            });
    }, [page, limit, title]);
    useEffect(() => {
        fetch();
    }, [fetch]);
    return [data, total, fetch];
}
