import React, { useState } from 'react';
import { Select, Table, Pagination, Button, message } from 'antd';
import moment from 'moment';
import { useGoList, useUserGoLogs } from '../../../hooks/admin/go';
import GoFormModal from './go-form-modal';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const COLUMNS = [
    {
        title: 'id',
        dataIndex: 'id',
        key: 'id',
    },
    {
        title: '用户id',
        dataIndex: 'userId',
        key: 'userId',
    },
    {
        title: '用户名',
        dataIndex: ['user', 'name'],
        key: 'name',
    },
    {
        title: '时间',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: createdAt =>
            createdAt && moment(createdAt).format('YYYY-MM-DD HH:mm:ss'),
    },
];

function AdminGo() {
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [goUuid, setGoUuid] = useState(null);
    const [createModalVisible, setCreateModalVisible] = useState(null);
    // const [range, setRange] = useState([null, null]);

    const [goList, , fetchGoList] = useGoList();
    const [goLogs, total, fetchGoLogs] = useUserGoLogs(
        goUuid,
        pageSize * (page - 1),
        pageSize
    );

    return (
        <div style={{ marginTop: '20px' }}>
            {/*<Button onClick={test}>test</Button>*/}
            <Select
                virtual={false}
                value={goUuid}
                placeholder={'邮箱跳转链接'}
                style={{ width: '300px', marginBottom: '10px' }}
                onChange={v => {
                    setGoUuid(v);
                }}
            >
                {goList.map(go => (
                    <Select.Option value={go.uuid} key={go.uuid}>
                        {go.remark || go.uuid}
                    </Select.Option>
                ))}
            </Select>
            {/*<DatePicker.RangePicker*/}
            {/*    value={range}*/}
            {/*    placeholder={'日期范围'}*/}
            {/*    format={['YYYY-MM-DD', 'YYYY-MM-DD']}*/}
            {/*    onChange={r => {*/}
            {/*        setRange(r);*/}
            {/*    }}*/}
            {/*/>*/}
            <Button
                type={'primary'}
                onClick={() => setCreateModalVisible(true)}
            >
                创建链接
            </Button>
            <div>
                uuid：{goUuid}
                {goUuid && (
                    <CopyToClipboard
                        text={goUuid}
                        onCopy={() => message.success('已复制')}
                    >
                        <span
                            style={{
                                color: 'blue',
                                marginRight: '10px',
                                cursor: 'pointer',
                            }}
                        >
                            复制
                        </span>
                    </CopyToClipboard>
                )}
            </div>
            <div>
                当前链接：
                {goUuid
                    ? goList.find(({ uuid }) => uuid === goUuid).url
                    : '未选择'}
            </div>
            <div>
                {goUuid
                    ? goList.find(({ uuid }) => uuid === goUuid).remark
                    : '未选择'}
                事件（总数：{total || 0}）
            </div>
            <Table
                columns={[
                    ...COLUMNS,
                    /*    {
                        title: '操作',
                        key: 'operations',
                        render: row => (
                            <div>
                                <Button
                                    disabled={
                                        ![
                                            'daily-sign-in',
                                            'daily-view-courses',
                                            'daily-view-lessons',
                                        ].find(item => item === goUuid)
                                    }
                                    onClick={() => setEditRow(row)}
                                >
                                    编辑
                                </Button>
                                <Popconfirm
                                    title="确定要删除吗"
                                    onConfirm={() => deleteRow(row)}
                                >
                                    <Button
                                        disabled={
                                            ![
                                                'daily-sign-in',
                                                'daily-view-courses',
                                                'daily-view-lessons',
                                            ].find(item => item === goUuid)
                                        }
                                        type={'danger'}
                                    >
                                        删除
                                    </Button>
                                </Popconfirm>
                            </div>
                        ),
                    },*/
                ]}
                dataSource={goLogs}
                pagination={false}
                rowKey={'id'}
            />
            <Pagination
                showQuickJumper
                defaultCurrent={1}
                current={page}
                total={total}
                defaultPageSize={pageSize}
                pageSizeOptions={['10', '15', '20']}
                onChange={(_page, _pageSize) => {
                    setPage(_page);
                    setPageSize(_pageSize);
                }}
            />
            {createModalVisible && (
                <GoFormModal
                    visible={true}
                    onOk={() => {
                        setCreateModalVisible(false);
                        fetchGoList();
                        fetchGoLogs();
                    }}
                    onCancel={() => setCreateModalVisible(false)}
                />
            )}
        </div>
    );
}

export default AdminGo;
