import React, { Component, createRef } from 'react';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import Player from '@boyuai/player';
import '@boyuai/player/dist/index.css';
import { UserContextModule } from '@boyuai/utils';
import { remote } from '@boyuai/utils';
import CalculateActiveLesson from '../access-error';
import InfoTab from '../info-tab.js';
import Card from '../../../components/card';
// import Onboarding from '../../components/onboarding';
import './index.less';
import { Result, Button } from 'antd';
import { useReportEvent } from '@boyuai/utils';
import { ClockCircleTwoTone } from '@ant-design/icons';
import CommentNotify from './comment-notify';

class VideoPage extends Component {
    static contextType = UserContextModule.UserContext;
    typeChangeRef = createRef(null);
    state = {
        videoDetails: null,
        checkpoints: [],
        comments: [],
        videoTime: [0, 0],
    };
    get courseUuid() {
        return this.props.match.params.uuid;
    }
    componentDidMount() {
        this.fetch();
        const load = localStorage.getItem(this.checkpointsKey);
        if (load) {
            try {
                this.setState({ checkpoints: JSON.parse(load) });
            } catch (e) {}
        }
    }
    async componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            await this.fetch();
            const load = localStorage.getItem(this.checkpointsKey);
            if (load) {
                try {
                    this.setState({ checkpoints: JSON.parse(load) });
                } catch (e) {
                    this.setState({ checkpoints: [] });
                }
            } else {
                this.setState({ checkpoints: [] });
            }
        }
    }

    get videoUuid() {
        return this.props.match.params.videouuid;
    }

    get commentUuid() {
        return this.props.match.params.uuid;
    }
    get checkpointsKey() {
        return `video-checkpoints-${this.videoUuid}`;
    }
    handleCommentsFetch = comments => this.setState({ comments });
    getTypeChangeRef = func => (this.typeChangeRef.current = func);
    handelGotoComment = type => {
        if (!this.videoInstance || !this.typeChangeRef.current) return;
        if (this.videoInstance.fullscreenService.getIsFullScreen()) {
            this.videoInstance.fullscreenService.cancelFullScreen();
        }
        this.videoInstance.pause();
        // 关闭全屏需要点时间
        setTimeout(() => {
            this.typeChangeRef.current(type);
        }, 100);
    };
    render() {
        const { videoDetails, comments, videoTime } = this.state;
        const { isSideBarCollapsed } = this.props;
        return (
            <div className="video-page" data-is-collapsed={isSideBarCollapsed}>
                {videoDetails && this.renderVideoDetails()}
                {videoDetails && (
                    <InfoTab
                        videoDetails={videoDetails}
                        uuid={this.videoUuid}
                        commentUuid={this.commentUuid}
                        onCommentsFetch={this.handleCommentsFetch}
                        getTypeChangeRef={this.getTypeChangeRef}
                    />
                )}
                {videoDetails && videoDetails.vid ? (
                    <CommentNotify
                        videoUuid={this.videoUuid}
                        comments={comments}
                        duration={videoTime[0]}
                        currentTime={videoTime[1]}
                        gotoComment={this.handelGotoComment}
                    />
                ) : null}
            </div>
        );
    }

    async fetch() {
        const uuid = this.videoUuid;
        this.setState({ videoDetails: null });
        try {
            const videoDetails = await remote.$get(
                `/course/videos/${uuid}`,
                {},
                { hideLoading: true, throwException: 'showMessage' }
            );
            this.setState({ videoDetails });
        } catch (e) {
            if (
                _.get(e, 'response.data') === '知识点尚未购买' ||
                _.get(e, 'response.data.message') === '知识点尚未购买'
            ) {
                const { lessons } = await remote.$get(
                    `/course/${this.courseUuid}`,
                    {},
                    { hideLoading: true }
                );
                const lessonUuid = lessons[CalculateActiveLesson()].lesson.uuid;
                window.location.href = `${process.env.REACT_APP_SUB_DIRECTORY}/course/${this.courseUuid}/lesson/${lessonUuid}`;
            }
        }
        this.props.reportEvent('video-enter', { videoUUID: uuid });
    }

    reportLearned = async () => {
        if (remote.isLogin()) {
            await remote.$post(
                `/course/learningLog`,
                { uuid: this.videoUuid },
                { hideLoading: true }
            );
        }
        await this.props.reportEvent('video-finish', {
            videoUUID: this.videoUuid,
        });
    };

    onCheckpointUpdate = (current, length) => {
        const { checkpoints, videoDetails } = this.state;
        if (videoDetails && videoDetails.learningLog) {
            return;
        }
        if (!checkpoints.includes(current)) {
            const newPoints = [...checkpoints, current];
            this.setState({ checkpoints: newPoints });
            localStorage.setItem(
                this.checkpointsKey,
                JSON.stringify(newPoints)
            );
            if (newPoints.length >= (length * 3) / 5) {
                this.setState({
                    videoDetails: { ...videoDetails, learningLog: true },
                });
                localStorage.removeItem(this.checkpointsKey);
                this.reportLearned();
            }
        }
    };

    handleGetVideoInstance = instance => {
        this.videoInstance = instance;
    };

    handleVideoTimeUpdate = (duration, current) =>
        this.setState({ videoTime: [duration, current] });
    renderVideoDetails = () => {
        const {
            title,
            files,
            coverImage,
            author,
            vid,
        } = this.state.videoDetails;
        const {
            user: { id: userID },
        } = this.context;
        if (!files || (!files.length && !vid)) {
            return null;
        }
        return (
            <Card title={title} authors={[author]} isShowCourseTitle={true}>
                {/*测试服视频无法播放，提供一键学习按钮模拟“完成视频学习”的操作*/}
                {process.env.REACT_APP_ENV !== 'production' &&
                    !(
                        this.state.videoDetails &&
                        this.state.videoDetails.learningLog
                    ) && (
                        <Button
                            style={{ marginBottom: 16 }}
                            onClick={this.reportLearned}
                        >
                            一键学习
                        </Button>
                    )}
                <div className="video-container">
                    <div className="video-box">
                        {files.length ? (
                            <Player
                                coverImage={coverImage}
                                videos={files}
                                onCheckpointUpdate={this.onCheckpointUpdate}
                                onTimeUpdate={this.handleVideoTimeUpdate}
                                onGetInstance={this.handleGetVideoInstance}
                                cacheId={`course-uid-${userID || 'guest'}`}
                                useMemory
                            />
                        ) : (
                            <Result
                                icon={<ClockCircleTwoTone />}
                                title="视频正在转码..."
                            />
                        )}
                    </div>
                </div>
            </Card>
        );
    };
}

function withEventReporter(Component) {
    return function WrappedComponent(props) {
        const reportEvent = useReportEvent();
        return <Component {...props} reportEvent={reportEvent} />;
    };
}

export default withEventReporter(withRouter(VideoPage));
