import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { remote } from '@boyuai/utils';
import moment from 'moment';
import Modal1 from './modal1';
import Modal2 from './modal2';
import Modal3 from './modal3';
import CommonModal from './common-modal';
import './index.less';

let fetched = false;

// 本次活动content字段说明
// isQianren 是否千人计划
// poped 是否完成首次弹窗
// course 选择的课程
// lessons 等待打卡的课程
// users 邀请的用户 phone 手机号 createdAt 时间 active 是否活跃用户
// rewards 获得的奖励 createdAt 时间 content 内容
// modal2Guided 弹窗二小手是否使用

// 本次活动state说明
// 1 完成了第一个邀请条件
// 2 完成了第二个邀请条件

export default function Boyu618({ activity: _activity }) {
    const [modalVisible, setModalVisible] = useState(false);
    const [pending, setPending] = useState(false); // 刷新进度的接口耗时严重，需要一个loading
    const [createdActivity, setCreatedActivity] = useState(null);
    const activity = createdActivity || _activity;
    // console.log(activity);

    useEffect(() => {
        if (!activity.userId && remote.isLogin()) {
            // 生成一份自己的
            remote
                .$post(
                    `/course/activities`,
                    { fromActivityId: activity.id },
                    { hideLoading: true }
                )
                .then(setCreatedActivity);
        }
    }, [activity]);
    useEffect(() => {
        if (activity.userId && !activity.content.poped) {
            // 标记弹窗
            remote.$patch(
                `/course/activities/${activity.id}/content`,
                { content: { poped: true } },
                { hideLoading: true }
            );
            // 首次强制弹窗
            setModalVisible(true);
        }
    }, [activity]);

    // 更新详细信息：刷新邀请进度（比较耗时，所以不必要的时候就不请求了）
    useEffect(() => {
        if (fetched) {
            return;
        }
        if (
            !activity.userId ||
            !activity.content.poped ||
            !activity.content.course ||
            !activity.content.modal1InviteButtonClicked
        ) {
            return;
        }
        if (activity.state === 1 && !activity.content.isQianren) {
            return; // 普通用户只能进行到1，满足第一个邀请条件
        }
        if (activity.state === 2) {
            return; // 满足第二个邀请条件
        }
        fetched = true; // 确保只获取一次
        setPending(true);
        remote
            .$get(
                `/course/activities/${activity.id}`,
                {},
                { hideLoading: true }
            )
            .then(value => {
                setCreatedActivity(value);
                setPending(false);
            });
    }, [activity]);

    const modal3Visible = useMemo(() => {
        if (!activity) {
            return false;
        }
        if (!activity.content.modal2StartNoticePopped) {
            return false;
        }
        if (activity.state < 1) {
            return false;
        }
        // 今天签到过了
        if (
            activity.content.signLogs &&
            activity.content.signLogs.find(log =>
                moment(log.createdAt).isAfter(moment().startOf('day'))
            )
        ) {
            return false;
        }
        // 签没了
        if (
            activity.content.signLogs &&
            activity.content.signLogs.length >= activity.content.lessons.length
        ) {
            return false;
        }
        // 活动已结束
        if (
            activity.content.signExpiresAt &&
            moment(activity.content.signExpiresAt).isBefore(moment())
        ) {
            return false;
        }
        // 活动已结束
        if (activity.endAt && moment(activity.endAt).isBefore(moment())) {
            return false;
        }
        return true;
    }, [activity]);

    // 开启打卡挑战的弹窗关闭
    const onStartNoticePopped = useCallback(async () => {
        if (!activity.content.modal2StartNoticePopped) {
            const act = await remote.$patch(
                `/course/activities/${activity.id}/content`,
                { content: { modal2StartNoticePopped: true } },
                { hideLoading: true }
            );
            setCreatedActivity(act);
        }
    }, [activity]);
    // 机器学习续60天成功的弹窗关闭
    const onStep2SuccessPopped = useCallback(async () => {
        if (!activity.content.modal2Step2SuccessPopped) {
            const act = await remote.$patch(
                `/course/activities/${activity.id}/content`,
                { content: { modal2Step2SuccessPopped: true } },
                { hideLoading: true }
            );
            setCreatedActivity(act);
        }
    }, [activity]);

    return (
        <div className="activity-boyu-618">
            <div className="float-button" onClick={() => setModalVisible(true)}>
                <img
                    src="https://staticcdn.boyuai.com/materials/2020/06/17/DtD4a3b-ZXVA--4CxDMJ-.png!png"
                    alt=""
                    width={107}
                    height={100}
                />
            </div>
            {!(
                activity.content.course &&
                activity.content.modal1InviteButtonClicked
            ) && (
                <Modal1
                    pending={pending}
                    visible={modalVisible}
                    onClose={() => setModalVisible(false)}
                    activity={activity}
                    onActivityChange={setCreatedActivity}
                />
            )}
            {activity.content.course &&
                activity.content.modal1InviteButtonClicked && (
                    <Modal2
                        pending={pending}
                        visible={modalVisible}
                        onClose={() => setModalVisible(false)}
                        activity={activity}
                        onActivityChange={setCreatedActivity}
                    />
                )}
            {modal3Visible && (
                <Modal3
                    activity={activity}
                    onActivityChange={setCreatedActivity}
                />
            )}
            {!activity.content.modal2StartNoticePopped &&
            activity.content.modal1InviteButtonClicked &&
            activity.state >= 1 ? (
                <CommonModal
                    visible={true}
                    onCancel={onStartNoticePopped}
                    title={<span>&nbsp;挑战开始！</span>}
                    hint={'您已开启打卡挑战，每日登录可以依次解锁知识点'}
                    onOk={onStartNoticePopped}
                    okText={'马上打卡'}
                    buttonVisible={true}
                    imgSrc={
                        'https://staticcdn.boyuai.com/materials/2020/06/17/H_tJKRXaOF5lCS0BBp_cQ.png!png'
                    }
                />
            ) : null}
            {!activity.content.modal2Step2SuccessPopped &&
            activity.content.modal2StartNoticePopped &&
            activity.state >= 2 ? (
                <CommonModal
                    visible={true}
                    onCancel={onStep2SuccessPopped}
                    title={'恭喜您！'}
                    hint={'您获得机器学习延长60天学习奖励'}
                    onOk={() => {
                        onStep2SuccessPopped();
                        window.open(
                            'https://www.boyuai.com/elites/course/x3fyYxaRhVWJxGSI'
                        );
                    }}
                    okText={'立即前往学习'}
                    buttonVisible={true}
                    imgSrc={
                        'https://staticcdn.boyuai.com/materials/2020/03/01/PX8TXD5jnisRRdk1Qcm9Y.png!png'
                    }
                />
            ) : null}
        </div>
    );
}
