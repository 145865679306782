import React, { useState, useCallback } from 'react';
import {
    Select,
    Table,
    Pagination,
    // DatePicker,
    Button,
    Popconfirm,
} from 'antd';
import moment from 'moment';
import { useEvents } from '../../../hooks/events';
import { remote } from '@boyuai/utils';
import EventFormModal from './event-form-modal';

const COLUMNS = [
    {
        title: 'key',
        dataIndex: 'event',
        key: 'event',
    },
    {
        title: '用户id',
        dataIndex: 'userId',
        key: 'userId',
    },
    {
        title: '时间',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: time => time && moment(time).format('YYYY-MM-DD HH:mm:ss'),
    },
];

const EVENTS = [
    {
        key: 'click-fapiao',
        title: '点击发票',
    },
    {
        key: 'video-enter',
        title: '开始观看视频',
    },
    {
        key: 'video-finish',
        title: '视频观看结束',
    },
    {
        key: 'daily-sign-in',
        title: '每日签到',
    },
    {
        key: 'daily-view-courses',
        title: '每日查看课程',
    },
    {
        key: 'daily-view-lessons',
        title: '每日查看知识点',
    },
    {
        key: 'recall-1',
        title: '第一次沉默用户唤醒（领取雨滴）',
    },
];

const getEventColumns = key => {
    switch (key) {
        case 'daily-sign-in':
            return [
                ...COLUMNS,
                {
                    title: '起始日期',
                    dataIndex: ['data', 'startedDate'],
                    key: 'startedDate',
                },
                {
                    title: '连续签到天数',
                    key: 'consecutiveDays',
                    render: row => {
                        const diffDays = moment(row.createdAt).diff(
                            moment(row.data.startedDate, 'YYYYMMDD'),
                            'd'
                        );
                        return `${diffDays + 1}（视作第${(diffDays % 7) +
                            1}天）`;
                    },
                },
            ];
        case 'daily-view-courses':
            return [
                ...COLUMNS,
                {
                    title: 'courseUUID',
                    dataIndex: ['data', 'courseUUID'],
                    key: 'courseUUID',
                },
            ];
        case 'daily-view-lessons':
            return [
                ...COLUMNS,
                {
                    title: 'lessonUUID',
                    dataIndex: ['data', 'lessonUUID'],
                    key: 'lessonUUID',
                },
            ];
        default:
            return COLUMNS;
    }
};

function AdminEvents() {
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [eventKey, setEventKey] = useState(null);
    const [editRow, setEditRow] = useState(null);
    const [range] = useState([null, null]);

    const [events, total, fetchEvents] = useEvents(
        eventKey,
        pageSize * (page - 1),
        pageSize,
        ...range
    );

    const deleteRow = useCallback(
        async row => {
            if (row && row.event && row.userId && row.date && row.stamp) {
                await remote.$delete(
                    `/events/admin/${row.event}/${row.userId}/${row.date}/${row.stamp}`
                );
                fetchEvents();
            }
        },
        [fetchEvents]
    );

    // const updateRow = useCallback(
    //     async (row, payload) => {
    //         if (row && row.event && row.userId && row.date && row.stamp) {
    //             await remote.$patch(
    //                 `/events/admin/${row.event}/${row.userId}/${row.date}/${row.stamp}`,
    //                 {
    //                     payload: {
    //                         startedDate: payload.startedDate,
    //                     },
    //                 }
    //             );
    //             fetchEvents();
    //             setEditRow(null);
    //         }
    //     },
    //     [fetchEvents]
    // );

    return (
        <div style={{ marginTop: '20px' }}>
            {/*<Button onClick={test}>test</Button>*/}
            <Select
                virtual={false}
                value={eventKey}
                placeholder={'事件'}
                style={{ width: '300px', marginBottom: '10px' }}
                onChange={v => {
                    setEventKey(v);
                }}
            >
                {EVENTS.map(event => (
                    <Select.Option value={event.key} key={event.key}>
                        {event.title}
                    </Select.Option>
                ))}
            </Select>
            {/* <DatePicker.RangePicker
                value={range}
                placeholder={'日期范围'}
                format={['YYYY-MM-DD', 'YYYY-MM-DD']}
                onChange={r => {
                    setRange(r || [null, null]);
                }}
            /> */}
            <div>
                {eventKey
                    ? EVENTS.find(({ key }) => key === eventKey).title
                    : '未选择事件'}
                事件（总数：{total || 0}）
            </div>
            <Table
                columns={[
                    ...getEventColumns(eventKey),
                    {
                        title: '操作',
                        key: 'operations',
                        render: row => (
                            <div>
                                <Button
                                    disabled={
                                        ![
                                            'daily-sign-in',
                                            'daily-view-courses',
                                            'daily-view-lessons',
                                        ].find(item => item === eventKey)
                                    }
                                    onClick={() => setEditRow(row)}
                                >
                                    编辑
                                </Button>
                                <Popconfirm
                                    title="确定要删除吗"
                                    onConfirm={() => deleteRow(row)}
                                    disabled={
                                        ![
                                            'daily-sign-in',
                                            'daily-view-courses',
                                            'daily-view-lessons',
                                            'recall-1',
                                        ].find(item => item === eventKey)
                                    }
                                >
                                    <Button
                                        disabled={
                                            ![
                                                'daily-sign-in',
                                                'daily-view-courses',
                                                'daily-view-lessons',
                                                'recall-1',
                                            ].find(item => item === eventKey)
                                        }
                                        type={'danger'}
                                    >
                                        删除
                                    </Button>
                                </Popconfirm>
                            </div>
                        ),
                    },
                ]}
                dataSource={events}
                pagination={false}
                rowKey={item => `${item.event}-${item.userId}-${item.stamp}`}
            />
            <Pagination
                showQuickJumper
                defaultCurrent={1}
                current={page}
                total={total}
                defaultPageSize={pageSize}
                pageSizeOptions={['10', '15', '20']}
                onChange={(_page, _pageSize) => {
                    setPage(_page);
                    setPageSize(_pageSize);
                }}
            />
            <EventFormModal
                visible={!!editRow}
                onOk={() => setEditRow(null)}
                // onOk={values => updateRow(values, values)}
                initialValues={editRow}
                onCancel={() => setEditRow(null)}
                title={'编辑事件'}
                formItems={
                    editRow
                        ? Object.keys(editRow).map(field => {
                              return {
                                  key: field,
                                  label: field,
                                  name: field,
                                  disabled: true,
                                  // disabled: field !== 'startedDate',
                              };
                          })
                        : []
                }
            />
        </div>
    );
}

export default AdminEvents;
