import React, { useEffect, useCallback, useState, useMemo } from 'react';
import { Button, Modal, Popover, Spin } from 'antd';
import moment from 'moment';
import { remote } from '@boyuai/utils';
import QRCode from 'qrcode';
import Explanation from './explanation';
import Guide from '../activity-components/guide';
import Loading from '../activity-components/loading';

const translateDate = formattedDate => {
    return formattedDate
        .split('-')
        .map((item, index) => {
            if (index === 0) {
                return `${parseInt(item)}月`;
            }
            if (index === 1) {
                return `${parseInt(item)}日`;
            }
            return item;
        })
        .join('');
};

const translateReward = item => {
    const contentList = item.content.split(' ');
    return (
        <span className="activity-qianren-laxin-modal-content-box-reward">
            {translateDate(moment(item.createdAt).format('MM-DD'))}
            {/*12月29日*/}
            {item.link ? (
                <div
                    className="activity-qianren-laxin-modal-content-box-reward-text"
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                >
                    {contentList[0]}
                    &nbsp; &nbsp;
                    {contentList[1]}
                    <Button
                        type={'link'}
                        style={{
                            padding: 'unset',
                            fontSize: 16,
                        }}
                        onClick={() => {
                            window.open(
                                item.link.replace(
                                    'https://www.boyuai.com/elites',
                                    process.env.REACT_APP_SUB_DIRECTORY
                                )
                            );
                        }}
                    >
                        <div className="activity-qianren-laxin-modal-content-box-reward-link">
                            {contentList[2]}
                        </div>
                    </Button>
                    {contentList[3]}
                </div>
            ) : (
                item.content
            )}
        </span>
    );
};

export default function Modal2({ visible, onClose, activity, pending }) {
    const [qrcode, setQrcode] = useState(null);
    const [loading, setLoading] = useState(false);
    const fetch = useCallback(async () => {
        setLoading(true);
        await remote.$get('/user/public/referral');
        const publicToken = await remote.$get('/user/public/token');
        const site = process.env.REACT_APP_ENV === 'production' ? '' : '.dev';
        const image = await QRCode.toDataURL(
            `https://share${site}.boyuai.com/register/${publicToken}`
        );
        setQrcode(image);
        setLoading(false);
    }, []);
    useEffect(() => {
        if (visible) {
            fetch();
        }
        // eslint-disable-next-line
    }, [visible]);
    // const onQrcodeClicked = useCallback(async () => {
    //     if (!activity.content.modal2Guided) {
    //         // onActivityChange(prev => ({
    //         //     ...prev,
    //         //     content: {
    //         //         ...prev.content,
    //         //         modal2Guided: true,
    //         //     },
    //         // }));
    //         const act = await remote.$patch(
    //             `/course/activities/${activity.id}/content`,
    //             { content: { modal2Guided: true } },
    //             { hideLoading: true }
    //         );
    //         onActivityChange(act);
    //     }
    // }, [onActivityChange, activity]);

    // const reversedUsers = useMemo(() => {
    //     if (activity.content.users) {
    //         const l = [...activity.content.users];
    //         l.reverse();
    //         return l;
    //     } else {
    //         return [];
    //     }
    // }, [activity.content.users]);

    const reversedRewards = useMemo(() => {
        if (activity.content.rewards) {
            const l = [...activity.content.rewards];
            l.reverse();
            return l;
        } else {
            return [];
        }
    }, [activity.content.rewards]);

    return (
        <Modal
            wrapClassName="activity-boyu-202008-modal modal2"
            visible={visible}
            footer={null}
            onCancel={onClose}
            title={null}
            width={890}
        >
            <img
                onClick={() => onClose()}
                className="close-button"
                src="https://staticcdn.boyuai.com/materials/2020/08/01/U0eGfF6-XzSu3NdTrJvbU.png!png"
                alt="关闭"
            />
            <div className="activity-qianren-laxin-modal-content">
                <img
                    className="activity-qianren-laxin-modal-content-title"
                    src="https://staticcdn.boyuai.com/materials/2020/08/01/mJ-nQGsI5r76ioap2iwWA.png!png"
                    // alt="春暖花开"
                    alt=""
                    style={{
                        top: 58,
                        left: 40,
                        width: 444,
                    }}
                />
                <img
                    className="activity-qianren-laxin-modal-content-thanks"
                    src="https://staticcdn.boyuai.com/materials/2020/08/01/leto6R8ciG1EaNySv2xOH.png!png"
                    // alt="感谢有你"
                    alt=""
                    style={{
                        top: 100,
                        right: 46,
                        width: 320,
                    }}
                />
                {pending ? (
                    <Loading />
                ) : (
                    <Spin spinning={loading}>
                        <div
                            className="activity-qianren-laxin-modal-content-box"
                            style={{
                                top: 272 - 24,
                                width: 228,
                                padding: '26px 20px 8px 20px',
                                height: 246,
                            }}
                        >
                            <div className="activity-qianren-laxin-modal-content-box-title">
                                邀请详情
                            </div>
                            <div
                                style={{
                                    textAlign: 'center',
                                }}
                            >
                                <img
                                    src={qrcode}
                                    alt="邀请二维码"
                                    width="140px"
                                    height="140px"
                                    style={{
                                        position: 'relative',
                                        borderRadius: 8,
                                        marginTop: 8,
                                    }}
                                />
                                {!activity.content.modal2Guided && (
                                    <Guide
                                        left={-148}
                                        top={64}
                                        imgSrc="https://staticcdn.boyuai.com/materials/2020/08/01/PZV-0orVtZ1amHrbQGZh3.png!png"
                                        width="200px"
                                    />
                                )}
                                <img
                                    src="https://staticcdn.boyuai.com/materials/2020/08/01/e0rJ-hlx_4I1-h7YHaHc5.png!png"
                                    alt=""
                                    width="188px"
                                    style={{ marginTop: 16 }}
                                />
                            </div>
                        </div>
                        <div
                            className="activity-qianren-laxin-modal-content-box"
                            style={{
                                top: 272 - 24,
                                right: 0,
                                width: 546,
                                height: 246,
                                padding: '22px 24px 16px 24px',
                            }}
                        >
                            <div
                                className="activity-qianren-laxin-modal-content-box-title"
                                style={{ left: 50 }}
                            >
                                获得奖励
                            </div>
                            <div
                                style={{
                                    lineHeight: '20px',
                                    color: 'white',
                                }}
                            >
                                {activity.state >= 1 &&
                                activity.state >= 1 &&
                                activity.content.signExpiresAt ? (
                                    <span className="activity-qianren-laxin-modal-content-box-reward">
                                        已开启签到挑战，您可在
                                        <span className="activity-qianren-laxin-modal-content-box-reward-high-light">
                                            {translateDate(
                                                moment(
                                                    activity.content
                                                        .signExpiresAt
                                                ).format('MM-DD')
                                            )}
                                        </span>
                                        前每日签到解锁知识点
                                    </span>
                                ) : (
                                    <div
                                        style={{
                                            color: 'white',
                                            padding: 16,
                                        }}
                                    >
                                        暂无奖励
                                    </div>
                                )}
                            </div>
                            <div
                                className="scroll-without-bar-shell"
                                style={{
                                    color: 'white',
                                    height: 320,
                                    marginTop: 8,
                                }}
                            >
                                <div className="scroll-without-bar-container">
                                    {reversedRewards.map((item, index) => (
                                        <div key={index}>
                                            {translateReward(item)}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </Spin>
                )}
                <Popover
                    placement="topRight"
                    content={
                        <div style={{ width: 780 }}>
                            <Explanation />
                        </div>
                    }
                >
                    <div
                        className="activity-qianren-laxin-modal-content-explanation"
                        style={{ fontSize: 14, fontWeight: 'normal' }}
                    >
                        查看活动详情
                    </div>
                </Popover>
            </div>
        </Modal>
    );
}
