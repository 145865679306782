import React from 'react';
import styles from './payment-credit.module.less';

const ICON_DROP_BLUE =
    'https://staticcdn.boyuai.com/materials/2019/10/16/TY6YvwFQuNFkHSn54y0v6.jpg';
const ICON_DROP_RED =
    'https://staticcdn.boyuai.com/materials/2019/10/16/X01Ybx_GQIjq1juU3cWj_.jpg';

export default function PaymentCredit({ insufficient, value }) {
    return (
        <div className={styles['balance-detail']}>
            <img
                className={styles.drop}
                src={insufficient ? ICON_DROP_RED : ICON_DROP_BLUE}
                alt="drop"
            />
            <div className={styles.label}>雨滴(积分)</div>
            <div>可用：</div>
            {insufficient ? (
                <div
                    style={{
                        color: 'red',
                    }}
                >
                    {value / 100}
                    （雨滴不足）
                </div>
            ) : (
                <div
                    style={{
                        color: ' #31AFF9',
                    }}
                >
                    {value / 100}
                    （雨滴充足）
                </div>
            )}
        </div>
    );
}
