import { useCallback, useEffect, useState } from 'react';
import { remote } from '@boyuai/utils';

export function useAdminCourses() {
    const [courses, setCourses] = useState([]);
    const fetchCourses = useCallback(() => {
        remote.$get('/course/admin/courses').then(([courses]) => {
            setCourses(courses);
        });
    }, []);
    useEffect(() => {
        fetchCourses();
    }, [fetchCourses]);
    return [courses, fetchCourses];
}

export function useCourseAuthors(course) {
    const [courseAuthors, setCourseAuthors] = useState([]);
    const fetchCourseAuthors = useCallback(() => {
        if (course) {
            remote
                .$get(`/course/admin/courses/${course}/authors`)
                .then(courseAuthors => {
                    setCourseAuthors([...courseAuthors]);
                });
        }
    }, [course]);
    useEffect(() => {
        fetchCourseAuthors();
    }, [fetchCourseAuthors]);
    return [courseAuthors, fetchCourseAuthors];
}

export function useCourseLessons(course) {
    const [courseLessons, setCourseLessons] = useState([]);
    const fetchCourseLessons = useCallback(() => {
        if (course) {
            remote
                .$get(`/course/admin/courses/${course}/lessons`)
                .then(courseLessons => {
                    setCourseLessons([...courseLessons]);
                });
        }
    }, [course]);
    useEffect(() => {
        fetchCourseLessons();
    }, [fetchCourseLessons]);
    return [courseLessons, fetchCourseLessons];
}
