import { PayCircleOutlined } from '@ant-design/icons';
import React from 'react';
import styles from './payment-recharge-buy.module.less';

export default function PaymentRechargeBuy() {
    return (
        <div className={styles.container}>
            <PayCircleOutlined className={`${styles.icon} ${styles.money}`} />
            <div className={styles.text}>直接购买</div>
        </div>
    );
}
