import React from 'react';
import { Form, Input } from 'antd';
import Upload from '../components/upload';
//
// const DEFAULT_PORTRAIT =
//     'https://static.boyuai.com/user/portrait/2019/06/12/HV1escOlF_DXE3jfs-P4J.jpg';

const colConfig = {
    labelCol: { span: 3 },
    wrapperCol: { span: 20 },
};

export default function AuthorForm(props) {
    const { author } = props;

    return (
        <Form>
            <Form.Item
                label={'头像'}
                {...colConfig}
                name="coverImage"
                valuePropName="fileList"
                getValueFromEvent={e => e.fileList.slice(-1)}
                rules={[{ required: true }]}
                initialValue={
                    author
                        ? [
                              {
                                  uid: '-1',
                                  name: 'file',
                                  status: 'done',
                                  url: author.coverImage,
                              },
                          ]
                        : null
                }
            >
                <Upload endpoint={'/course/admin/materials'} />
            </Form.Item>
            <Form.Item
                label={'姓名'}
                {...colConfig}
                name="name"
                initialValue={author ? author.name : null}
                rules={[{ required: true }]}
            >
                <Input placeholder={'请输入姓名'} maxLength={30} />
            </Form.Item>
            <Form.Item
                label={'头衔'}
                {...colConfig}
                name="title"
                initialValue={author ? author.title : null}
                rules={[{ required: true }]}
            >
                <Input placeholder={'请输入头衔'} maxLength={100} />
            </Form.Item>
            <Form.Item
                label={'简介'}
                {...colConfig}
                name="content"
                initialValue={author ? author.content : null}
                rules={[{ required: true }]}
            >
                <Input.TextArea
                    placeholder={'请输入简介'}
                    rows={5}
                    maxLength={2000}
                />
            </Form.Item>
        </Form>
    );
}
