import React, { useState } from 'react';
import { remote } from '@boyuai/utils';
import { Button, Modal, Popconfirm } from 'antd';
import AdminTable from './table';
import AdminFormModal from './form-modal';
import Quiz from './quiz';
import QuizPreview from './components/quiz-preview';

const formItems = [
    {
        label: '标题',
        name: 'title',
        type: 'input',
        required: true,
    },
    {
        label: '小标题',
        name: 'miniTitle',
        type: 'input',
        placeholder: '课后练习',
    },
    {
        label: '排序',
        name: 'order',
        type: 'number',
        required: true,
        placeholder: '在整个知识点下所有视频、代码、习题的排序',
    },
];

function AdminLessonQuizzes(props) {
    const [visible, setVisible] = useState(false);
    const [quizId, setQuizId] = useState(null);
    const [previewQuizId, setPreviewQuizId] = useState(null);

    const onCreate = async values => {
        const data = { ...values };
        try {
            await remote.$post(
                `/course/admin/courses/0/lessons/${props.lessonId}/quizzes`,
                data,
                { throwException: 'showMessage' }
            );
            props.onChange();
            setVisible(false);
        } catch (e) {}
    };

    const onDelete = async id => {
        try {
            await remote.$delete(
                `/course/admin/courses/0/lessons/0/quizzes/${id}`,
                {},
                { throwException: 'showMessage' }
            );
            props.onChange();
        } catch (e) {}
    };

    const columns = [
        {
            title: '标题',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: '小标题',
            dataIndex: 'miniTitle',
            key: 'miniTitle',
            render: text => text || '课后练习',
        },
        {
            title: '排序',
            dataIndex: 'order',
            key: 'order',
            sorter: (a, b) => a.order - b.order,
        },
        {
            title: '操作',
            dataIndex: 'id',
            key: 'operations',
            render: id => (
                <div>
                    <Button onClick={() => setQuizId(id)}>查看详情</Button>
                    &nbsp;
                    <Button onClick={() => setPreviewQuizId(id)}>预览</Button>
                    &nbsp;
                    <Popconfirm
                        title="确定要删除吗"
                        onConfirm={() => onDelete(id)}
                    >
                        <Button type="danger">删除</Button>
                    </Popconfirm>
                </div>
            ),
        },
    ];

    return (
        <div style={{ marginTop: '20px' }}>
            <h3>
                试卷列表{' '}
                <Button onClick={() => setVisible(true)}>创建试卷</Button>
                <Button href="https://staticcdn.boyuai.com/materials/2020/04/10/quiz_sample.md">
                    下载格式模板
                </Button>
            </h3>
            <AdminTable
                columns={columns}
                datasource={props.datasource}
                hidePagination
            />
            <AdminFormModal
                title="添加试卷"
                visible={visible}
                onCancel={() => setVisible(false)}
                onSubmit={onCreate}
                formItems={formItems}
                formValues={{ title: props.lessonTitle }}
            />
            <Modal
                visible={!!quizId}
                onCancel={() => setQuizId(null)}
                onOk={() => setQuizId(null)}
                title="试卷详情"
                width="90%"
            >
                <Quiz quizId={quizId} />
            </Modal>
            <Modal
                visible={!!previewQuizId}
                onCancel={() => setPreviewQuizId(null)}
                onOk={() => setPreviewQuizId(null)}
                title="试卷预览"
                width="90%"
                footer={null}
            >
                <QuizPreview
                    quizId={previewQuizId}
                    onClose={() => setPreviewQuizId(null)}
                />
                <div>预览状态下提交按钮只作检查，不进行提交</div>
            </Modal>
        </div>
    );
}

export default AdminLessonQuizzes;
