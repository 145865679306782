import React, { Component } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import SearchCourse from '@boyuai/search-course';
import { BaseHeader, BaseHeaderLeft, BaseHeaderRight } from '@boyuai/header';
import './index.less';

export default class HomeHeader extends Component {
    state = {
        showSearch: false,
        fullMode: false,
        openLeftSearch: false,
        openRightSearch: false,
    };

    lastLayoutY = 0;

    componentDidMount() {
        this.layoutRef = document.querySelector('div#layout');
        document.addEventListener('scroll', this.handleScroll);
        return () => {
            document.removeEventListener('scroll', this.handleScroll);
        };
    }

    handleScroll = () => {
        if (this.state.openRightSearch || this.state.openLeftSearch) return;
        if (!this.layoutRef) return;
        // 避免一些细微的滚动引起反复变化
        if (
            Math.abs(
                Math.abs(this.layoutRef.getBoundingClientRect().y) -
                    Math.abs(this.lastLayoutY)
            ) <= 32
        )
            return;
        const nextState =
            this.layoutRef.getBoundingClientRect().y <= this.lastLayoutY;
        this.lastLayoutY = this.layoutRef.getBoundingClientRect().y;
        this.setState({
            fullMode: nextState,
            showSearch: !nextState && this.state.showSearch,
        });
    };

    render() {
        let leftContent = (
            <>
                {!this.state.fullMode && this.state.showSearch ? (
                    <div className="header-search-left">
                        <SearchCourse
                            dropdownClassName="header-dropdown"
                            open={this.state.openLeftSearch}
                            setOpen={openLeftSearch =>
                                this.setState({ openLeftSearch })
                            }
                            onBlur={() => this.setState({ showSearch: false })}
                        />
                    </div>
                ) : (
                    <>
                        <BaseHeaderLeft />
                        {!this.state.fullMode && (
                            <>
                                <div className="base-header-left-divider" />
                                <SearchOutlined
                                    style={{
                                        color: '#0D7DFC',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() =>
                                        this.setState({ showSearch: true })
                                    }
                                />
                            </>
                        )}
                    </>
                )}
            </>
        );

        let rightContent = (
            <div className="header-right-wrapper">
                <BaseHeaderRight />
                <div className="header-search-right">
                    <SearchCourse
                        autoFocus={false}
                        dropdownClassName="header-dropdown"
                        open={this.state.openRightSearch}
                        setOpen={openRightSearch =>
                            this.setState({ openRightSearch })
                        }
                    />
                </div>
            </div>
        );

        return (
            <BaseHeader
                className={`${this.state.fullMode ? ' full' : ''}`}
                leftContent={leftContent}
                rightContent={rightContent}
            />
        );
    }
}
