import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { UserContext } from '@boyuai/utils/es/context/user-context';
import { WechatOutlined } from '@ant-design/icons';
import { Button, Form, Input, message, Modal } from 'antd';
import { remote } from '@boyuai/utils';
import FuwuhaoBindModal from '@boyuai/fuwuhao-bind-modal';
import { CaptchaInput } from '@boyuai/captcha';
import './index.less';

const EmailMode = {
    TO_BE_BOUND: '前去绑定',
    TO_BE_UNBOUND: '取消绑定',
    TO_BE_CHANGED: '更改邮箱',
    TO_BE_UNCHANGED: '取消更改',
};

const EMAIL_HELP = '输入邮箱获取验证码';
const PASSWORD_HELP = '设置 8 位以上的密码';

const MyAccount = () => {
    const {
        user,
        weChatNickname,
        updateWeChatNickname,
        updateUser,
    } = useContext(UserContext);
    const [wechatModalVisible, setWechatModalVisible] = useState(false);

    /** 邮箱相关 state */
    const location = useLocation();
    const [emailCodeCanSend, setEmailCodeCanSend] = useState(false);
    const [emailHelp, setEmailHelp] = useState(EMAIL_HELP);
    const setEmailError = error => {
        setEmailHelp(<div className="error">{error}</div>);
    };
    const [emailMode, setEmailMode] = useState();

    const initEmailMode = useCallback(() => {
        if (user.emailAddress) {
            setEmailMode(EmailMode.TO_BE_CHANGED);
            return;
        }
        setEmailMode(EmailMode.TO_BE_BOUND);
    }, [user.emailAddress]);

    // 当 location search 是 email mode，展开邮箱编辑
    const initEmailModeFromLocation = useCallback(() => {
        if (!location.search.includes('mode=email')) {
            return;
        }
        if (user.emailAddress) {
            setEmailMode(EmailMode.TO_BE_UNCHANGED);
            return;
        }
        setEmailMode(EmailMode.TO_BE_UNBOUND);
    }, [location.search, user.emailAddress]);

    useEffect(() => {
        initEmailMode();
        initEmailModeFromLocation();

        /**
         * 调试安全验证
         */
        // remote.$post('/user/lock', {});
    }, [initEmailMode, initEmailModeFromLocation]);

    const toggleEmailMode = () => {
        switch (emailMode) {
            case EmailMode.TO_BE_BOUND:
                setEmailMode(EmailMode.TO_BE_UNBOUND);
                _setShowPasswordInput(false);
                break;
            case EmailMode.TO_BE_UNBOUND:
                setEmailMode(EmailMode.TO_BE_BOUND);
                break;
            case EmailMode.TO_BE_CHANGED:
                setEmailMode(EmailMode.TO_BE_UNCHANGED);
                _setShowPasswordInput(false);
                break;
            case EmailMode.TO_BE_UNCHANGED:
                setEmailMode(EmailMode.TO_BE_CHANGED);
                break;
            default:
                break;
        }
    };

    const sendEmail = email => {
        return remote
            .$patch(
                '/user/email',
                {
                    email,
                },
                { throwException: true }
            )
            .then(() => {
                setEmailHelp('验证码将发送至你的邮箱内');
            })
            .catch(err => {
                setEmailError(err?.response?.data?.message);
            });
    };

    const handleEmailBind = () => {
        remote
            .$post(
                '/user/email-verification-code',
                {
                    code: form.getFieldValue('emailCode'),
                },
                { throwException: true }
            )
            .then(data => {
                updateUser({
                    ...user,
                    emailAddress: form.getFieldValue('email'),
                });
                setEmailMode(EmailMode.TO_BE_CHANGED);
                const { reward, rewardSJTU } = data;
                if (rewardSJTU) {
                    remote
                        .$post(
                            '/course/email-validation-reward',
                            {},
                            { throwException: 'showMessage' }
                        )
                        .then(() => {
                            Modal.success({
                                width: 465,
                                content: (
                                    <div>
                                        已为 @sjtu.edu.cn 邮箱自动解锁课程
                                        <a
                                            href={`https://${
                                                process.env.REACT_APP_ENV ===
                                                'production'
                                                    ? 'www.boyuai.com/elites'
                                                    : 'dev.boyuai.com/course'
                                            }/course/5ICEBwpbHVwwnK3C/video/8x97b6NLLi2nwXbZ0pMO4`}
                                        >
                                            《ACM班机器学习》
                                        </a>
                                    </div>
                                ),
                            });
                        });
                }
                if (reward) {
                    message.success(`获得 ${reward} 雨滴奖励`);
                }
            })
            .catch(err => {
                setEmailError(err?.response?.data?.message);
            });
    };

    const handleEmailInputBlur = fieldName => {
        form.validateFields([fieldName])
            .then(() => {
                setEmailHelp(EMAIL_HELP);
            })
            .catch(info => {
                setEmailError(info?.errorFields?.[0]?.errors?.[0]);
            });
    };

    /** 密码相关 state */
    const [passwordHelp, setPasswordHelp] = useState(PASSWORD_HELP);
    const setPasswordError = error => {
        setPasswordHelp(<div className="error">{error}</div>);
    };
    const [showPasswordInput, _setShowPasswordInput] = useState(false);
    const setShowPasswordInput = useCallback(
        show => {
            _setShowPasswordInput(show);
            // 密码和邮箱修改不同时显示，降低程序的复杂度，单个输入区域的用户体验也可能好一些
            initEmailMode();
        },
        [initEmailMode]
    );
    // 当 location search 是 password mode，展开密码编辑
    useEffect(() => {
        if (location.search.includes('mode=password')) {
            setShowPasswordInput(true);
        }
    }, [location.search, setShowPasswordInput]);
    const changePassword = password => {
        setPasswordHelp(' ');
        return remote
            .$patch('/user/password', { password }, { throwException: true })
            .then(() => {
                setPasswordHelp(PASSWORD_HELP);
                setShowPasswordInput(false);
                updateUser({ ...user, password: true });
                message.success('密码设置成功');
            })
            .catch(err => {
                setPasswordError(err?.response?.data?.message);
            });
    };
    const handlePasswordInputBlur = fieldNames => {
        form.validateFields(fieldNames)
            .then(() => {
                setPasswordHelp(PASSWORD_HELP);
            })
            .catch(info => {
                setPasswordError(info?.errorFields?.[0]?.errors?.[0]);
            });
    };

    const [form] = Form.useForm();

    return (
        <div className="my-account-wrapper">
            <div className="security-row">
                <div className="label">绑定手机</div>
                <div className="content">
                    <span>
                        +{user.countryCode} {user.phone}
                    </span>
                </div>
            </div>
            <div className="divider" />
            <div className="security-row">
                <div className="label">绑定邮箱</div>
                <div className="content">
                    {emailMode === EmailMode.TO_BE_UNBOUND ||
                    emailMode === EmailMode.TO_BE_UNCHANGED ? (
                        <Form
                            form={form}
                            onFieldsChange={(changedFields, allFields) => {
                                setEmailCodeCanSend(
                                    allFields[0].errors.length === 0 &&
                                        !!allFields[0].value
                                );
                            }}
                            onFinish={() => {
                                handleEmailBind();
                            }}
                        >
                            <Form.Item
                                name="email"
                                validateTrigger="onBlur"
                                rules={[
                                    {
                                        type: 'email',
                                        message: '该邮箱地址无效，请修改',
                                    },
                                ]}
                            >
                                <Input
                                    autoFocus
                                    placeholder="请输入邮箱地址"
                                    type="email"
                                    onBlur={() => handleEmailInputBlur('email')}
                                />
                            </Form.Item>
                            <Form.Item
                                style={{ marginBottom: 0 }}
                                name="emailCode"
                                extra={emailHelp}
                                validateTrigger="onBlur"
                                rules={[
                                    { required: true, message: '请输入验证码' },
                                ]}
                            >
                                <CaptchaInput
                                    onSend={() =>
                                        sendEmail(form.getFieldValue('email'))
                                    }
                                    onSendError={err => message.warn(err)}
                                    btnProps={{ disabled: !emailCodeCanSend }}
                                />
                            </Form.Item>
                            <Button type="primary" htmlType="submit">
                                绑定
                            </Button>
                        </Form>
                    ) : (
                        <div>
                            {user.emailAddress
                                ? user.emailAddress
                                : '暂未绑定邮箱'}
                        </div>
                    )}
                    <Button type="link" onClick={() => toggleEmailMode()}>
                        {emailMode}
                    </Button>
                </div>
            </div>
            <div className="divider" />
            <div className="security-row">
                <div className="label">绑定第三方账户</div>
                <div className="content">
                    {weChatNickname ? (
                        <div className="content-hint">
                            <WechatOutlined
                                style={{
                                    color: '#60BE38',
                                    fontSize: 21,
                                    marginRight: 8,
                                }}
                            />
                            {weChatNickname}
                            <span className="content-info">（已绑定）</span>
                        </div>
                    ) : (
                        <div className="content-hint">
                            <WechatOutlined
                                style={{
                                    color: '#60BE38',
                                    fontSize: 21,
                                    marginRight: 8,
                                }}
                            />
                            <span className="content-advice">
                                绑定后支持微信扫码登录、提问回复实时提醒
                            </span>
                        </div>
                    )}
                    {weChatNickname ? (
                        <Button
                            type="link"
                            onClick={e => {
                                remote.$delete('/oauth/fuwuhao').then(() => {
                                    message.success('解绑成功');
                                    updateWeChatNickname(null);
                                });
                            }}
                        >
                            解除绑定
                        </Button>
                    ) : (
                        <Button
                            type="link"
                            onClick={() => {
                                setWechatModalVisible(true);
                            }}
                        >
                            绑定微信
                        </Button>
                    )}
                </div>
            </div>
            <div className="divider" />
            <div className="security-row">
                <div className="label">密码设置</div>
                <div className="content">
                    {showPasswordInput ? (
                        <>
                            <Form
                                form={form}
                                onFinish={values => {
                                    if (!values?.password) return;
                                    changePassword(values?.password);
                                }}
                            >
                                <Form.Item
                                    name="password"
                                    validateTrigger="onBlur"
                                    rules={[
                                        {
                                            required: true,
                                            message: '请输入新密码',
                                        },
                                    ]}
                                >
                                    <Input
                                        autoFocus
                                        placeholder="请输入新密码"
                                        type="password"
                                        onBlur={() =>
                                            handlePasswordInputBlur([
                                                'password',
                                            ])
                                        }
                                    />
                                </Form.Item>
                                <Form.Item
                                    style={{ marginBottom: 0 }}
                                    name="confirmPassword"
                                    extra={passwordHelp}
                                    dependencies={['password']}
                                    validateTrigger="onBlur"
                                    rules={[
                                        {
                                            required: true,
                                            message: '请再次输入密码',
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(rule, value) {
                                                // TODO: password 联动校验没法触发内部 onBlur，加上 hook 在这里面无法使用，暂不显示联动 error
                                                if (
                                                    !value ||
                                                    getFieldValue(
                                                        'password'
                                                    ) === value
                                                ) {
                                                    return Promise.resolve(
                                                        PASSWORD_HELP
                                                    );
                                                }
                                                return Promise.reject(
                                                    '两次输入密码不一致'
                                                );
                                            },
                                        }),
                                    ]}
                                >
                                    <Input
                                        placeholder="再次输入密码"
                                        type="password"
                                        onBlur={() =>
                                            handlePasswordInputBlur([
                                                'confirmPassword',
                                            ])
                                        }
                                    />
                                </Form.Item>
                                <Button type="primary" htmlType="submit">
                                    设置密码
                                </Button>
                            </Form>
                            <Button
                                type="link"
                                onClick={() => setShowPasswordInput(false)}
                            >
                                取消设置
                            </Button>
                        </>
                    ) : (
                        <>
                            <span>{user.password ? '已设置' : '未设置'}</span>
                            <Button
                                type="link"
                                onClick={() => setShowPasswordInput(true)}
                            >
                                {user.password ? '修改密码' : '设置密码'}
                            </Button>
                        </>
                    )}
                </div>
            </div>
            {wechatModalVisible && (
                <FuwuhaoBindModal
                    userId={user.id}
                    ignoreStorage
                    rewardMessageDisabled
                    onSuccess={async () => {
                        setWechatModalVisible(false);
                        const wechatUser = await remote.$get(
                            '/oauth/fuwuhao',
                            {},
                            { hideLoading: true }
                        );
                        updateWeChatNickname(wechatUser?.nickname);
                    }}
                    onCancel={() => {
                        setWechatModalVisible(false);
                    }}
                />
            )}
        </div>
    );
};

export { MyAccount };
