import React, { useState, useCallback, useEffect } from 'react';
import { Button, Radio, Table } from 'antd';
import { remote } from '@boyuai/utils';
import FuwuhaoAutoReplyFormModal from './auto-reply-form-modal';

const FUWUHAO = 'fuwuhao'; // 伯禹教育
const FUWUHAO_BOXIAOYU = 'fuwuhao-boxiaoyu'; // 博小鱼

const COLUMNS = [
    {
        title: 'key',
        dataIndex: 'key',
        key: 'key',
    },
    {
        title: '关键词',
        dataIndex: 'keyword',
        key: 'keyword',
        width: '150px',
    },
    {
        title: 'content',
        dataIndex: 'content',
        key: 'content',
        render: content => JSON.stringify(content),
    },
];

export default function AdminFuwuhaoAutoReply() {
    const [fuwuhaoType, setFuwuhaoType] = useState(FUWUHAO);
    const [editRow, setEditRow] = useState(null);
    const [createModalVisible, setCreateModalVisible] = useState(false);
    const [autoReplyList, setAutoReplyList] = useState([]);

    const fetchAutoReplies = useCallback(async () => {
        const res = await remote.$get('/admin/fuwuhao/auto-replies', {
            fuwuhaoType,
        });
        setAutoReplyList(res);
    }, [fuwuhaoType]);

    useEffect(() => {
        fetchAutoReplies();
    }, [fetchAutoReplies]);

    const deleteAutoReply = useCallback(
        async key => {
            await remote.$delete(
                `/admin/fuwuhao/auto-replies?fuwuhaoType=${fuwuhaoType}&key=${key}`,
                null
            );
            await fetchAutoReplies();
        },
        [fuwuhaoType, fetchAutoReplies]
    );

    return (
        <div>
            <h3 style={{ marginBottom: 16 }}>服务号自动回复</h3>
            <div
                style={{
                    marginBottom: 16,
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
            >
                <Radio.Group
                    buttonStyle="solid"
                    value={fuwuhaoType}
                    onChange={e => setFuwuhaoType(e.target.value)}
                >
                    <Radio.Button value={FUWUHAO}>服务号</Radio.Button>
                    <Radio.Button value={FUWUHAO_BOXIAOYU}>博小鱼</Radio.Button>
                </Radio.Group>
                <Button
                    type="primary"
                    onClick={() => setCreateModalVisible(true)}
                >
                    创建新的自动回复
                </Button>
            </div>
            <Table
                columns={[
                    ...COLUMNS,
                    {
                        title: '操作',
                        key: 'operation',
                        width: '240px',
                        render: row => (
                            <div>
                                <Button onClick={() => setEditRow(row)}>
                                    编辑
                                </Button>
                                &nbsp;
                                <Button
                                    type="danger"
                                    onClick={() => deleteAutoReply(row.key)}
                                >
                                    删除
                                </Button>
                            </div>
                        ),
                    },
                ]}
                dataSource={autoReplyList}
                pagination={false}
                rowKey={'key'}
            />
            {editRow && (
                <FuwuhaoAutoReplyFormModal
                    fuwuhaoType={fuwuhaoType}
                    value={editRow}
                    visible={true}
                    onOk={() => {
                        setEditRow(null);
                        fetchAutoReplies();
                    }}
                    onCancel={() => setEditRow(null)}
                />
            )}
            {createModalVisible && (
                <FuwuhaoAutoReplyFormModal
                    fuwuhaoType={fuwuhaoType}
                    visible={true}
                    onOk={() => {
                        setCreateModalVisible(false);
                        fetchAutoReplies();
                    }}
                    onCancel={() => setCreateModalVisible(false)}
                />
            )}
        </div>
    );
}
