import React, { useCallback, useState, useEffect } from 'react';
import moment from 'moment';
import { Popconfirm, Spin } from 'antd';
import convertSiteToHostName from '@boyuai/site-address-converter';
import { PreviewEditor } from '@boyuai/comment';
import { $delete, $get } from '@boyuai/utils/es/remote';

const ILike =
    'https://staticcdn.boyuai.com/materials/2020/11/19/NIeVyyXa8O1DAG4y5NM4K.png';
const IReply =
    'https://staticcdn.boyuai.com/materials/2020/11/19/mLLHrqspeIAoME_hMWPtz.png';

export default function UserComment({ comment, onDelete }) {
    const [title, setTitle] = useState(null);
    const [titleLoading, setTitleLoading] = useState(null);
    const deleteComment = useCallback(async () => {
        await $delete(`/comment/${comment.id}`);
        onDelete(comment.id);
    }, [onDelete, comment]);
    useEffect(() => {
        if (comment.link && comment.site) {
            const search = /video\/([^/#]*)/.exec(comment.link);
            if (search && search[1]) {
                setTitle(true);
                $get(
                    `/course/get-lesson-name-from-video-uuid/${search[1]}`,
                    {},
                    { throwException: true }
                )
                    .then(value => {
                        setTitle(value);
                        setTitleLoading(false);
                    })
                    .catch(e => {
                        setTitleLoading(false);
                    });
            }
        }
    }, [comment]);
    if (!comment) {
        return null;
    }
    const { site, link, createdAt, content, likeCount, replyCount } = comment;
    const commentLink =
        site && link
            ? `${convertSiteToHostName(site, process.env.REACT_APP_ENV)}${link}`
            : null;
    return (
        <div className="comment-block">
            <div className="comment-header">
                {commentLink && (
                    <div>
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={commentLink}
                        >
                            <Spin spinning={titleLoading}>
                                {title || '评论链接'}
                            </Spin>
                        </a>
                    </div>
                )}
                <div>
                    <img src={IReply} alt="回复" />
                    {replyCount}
                </div>
                <div>
                    <img src={ILike} alt="赞" />
                    {likeCount}
                </div>
                <div className="time">
                    {moment
                        ? moment(createdAt).format('YYYY-MM-DD HH:mm')
                        : '未知时间'}
                </div>
                <div className="space" />
                <div className="delete">
                    <Popconfirm
                        onConfirm={deleteComment}
                        title="确定要删除吗，删除后不可恢复"
                    >
                        删除
                    </Popconfirm>
                </div>
            </div>
            <div className="comment-body">
                {content ? <PreviewEditor value={content} /> : '**评论已删除**'}
            </div>
        </div>
    );
}
