import React, { useState, useEffect, useCallback } from 'react';
import { $get } from '@boyuai/utils/es/remote';
import { Tabs, Pagination, Spin } from 'antd';
import LessonsContent from './lessons-content';
import './my-lesson.less';
const { TabPane } = Tabs;

const PAGE_SIZE = 20;

export default function MyLessons() {
    const [finishedLessonsArr, setFinishedLessonsArr] = useState([]);
    const [finishedLessonsCount, setFinishedLessonsCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [currentFinishedPage, setCurrentFinishedPage] = useState(1);
    const [currentFinishedOrder, setCurrentFinishedOrder] = useState(
        'lastLearntAt'
    );
    const [shouldFilterQuizzing, setShouldFilterQuizzing] = useState(false);
    const [shouldFilterFeedback, setShouldFilterFeedback] = useState(false);
    const [unfinishedLessonsCount, setUnfinishedLessonsCount] = useState(0);
    const [unfinishedLessonsArr, setUnfinishedLessonsArr] = useState([]);
    const [currentUnfinishedPage, setCurrentUnfinishedPage] = useState(1);
    const [currentUnfinishedOrder, setCurrentUnfinishedOrder] = useState(
        'lastLearntAt'
    );
    const [tabKey, setTabKey] = useState(0);
    const [reload, setReload] = useState(false);
    const tabArr = [
        {
            key: 0,
            title: `未完成（${unfinishedLessonsCount}）`,
        },
        {
            key: 1,
            title: `已完成（${finishedLessonsCount}）`,
        },
    ];

    const fetchFinishedLessons = useCallback(async () => {
        setLoading(true);
        let { lessons, count } = await $get('/course/my/lessons', {
            offset: (currentFinishedPage - 1) * PAGE_SIZE,
            limit: PAGE_SIZE,
            filter: shouldFilterFeedback ? 'feedback' : 'finished',
            order: currentFinishedOrder,
        });
        setLoading(false);
        setFinishedLessonsArr(lessons);
        setFinishedLessonsCount(count);
        setReload(false);
    }, [currentFinishedPage, currentFinishedOrder, shouldFilterFeedback]);

    const fetchUnfinishedLessons = useCallback(async () => {
        setLoading(true);
        const { lessons, count } = await $get('/course/my/lessons', {
            offset: (currentUnfinishedPage - 1) * PAGE_SIZE,
            limit: PAGE_SIZE,
            filter: shouldFilterQuizzing ? 'quizzing' : 'learning',
            order: currentUnfinishedOrder,
        });
        setLoading(false);
        setUnfinishedLessonsArr(lessons);
        setUnfinishedLessonsCount(count);
    }, [currentUnfinishedPage, currentUnfinishedOrder, shouldFilterQuizzing]);

    useEffect(() => {
        fetchUnfinishedLessons();
    }, [fetchUnfinishedLessons]);
    useEffect(() => {
        fetchFinishedLessons();
    }, [fetchFinishedLessons]);

    useEffect(() => {
        if (reload) {
            fetchFinishedLessons();
        }
    }, [fetchFinishedLessons, reload]);

    const onOrderChange = order => {
        tabKey
            ? setCurrentFinishedOrder(order)
            : setCurrentUnfinishedOrder(order);
        tabKey ? setCurrentFinishedPage(1) : setCurrentUnfinishedPage(1);
    };

    const renderTabPane = () => {
        return tabArr.map(info => {
            const { key, title } = info;
            return (
                <TabPane tab={title} key={key} onChange={setTabKey}>
                    {loading ? (
                        <div className="loading">
                            <Spin />
                        </div>
                    ) : (
                        <>
                            <LessonsContent
                                lessonArr={
                                    key
                                        ? finishedLessonsArr
                                        : unfinishedLessonsArr
                                }
                                isFinished={!!key}
                                onOrderChange={onOrderChange}
                                order={
                                    key
                                        ? currentFinishedOrder
                                        : currentUnfinishedOrder
                                }
                                onFilter={
                                    key
                                        ? setShouldFilterFeedback
                                        : setShouldFilterQuizzing
                                }
                                shouldFilter={
                                    key
                                        ? shouldFilterFeedback
                                        : shouldFilterQuizzing
                                }
                                setReload={setReload}
                            />
                            <Pagination
                                showQuickJumper
                                defaultCurrent={1}
                                current={
                                    key
                                        ? currentFinishedPage
                                        : currentUnfinishedPage
                                }
                                total={
                                    key
                                        ? finishedLessonsCount
                                        : unfinishedLessonsCount
                                }
                                pageSize={PAGE_SIZE}
                                onChange={
                                    key
                                        ? setCurrentFinishedPage
                                        : setCurrentUnfinishedPage
                                }
                            />
                        </>
                    )}
                </TabPane>
            );
        });
    };
    return (
        <div className="my-lessons-wrapper">
            <Tabs defaultActiveKey="unfinished">{renderTabPane()}</Tabs>
        </div>
    );
}
