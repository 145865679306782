import React, { useCallback, useEffect, useState } from 'react';
import { Button, Popconfirm, Spin } from 'antd';
import moment from 'moment';
import { PreviewEditor } from '@boyuai/comment';
import { remote } from '@boyuai/utils';
import { StarOutlined, UserOutlined, LikeOutlined } from '@ant-design/icons';
import './comment-table-item.less';
import converter from '@boyuai/site-address-converter';
import CommentBanModal from './comment-ban-modal';

const convertSiteToHostName = site => {
    return converter(site, process.env.REACT_APP_ENV); // 由于后端也要用，所以抽成包了
};

const translateType = type => {
    switch (type) {
        case 'question':
            return '提问';
        case 'note':
            return '笔记';
        case 'talk':
            return '闲聊';
        default:
            return '闲聊';
    }
};

const CommentTableItem = props => {
    const { item, index, onSearch, onDelete } = props;
    const [title, setTitle] = useState(null);
    const [titleLoading, setTitleLoading] = useState(null);
    const [banModalVisible, setBanModalVisible] = useState(false);

    const deleteComment = useCallback(async () => {
        await remote.$delete(`/course/admin/comments/${item.id}`);
        onDelete && onDelete();
    }, [item, onDelete]);

    useEffect(() => {
        if (item.link && item.site) {
            const search = /video\/([^/#]*)/.exec(item.link);
            if (search && search[1]) {
                setTitle(true);
                remote
                    .$get(
                        `/course/get-names-from-video-uuid/${search[1]}`,
                        {},
                        { throwException: true }
                    )
                    .then(({ lessonTitle, courseTitle }) => {
                        setTitle(`${lessonTitle} -- 《${courseTitle}》`);
                        setTitleLoading(false);
                    })
                    .catch(e => {
                        setTitleLoading(false);
                    });
            }
        }
    }, [item]);

    const commentBanExpiredAt =
        item.commentBan &&
        moment(item.commentBan.expiredAt).format('YYYY-MM-DD HH:mm:ss');

    const commentLink =
        item.site && item.link
            ? `${convertSiteToHostName(item.site)}${item.link}`
            : null;

    return (
        <div className="comment-table-item">
            <div className="comment-table-item-index">#{index + 1}</div>
            <div className="comment-table-item-left">
                <div className="comment-table-item-type">
                    {translateType(item.type)}
                </div>
                <div>
                    {item.selected && <StarOutlined style={{ color: 'red' }} />}
                </div>
            </div>
            <div className="comment-table-item-detail">
                <div className="comment-table-item-head">
                    <div className="comment-table-item-info">
                        <span>
                            <UserOutlined type="user" />
                        </span>
                        {item.userName ? (
                            item.userName
                        ) : (
                            <span
                                style={{
                                    fontStyle: 'italic',
                                    color: '#a9a9a9',
                                }}
                            >
                                无
                            </span>
                        )}
                        {item.user && item.user.categoryLabels && (
                            <span
                                style={{ color: '#08c' }}
                            >{`[${item.user.categoryLabels}]`}</span>
                        )}
                        <span style={{ color: 'red' }}>
                            <LikeOutlined />
                            {item.like}
                        </span>
                    </div>
                    <div>
                        {commentLink && (
                            <a
                                className="comment-table-item-link"
                                href={commentLink}
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                <Spin spinning={titleLoading}>
                                    {title || '评论链接'}
                                </Spin>
                            </a>
                        )}
                    </div>
                    <div className="comment-table-item-time">
                        {moment(item.createdAt).format('YYYY-MM-DD HH:mm:ss')}
                    </div>
                </div>
                <div className="comment-table-item-content">
                    {item.content && Object.keys(item.content).length ? (
                        <PreviewEditor
                            value={item.content}
                            maskHeight={100}
                            foldHeight={100}
                        />
                    ) : (
                        '**评论已删除**'
                    )}
                </div>
            </div>
            <div className="comment-table-item-operation">
                {banModalVisible && (
                    <CommentBanModal
                        commentItem={item}
                        onClose={() => setBanModalVisible(false)}
                    />
                )}
                <Button type="primary" onClick={() => onSearch()}>
                    TA的所有评论
                </Button>
                <Button
                    ghost
                    type="danger"
                    onClick={() => setBanModalVisible(true)}
                >
                    禁言
                </Button>
                <div>
                    {commentBanExpiredAt && `已禁言至${commentBanExpiredAt}`}
                </div>
                {item.deletedAt ? (
                    <div>
                        <Button type="danger" disabled>
                            已删除
                        </Button>
                        <div>
                            {moment(item.deletedAt).format(
                                'YYYY-MM-DD HH:mm:ss'
                            )}
                        </div>
                    </div>
                ) : (
                    <Popconfirm
                        title="确定要删除？"
                        onConfirm={() => deleteComment(item.id)}
                    >
                        <Button type="danger">删除</Button>
                    </Popconfirm>
                )}
            </div>
        </div>
    );
};
export default CommentTableItem;
