/**
 * 很老的代码了，临时用一下
 * @author helsonxiao
 */
import React from 'react';
import lodash from 'lodash';
import {
  message,
  Button,
  Card,
  Spin,
  Empty,
  Row,
  Col,
  Popover,
  List,
  Form,
  Input,
  Radio,
  Switch,
} from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import './index.less';

type EditorMode = number;
const PreviewMode: EditorMode = 0;
const EditMode: EditorMode = 1;
const Mode: { [key: string]: EditorMode } = {
  PREVIEW: PreviewMode,
  EDIT: EditMode,
};
const MenuItemLimit = 3;
const SubMenuItemLimit = 5;

interface IMenuItem {
  name: string;
  type?: 'view' | 'media_id';
  url?: string;
  media_id?: string;
  sub_button?: {
    list: IMenuItem[]
  };
}

interface IWechatMenu {
  button: IMenuItem[];
}

function PreviewMenuButton({
  item,
  width,
}: {
  item: IMenuItem;
  width: string;
}) {
  return (
    <Popover
      trigger="hover"
      content={
        <List
          dataSource={item.sub_button?.list || []}
          renderItem={subItem => (
            <List.Item>
              <List.Item.Meta
                title={
                  <span
                    className="clickable"
                    onClick={() => {
                      if (subItem.media_id) {
                        message.warn({ content: '暂不支持预览素材' });

                        return;
                      }
                      window.open(subItem.url, '_blank');
                    }}
                  >
                    {subItem.name}
                  </span>
                }
              />
            </List.Item>
          )}
        />
      }
    >
      <Button /* icon="menu" */ style={{ width }}>
        {item.name}
      </Button>
    </Popover>
  );
}

function PreviewButton({ item, width }: { item: IMenuItem; width: string }) {
  return (
    <Button onClick={() => window.open(item.url, '_blank')} style={{ width }}>
      {item.name}
    </Button>
  );
}

function EditButton({
  item,
  width,
  onClick,
}: {
  item: IMenuItem;
  width: string;
  onClick: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
}) {
  return (
    <Button /* icon="edit" */ style={{ width }} onClick={onClick}>
      <span>{item.name}</span>
    </Button>
  );
}

interface IEditMenuButton {
  item: IMenuItem;
  width: string;
  onClick: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
  onAdd: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
  onSubClick: Function;
  onSubDelete: Function;
}

function EditMenuButton({
  item,
  width,
  onClick,
  onAdd,
  onSubClick,
  onSubDelete,
}: IEditMenuButton) {
  console.log({ item });
  
  return (
    <Popover
      trigger="hover"
      content={
        <List
          dataSource={item.sub_button?.list || []}
          renderItem={subItem => (
            <List.Item>
              <List.Item.Meta
                title={
                  <span>
                    <span
                      className="clickable"
                      onClick={() => onSubClick(subItem)}
                    >
                      {subItem.name}{' '}
                    </span>
                    <DeleteOutlined
                      className="clickable"
                      onClick={() => onSubDelete(subItem)}
                    />
                  </span>
                }
              />
            </List.Item>
          )}
        >
          <List.Item>
            <span className="clickable" onClick={onAdd}>
              添加子菜单
            </span>
          </List.Item>
        </List>
      }
    >
      <Button /* icon="edit" */ style={{ width }} onClick={onClick}>
        <span>{item.name}</span>
      </Button>
    </Popover>
  );
}

interface IProps {
  loading?: boolean;
  releasing?: boolean;
  menu: IWechatMenu;
  onChange: Function;
  onRelease: Function;
}

class WechatMenuEditor extends React.Component<IProps> {
  state: {
    mode: EditorMode;
    currentItem: IMenuItem | null;
    newItem: IMenuItem | null;
    menuData: IWechatMenu | null;
    editingSubItem: boolean;
    defaultMenu: IWechatMenu | null;
  } = {
    mode: Mode.EDIT,
    currentItem: null,
    newItem: null,
    menuData: null,
    editingSubItem: false,
    defaultMenu: null,
  };

  componentDidMount() {
    const { props: newProps } = this;
    console.log('componentDidMount', { newProps });

    this.setState({
      menuData: lodash.cloneDeep(newProps.menu),
    });
    if (newProps.menu.button[0]) {
      this.setState({
        currentItem: newProps.menu.button[0],
        newItem: lodash.cloneDeep(newProps.menu.button[0]),
        defaultMenu: lodash.cloneDeep(newProps.menu),
      });
    }
  }

  switchMode = () => {
    if (this.state.mode === Mode.PREVIEW) {
      this.setState({ mode: Mode.EDIT });

      return;
    }

    this.setState({ mode: Mode.PREVIEW });
  };

  getMenuButtonWidth = (buttons: IMenuItem[]) => {
    if (this.state.mode === Mode.EDIT) {
      if (buttons.length < MenuItemLimit) {
        return ((1 / (buttons.length + 1)) * 100).toFixed(2) + '%';
      }
    }

    return ((1 / buttons.length) * 100).toFixed(2) + '%';
  };

  addMenuItem = () => {
    const { menuData } = this.state;
    if (!menuData) return;
    if (menuData.button.length === MenuItemLimit) return;
    const newMenuItem: IMenuItem = {
      name: `菜单${menuData.button.length + 1}`,
      sub_button: {
        list: [],
      },
    };
    const newMenuData = lodash.cloneDeep(menuData);
    newMenuData.button.push(newMenuItem);
    this.setState(
      {
        menuData: newMenuData,
        currentItem: newMenuItem,
        newItem: lodash.cloneDeep(newMenuItem),
        editingSubItem: false,
      },
      () => {
        this.props.onChange(this.state.menuData);
      },
    );
  };

  addSubMenuItem = (item: IMenuItem) => {
    if (!item.sub_button) return;
    if (item.sub_button.list.length === SubMenuItemLimit) {
      message.warning({
        content: `每个一级菜单最多包含 ${SubMenuItemLimit} 个二级菜单`,
      });

      return;
    }
    const newSubItem: IMenuItem = {
      name: `二级菜单${item.sub_button.list.length + 1}`,
      type: 'view',
    };
    item.sub_button.list.push(newSubItem);
    this.setState(
      {
        menuData: this.state.menuData,
        currentItem: newSubItem,
        newItem: lodash.cloneDeep(newSubItem),
        editingSubItem: true,
      },
      () => {
        this.props.onChange(this.state.menuData);
      },
    );
  };

  deleteMenuItem = (name: string, isSubItem: boolean) => {
    const { menuData } = this.state;
    if (!menuData) return;
    if (!isSubItem) {
      const deprecatedIndex = menuData.button.findIndex(
        item => item.name === name,
      );
      if (deprecatedIndex !== -1) {
        menuData.button.splice(deprecatedIndex, 1);
        this.setState(
          { menuData: this.state.menuData, currentItem: null, newItem: null },
          () => {
            this.props.onChange(this.state.menuData);
          },
        );
      }

      return;
    }

    let deprecatedIndex = -1;
    let parentIndex = -1;
    menuData.button.forEach((item, index) => {
      if (item.sub_button && item.sub_button.list.length) {
        const tmp = item.sub_button.list.findIndex(subItem => subItem.name === name);
        if (tmp !== -1) {
          parentIndex = index;
          deprecatedIndex = tmp;
        }
      }
    });
    if (deprecatedIndex !== -1 && parentIndex !== -1) {
      const parent = menuData.button[parentIndex];
      if (parent.sub_button) {
        parent.sub_button.list.splice(deprecatedIndex, 1);
        this.setState(
          { menuData: this.state.menuData, currentItem: null, newItem: null },
          () => {
            this.props.onChange(this.state.menuData);
          },
        );
      }
    }
  };

  renderMenu = () => {
    if (this.props.loading) {
      return (
        <Spin spinning>
          <div className="wechat-menu" />
        </Spin>
      );
    }

    const { menuData } = this.state;
    if (!menuData) return;
    const buttons = menuData.button;
    const width = this.getMenuButtonWidth(buttons);

    if (this.state.mode === Mode.PREVIEW) {
      return (
        <div className="wechat-menu">
          {buttons.map(item => {
            if (item.sub_button) {
              return (
                <PreviewMenuButton key={item.name} width={width} item={item} />
              );
            }

            return <PreviewButton key={item.name} width={width} item={item} />;
          })}
        </div>
      );
    }

    return (
      <div className="wechat-menu">
        {buttons.map(item => {
          if (item.sub_button) {
            return (
              <EditMenuButton
                key={item.name}
                item={item}
                width={width}
                onClick={() => this.handleItemClick(item, false)}
                onAdd={() => this.addSubMenuItem(item)}
                onSubClick={(subItem: IMenuItem) =>
                  this.handleItemClick(subItem, true)
                }
                onSubDelete={(subItem: IMenuItem) =>
                  this.deleteMenuItem(subItem.name, true)
                }
              />
            );
          }

          return (
            <EditButton
              key={item.name}
              item={item}
              width={width}
              onClick={() => this.handleItemClick(item, false)}
            />
          );
        })}
        {buttons.length < MenuItemLimit && (
          <Button /* icon="plus" */ style={{ width }} onClick={this.addMenuItem}>+</Button>
        )}
      </div>
    );
  };

  handleItemClick = (item: IMenuItem, editingSubItem: boolean) => {
    this.setState({
      currentItem: item,
      newItem: lodash.cloneDeep(item),
      editingSubItem,
    });
  };

  sameItemExists = (name: string) => {
    let res = false;
    const { menuData } = this.state;
    if (!menuData) return true;
    menuData.button.forEach(item => {
      if (item.name === name) {
        res = true;
      }
      if (item.sub_button && item.sub_button.list.length) {
        item.sub_button.list.forEach(subItem => {
          if (subItem.name === name) {
            res = true;
          }
        });
      }
    });

    return res;
  };

  handleItemSubmit = (e?: React.FormEvent<HTMLFormElement>) => {
    if (e) {
      e.preventDefault();
    }
    const { newItem, currentItem, menuData } = this.state;
    if (!newItem || !currentItem || !menuData) return;
    if (
      newItem.name !== currentItem.name &&
      this.sameItemExists(newItem.name)
    ) {
      message.warn({ content: '已存在同名的菜单' });

      return;
    }

    const newMenu: IWechatMenu = lodash.cloneDeep(menuData);
    let ref: any = null;
    newMenu.button.forEach(item => {
      if (item.sub_button) {
        item.sub_button.list.forEach(subItem => {
          if (subItem.name === currentItem.name) {
            ref = subItem;
          }
        });
      }

      if (item.name === currentItem.name) {
        ref = item;
      }
    });

    if (!ref) {
      message.error({ content: '保存失败' });

      return;
    }
    Object.keys(ref).forEach(key => delete ref[key]);
    Object.assign(ref, newItem);
    this.setState({ menuData: newMenu, currentItem: newItem }, () => {
      this.props.onChange(this.state.menuData);
      message.success({ content: '保存菜单项成功' });
    });
  };

  renderItem = () => {
    const { newItem, editingSubItem, mode } = this.state;
    if (!newItem) return null;
    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
    };

    return (
      <Form {...formItemLayout} onFinish={this.handleItemSubmit}>
        <Form.Item
          label="菜单名称"
          help="一级菜单最多 4 个汉字，二级菜单最多 7 个汉字，多出来的部分将会以 “...” 代替"
        >
          <Input
            disabled={mode === Mode.PREVIEW}
            placeholder="请输入菜单名称"
            value={newItem.name}
            onChange={(e: any) => {
              this.setState({ newItem: { ...newItem, name: e.target.value } });
            }}
          />
        </Form.Item>
        {!editingSubItem && (
          <Form.Item
            label="开启子菜单"
            help="开启后必须添加子菜单，否则无法发布"
          >
            <Switch
              disabled={mode === Mode.PREVIEW}
              checked={newItem.type === undefined}
              onChange={(checked: boolean) => {
                if (checked) {
                  delete newItem.type;
                  delete newItem.url;
                  delete newItem.media_id;
                  this.setState({ newItem: { ...newItem, sub_button: [] } });

                  return;
                }
                delete newItem.sub_button;
                delete newItem.url;
                delete newItem.media_id;
                this.setState({ newItem: { ...newItem, type: 'view' } });
              }}
            />
          </Form.Item>
        )}
        {newItem.type !== undefined && (
          <Form.Item label="操作类型">
            <Radio.Group value={newItem.type}>
              <Radio
                disabled={mode === Mode.PREVIEW}
                value="view"
                onChange={() => {
                  delete newItem.media_id;
                  this.setState({ newItem: { ...newItem, type: 'view' } });
                }}
              >
                跳转链接
              </Radio>
              <Radio
                disabled={mode === Mode.PREVIEW}
                value="media_id"
                onChange={() => {
                  delete newItem.url;
                  this.setState({ newItem: { ...newItem, type: 'media_id' } });
                }}
              >
                返回素材
              </Radio>
            </Radio.Group>
          </Form.Item>
        )}
        {newItem.type === 'view' && (
          <Form.Item required label="链接地址">
            <Input
              disabled={mode === Mode.PREVIEW}
              placeholder="请输入链接地址"
              value={newItem.url}
              onChange={(e: any) => {
                this.setState({ newItem: { ...newItem, url: e.target.value } });
              }}
            />
          </Form.Item>
        )}
        {newItem.type === 'media_id' && (
          <Form.Item required label="素材 ID">
            <Input
              disabled={mode === Mode.PREVIEW}
              placeholder="请输入公众号素材 ID"
              value={newItem.media_id}
              onChange={(e: any) => {
                this.setState({
                  newItem: { ...newItem, media_id: e.target.value },
                });
              }}
            />
          </Form.Item>
        )}
        <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
          <Button
            disabled={mode === Mode.PREVIEW}
            type="primary"
            htmlType="submit"
          >
            保存当前菜单项
          </Button>
        </Form.Item>
      </Form>
    );
  };

  renderItemLayout = () => {
    const { mode, currentItem, editingSubItem } = this.state;

    return (
      <Card
        loading={this.props.loading}
        className="menu-item-layout"
        title="菜单编辑"
        extra={
          mode === Mode.EDIT && (
            <span
              onClick={e => {
                e.preventDefault();
                if (currentItem)
                  this.deleteMenuItem(currentItem.name, editingSubItem);
              }}
            >
              删除菜单
            </span>
          )
        }
      >
        {currentItem ? this.renderItem() : <Empty />}
      </Card>
    );
  };

  handleReset = () => {
    this.setState({ menuData: this.state.defaultMenu }, () => {
      this.props.onChange(this.state.menuData);
    });
    message.success({ content: '重置成功' });
  };

  render() {
    return (
      <div className="wechat-menu-editor">
        <Row gutter={[16, 16]}>
          <Col span={10}>{this.renderMenu()}</Col>
          <Col span={14}>{this.renderItemLayout()}</Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={10}>
            <Row className="editor-bottom" justify="space-between">
              <Switch
                checked={this.state.mode === Mode.EDIT}
                checkedChildren="编辑模式"
                unCheckedChildren="预览模式"
                onChange={this.switchMode}
              />
              <div>
                <Button
                  style={{ marginRight: '8px' }}
                  onClick={this.handleReset}
                >
                  重置
                </Button>
                <Button
                  loading={this.props.releasing}
                  type="primary"
                  onClick={() => this.props.onRelease()}
                >
                  发布
                </Button>
              </div>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}

export default WechatMenuEditor;
