import React from 'react';

const LiangZiWeiBannerLink = ({ desc, text, href, comingSoon }) => {
    return (
        <div className={`liang-zi-wei ${comingSoon ? 'coming-soon' : ''}`}>
            <div className="desc">{desc}</div>
            <a
                href={href}
                disabled={comingSoon}
                className="enter"
                target="_blank"
                rel="noopener noreferrer"
            >
                <div className="circle">
                    {!comingSoon ? (
                        <img
                            src="https://static-dev.boyuai.com/materials/2019/12/20/E6te0j3hjeeLPQrDcBgiz.png"
                            className="arrow"
                            alt=""
                            width="15px"
                            height="12px"
                        />
                    ) : (
                        <img
                            className="star"
                            src="https://staticcdn.boyuai.com/materials/2020/09/03/c50E5lAPzT9Oxb5qZ-f3Z.png!png"
                            alt=""
                            width="15px"
                            height="12px"
                        />
                    )}
                </div>
                {comingSoon ? (
                    <div className="text space">春季即将上线</div>
                ) : (
                    <div className="text space">{text}</div>
                )}
            </a>
        </div>
    );
};

export default LiangZiWeiBannerLink;
