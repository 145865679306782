import React, { useState, useCallback } from 'react';
import { Select, Table, Pagination, Button, Popconfirm } from 'antd';
import { useUserActivities } from '../../../hooks/admin/activities';
import _ from 'lodash';
import { remote } from '@boyuai/utils';
import moment from 'moment';

const COLUMNS = [
    {
        title: '活动名称',
        dataIndex: 'type',
        key: 'type',
        render: type => {
            const act = ACTIVITIES.find(a => a.key === type);
            return act ? (
                <div>
                    <div>{act.title}</div>
                    <div>{act.type}</div>
                </div>
            ) : null;
        },
    },
    {
        title: '用户id',
        dataIndex: 'userId',
        key: 'userId',
    },
    // {
    //     title: '时间',
    //     dataIndex: 'createdAt',
    //     key: 'createdAt',
    //     render: createdAt =>
    //         createdAt && moment(createdAt).format('YYYY-MM-DD HH:mm:ss'),
    // },
];

const ACTIVITIES = [
    {
        key: 'boyu-4th',
        title: '第四次打卡活动',
    },
];

const getColumns = activityType => {
    switch (activityType) {
        case 'boyu-4th':
            return [
                ...COLUMNS,
                {
                    title: '手机号',
                    dataIndex: ['user', 'phone'],
                    key: 'user.phone',
                },
                {
                    title: '用户名',
                    dataIndex: ['user', 'name'],
                    key: 'user.name',
                },
                {
                    title: '活动进展(开始打卡)',
                    dataIndex: 'state',
                    key: 'state',
                    render: state => (state === 1 ? '是' : ''),
                },
                {
                    title: '活动进展(弹窗)',
                    dataIndex: ['content', 'popped'],
                    key: 'content.popped',
                    render: popped => (popped ? '是' : ''),
                },
                {
                    title: '活动进展(点击领取课程)',
                    dataIndex: ['content', 'modal1CourseButtonClickedAt'],
                    key: 'content.modal1CourseButtonClickedAt',
                },
                {
                    title: '活动进展(选课)',
                    dataIndex: ['content', 'course'],
                    key: 'content.course',
                },
                {
                    title: '活动进展(打卡天数)',
                    dataIndex: ['content', 'signLogs'],
                    key: 'content.signLogs',
                    render: signLogs => signLogs && signLogs.length,
                },
                {
                    title: '结束时间',
                    dataIndex: 'endAt',
                    key: 'endAt',
                    render: endAt =>
                        moment(endAt).isBefore(moment())
                            ? '已过期'
                            : moment(endAt).format('YYYY-MM-DD HH:mm:ss'),
                },
            ];
        default:
            return COLUMNS;
    }
};

export default function AdminActivities() {
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [activityType, setActivityType] = useState('boyu-4th');
    const [phones, setPhones] = useState([]);

    const [events, total, fetchEvents] = useUserActivities(
        activityType,
        pageSize * (page - 1),
        pageSize,
        phones
    );

    const activateState1 = useCallback(
        async row => {
            await remote.$patch(
                `/course/admin/user-activity/${row.id}/${row.userId}`,
                {
                    content: {
                        manuallyActivateState1: true,
                    },
                }
            );
            fetchEvents();
        },
        [fetchEvents]
    );

    return (
        <div style={{ marginTop: '20px' }}>
            <Select
                virtual={false}
                value={activityType}
                placeholder={'活动'}
                style={{ width: '300px', marginBottom: '10px' }}
                onChange={v => {
                    setActivityType(v);
                }}
            >
                {ACTIVITIES.map(event => (
                    <Select.Option value={event.key} key={event.key}>
                        {event.title}
                    </Select.Option>
                ))}
            </Select>
            <Select
                placeholder={'手机号'}
                mode="tags"
                style={{ width: '100%' }}
                value={phones}
                allowClear={true}
                onChange={value => {
                    const formattedValue = value
                        .toString()
                        .replace(/,/g, ' ')
                        .split(' ')
                        .filter(Boolean);
                    setPhones(_.uniq(formattedValue).map(Number));
                }}
            />
            <div>
                {activityType
                    ? ACTIVITIES.find(({ key }) => key === activityType).title
                    : '未选择活动'}
                （总人数：{total || 0}）
            </div>
            <Table
                columns={[
                    ...getColumns(activityType),
                    {
                        title: '操作',
                        key: 'operations',
                        render: row => {
                            const disabled =
                                !(row.content && row.content.course) ||
                                row.state === 1 ||
                                moment(row.endAt).isBefore(moment());
                            return (
                                <Popconfirm
                                    title="确定要开启打卡吗"
                                    disabled={disabled}
                                    onConfirm={() => activateState1(row)}
                                >
                                    <Button
                                        type={'primary'}
                                        disabled={disabled}
                                    >
                                        开启打卡
                                    </Button>
                                </Popconfirm>
                            );
                        },
                    },
                ]}
                dataSource={events}
                pagination={false}
                rowKey={item => `${item.event}-${item.userId}-${item.stamp}`}
            />
            <Pagination
                showQuickJumper
                defaultCurrent={1}
                current={page}
                total={total}
                defaultPageSize={pageSize}
                pageSizeOptions={['10', '15', '20']}
                onChange={(_page, _pageSize) => {
                    setPage(_page);
                    setPageSize(_pageSize);
                }}
            />
        </div>
    );
}
