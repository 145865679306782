import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { remote } from '@boyuai/utils';
import _ from 'lodash';
import CalculateActiveLesson from './access-error';
import './jupyter-page.less';
import Card from '../../components/card';
import { Button } from 'antd';

class JupyterPage extends Component {
    state = {
        jupyter: null,
        page: null,
    };
    componentDidMount() {
        this.fetch();
    }
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            this.fetch();
        }
    }
    get courseUuid() {
        return this.props.match.params.uuid;
    }
    goJupyter() {
        const { jupyter } = this.state;
        if (!jupyter || !jupyter.tianchiURL) {
            return;
        }
        window.open(jupyter.tianchiURL);
    }
    async fetch() {
        this.setState({ jupyter: null }); // 先设置为空，避免卡了上一个视频的播放进度
        const uuid = this.props.match.params.jupyteruuid;
        try {
            const jupyter = await remote.$get(
                `/course/jupyters/${uuid}`,
                {},
                { hideLoading: true, throwException: 'showMessage' }
            );
            this.setState({ jupyter });
            // 获取完立马标记为已学习
            remote.isLogin() &&
                remote.$post(
                    `/course/learningLog`,
                    { uuid },
                    { hideLoading: true }
                );
            // 需要对jupyter的html添加一些脚本
            const page = await remote.$get(
                `/course/jupyters/page/${encodeURIComponent(jupyter.url)}`
            );
            this.setState({ page });
        } catch (e) {
            if (
                _.get(e, 'response.data') === '知识点尚未购买' ||
                _.get(e, 'response.data.message') === '知识点尚未购买'
            ) {
                const { lessons } = await remote.$get(
                    `/course/${this.courseUuid}`,
                    {},
                    { hideLoading: true }
                );
                const lessonUuid = lessons[CalculateActiveLesson()].lesson.uuid;
                window.location.href = `${process.env.REACT_APP_SUB_DIRECTORY}/course/${this.courseUuid}/lesson/${lessonUuid}`;
            }
        }
    }
    render() {
        const { jupyter, page } = this.state;
        if (!jupyter) {
            return null;
        }
        const { title, author, tianchiURL } = jupyter;
        return (
            <div className="jupyter-page">
                <Card
                    title={title}
                    style={{ height: '100%' }}
                    authors={[author]}
                    isShowCourseTitle={true}
                >
                    {tianchiURL && (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                            }}
                        >
                            <Button onClick={() => this.goJupyter()}>
                                运行 @天池
                            </Button>
                        </div>
                    )}
                    <div className="iframe-wrapper">
                        {/* eslint-disable-next-line */}
                        <iframe
                            srcDoc={page}
                            width="100%"
                            height="100%"
                            frameBorder={0}
                        />
                    </div>
                </Card>
            </div>
        );
    }
}

export default withRouter(JupyterPage);
