import React, { useContext, useReducer } from 'react';

const initialState = {
    path: '',
};
const context = React.createContext(initialState);
const { Provider } = context;

export const UPDATE_PATH = 'UPDATE_PATH';

export const ModuleProvider = ({ children }) => {
    const [state, dispatch] = useReducer((state, action) => {
        switch (action.type) {
            case UPDATE_PATH: {
                const newState = { ...state, path: action.payload };
                return newState;
            }
            default:
                console.error('action type is required');
        }
    }, initialState);

    return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export const useModuleDispatch = () => {
    const { dispatch } = useContext(context);
    if (!dispatch) {
        return () => {};
    }
    return dispatch;
};

export const useModulePath = () => {
    const { state } = useContext(context);
    if (!state) {
        return {};
    }
    return state.path;
};
