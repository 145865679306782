import React, { Component } from 'react';
import Card from './sidebar-card';
import SectionCard from '../../components/card';
import './sidebar.less';
import { CaretRightOutlined, CaretDownOutlined } from '@ant-design/icons';
import { isMobile } from 'src/utils';

export default class SideBar extends Component {
    sidebarRef = React.createRef();

    state = {
        isFoldAll: false,
        show: false,
        isCourseIntroPage: false,
    };

    componentDidMount() {
        const pathName = window.location.pathname;
        let num = 0;
        pathName.split('').forEach(item => {
            if (item === '/') {
                num++;
            }
        });
        const pathBeginName =
            process.env.NODE_ENV !== 'production'
                ? '/course'
                : '/elites/course';
        const targetSplitNum = process.env.NODE_ENV !== 'production' ? 2 : 3;
        const isCourseIntroPage =
            pathName.indexOf(pathBeginName) === 0 &&
            (num === targetSplitNum ||
                (num === targetSplitNum + 1 &&
                    pathName.lastIndexOf('/') === pathName.length - 1));
        this.setState({ isCourseIntroPage });
    }

    render() {
        const { isCollapsed } = this.props;
        return (
            <>
                {!isMobile() || (isMobile() && this.state.show) ? (
                    <div
                        className={`sidebar${
                            isMobile() ? ' sidebar-mobile' : ''
                        }`}
                        data-is-collapsed={isCollapsed}
                        style={this.props.scrollStyle}
                        ref={this.sidebarRef}
                    >
                        {this.renderExtended()}
                    </div>
                ) : null}
                {isMobile() &&
                this.state.isCourseIntroPage &&
                !this.state.show ? (
                    <div className="show-btn" onClick={this.showSidebar}>
                        课程大纲
                    </div>
                ) : null}
            </>
        );
    }

    toggleFold = () => {
        this.setState({ isFoldAll: !this.state.isFoldAll });
    };

    setScrollHeight = height => {
        if (this.sidebarRef.current) {
            setTimeout(() => {
                this.sidebarRef.current.scrollTop = height;
            }, 100);
        }
    };

    renderToggleAll() {
        const { isFoldAll } = this.state;
        return (
            <div className="toggle-all">
                <div onClick={this.toggleFold}>
                    {isFoldAll ? <CaretRightOutlined /> : <CaretDownOutlined />}
                    {isFoldAll ? '全部展开' : '全部折叠'}
                </div>
                {isMobile() ? (
                    <div className="close-btn" onClick={this.hideSidebar}>
                        关闭大纲
                    </div>
                ) : null}
            </div>
        );
    }

    showSidebar = () => {
        this.setState({ show: true });
        window.document.body.style.overflow = 'hidden';
    };

    hideSidebar = () => {
        this.setState({ show: false });
        window.document.body.style.overflow = 'auto';
    };

    renderExtended = () => {
        const { items, uuid, course } = this.props;
        const { isFoldAll } = this.state;
        if (isMobile() && !this.state.isCourseIntroPage) return null;
        return (
            <SectionCard
                title="课程大纲"
                titleLink={`/course/${uuid}`}
                optionalEle={this.renderToggleAll()}
            >
                <div className="sidebar-container">
                    <ul>
                        {items.map((item, index) => (
                            <Card
                                key={item.id}
                                lesson={item.lesson}
                                uuid={uuid}
                                setScrollHeight={this.setScrollHeight}
                                packageOnly={course.packageOnly}
                                isFold={isFoldAll}
                                // 把下一篇课程的信息传进去，用于大纲定位
                                nextCourseLessons={
                                    items[index + 1]
                                        ? items[index + 1].lesson
                                        : {}
                                }
                                idx={index}
                            />
                        ))}
                    </ul>
                </div>
            </SectionCard>
        );
    };
}
