import React, { useState } from 'react';
import { remote } from '@boyuai/utils';
import { Button, Modal, Popconfirm } from 'antd';
import AdminTable from './table';
import AdminFormModal from './form-modal';
import Markdown from '../../components/markdown';
import { getCodeGroups, getCodeItems } from '../../utils';

const formItems = [
    {
        label: '标题',
        name: 'title',
        type: 'input',
        required: true,
    },
    {
        label: '小标题',
        name: 'miniTitle',
        type: 'input',
        placeholder: '代码实践',
    },
    {
        label: '排序',
        name: 'order',
        type: 'number',
        required: true,
        placeholder: '在整个知识点下所有视频、代码、习题的排序',
    },
];

const detailFormItems = [
    {
        label: '题干',
        name: 'title',
        type: 'textarea',
        required: true,
    },
    {
        label: 'trinket',
        name: 'code',
        type: 'input',
        required: true,
        placeholder: 'https://trinket.io/embed/python/**所需内容**/xxx',
    },
    {
        label: '思考题',
        name: 'question',
        type: 'textarea',
    },
    {
        label: '思考题答案',
        name: 'answer',
        type: 'textarea',
    },
];

function AdminLessonCodes(props) {
    const [visible, setVisible] = useState(false);
    const [codeItemVisible, setCodeItemVisible] = useState(false);
    const [codeId, setCodeId] = useState(null);

    const onCreate = async values => {
        const data = { ...values };
        try {
            await remote.$post(
                `/course/admin/courses/0/lessons/${props.lessonId}/codes`,
                data,
                { throwException: 'showMessage' }
            );
            props.onChange();
            setVisible(false);
        } catch (e) {}
    };

    const onDelete = async id => {
        try {
            await remote.$delete(
                `/course/admin/courses/0/lessons/0/codes/${id}`,
                {},
                { throwException: 'showMessage' }
            );
            props.onChange();
        } catch (e) {}
    };

    const columns = [
        {
            title: '标题',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: '小标题',
            dataIndex: 'miniTitle',
            key: 'miniTitle',
            render: text => text || '代码实践',
        },
        {
            title: '排序',
            dataIndex: 'order',
            key: 'order',
            sorter: (a, b) => a.order - b.order,
        },
        {
            title: '操作',
            dataIndex: 'id',
            key: 'operations',
            render: id => (
                <div>
                    <Button onClick={() => setCodeId(id)}>查看详情</Button>
                    &nbsp;
                    <Popconfirm
                        title="确定要删除吗"
                        onConfirm={() => onDelete(id)}
                    >
                        <Button type="danger">删除</Button>
                    </Popconfirm>
                </div>
            ),
        },
    ];

    const detailColumns = [
        {
            title: '题干',
            dataIndex: 'title',
            key: 'title',
            render: value => <Markdown content={value} />,
        },
        {
            title: 'trinket',
            dataIndex: 'code',
            key: 'code',
            render: value =>
                value ? (
                    <a
                        href={`https://trinket.io/embed/python/${value}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {value}
                    </a>
                ) : null,
        },
        {
            title: '思考题',
            dataIndex: 'question',
            key: 'question',
            render: value => <Markdown content={value} />,
        },
        {
            title: '思考题答案',
            dataIndex: 'answer',
            key: 'answer',
            render: value => <Markdown content={value} />,
        },
        {
            title: '操作',
            dataIndex: 'operations',
            key: 'operations',
            render: (_, _1, index) => (
                <div>
                    <Popconfirm
                        title="确定要删除吗"
                        onConfirm={() => onDeleteCodeItem(index)}
                    >
                        <Button type="danger">删除</Button>
                    </Popconfirm>
                </div>
            ),
        },
    ];

    const detailDatasource = codeId
        ? getCodeGroups(
              JSON.parse(props.datasource.find(o => o.id === codeId).content)
          )
        : [];

    const onCreateCodeItem = async values => {
        const data = { ...values };
        try {
            const groups = [...detailDatasource, data];
            const content = getCodeItems(groups);
            const code = props.datasource.find(o => o.id === codeId);
            await remote.$patch(
                `/course/admin/courses/0/lessons/0/codes/${code.id}/content`,
                {
                    content,
                },
                { throwException: 'showMessage' }
            );
            props.onChange();
            setVisible(false);
            setCodeItemVisible(false);
        } catch (e) {}
    };

    const onDeleteCodeItem = async index => {
        try {
            const groups = detailDatasource.filter((_, i) => i !== index);
            const content = getCodeItems(groups);
            const code = props.datasource.find(o => o.id === codeId);
            await remote.$put(
                `/course/admin/courses/0/lessons/0/codes/${code.id}`,
                {
                    ...code,
                    content,
                },
                { throwException: 'showMessage' }
            );
            props.onChange();
        } catch (e) {}
    };

    return (
        <div style={{ marginTop: '20px' }}>
            <h3>
                代码列表{' '}
                <Button onClick={() => setVisible(true)}>创建代码</Button>
            </h3>
            <AdminTable
                columns={columns}
                datasource={props.datasource}
                hidePagination
            />
            <AdminFormModal
                title="添加代码"
                visible={visible}
                onCancel={() => setVisible(false)}
                onSubmit={onCreate}
                formItems={formItems}
                formValues={{ title: props.lessonTitle }}
            />
            <Modal
                visible={!!codeId}
                onCancel={() => setCodeId(null)}
                onOk={() => setCodeId(null)}
                title="代码详情"
                width="90%"
            >
                <h3>
                    题目列表{' '}
                    <Button onClick={() => setCodeItemVisible(true)}>
                        创建题目
                    </Button>
                </h3>
                <AdminTable
                    columns={detailColumns}
                    datasource={detailDatasource.map((o, index) => ({
                        ...o,
                        id: index,
                    }))}
                    hidePagination
                />
                <AdminFormModal
                    title="添加代码题目"
                    visible={codeItemVisible}
                    onCancel={() => setCodeItemVisible(false)}
                    onSubmit={onCreateCodeItem}
                    formItems={detailFormItems}
                />
            </Modal>
        </div>
    );
}

export default AdminLessonCodes;
