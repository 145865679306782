import React, { useState, useCallback, useMemo } from 'react';
import { Modal, Button, Popover, Spin } from 'antd';
import { remote } from '@boyuai/utils';
import CommonModal from './common-modal';
import './index.less';
import Guide from './guide';
import Pending from './pending';

const TITLE_QIANREN =
    'https://staticcdn.boyuai.com/materials/2020/02/29/4CaQPf9NxoBk3crPjogZU.png!png';
const TITLE_NOT_QIANREN =
    'https://staticcdn.boyuai.com/materials/2020/02/29/vGNiz_0s-ArA7sCTx4-94.png!png';
const THANK_QIANREN =
    'https://staticcdn.boyuai.com/materials/2020/02/29/yMkl27snbF7nqfjOBlwtq.png!png';
const THANK_NOT_QIANREN =
    'https://staticcdn.boyuai.com/materials/2020/02/29/LHGT1T8MVD5i9t4xI8UJW.png!png';

const courses = [
    {
        link: 'https://www.boyuai.com/elites/course/zDQMBV778Mu9td4p',
        title: '深度学习',
        uuid: 'zDQMBV778Mu9td4p',
    },
    {
        link: 'https://www.boyuai.com/elites/course/xVqhU42F5IDky94x',
        title: '强化学习',
        uuid: 'xVqhU42F5IDky94x',
    },
    {
        link: 'https://www.boyuai.com/elites/course/9SPUuFz3rvpkmbkn',
        title: '自然语言处理',
        uuid: '9SPUuFz3rvpkmbkn',
    },
    {
        link: 'https://www.boyuai.com/elites/course/D91JM0bv72Zop1D3',
        title: '人工智能数学基础',
        uuid: 'D91JM0bv72Zop1D3',
    },
    {
        link: 'https://www.boyuai.com/elites/course/KFQ5E46KzWpHF9yf',
        title: '计算机视觉-深度',
        uuid: 'KFQ5E46KzWpHF9yf',
    },
    {
        link: 'https://www.boyuai.com/elites/course/SetWD393PgQjRMzv',
        title: '计算机视觉-经典',
        uuid: 'SetWD393PgQjRMzv',
    },
    {
        link: 'https://www.boyuai.com/elites/course/x3fyYxaRhVWJxGSI',
        title: '机器学习',
        uuid: 'x3fyYxaRhVWJxGSI',
    },
    {
        link: 'https://www.boyuai.com/elites/course/jbXtxc2nxNSz8CWH',
        title: '信息检索',
        uuid: 'jbXtxc2nxNSz8CWH',
    },
];

export default function Modal1({
    visible,
    onClose,
    activity,
    onActivityChange,
    pending,
}) {
    const { isQianren } = activity.content;
    // const [popoverVisible, setPopoverVisible] = useState(false);
    // const [popoverAllowVisible, setPopoverAllowVisible] = useState(false);
    const [confirmModalVisible, setConfirmModalVisible] = useState(false);
    const [warningModalVisible, setWarningModalVisible] = useState(false); // 没选课点邀请
    const [warningModalVisible2, setWarningModalVisible2] = useState(false); // 选了课还点选课
    const [currentCourseUuid, setCurrentCourseUuid] = useState(null);
    const [loading, setLoading] = useState(false);
    // 确认选择之后不立即更新（更新会切换到Modal2），先暂存，等点了邀请才更新
    const chooseCourse = useCallback(async () => {
        if (currentCourseUuid) {
            setLoading(true);
            const act = await remote.$patch(
                `/course/activities/${activity.id}/content`,
                { content: { course: currentCourseUuid } },
                { hideLoading: true, throwException: 'showMessage' }
            );
            onActivityChange(act);
            setConfirmModalVisible(false);
            setLoading(false);
        }
    }, [activity, onActivityChange, currentCourseUuid]);

    const onInviteButtonClicked = useCallback(async () => {
        if (!activity.content.modal1InviteButtonClicked) {
            setLoading(true);
            const act = await remote.$patch(
                `/course/activities/${activity.id}/content`,
                { content: { modal1InviteButtonClicked: true } },
                { hideLoading: true }
            );
            onActivityChange(act);
        }
    }, [onActivityChange, activity]);

    const popoverAllowVisible = useMemo(() => {
        return !(
            !visible ||
            confirmModalVisible ||
            warningModalVisible ||
            warningModalVisible2 ||
            loading
        );
    }, [
        visible,
        confirmModalVisible,
        warningModalVisible,
        warningModalVisible2,
        loading,
    ]);

    return (
        <Modal
            wrapClassName="activity-qianren-laxin-modal"
            visible={visible}
            footer={null}
            onCancel={onClose}
            width={890}
        >
            <img
                onClick={() => onClose()}
                className="close-button"
                src="https://staticcdn.boyuai.com/materials/2020/02/24/MPk_uBjcortZgs-EYMRKM.png!png"
                alt="关闭"
            />
            <div className="activity-qianren-laxin-modal-content">
                <img
                    className="activity-qianren-laxin-modal-content-title"
                    style={{
                        top: 86,
                        left: 80,
                        width: 476,
                    }}
                    src={isQianren ? TITLE_QIANREN : TITLE_NOT_QIANREN}
                    alt=""
                    // alt="春暖花开"
                />
                <img
                    className="activity-qianren-laxin-modal-content-thanks"
                    src={isQianren ? THANK_QIANREN : THANK_NOT_QIANREN}
                    alt=""
                    // alt="感谢有你"
                    style={{
                        top: 64,
                        right: 130,
                        width: 360,
                    }}
                />
                {pending ? (
                    <Pending />
                ) : (
                    <Spin spinning={loading || pending}>
                        <div
                            className="activity-qianren-laxin-modal-content-box"
                            style={{
                                top: 250 - 24,
                            }}
                        >
                            <div className="activity-qianren-laxin-modal-content-box-title">
                                {isQianren ? '活动一：打卡挑战' : '打卡挑战'}
                            </div>
                            <div className="activity-qianren-laxin-modal-content-box-content">
                                邀请1个活跃新用户(或3个新用户)，即可开启30天打卡挑战。你可以选择平台
                                <br />
                                上的任意一门课，每登录一天，系统就会为你
                                <span
                                    className="activity-qianren-laxin-modal-content-box-content-highlight"
                                    style={{ margin: '0 8px' }}
                                >
                                    <span>免费</span>
                                </span>
                                解锁下一个知识点。
                            </div>
                            {isQianren ? (
                                <>
                                    <div
                                        className="activity-qianren-laxin-modal-content-box-title"
                                        style={{
                                            position: 'unset',
                                            marginTop: 12,
                                            marginBottom: 8,
                                            width: 288,
                                        }}
                                    >
                                        活动二：延长《机器学习》学习时间
                                    </div>
                                    <div className="activity-qianren-laxin-modal-content-box-content">
                                        邀请3个活跃新用户(或9个新用户)，《机器学习》
                                        <span className="activity-qianren-laxin-modal-content-box-content-highlight">
                                            <span>免费学习时间延长60天</span>
                                        </span>
                                    </div>
                                </>
                            ) : (
                                <div style={{ height: 36, margin: '20px 0' }} />
                            ) /*(
                        <div
                            className="activity-qianren-laxin-modal-content-box-title"
                            style={{
                                position: 'unset',
                                textAlign: 'left',
                                width: 429,
                                margin: '20px 0',
                                background:
                                    'linear-gradient(45deg, #FFDCDC, transparent)',
                            }}
                        >
                            已有&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;位学员报名此次挑战！一起学习，不再孤单！
                        </div>
                    )*/}
                            <div className="activity-qianren-laxin-modal-content-box-extra">
                                <span style={{ fontSize: 15 }}>特别说明：</span>
                                <br />
                                *&nbsp;&nbsp;新用户需要通过你分享的学习海报扫码注册
                                <br />
                                *&nbsp;&nbsp;历史邀请用户也可计入此次活动
                                <br />
                                *&nbsp;&nbsp;完成3个知识点的学习即可升级为活跃用户
                                <br />
                                *&nbsp;&nbsp;此次活动邀请截止时间：2020年3月30日
                            </div>
                            {/*{...(activity.content.course ? { visible: false } : {})}*/}
                            <Popover
                                {...(popoverAllowVisible &&
                                !activity.content.course
                                    ? {}
                                    : { visible: false })}
                                trigger="click"
                                content={
                                    <div className="activity-qianren-laxin-courses-popover">
                                        <div className="activity-qianren-laxin-courses-popover-hint">
                                            选择课程后，邀请指定数量好友，
                                            <br />
                                            即可参与每日登录解锁知识点挑战
                                        </div>
                                        {courses.map(course => (
                                            <div
                                                className="activity-qianren-laxin-courses-popover-item"
                                                key={course.title}
                                            >
                                                <img
                                                    src="https://staticcdn.boyuai.com/materials/2020/03/02/wbXKPYrI7YtaXCFQn4eVj.png!png"
                                                    alt=""
                                                    width="8px"
                                                    height="8px"
                                                />
                                                <span className="activity-qianren-laxin-courses-popover-item-container">
                                                    <span className="activity-qianren-laxin-courses-popover-item-title">
                                                        {course.title}
                                                    </span>
                                                    <span>
                                                        <a
                                                            href={course.link}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            <Button>
                                                                查看
                                                            </Button>
                                                        </a>
                                                        <Button
                                                            onClick={() => {
                                                                if (
                                                                    remote.isLogin()
                                                                ) {
                                                                    // setPopoverVisible(
                                                                    //     false
                                                                    // );
                                                                    setConfirmModalVisible(
                                                                        true
                                                                    );
                                                                    setCurrentCourseUuid(
                                                                        course.uuid
                                                                    );
                                                                } else {
                                                                    remote.requestLogin();
                                                                }
                                                            }}
                                                        >
                                                            选择
                                                        </Button>
                                                    </span>
                                                </span>
                                            </div>
                                        ))}
                                    </div>
                                }
                                title={null}
                            >
                                <div
                                    className="activity-qianren-laxin-modal-content-box-button"
                                    style={{
                                        position: 'absolute',
                                        right: '30px',
                                        bottom: '100px',
                                    }}
                                    onClick={() => {
                                        if (!activity.content.course) {
                                            // setPopoverVisible(v => !v);
                                        } else {
                                            setWarningModalVisible2(true);
                                        }
                                    }}
                                >
                                    <div
                                        className="circle"
                                        style={{ backgroundColor: '#fff' }}
                                    >
                                        <img
                                            src="https://staticcdn.boyuai.com/materials/2020/02/07/mVo_e7lKqCln9mMDWXh5k.png!png"
                                            alt=""
                                            width="15px"
                                            height="12px"
                                        />
                                    </div>
                                    <div className="text space">
                                        选择挑战课程
                                    </div>
                                    {!activity.content.course && (
                                        <Guide left={-86} top={0} />
                                    )}
                                </div>
                            </Popover>
                            <div
                                className="activity-qianren-laxin-modal-content-box-button"
                                style={{
                                    position: 'absolute',
                                    right: '30px',
                                    bottom: '30px',
                                }}
                                onClick={() => {
                                    if (activity.content.course) {
                                        onInviteButtonClicked();
                                    } else {
                                        setWarningModalVisible(true);
                                    }
                                }}
                            >
                                <div
                                    className="circle"
                                    style={{ backgroundColor: '#fff' }}
                                >
                                    <img
                                        src="https://staticcdn.boyuai.com/materials/2020/02/07/mVo_e7lKqCln9mMDWXh5k.png!png"
                                        alt=""
                                        width="15px"
                                        height="12px"
                                    />
                                </div>
                                <div className="text space">邀请好友助力</div>
                                {activity.content.course && (
                                    <Guide left={-86} top={0} />
                                )}
                            </div>
                        </div>
                    </Spin>
                )}
                <div className="activity-qianren-laxin-modal-content-explanation">
                    *&nbsp;&nbsp;本次活动最终解释权归伯禹人工智能学院所有
                </div>
            </div>
            <CommonModal
                visible={confirmModalVisible}
                onCancel={() => {
                    setConfirmModalVisible(false);
                }}
                title={'您确定吗？'}
                hint={'确认选择课程后将不可更改'}
                onOk={() => chooseCourse()}
                okText={'确认选择'}
                buttonVisible={true}
                loading={loading || pending}
                imgSrc={
                    'https://staticcdn.boyuai.com/materials/2020/03/01/pTFpUDUgce-AdtnyRFfI2.png!png'
                }
            />
            <CommonModal
                visible={warningModalVisible}
                onCancel={() => {
                    setWarningModalVisible(false);
                }}
                title={'Oops!'}
                hint={'您还没有选择一门课程，无法邀请好友'}
                onOk={() => setWarningModalVisible(false)}
                okText={'返回'}
                buttonVisible={true}
                loading={loading || pending}
                imgSrc={
                    'https://staticcdn.boyuai.com/materials/2020/03/01/pTFpUDUgce-AdtnyRFfI2.png!png'
                }
            />
            <CommonModal
                visible={warningModalVisible2}
                onCancel={() => {
                    setWarningModalVisible2(false);
                }}
                title={'Oops!'}
                hint={'您已经选择过课程了，快去邀请好友助力吧'}
                onOk={() => setWarningModalVisible2(false)}
                okText={'返回'}
                buttonVisible={true}
                loading={loading || pending}
                imgSrc={
                    'https://staticcdn.boyuai.com/materials/2020/03/01/pTFpUDUgce-AdtnyRFfI2.png!png'
                }
            />
        </Modal>
    );
}
