import qs from 'qs';

export const isMobile = () => {
    return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
};

export const getCodeGroups = codeItems => {
    const groups = [];
    if (!codeItems) {
        return groups;
    }
    codeItems.forEach(item => {
        if (item.type === 'trinket') {
            groups[groups.length - 1].code = item.value;
        } else if (item.type === 'question') {
            groups[groups.length - 1].question = item.value;
        } else if (item.type === 'answer') {
            groups[groups.length - 1].answer = item.value;
        } else {
            groups.push({
                title: item.value,
            });
        }
    });
    return groups;
};

export const getCodeItems = groups => {
    const result = [];
    groups.forEach(group => {
        if (group.title) {
            result.push({
                type: 'text',
                value: group.title,
            });
        }
        if (group.code) {
            result.push({
                type: 'trinket',
                value: group.code,
            });
        }
        if (group.question) {
            result.push({
                type: 'question',
                value: group.question,
            });
        }
        if (group.answer) {
            result.push({
                type: 'answer',
                value: group.answer,
            });
        }
    });
    return result;
};

export const phoneRegExps = {
    // 截止20191225

    // 电信号段
    telecom: /^(133|149|153|173|177|180|181|189|199|191)/,
    // 联通
    unicom: /^(130|131|132|145|146|155|156|166|175|176|185|186)/,
    // 移动
    mobile: /^(134[0-8]|135|136|137|138|139|147|148|150|151|152|157|158|159|172|178|182|183|184|187|188|198)/,
    // 5G号段
    fifthGeneration: /^(190|197|196|192)/,

    // 物联网（禁止）
    iot: /^(144|165|140|167|141|1740)/,
    // 虚拟号段（禁止）
    virtual: /^(1700|1701|1702|1704|1707|1708|1709|171|1703|1705|1706|1349)/,
    telecomVirtual: /^(1700|1701|1702)/,
    unicomVirtual: /^(1704|1707|1708|1709|171)/,
    mobileVirtual: /^(1703|1705|1706)/,
    satelliteVirtual: /^(1349)/,
};

export const convertMarkdownToJson = arr => {
    let initIndex = []; // 下标数组
    let initArr = []; // 题目数组
    let finalArr = [];
    // 每个 h3 是一道题目，把所有的下标记录下来，用于下面的slice
    arr.forEach((element, index) => {
        if (element.type === 'heading_open' && element.tag === 'h3') {
            initIndex.push(index);
        }
    });
    initIndex.push(arr.length); // 这是末尾，用于下面的slice

    // 将md数组切分成不同的题目
    for (let i = 0; i < initIndex.length; i++) {
        if (initIndex[i + 1] <= arr.length) {
            initArr.push(arr.slice(initIndex[i], initIndex[i + 1]));
        }
    }

    // 题目数组
    for (let index = 0; index < initArr.length; index++) {
        finalArr[index] = {
            // id: index,
            type: 'FILL',
            // createdAt: new Date(Date.now()).toISOString(),
        };
        for (let i = 0; i < initArr[index].length; i++) {
            // h3标题为题目
            if (
                initArr[index][i].type === 'heading_open' &&
                initArr[index][i].tag === 'h3'
            ) {
                finalArr[index].title = initArr[index][i + 1].content;
            }
            // 如果有fence，则是题干
            /*if (
                initArr[index][i].type === 'fence' &&
                initArr[index][i].tag === 'code' &&
                initArr[index][i].markup === '```'
            ) {
                // console.log(initArr[index][i].content);
                finalArr[index].stem = initArr[index][i].content;
            }*/
            // h4标题分为题目类型和解答
            if (
                initArr[index][i].type === 'heading_open' &&
                initArr[index][i].tag === 'h4'
            ) {
                if (initArr[index][i + 1].content === '选项') {
                    finalArr[index].type = 'SELECT';
                    finalArr[index].options = [];
                    //ol标签开启
                    if (initArr[index][i + 3].type === 'ordered_list_open') {
                        let olIndex = initArr[index].findIndex(
                            element => element.type === 'ordered_list_close'
                        );
                        let olArr = initArr[index].slice(i + 3, olIndex);
                        olArr.forEach(element => {
                            if (element.type === 'inline' && element.content) {
                                finalArr[index].options.push(element.content);
                            }
                        });
                    }
                }
                //解答
                else if (initArr[index][i + 1].content === '解答') {
                    // console.log(initArr[index], i + 1);
                    let hintTempArr = initArr[index].slice(
                        i + 2,
                        initArr[index].length
                    );
                    let hintArr = [];
                    hintTempArr.forEach(element => {
                        if (element.content) {
                            hintArr.push(element.content);
                        }
                    });
                    finalArr[index].hint = hintArr.join('\n\n');
                }
                // 答案
                else if (initArr[index][i + 1].content === '答案') {
                    finalArr[index].answer = initArr[index][i + 4].content;
                }
            }
        }
        // 如果有fence，则是题干
        const stemElement = initArr[index].find(
            o => o.type === 'fence' && o.tag === 'code' && o.markup === '```'
        );
        finalArr[index].title += stemElement
            ? `\n\n\`\`\`\n${stemElement.content}\n\`\`\``
            : '';
    }
    return finalArr.map(q => {
        if (q.type === 'SELECT') {
            q.answer = `${q.answer - 1}`; // 下标，且字符化
            q.options = q.options.map((option, index) => ({
                value: `${index}`,
                text: option,
            }));
        } else if (q.type === 'FILL') {
            q.answer = `${q.answer}`; // 字符化
        }
        return q;
    });
};

// 转换成learn以及@boyuai/quiz中的格式——{[questionId]:answer}
export const changeQuizAnswersToObjectForm = answersObject => {
    return Object.keys(answersObject).map(key => ({
        id: parseInt(key),
        answer: answersObject[key],
    }));
};

// 转换成course中的格式——[{id:questionId, answers: xxx}]
export const changeQuizAnswersToArrayForm = answersArray => {
    const answersObject = {};
    answersArray.forEach(({ id, answer }) => {
        answersObject[id] = answer;
    });
    return answersObject;
};

export function getPercentage(numerator, denominator) {
    if (denominator === 0) {
        return 0;
    } else {
        return parseFloat((numerator / denominator).toFixed(2));
    }
}

// 波波鱼用户数据生成csv并下载
export const downloadCSV = (data, columns, format, name = 'download') => {
    let str = columns;
    for (const row of data) {
        str += format(row);
    }
    let uri = 'data:text/csv;charset=utf-8,' + encodeURIComponent(str);
    let link = document.createElement('a');
    link.href = uri;
    link.download = `${name}.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};
// 用户数据生成csv并下载
// export const downloadCSV = list => {
//     let str =
//         '用户名, 国家号, 手机号, 学习知识点数, quiz答对数, quiz总数, quiz正确率, 笔记数\n';
//     for (const row of list) {
//         str += `${row.name}, ${row.countryCode || 0}, ${row.phone || 0}, ${
//             row.learnedLessons ? row.learnedLessons.length : 0
//         },${
//             row.accuracy
//                 ? `${row.accuracy[0]},${row.accuracy[1]},${getPercentage(
//                       row.accuracy[0],
//                       row.accuracy[1]
//                   )}`
//                 : `0,0,0`
//         },${row.notes ? row.notes.length : 0}\n`;
//     }
//     let uri = 'data:text/csv;charset=utf-8,' + encodeURIComponent(str);
//     let link = document.createElement('a');
//     link.href = uri;
//     link.download = 'download.csv';
//     document.body.appendChild(link);
//     link.click();
//     document.body.removeChild(link);
// };

export function getSearchParams() {
    const query = window.location.search.substring(1);
    return qs.parse(query);
}
