import { Button, message } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { useState } from 'react';
import './quiz-general.less';
import { CopyToClipboard } from 'react-copy-to-clipboard';

export default function QuizGeneral() {
    const [words, setWords] = useState('');
    const [result, setResult] = useState('');
    const generateUuid = () => {
        return 'xx4xxxyxxx'.replace(/[xy]/g, function(c) {
            var r = (Math.random() * 16) | 0,
                v = c === 'x' ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        });
    };

    const generateQuiz = arr => {
        let title = '';
        let options = [];
        let answer = '';
        let hint = '';
        arr.forEach(t => {
            // 判断以“数字、”开头的为题目
            t = t.trim();
            if (/^\d*(、|\.|\)|）)/.test(t)) {
                title = t.replace(/^\d*(、|\.|\)|）)/, '');
            } else if (/^答案：|答案:|答案为/.test(t)) {
                answer = t.replace(/^(答案：|答案:|答案为)(\s*)/, '');
            } else if (/^(hint:|hint：)/.test(t)) {
                hint = t.replace(/^(hint:|hint：)/, '');
            } else if (/^[A-Z](、|\.|\)|）|\s*)/.test(t)) {
                options.push({
                    value: options.length.toString(),
                    text: t.replace(/^[A-Z](、|\.|\)|）|\s*)/, ''),
                });
            } else {
                // 不知道是什么的就加到hint里面
                hint += `\n${t}`;
            }
        });
        if (!title || !answer) {
            return (
                '************************************\n' +
                arr.join('\n') +
                '\n\n题目转换错误，请检查原文格式\n************************************'
            );
        }
        return `quizzes.append({
  "id": "choice-${generateUuid()}",
  "type": "SELECT",
  "title": "${title}",
  "answer": "${answer.charCodeAt() - 'A'.charCodeAt()}",${
            !!hint ? `\n  "hint": """\n${hint.toString()}\n""",` : ''
        }
  "options": ${JSON.stringify(options)}
})`;
    };

    const convert = txt => {
        const lines = txt.split('\n');
        const fmt = lines.reduce((prev, curr, index) => {
            if (curr.trim() === '') {
                return prev;
            }
            if (/^\d*(、|\.|\)|）)/.test(curr)) {
                // 一道新的题目
                prev.push([curr.trim()]);
            } else {
                prev[prev.length - 1].push(curr.trim());
            }
            return prev;
        }, []);
        return fmt
            .filter(line => Boolean(line.length))
            .map(line => generateQuiz(line))
            .join('\n\n');
    };

    return (
        <div>
            <h1>Quiz格式转换器</h1>
            <p>
                功能介绍：
                <a
                    target="blank"
                    href="https://li9qn6s6nl5.feishu.cn/docx/BbWtdaqqNoyvw6xU6gocrnY9nPp"
                >
                    飞书文档
                </a>
            </p>
            <div className="op-wrapper">
                <Button
                    className="convert-btn"
                    onClick={() => setResult(convert(words))}
                    type="primary"
                    disabled={!words}
                >
                    转换
                </Button>
                <div>
                    <Button
                        className="convert-btn"
                        onClick={() => {
                            setResult('');
                            setWords('');
                        }}
                        type="danger"
                        style={{ marginRight: 6 }}
                    >
                        清空
                    </Button>
                    <CopyToClipboard
                        text={result}
                        onCopy={() => message.success('已复制')}
                    >
                        <Button type="primary">一键复制</Button>
                    </CopyToClipboard>
                </div>
            </div>
            <div className="widget">
                <TextArea
                    className="input"
                    value={words}
                    onChange={e => setWords(e.target.value)}
                />
                <TextArea className="result" value={result} disabled />
            </div>
        </div>
    );
}
