import React, { useMemo } from 'react';
import { remote } from '@boyuai/utils';
import useCoursePrice from './hooks/course-price';
import './course-price.less';

const IDrop =
    'https://staticcdn.boyuai.com/materials/2019/12/01/GHXI-ufg4YKe30xmay0MX.jpg';
const ICloud =
    'https://staticcdn.boyuai.com/materials/2019/10/07/Rs4BqvA59vVovI7x-9z9A.jpg';
const IDropLight =
    'https://staticcdn.boyuai.com/materials/2019/12/01/j4kRymKE_-3veefDyO7wm.jpg';
const ICloudLight =
    'https://staticcdn.boyuai.com/materials/2019/12/01/0Reft6ntLL600lyWPZzA1.jpg';

export default function CoursePrice({
    course,
    lightMode = false,
    shouldHideCoursePrice,
}) {
    const remainPrice = useCoursePrice(course.uuid);
    const price = useMemo(() => {
        if (remainPrice) {
            return remainPrice;
        }
        return (
            (course.price ||
                course.lessons.reduce((p, item) => p + item.lesson.price, 0)) /
            100
        );
    }, [course, remainPrice]);
    if (remainPrice === 0) {
        return null;
    }
    if (remainPrice === -1) {
        // 没登陆的情况下提示已购买全部知识点，肯定是因为免费开放了
        return (
            <div
                className="course-price-wrapper"
                data-is-light-mode={lightMode}
            >
                {remote.isLogin()
                    ? '已购买全部知识点'
                    : '登录后可观看全部知识点'}
            </div>
        );
    }

    // 没买 而且是 动手学 则提示不开放购买
    if (shouldHideCoursePrice) {
        return (
            <div
                className="course-price-wrapper"
                data-is-light-mode={lightMode}
                style={{ fontSize: 20 }}
            >
                活动期间暂不开放购买
            </div>
        );
    }
    return (
        <div className="course-price-wrapper" data-is-light-mode={lightMode}>
            {!course.packageOnly && (
                <div className="price">
                    <div className="spliter">剩余知识点打包价</div>
                    <div className="price-number">
                        {(
                            Math.floor(
                                (price * (course.packageDiscount / 100) * 100) /
                                    10
                            ) / 10
                        ).toFixed(2)}
                    </div>
                    <div className="icon">
                        <img
                            src={lightMode ? ICloudLight : ICloud}
                            alt="云朵"
                        />
                    </div>
                    <div>云朵</div>
                    <div className="spliter">或</div>
                    <div className="price-number">
                        {Math.floor(
                            price * (course.packageDiscount / 100) * 10
                        ) * 10}
                    </div>
                    <div className="icon">
                        <img
                            src={lightMode ? IDropLight : IDrop}
                            alt="雨滴"
                            width="17px"
                            height="20px"
                        />
                    </div>
                    <div>雨滴</div>
                </div>
            )}
            {!course.packageOnly && (
                <div className="price price-origin">
                    <div className="spliter">分知识点购买原价</div>
                    <div className="price-number">
                        {(Math.floor(price * 100) / 100).toFixed(2)}
                    </div>
                    <div className="icon">
                        <img
                            src={lightMode ? ICloudLight : ICloud}
                            alt="云朵"
                        />
                    </div>
                    <div>云朵</div>
                    <div className="spliter">或</div>
                    <div className="price-number">
                        {Math.floor(price * 100)}
                    </div>
                    <div className="icon">
                        <img
                            src={lightMode ? IDropLight : IDrop}
                            alt="雨滴"
                            width="17px"
                            height="20px"
                        />
                    </div>
                    <div>雨滴</div>
                </div>
            )}
            {course.packageOnly && (
                <div className="price">
                    <div className="spliter">课程价格</div>
                    <div className="price-number">{price}</div>
                    <div className="icon">
                        <img
                            src={lightMode ? ICloudLight : ICloud}
                            alt="云朵"
                        />
                    </div>
                    <div>云朵</div>
                    <div className="spliter">或</div>
                    <div className="price-number">{price * 100}</div>
                    <div className="icon">
                        <img
                            src={lightMode ? IDropLight : IDrop}
                            alt="雨滴"
                            width="17px"
                            height="20px"
                        />
                    </div>
                    <div>雨滴</div>
                </div>
            )}
            {course.packageOnly && (
                <div className="price price-origin">
                    <div className="spliter">原价</div>
                    <div className="price-number">
                        {course.originalPrice / 100}
                    </div>
                    <div className="icon">
                        <img
                            src={lightMode ? ICloudLight : ICloud}
                            alt="云朵"
                        />
                    </div>
                    <div>云朵</div>
                    <div className="spliter">或</div>
                    <div className="price-number">{course.originalPrice}</div>
                    <div className="icon">
                        <img
                            src={lightMode ? IDropLight : IDrop}
                            alt="雨滴"
                            width="17px"
                            height="20px"
                        />
                    </div>
                    <div>雨滴</div>
                </div>
            )}
        </div>
    );
}
