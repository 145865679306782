import React, { useState } from 'react';
import { remote } from '@boyuai/utils';
import { Button, message, Popconfirm } from 'antd';
import AdminTable from './table';
import AdminFormModal from './form-modal';

function AdminLessonJupiters(props) {
    const [visible, setVisible] = useState(false);
    const [editingJupyter, setEditingJupyter] = useState(null);

    const onCreate = async values => {
        const data = { ...values };
        const { url } = values;
        // console.log("创建", values)
        if (url.length > 1) {
            return message.error('只能上传一个文件');
        }
        if (!url[0].response) {
            return message.error('文件过大，请删除重试');
            // uploadHtml组件中的文件大小检验不通过，url[0]没有response属性，不能执行下去
        }
        data.url = url[0].response.url;
        try {
            await remote.$post(
                `/course/admin/courses/0/lessons/${props.lessonId}/jupyters`,
                data,
                { throwException: 'showMessage' }
            );
            props.onChange();
            setVisible(false);
        } catch (e) {}
    };

    const onDelete = async id => {
        try {
            await remote.$delete(
                `/course/admin/courses/0/lessons/0/jupyters/${id}`,
                {},
                { throwException: 'showMessage' }
            );
            props.onChange();
        } catch (e) {}
    };

    const onEditConfirm = async data => {
        const { title, miniTitle, order, originalUrl, kesciGroupId } = data;
        if (editingJupyter && editingJupyter.id) {
            await remote.$patch(
                `/course/admin/courses/0/lessons/0/jupyters/${editingJupyter.id}`,
                { title, miniTitle, order, originalUrl, kesciGroupId },
                { throwException: 'showMessage' }
            );
            setEditingJupyter(null);
            props.onChange();
        }
    };

    const columns = [
        {
            title: '标题',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: '小标题',
            dataIndex: 'miniTitle',
            key: 'miniTitle',
            render: text => text || 'Jupyter',
        },
        {
            title: '排序',
            dataIndex: 'order',
            key: 'order',
            sorter: (a, b) => a.order - b.order,
        },
        {
            title: 'HTML文件',
            dataIndex: 'url',
            key: 'url',
            render: src => (
                <a href={src} target="_blank" rel="noopener noreferrer">
                    预览
                </a>
            ),
        },
        {
            title: '原始链接',
            dataIndex: 'originalUrl',
            key: 'originalUrl',
            render: src => (
                <a href={src} target="_blank" rel="noopener noreferrer">
                    预览
                </a>
            ),
        },
        {
            title: '操作',
            dataIndex: 'id',
            key: 'operations',
            render: (id, row) => (
                <>
                    <Button onClick={() => setEditingJupyter(row)}>编辑</Button>
                    &nbsp;
                    <Popconfirm
                        title="确定要删除吗"
                        onConfirm={() => onDelete(id)}
                    >
                        <Button type="danger">删除</Button>
                    </Popconfirm>
                </>
            ),
        },
    ];

    return (
        <div style={{ marginTop: '20px' }}>
            <h3>
                Jupyter列表{' '}
                <Button onClick={() => setVisible(true)}>创建Jupyter</Button>
            </h3>
            <AdminTable
                columns={columns}
                datasource={props.datasource}
                hidePagination
            />
            <AdminFormModal
                title="添加Jupyter"
                visible={visible}
                onCancel={() => setVisible(false)}
                onSubmit={onCreate}
                formItems={[
                    {
                        label: '标题',
                        name: 'title',
                        type: 'input',
                        required: true,
                    },
                    {
                        label: '小标题',
                        name: 'miniTitle',
                        type: 'input',
                        placeholder: 'Jupyter',
                    },
                    {
                        label: '排序',
                        name: 'order',
                        type: 'number',
                        required: true,
                        placeholder: '在整个知识点下所有视频、代码、习题的排序',
                    },
                    {
                        label: 'html文件',
                        name: 'url',
                        type: 'uploadHtml',
                        required: true,
                        endpoint: `/course/admin/courses/0/lessons/${props.lessonId}/jupyters/html`,
                    },
                    {
                        label: '原始链接',
                        name: 'originalUrl',
                        type: 'input',
                        required: true,
                        placeholder: '原始链接',
                    },
                    {
                        label: 'Kesci GroupID',
                        name: 'kesciGroupId',
                        type: 'input',
                        placeholder: 'Kesci GroupID',
                    },
                ]}
                formValues={{ title: props.lessonTitle }}
            />
            {editingJupyter && (
                <AdminFormModal
                    title="编辑"
                    visible={true}
                    onCancel={() => setEditingJupyter(null)}
                    onSubmit={onEditConfirm}
                    formItems={[
                        {
                            label: '标题',
                            name: 'title',
                            type: 'input',
                            required: true,
                        },
                        {
                            label: '小标题',
                            name: 'miniTitle',
                            type: 'input',
                            placeholder: 'Jupyter',
                        },
                        {
                            label: '排序',
                            name: 'order',
                            type: 'number',
                            required: true,
                            placeholder:
                                '在整个知识点下所有视频、代码、习题的排序',
                        },
                        {
                            label: '原始链接',
                            name: 'originalUrl',
                            type: 'input',
                            required: true,
                            placeholder: '原始链接',
                        },
                        {
                            label: 'Kesci GroupID',
                            name: 'kesciGroupId',
                            type: 'input',
                            placeholder: 'Kesci GroupID',
                        },
                    ]}
                    formValues={{
                        ...editingJupyter,
                    }}
                />
            )}
        </div>
    );
}

export default AdminLessonJupiters;
