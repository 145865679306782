import React, { Component } from 'react';
import { Tabs, Badge, Pagination } from 'antd';
import _ from 'lodash';
import { $put, $post } from '@boyuai/utils/es/remote';
import convertSiteToHostName from '@boyuai/site-address-converter';

import './message.less';

const userRead =
    'https://staticcdn.boyuai.com/materials/2020/11/19/7vUa4n5Lxl4m8qlKp-p6Y.png';
const userUnread =
    'https://staticcdn.boyuai.com/materials/2020/11/19/lzwnOG1IRnTM4zwkk_FdW.png';
const managerRead =
    'https://staticcdn.boyuai.com/materials/2020/11/19/k3c6sM_Dqp1KI0_3VtBKG.png';
const managerUnread =
    'https://staticcdn.boyuai.com/materials/2020/11/19/lX7iRRc9T7XsdLdCgbBej.png';

const { TabPane } = Tabs;
const PAGINATION_SIZE = 12;

export default class Message extends Component {
    state = {
        messageArr: this.props.messageArr,
        currentIndex: 1,
    };

    get readMessageList() {
        return this.fullMessageList.filter(item => item.read);
    }

    get unreadMessageList() {
        return this.fullMessageList.filter(item => !item.read);
    }

    get fullMessageList() {
        //  todo: 因为历史数据的原因，暂时把没有site的消息也设为已读
        return this.state.messageArr
            .map(item => {
                return { ...item, read: !item.site || item.read };
            })
            .sort((a, b) => {
                const dateA = new Date(a);
                const dateB = new Date(b);
                return dateA.getTime() - dateB.getTime();
            });
    }

    onMessageClick = async clickedMsg => {
        const { read, id, site, link } = clickedMsg;
        if (!site) {
            return;
        }
        if (!read) {
            const readMsg = { ...clickedMsg, read: true };
            this.setState(
                {
                    messageArr: [
                        ...this.state.messageArr.filter(msg => msg.id !== id),
                        readMsg,
                    ],
                },
                () => this.props.onUnreadChange(this.unreadMessageList.length)
            );
            await $put(
                `/message/${id}/read`,
                { read: true },
                { hideLoading: true }
            );
        }
        // 对site作判断
        const jumpLink = `${convertSiteToHostName(
            site,
            process.env.REACT_APP_ENV
        )}${link}`;
        window.open(jumpLink);
    };

    readAll = async () => {
        const readAllMessageList = this.fullMessageList.map(msg => {
            return { ...msg, read: true };
        });
        await $post(`/message/read`, {}, { hideLoading: true });
        this.props.updateMessageList(readAllMessageList);
    };

    componentDidUpdate(prevProps) {
        if (prevProps.messageArr !== this.props.messageArr) {
            this.setState({ messageArr: this.props.messageArr });
        }
    }

    render() {
        // console.log(this.fullMessageList)
        return (
            <div className="message-tabs">
                <Tabs
                    defaultActiveKey="all"
                    onTabClick={() => this.setState({ currentIndex: 1 })}
                >
                    {this.renderTabPane()}
                </Tabs>
                <div className="read-all" onClick={this.readAll}>
                    全部设为已读
                </div>
            </div>
        );
    }

    renderTabPane() {
        const messageTabInfo = [
            {
                key: 'all',
                title: (
                    <Badge
                        count={this.unreadMessageList.length}
                        overflowCount={99}
                        offset={[6, -5]}
                    >
                        全部通知
                    </Badge>
                ),
                list: _.sortBy(this.fullMessageList, 'read'),
            },
            {
                key: 'unread',
                title: (
                    <Badge
                        count={this.unreadMessageList.length}
                        overflowCount={99}
                        offset={[6, -5]}
                    >
                        <div>未读通知</div>
                    </Badge>
                ),
                list: this.unreadMessageList,
            },
            {
                key: 'read',
                title: '已读通知',
                list: this.readMessageList,
            },
        ];
        return messageTabInfo.map(info => {
            const { key, title, list } = info;
            return (
                <TabPane key={key} tab={title}>
                    {this.renderListItem(list)}
                </TabPane>
            );
        });
    }

    renderListItem(list) {
        if (!list.length) {
            return <div className="empty">暂无消息</div>;
        }
        const { currentIndex } = this.state;
        const paginatedList = list.slice(
            (currentIndex - 1) * PAGINATION_SIZE,
            currentIndex * PAGINATION_SIZE
        );
        const messageInfo = paginatedList.map(msg => {
            const { fromUserName, title, content, id, read, site } = msg;
            const isRead = !site || read;
            let imgSrc = isRead ? userRead : userUnread;
            if (fromUserName === '管理员') {
                imgSrc = isRead ? managerRead : managerUnread;
            }
            return (
                <div
                    key={id}
                    className="msg-info-item"
                    data-is-read={isRead}
                    data-with-site={!!site}
                >
                    <img src={imgSrc} className="user-icon" alt="user" />
                    <div className="user-name">{fromUserName}</div>
                    <div
                        className="msg-wrapper"
                        onClick={() => this.onMessageClick(msg)}
                    >
                        <div className="msg-title">{title}</div>
                        <div className="msg-content">{content}</div>
                    </div>
                </div>
            );
        });
        return (
            <div className="pagination-wrapper">
                <div className="list-wrapper">{messageInfo}</div>
                {this.renderPagination(list.length)}
            </div>
        );
    }

    onChangePagination = page => {
        this.setState({ currentIndex: page });
    };

    renderPagination(length) {
        return (
            <Pagination
                current={this.state.currentIndex}
                onChange={this.onChangePagination}
                total={length}
                pageSize={PAGINATION_SIZE}
                hideOnSinglePage
            />
        );
    }
}
