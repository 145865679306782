import React, { useState } from 'react';
import { Button, Popconfirm } from 'antd';
import AdminTable from './table';
import AdminFormModal from './form-modal';
import Markdown from '../../components/markdown';

const formItems = [
    {
        label: '值',
        name: 'value',
        type: 'input',
        required: true,
        placeholder: '一般直接填写0-3的数字',
    },
    {
        label: '选项内容',
        name: 'text',
        type: 'textarea',
        required: true,
    },
];

function AdminLessonQuizOptions(props) {
    const [visible, setVisible] = useState(false);

    const onCreate = async values => {
        const data = { ...values };
        const options = [...props.options, data];
        setVisible(false);
        props.onChange(options);
    };

    const onDelete = async index => {
        const options = props.options.filter((_, i) => i !== index);
        props.onChange(options);
    };

    const columns = [
        {
            title: '值',
            dataIndex: 'value',
            key: 'value',
        },
        {
            title: '选项内容',
            dataIndex: 'text',
            key: 'text',
            render: value => (
                <Markdown content={value.replace(/\n/g, '\n\n')} />
            ),
        },
        {
            title: '操作',
            dataIndex: 'operations',
            key: 'operations',
            render: (_, _1, index) => (
                <div>
                    <Popconfirm
                        title="确定要删除吗"
                        onConfirm={() => onDelete(index)}
                    >
                        <Button type="danger">删除</Button>
                    </Popconfirm>
                </div>
            ),
        },
    ];

    return (
        <div style={{ marginTop: '20px' }}>
            <h3>
                选项列表{' '}
                <Button onClick={() => setVisible(true)}>添加选项</Button>
            </h3>
            <AdminTable
                columns={columns}
                datasource={props.options}
                hidePagination
            />
            <AdminFormModal
                title="添加选项"
                visible={visible}
                onCancel={() => setVisible(false)}
                onSubmit={onCreate}
                formItems={formItems}
            />
        </div>
    );
}

export default AdminLessonQuizOptions;
