import React from 'react';
import './index.less';

export default function Guide({ left, top, imgSrc, width }) {
    return (
        <div
            className="ui-activity-guide"
            style={{
                position: 'absolute',
                left,
                top,
            }}
        >
            <img
                src={
                    imgSrc ||
                    'https://staticcdn.boyuai.com/materials/2020/02/22/BVParQ3-DKgeKcBNRYZZ0.png!png'
                }
                alt=""
                width={width || '74px'}
            />
        </div>
    );
}
