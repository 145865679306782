/** 创建|编辑自动回复 **/
import React, { useCallback, useEffect } from 'react';
import { Form, Input, Modal } from 'antd';
import { remote } from '@boyuai/utils';

const formItemLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 },
};

const FuwuhaoAutoReplyFormModal = ({
    value,
    visible,
    onOk,
    onCancel,
    fuwuhaoType,
}) => {
    const [form] = Form.useForm();

    const onSubmit = useCallback(() => {
        form.validateFields().then(async res => {
            const { key, content, keyword } = res;
            if (value) {
                remote
                    .$patch('/admin/fuwuhao/auto-replies', {
                        fuwuhaoType,
                        key,
                        content,
                        keyword,
                    })
                    .then(onOk);
            } else {
                remote
                    .$post('/admin/fuwuhao/auto-replies', {
                        fuwuhaoType,
                        key,
                        content,
                        keyword,
                    })
                    .then(onOk);
            }
        });
    }, [form, onOk, value, fuwuhaoType]);

    useEffect(() => {
        if (form && value) {
            form.setFieldsValue(value);
        }
    }, [form, value]);

    return (
        <Modal
            title={'创建链接'}
            visible={visible}
            onCancel={onCancel}
            cancelText={'取消'}
            onOk={onSubmit}
            okText={'确认'}
            width={800}
        >
            <Form form={form} name="go">
                <Form.Item
                    key={'key'}
                    name={'key'}
                    label={'key'}
                    rules={[{ required: true }]}
                    {...formItemLayout}
                >
                    <Input disabled={!!value} />
                </Form.Item>
                <Form.Item
                    key={'keyword'}
                    name={'keyword'}
                    label={'关键词'}
                    {...formItemLayout}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    key={'content'}
                    name={'content'}
                    label={'content'}
                    rules={[{ required: true }]}
                    {...formItemLayout}
                >
                    <Input.TextArea rows={10} />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default FuwuhaoAutoReplyFormModal;
