import React, { useEffect, useRef } from 'react';
import { Form, Input, Button, message } from 'antd';
import { remote } from '@boyuai/utils';

let loaded = false;

export default function VideoRepare({ form }) {
    const formRef = useRef(null);
    useEffect(() => {
        // 防止死循环 https://github.com/ant-design/ant-design/issues/2985
        if (loaded || !formRef.current) {
            return;
        }
        loaded = true;
        const search = /uuid=(.*)$/.exec(window.location.search);
        if (search) {
            formRef.current.setFields({ uuid: { value: search[1] } });
        }
    }, []);
    const handleSubmit = async values => {
        const { uuid } = values;
        await remote.$post(
            `/course/admin/videos/${uuid}/retrans`,
            {},
            { throwException: 'showMessage' }
        );
        message.success('提交成功');
        form.resetFields();
    };
    return (
        <div>
            <Form onSubmit={handleSubmit}>
                <Form.Item
                    label="视频UUID"
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 18 }}
                    name="uuid"
                    rules={[{ required: true }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item wrapperCol={{ span: 18, offset: 6 }}>
                    <Button type="primary" htmlType="submit">
                        重新转码
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
}
