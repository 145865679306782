import React, { Component } from 'react';
import { message, Upload, Button } from 'antd';
import { remote } from '@boyuai/utils';
import { UploadOutlined } from '@ant-design/icons';

const beforeUpload = file => {
    const isLt2M = file.size / 1024 / 1024 < 8;
    if (!isLt2M) {
        message.error('支持8MB以内的文件!');
    }
    return isLt2M;
};

export default class AdminFormItemUpload extends Component {
    static fieldProps = {
        valuePropName: 'fileList',
        getValueFromEvent: e => e.fileList,
    };
    render() {
        return (
            <Upload
                {...this.props}
                listType="text"
                name="file"
                accept=".html"
                multiple={false}
                action={`${remote.API_ROOT}${this.props.endpoint}`}
                headers={remote.getHeaders()}
                beforeUpload={beforeUpload}
            >
                <Button>
                    <UploadOutlined /> 点击上传Html文件
                </Button>
            </Upload>
        );
    }
}
