import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import AdminForm from './form';

function AdminFormModal(props) {
    const formRef = useRef(null);

    const onSubmit = async () => {
        const values = await formRef.current.validateFields();
        props.onSubmit(values);
    };
    return (
        <Modal
            title={props.title}
            visible={props.visible}
            onCancel={props.onCancel}
            onOk={onSubmit}
            width="600px"
        >
            <AdminForm
                ref={formRef}
                formItems={props.formItems}
                formValues={props.formValues}
            />
        </Modal>
    );
}

AdminFormModal.propTypes = {
    title: PropTypes.string.isRequired,
    visible: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    formItems: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    formValues: PropTypes.shape(),
};

export default AdminFormModal;
