import React from 'react';
import { Table } from 'antd';
import { useAddUserCourseLog } from '../../hooks/admin/admin-log';
import moment from 'moment';

function AdminAddCourseForUserLog() {
    const [addUserCourseLog] = useAddUserCourseLog();
    return (
        <div style={{ marginTop: '20px' }}>
            <h2>手动开通课程记录</h2>
            <Table
                columns={[
                    {
                        title: '#',
                        key: 'id',
                        dataIndex: 'id',
                    },
                    {
                        title: '用户id',
                        key: 'userId',
                        dataIndex: 'userId',
                    },
                    {
                        title: '批次标签',
                        key: 'tag',
                        dataIndex: 'tag',
                    },
                    {
                        title: '开通时间',
                        dataIndex: 'createdAt',
                        key: 'createdAt',
                        render: createdAt =>
                            createdAt &&
                            moment(createdAt).format('YYYY-MM-DD HH:mm:ss'),
                    },
                ]}
                dataSource={addUserCourseLog}
                rowKey={'id'}
            />
        </div>
    );
}

export default AdminAddCourseForUserLog;
