import React, { useCallback, useState, useEffect } from 'react';
import moment from 'moment';
import { Popconfirm, Spin, message } from 'antd';
import convertSiteToHostName from '@boyuai/site-address-converter';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { PreviewEditor, CommentUserInfo, linkIcon } from '@boyuai/comment';
import { $delete, $get, $put } from '@boyuai/utils/es/remote';
import CiteComment from './cite-content';
import './comment.less';

const ILike =
    'https://staticcdn.boyuai.com/materials/2020/04/01/YMqSsER6-2x7ophW_VAFy.png!png';
const ILikeToCancel =
    'https://staticcdn.boyuai.com/materials/2020/04/02/aaBGZ4ydq8ysH5_IvDuaR.png!png';
const IReply =
    'https://staticcdn.boyuai.com/materials/2020/04/01/B5QGCCPEqBcZUKLY9Yfpv.png!png';

export default function UserComment({
    comment,
    isLike, // 是否我的点赞
    hideUser,
    onDelete,
    onUpdateCommentLike,
}) {
    const [title, setTitle] = useState(null);
    const [titleLoading, setTitleLoading] = useState(null);
    const [likeLoading, setLikeLoading] = useState(false);

    const deleteComment = useCallback(async () => {
        await $delete(`/comment/${comment.id}`);
        onDelete(comment.id);
    }, [onDelete, comment]);

    const onClickLike = useCallback(async () => {
        if (comment.likeLog) {
            setLikeLoading(true);
            await $put(
                `/comment/${comment.uuid}/like`,
                { like: false, likeId: comment.likeLog.id },
                { throwException: 'showMessage' }
            );
            setLikeLoading(false);
            onUpdateCommentLike(comment.id, comment.likeCount - 1, null);
        } else {
            setLikeLoading(true);
            const likeLog = await $put(
                `/comment/${comment.uuid}/like`,
                {
                    like: true,
                },
                { throwException: 'showMessage' }
            );
            setLikeLoading(false);
            onUpdateCommentLike(comment.id, comment.likeCount + 1, likeLog);
        }
    }, [comment, onUpdateCommentLike]);

    useEffect(() => {
        // && !title只渲染一次
        if (comment.link && comment.site && !title) {
            const search = /video\/([^/#]*)/.exec(comment.link);
            if (search && search[1]) {
                setTitle(true);
                $get(
                    `/course/get-lesson-name-from-video-uuid/${search[1]}`,
                    {},
                    { throwException: true }
                )
                    .then(value => {
                        setTitle(value);
                        setTitleLoading(false);
                    })
                    .catch(e => {
                        setTitleLoading(false);
                    });
            }
        }
    }, [comment, title]);

    // 获取缩略图
    /*const commentPicture = useMemo(() => {
        if (comment && comment.text && comment.text.indexOf('[图片]') !== -1) {
            return comment.content.document.nodes.filter(
                n => n.type === 'image'
            )[0].data.src;
        } else {
            return false;
        }
    }, [comment]);*/

    if (!comment) {
        return null;
    }
    const {
        site,
        type,
        link,
        createdAt,
        content,
        likeCount,
        replyCount,
        selected,
        parent,
        parentId,
        likeLog,
        user,
    } = comment;
    const commentLink =
        site && link
            ? `${convertSiteToHostName(site, process.env.REACT_APP_ENV)}${link}`
            : null;
    const parentLink =
        parent && parent.site && parent.link
            ? `${convertSiteToHostName(
                  parent.site,
                  process.env.REACT_APP_ENV
              )}${parent.link}`
            : null;

    return (
        <div className="page-comment-block">
            <div className="comment-header">
                {type === 'question' && (
                    <span className="type-icon type-icon-question">提问</span>
                )}
                {type === 'note' && (
                    <span className="type-icon type-icon-note">笔记</span>
                )}
                {type === 'question-reply' && (
                    <span className="type-icon type-icon-reply">回答</span>
                )}
                {!(
                    type === 'question-reply' ||
                    type === 'question' ||
                    type === 'note'
                ) && <span className="type-icon type-icon-other">其他</span>}
                {commentLink && (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                        }}
                    >
                        &nbsp;&nbsp;&nbsp;&nbsp;来自&nbsp;&nbsp;
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={commentLink}
                        >
                            <Spin spinning={titleLoading}>
                                {title || '评论链接'}
                            </Spin>
                        </a>
                    </div>
                )}
                {selected && (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            marginLeft: 36,
                            fontSize: 14,
                        }}
                    >
                        <img
                            width={'18px'}
                            height={'18px'}
                            src={
                                'https://staticcdn.boyuai.com/materials/2020/04/01/G5PLXPSO0qRqR6Cj_t2_M.png!png'
                            }
                            alt={''}
                        />
                        &nbsp;&nbsp;精华
                    </div>
                )}
                <div className="space" />
            </div>
            <div className="comment-body">
                {!!parentId && (
                    <CiteComment comment={parent} commentLink={parentLink} />
                )}
                {content ? (
                    <PreviewEditor
                        value={content}
                        maskHeight={120}
                        foldHeight={120}
                    />
                ) : (
                    '**评论已删除**'
                )}
            </div>
            <div className="comment-footer">
                <div className="counts">
                    <span
                        className={'pointer'}
                        style={isLike && likeLog ? { color: '#2770F3' } : null}
                        onClick={
                            isLike && !likeLoading
                                ? () => onClickLike()
                                : () => message.error('不能赞自己的评论')
                        }
                    >
                        <img
                            src={isLike && likeLog ? ILikeToCancel : ILike}
                            alt="赞"
                        />
                        {likeCount}
                    </span>

                    <span
                        className="pointer"
                        onClick={() => window.open(commentLink)}
                    >
                        <img src={IReply} alt="回复" />
                        {replyCount}
                    </span>
                </div>
                <CopyToClipboard
                    text={commentLink}
                    onCopy={() => message.success('评论链接已复制到剪贴板')}
                >
                    <div className="link">
                        <button data-is-like={false}>
                            <img src={linkIcon} alt="link" />
                            复制链接
                        </button>
                    </div>
                </CopyToClipboard>
                <div className="space" />
                <div className="author">
                    {!hideUser ? <CommentUserInfo user={user} /> : null}
                </div>
                {isLike && (
                    <div className="time">
                        {likeLog
                            ? likeLog.createdAt
                                ? moment(likeLog.createdAt).format(
                                      'YYYY-MM-DD 赞过'
                                  )
                                : '未知时间 赞过'
                            : '您已取消点赞'}
                    </div>
                )}
                {!isLike && (
                    <div className="time">
                        {createdAt
                            ? moment(createdAt).format('YYYY-MM-DD HH:mm')
                            : '未知时间'}
                    </div>
                )}
                {!isLike && (
                    <div className="delete">
                        <Popconfirm
                            onConfirm={deleteComment}
                            placement={'topLeft'}
                            title={
                                <div style={{ width: 200 }}>
                                    确定要删除吗，删除后不可恢复
                                </div>
                            }
                        >
                            删除
                        </Popconfirm>
                    </div>
                )}
            </div>
        </div>
    );
}
