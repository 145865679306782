import React from 'react';
const TITLE_STYLE = { fontSize: 16, fontWeight: 'bold', marginTop: 16 };
export default function Explanation() {
    return (
        <div style={{ position: 'relative', fontSize: 12, color: '#53759B' }}>
            <div style={{ position: 'absolute', top: -40, right: -60 }}>
                <img
                    src="https://staticcdn.boyuai.com/materials/2020/03/02/RWrxq80k3qB60iZJn_4zE.png!png"
                    alt=""
                    width={'300px'}
                />
            </div>
            <div>
                <div style={TITLE_STYLE}>【活动名称】</div>
                伯禹AI打卡挑战（618限时活动）
                <div style={TITLE_STYLE}>【活动时间】</div>
                即日起至2020年6月25日23:59。
                <div style={TITLE_STYLE}>【活动对象】</div>
                伯禹学习平台所有用户。
                <div style={TITLE_STYLE}>【活动规则】</div>
                若用户成功邀请1个活跃新用户（或3个新用户），额外增加交大ACM班《机器学习》(基础）课程60天的免费使用权限，同时可开启任意一门未完全解锁课程的每日登录解锁知识点权限。
                <br />
                其中：
                <br />
                活跃用户定义：完成3个及以上的知识点学习(视频+quiz)；
                <br />
                邀请用户人数自本次活动开启之时开始计算，历史邀请用户不计入本次活动；
                <br />
                新用户通过老用户分享的学习海报扫码注册即为邀请成功；
                <br />
                每邀请一位新用户，老用户可得1200雨滴奖励，新用户最高可得8800雨滴（受邀+完善账户信息）
                <br />
                用户在同一时间内，只可参与一项打卡活动，已参与第1-2期活动且每日登录解锁任务期尚未结束的用户，暂时无法参与本次618限时活动。
                <div style={TITLE_STYLE}>{'{每日登录解锁规则}'}</div>
                *
                用户在参与活动最初依据课程引导选择一门感兴趣的课程(选择之后无法再次更改，需要用户谨慎选择)，该门课程开启每日登录解锁权限；
                <br />*
                若该门课程非永久解锁的知识点数量为X，则用户可参与该门课程每日登录解锁任务的时间为X天，解锁的每个知识点都可获得1.25X天的使用权限。
                <br />
                如：A课程有18个非永久解锁的知识点，用户选择A课程参与每日登录解锁任务，并在6月20日达到邀请1个活跃用户（或3个新用户）的要求，则用户可在6月20日至7月7日期间，通过每日登录的方式解锁知识点，解锁的知识点都可获得23天的使用权限（18*1.25=23天，非整数天数取整处理）
                <br />*
                用户在参与每日登录解锁任务期内，每登录一天，即可按课程内的知识点顺序依次解锁下一个非永久解锁的知识点；
                <br />* 若用户连续登录7天，则在第7天解锁两个知识点；
                <br />* 用户不需要每日连续登录；
                <br />*
                活动将持续至6月25日24时，若用户在6月25日24时之前，选择课程参与每日签到解锁任务，则用户在6月25日24时之后，仍可以每日登录解锁知识点，直至X天后任务截止为止；反之，则用户无法参与活动。
            </div>
            <div style={{ textAlign: 'end', marginTop: 8 }}>
                本次活动最终解释权归伯禹人工智能学院所有
            </div>
        </div>
    );
}
