import React, { useCallback, useState } from 'react';
import { remote } from '@boyuai/utils';
import { Button, message } from 'antd';
import AdminTable from './table';
import AdminFormModal from './form-modal';
import { useAllAuthors } from '../../hooks/authorHooks';

const formItems = [
    {
        label: '标题',
        name: 'title',
        type: 'input',
        required: true,
    },
    {
        label: '小标题',
        name: 'miniTitle',
        type: 'input',
        placeholder: '课程视频',
    },
    {
        label: '排序',
        name: 'order',
        type: 'number',
        required: true,
        placeholder: '在整个知识点下所有视频、代码、习题的排序',
    },
    {
        label: '封面图',
        name: 'coverImage',
        type: 'upload',
        // required: true,
        endpoint: '/course/admin/coverImage',
    },
    {
        label: '视频ID',
        name: 'vid',
        type: 'video',
    },
];

function AdminLessonVideos(props) {
    const [visible, setVisible] = useState(false);
    const [editingVideo, setEditingVideo] = useState(null);
    const [allAuthors] = useAllAuthors();

    const onCreate = async values => {
        const data = { ...values };
        const { coverImage, order } = values;
        if (coverImage && coverImage.length > 1) {
            return message.error('只能保留一张封面图');
        }
        if (order === 0) {
            return message.error('order不能为0');
        }
        data.coverImage =
            coverImage && coverImage[0] && coverImage[0].response
                ? coverImage[0].response.url
                : null;
        try {
            await remote.$post(
                `/course/admin/courses/0/lessons/${props.lessonId}/videos`,
                data,
                { throwException: 'showMessage' }
            );
            props.onChange();
            setVisible(false);
        } catch (e) {}
    };

    /*    const onDelete = async id => {
        try {
            await remote.$delete(
                `/course/admin/courses/0/lessons/0/videos/${id}`,
                {},
                { throwException: 'showMessage' }
            );
            props.onChange();
        } catch (e) {}
    };*/
    const onEditConfirm = useCallback(
        async v => {
            const { title, miniTitle, authorId, vid } = v;
            if (editingVideo && editingVideo.id) {
                await remote.$patch(
                    `/course/admin/courses/0/lessons/0/videos/${editingVideo.id}`,
                    { title, miniTitle, authorId, vid },
                    { throwException: 'showMessage' }
                );
                setEditingVideo(null);
                props.onChange();
            }
        },
        [editingVideo, props]
    );

    const columns = [
        {
            title: '标题',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: '小标题',
            dataIndex: 'miniTitle',
            key: 'miniTitle',
            render: text => text || '课程视频',
        },
        {
            title: '作者',
            dataIndex: 'author',
            key: 'author',
            render: author =>
                author ? `${author.name}(id:${author.id})` || author.id : '',
        },
        {
            title: '排序',
            dataIndex: 'order',
            key: 'order',
            sorter: (a, b) => a.order - b.order,
        },
        {
            title: '封面图',
            dataIndex: 'coverImage',
            key: 'coverImage',
            render: coverImage =>
                coverImage && <img src={coverImage} width="100" alt="封面图" />,
        },
        {
            title: '视频ID',
            dataIndex: 'vid',
            key: 'vid',
            render: vid => {
                if (!vid) {
                    return '暂无';
                }
                return (
                    <a
                        href={`https://vod.console.aliyun.com/#/media/video/detail/${vid}/video`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {vid}
                    </a>
                );
            },
        },
        {
            title: '操作',
            dataIndex: 'id',
            key: 'operations',
            render: id => (
                <Button
                    onClick={() =>
                        setEditingVideo(
                            props.datasource.find(item => item.id === id)
                        )
                    }
                    type="danger"
                >
                    编辑
                </Button>
            ),
        },
    ];

    return (
        <div style={{ marginTop: '20px' }}>
            <h3>
                视频列表&nbsp;
                <Button onClick={() => setVisible(true)}>创建视频</Button>
            </h3>
            <AdminTable
                columns={columns}
                datasource={props.datasource}
                hidePagination
            />
            <AdminFormModal
                title="添加视频"
                visible={visible}
                onCancel={() => setVisible(false)}
                onSubmit={onCreate}
                formItems={[
                    {
                        label: '作者',
                        name: 'authorId',
                        type: 'select',
                        // required: true,
                        placeholder: '请选择程视频作者',
                        mode: 'single',
                        datasource: [...allAuthors].map(author => {
                            return {
                                value: author.id,
                                text: author.name,
                                key: author.id,
                            };
                        }),
                    },
                    ...formItems,
                ]}
                formValues={{ title: props.lessonTitle }}
            />
            {editingVideo && (
                <AdminFormModal
                    title="编辑"
                    visible={true}
                    onCancel={() => setEditingVideo(null)}
                    onSubmit={onEditConfirm}
                    formItems={[
                        {
                            label: '作者',
                            name: 'authorId',
                            type: 'select',
                            // required: true,
                            placeholder: '请选择课程视频作者',
                            mode: 'single',
                            datasource: [...allAuthors].map(author => {
                                return {
                                    value: author.id,
                                    text: author.name,
                                    key: author.id,
                                };
                            }),
                        },
                        {
                            label: '标题',
                            name: 'title',
                            type: 'input',
                            required: true,
                        },
                        {
                            label: '小标题',
                            name: 'miniTitle',
                            type: 'input',
                            placeholder: '课程视频',
                        },
                        {
                            label: '视频ID',
                            name: 'vid',
                            type: 'video',
                            // required: true,
                        },
                    ]}
                    formValues={{
                        ...editingVideo,
                        authorId:
                            editingVideo && editingVideo.author
                                ? editingVideo.author.id
                                : null,
                    }}
                />
            )}
        </div>
    );
}

export default AdminLessonVideos;
