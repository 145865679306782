import { useCallback, useEffect, useState } from 'react';
import { remote } from '@boyuai/utils';

export const useGoList = () => {
    const [goList, setGoList] = useState([]);
    const [total, setTotal] = useState(0);
    const fetchGoList = useCallback(async () => {
        const res = await remote.$get(`/go/admin`);
        if (res) {
            const [list, count] = res;
            setTotal(count);
            setGoList(list);
        }
    }, []);
    useEffect(() => {
        fetchGoList();
    }, [fetchGoList]);
    return [goList, total, fetchGoList];
};

export const useUserGoLogs = (uuid, skip, take) => {
    const [goLogs, setGoLogs] = useState([]);
    const [total, setTotal] = useState(0);
    const fetchGoLogs = useCallback(async () => {
        if (uuid) {
            const payload = {
                limit: take,
                offset: skip,
            };
            const res = await remote.$get(`/go/admin/${uuid}`, payload);
            if (res) {
                const [list, count] = res;
                setGoLogs(list);
                setTotal(count);
            }
        }
    }, [uuid, skip, take]);
    useEffect(() => {
        fetchGoLogs();
    }, [fetchGoLogs]);
    return [goLogs, total, fetchGoLogs];
};
