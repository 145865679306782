import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
    Table,
    Card,
    Breadcrumb,
    Button,
    Tooltip,
    Space,
    Tag,
    Form,
} from 'antd';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { UserContext } from '@boyuai/utils/es/context/user-context';
import { AdminSearch } from '@boyuai/admin-kit';

import {
    ENTITY_NAME,
    QuestionDifficultyToCN,
    QuestionTypeToCN,
} from '../config';
import { useModulePath } from '../hooks';
import { fetchList } from '../services';
import { ADMIN_ROOT_PATH } from '../../config';

const SuperAdminList = () => {
    const path = useModulePath();
    const [loading, setLoading] = useState(true);
    const [list, setList] = useState([]);
    const [query, setQuery] = useState({
        offset: 0,
        limit: 9,
        userId: null,
        tagIds: null,
    });
    const [total, setTotal] = useState(0);
    const refreshList = useCallback(() => {
        setLoading(true);
        fetchList({ ...query }).then(data => {
            setList(data[0]);
            setTotal(data[1]);
            setLoading(false);
        });
    }, [query]);
    useEffect(() => {
        refreshList();
    }, [refreshList]);

    const [form] = Form.useForm();
    const onFinish = values => {
        setQuery(prev => ({
            ...prev,
            offset: 0,
            userId: values.user?.id,
            tagIds: (values.tags || []).map(t => t.id),
        }));
    };

    const user = useContext(UserContext).user;

    return (
        <>
            <Breadcrumb style={{ marginBottom: 16 }}>
                <Breadcrumb.Item>
                    <Link to={ADMIN_ROOT_PATH}>管理后台</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>{ENTITY_NAME}列表</Breadcrumb.Item>
            </Breadcrumb>
            <Card>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: 16,
                    }}
                >
                    <Space>
                        <Form form={form} layout="inline" onFinish={onFinish}>
                            {user?.roleAdmin && (
                                <Form.Item name="user">
                                    <AdminSearch
                                        style={{ width: 300 }}
                                        type="user"
                                        placeholder="搜索题目作者"
                                    />
                                </Form.Item>
                            )}
                            <Form.Item name="tags">
                                <AdminSearch
                                    style={{ width: 300 }}
                                    type="quizQuestionTag"
                                    mode="multiple"
                                />
                            </Form.Item>
                            <Form.Item>
                                <Button htmlType="submit">筛选</Button>
                            </Form.Item>
                            <Form.Item>
                                <Button
                                    onClick={() => {
                                        form.resetFields();
                                        setQuery(prev => ({
                                            ...prev,
                                            offset: 0,
                                            userId: null,
                                            tagIds: null,
                                        }));
                                    }}
                                >
                                    重置
                                </Button>
                            </Form.Item>
                        </Form>
                    </Space>
                    <Space>
                        <Link to={`${path}/add`}>
                            <Button type="primary">添加{ENTITY_NAME}</Button>
                        </Link>
                    </Space>
                </div>
                <Table
                    rowKey="id"
                    dataSource={list}
                    loading={loading}
                    pagination={{
                        total,
                        current: query.offset / query.limit + 1,
                        pageSize: query.limit,
                        showQuickJumper: true,
                        showSizeChanger: false,
                        onChange: (page, pageSize) => {
                            setQuery(prev => ({
                                ...prev,
                                offset: (page - 1) * pageSize,
                            }));
                        },
                    }}
                    columns={[
                        {
                            title: '题干',
                            key: 'id',
                            dataIndex: 'title',
                            width: '30%',
                        },
                        {
                            title: '题型',
                            key: 'id',
                            dataIndex: 'type',
                            render: data => QuestionTypeToCN[data],
                        },
                        {
                            title: '作者',
                            key: 'id',
                            dataIndex: 'user',
                            render: user => user?.realName || user?.name,
                        },
                        {
                            title: '难度',
                            key: 'id',
                            dataIndex: 'difficulty',
                            render: value => QuestionDifficultyToCN[value],
                        },
                        {
                            title: '标签',
                            key: 'id',
                            dataIndex: 'tags',
                            render: tags =>
                                tags.map(t => <Tag key={t.id}>{t.title}</Tag>),
                        },
                        {
                            title: '创建时间',
                            key: 'id',
                            dataIndex: 'createdAt',
                            render: data =>
                                moment(data).format('YYYY-MM-DD HH:mm'),
                        },
                        {
                            title: '修改时间',
                            key: 'id',
                            dataIndex: 'updatedAt',
                            render: data =>
                                moment(data).format('YYYY-MM-DD HH:mm'),
                        },
                        {
                            title: '操作',
                            key: 'id',
                            render: (_, record) => {
                                const editDisabled = false;
                                const EditWrapper = props => {
                                    if (editDisabled) {
                                        return (
                                            <Tooltip title="编辑功能开考前 30 分钟截止">
                                                <Link
                                                    to={`${path}/${record.id}`}
                                                >
                                                    {props.children}
                                                </Link>
                                            </Tooltip>
                                        );
                                    }
                                    return (
                                        <Link to={`${path}/${record.id}`}>
                                            {props.children}
                                        </Link>
                                    );
                                };
                                return (
                                    <div>
                                        <EditWrapper>
                                            <Button
                                                type="link"
                                                style={{ paddingLeft: 0 }}
                                            >
                                                {editDisabled ? '查看' : '编辑'}
                                            </Button>
                                        </EditWrapper>
                                    </div>
                                );
                            },
                        },
                    ]}
                />
            </Card>
        </>
    );
};

export default SuperAdminList;
