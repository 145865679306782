import React, { useEffect, useState } from 'react';
import { remote } from '@boyuai/utils';

export default function LessonContent({ lessonId }) {
    const [content, setContent] = useState(null);
    useEffect(() => {
        remote
            .$get(`/course/admin/courses/0/lessons/${lessonId}`)
            .then(lesson => {
                setContent(lesson);
            });
    }, [lessonId]);

    return (
        content && (
            <span>
                <div>视频：{content.videos.length}</div>
                <div>代码：{content.codes.length}</div>
                <div>quiz：{content.quizzes.length}</div>
                <div>jupyter：{content.jupyters.length}</div>
            </span>
        )
    );
}
