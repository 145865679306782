import React, { useCallback, useMemo, useState } from 'react';
import moment from 'moment';
import { $patch } from '@boyuai/utils/es/remote';
import CommonModal from '../activity-components/common-modal';

export default function Modal3({ activity, onActivityChange }) {
    const [visible, setVisible] = useState(true);
    const [loading, setLoading] = useState(false);
    const [updatedAct, setUpdatedAct] = useState(null);
    const sign = useCallback(async () => {
        setLoading(true);
        const result = await $patch(
            `/course/activities/${activity.id}/content`,
            { content: { sign: true } },
            { throwException: 'showMessage' }
        );
        setUpdatedAct(result);
        setLoading(false);
    }, [activity]);
    const finalAct = updatedAct || activity;

    const goToLearn = useCallback(() => {
        if (finalAct.content.signLogs) {
            const latestLogs = finalAct.content.signLogs.filter(log =>
                moment(log.createdAt).isAfter(moment().startOf('day'))
            );
            // 如果有两个，也是跳第一个
            if (
                latestLogs[0] &&
                latestLogs[0].lesson.uuid &&
                latestLogs[0].lesson.courseUUID
            ) {
                window.open(
                    `${process.env.REACT_APP_SUB_DIRECTORY}/course/${latestLogs[0].lesson.courseUUID}/lesson/${latestLogs[0].lesson.uuid}`
                );
            }
        }
    }, [finalAct]);

    const onClickLink = useCallback(item => {
        if (item && item.lesson.uuid && item.lesson.courseUUID) {
            window.open(
                `${process.env.REACT_APP_SUB_DIRECTORY}/course/${item.lesson.courseUUID}/lesson/${item.lesson.uuid}`
            );
        }
        setVisible(false);
    }, []);

    const hasSignedToday = useMemo(() => {
        if (
            finalAct.content.signLogs &&
            finalAct.content.signLogs.find(log =>
                moment(log.createdAt).isAfter(moment().startOf('day'))
            )
        ) {
            return true;
        }
        return false;
    }, [finalAct]);
    return (
        <CommonModal
            visible={visible}
            onCancel={() => {
                setVisible(false);
                if (updatedAct) {
                    onActivityChange(updatedAct);
                }
            }}
            hint={
                updatedAct
                    ? updatedAct.content.signDaysCount % 7 === 0
                        ? `连续签到7天，您今日解锁了两个知识点~`
                        : `您已连续打卡${updatedAct.content.signDaysCount}天，每连续打卡7天可以多解锁1个知识点哦~`
                    : null
            }
            onOk={hasSignedToday ? goToLearn : sign}
            okText={hasSignedToday ? '立刻前往学习' : '领取奖励'}
            buttonVisible={true}
            loading={loading}
            imgSrc={
                'https://staticcdn.boyuai.com/materials/2020/06/17/D8p2Vgife6kU1gaCMpOkQ.png!png'
            }
            title={
                hasSignedToday
                    ? finalAct.content.signLogs &&
                      finalAct.content.signLogs
                          .filter(log =>
                              moment(log.createdAt).isAfter(
                                  moment().startOf('day')
                              )
                          )
                          .map((item, index) => (
                              <div
                                  key={index}
                                  style={{
                                      fontSize: 20,
                                      display: 'flex',
                                      flexDirection: 'row',
                                      alignItems: 'center',
                                  }}
                              >
                                  解锁{' '}
                                  <div
                                      className="sign-log-text"
                                      onClick={() => onClickLink(item)}
                                  >
                                      {item.lesson.title}
                                  </div>
                              </div>
                          ))
                    : '今日已登录，快快领取奖励吧~'
            }
        />
    );
}
