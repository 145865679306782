import React, { Component } from 'react';
import { Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import { UserOutlined } from '@ant-design/icons';
import './course-card.less';

const baseViews = {
    14: 376,
    25: 324,
    26: 172,
    27: 140,
    28: 144,
    16: 180,
};

export default class CourseCard extends Component {
    render() {
        const {
            title,
            id,
            listImage,
            uuid,
            showEnter,
            showPrice,
            course,
            limitedFree,
            isLastLine,
            staticLink,
        } = this.props;
        let footer;
        if (showEnter) {
            if (!staticLink) {
                footer = (
                    <div className="card-footer">
                        <div className="left">
                            <UserOutlined type="user" />
                            {(baseViews[course.id] || 0) + course.viewCount}
                            人看过
                        </div>
                        {limitedFree && (
                            <div className="right">限时免费学习</div>
                        )}
                    </div>
                );
            }
        } else {
            // footer = (
            //     <div className="card-footer">
            //         <div className="left"></div>
            //         <div className="right">敬请期待</div>
            //     </div>
            // );
        }
        const content = (
            <>
                <img alt="cover" src={listImage} className="card-img" />
                <div
                    className="card-content"
                    data-hide-footer={!showEnter && !showPrice}
                    data-show-price={showPrice}
                >
                    <div className="card-title">
                        <Tooltip title={title}>
                            {title.replace('ElitesAI·', '')}
                        </Tooltip>
                    </div>
                    <div className="card-intro">{course.intro}</div>
                    {footer}
                </div>
            </>
        );

        let contentMayWithLink = content;
        if (showEnter) {
            if (staticLink) {
                contentMayWithLink = (
                    <a
                        href={staticLink}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {content}
                    </a>
                );
            } else {
                contentMayWithLink = (
                    <Link to={staticLink || `/course/${uuid}`}>{content}</Link>
                );
            }
        }
        return (
            <div key={id} className="course-card" data-last-line={isLastLine}>
                {showPrice && (
                    <div className="discount-badge">
                        -{100 - course.packageDiscount}%
                    </div>
                )}
                {contentMayWithLink}
            </div>
        );
    }
}
